import React, { useRef, useState, useEffect, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { KassenService } from '../service/KassenService';
import { KundenService } from '../service/KundenService';
import { LieferscheinService} from '../service/LieferscheinService';
import { GlobalState } from './GlobalState';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

function Lieferscheine(props) {
	const [datumsbereich, setDatumsbereich] = useState([new Date(0), new Date(0)]);
	const [selectedKassen, setSelectedKassen] = useState(null);
    const [kassen, setKassen] = useState([]);
    const [lieferscheine, setLieferscheine] = useState([]);
    const [lieferscheinHTML, setLieferscheinHTML] = useState([]);
    const [lieferscheineDisplay, setLieferscheineDisplay] = useState([]);
    const [globalState, setGlobalState] = useContext(GlobalState);
    const [LieferscheinDetailsOffen, setLieferscheinDetailsOffen] = useState(false);
    const [kundenVisible, setKundenVisible] = useState(false);
    const [selectedLieferschein, setSelectedLieferschein] = useState(null);
    const [kunden, setKunden] = useState([]);
    const dt = useRef(null);
	const toastRef = useRef(null);
    const kassenService = new KassenService();
    const kundenService = new KundenService();
    const lieferscheinService = new LieferscheinService();

	useEffect(() => {
		setGlobalState({...globalState, page: 'Lieferscheine'});
		kassenService.getKassen().then(data => setKassen(data));
		onDatumsbereichChange({target: {value: [new Date(), new Date()]}});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
            setLieferscheineDisplay(
                lieferscheine.map(lieferschein =>
                    ({...lieferschein,
                        Datum: new Date(lieferschein.Datum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}),
                        Zeit: new Date(lieferschein.Datum).toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit', second: undefined })
                    })));
        }, [lieferscheine]
    )

    useEffect(() => {
       if (kundenVisible === true) {
           kundenService.getAll().then(data => setKunden(data));
       }
    }, [kundenVisible]
)

    const onDatumsbereichChange = e => {
		setDatumsbereich(e.target.value);
		if(e.target.value !== undefined && e.target.value !== null && e.target.value.length === 2 && e.target.value[0] !== undefined && e.target.value[0] !== null && e.target.value[1] !== undefined && e.target.value[1] !== null && !isNaN(e.target.value[0]) && !isNaN(e.target.value[1])) {
			lieferscheinService.getFromTo(
                e.target.value[0],
                e.target.value[1])
                .then(data => setLieferscheine(data));
		}
	}


	const renderDatumFilter = () => {
		return (
			<Calendar id="datumsbereich"
                className="column-filter"
                value={datumsbereich}
                onChange={onDatumsbereichChange}
                locale="de"
                showIcon={true}
                selectionMode="range"/>
		);
	};

    const renderKassenFilter = () => {
		return (
			<MultiSelect
                className="column-filter"
                value={selectedKassen}
                options={kassen}
                onChange={e => { setSelectedKassen(e.target.value); dt.current.filter(e.target.value, 'KassenID', 'in'); }}
                placeholder="Alle"
                optionLabel="KassenID"
                optionValue="KassenID"
                maxSelectedLabels={3}/>
		);
	};

    const onRowClicked = async e => {
        if (e.originalEvent?.target?.innerText === "Kunde zuweisen") {
            setSelectedLieferschein(e.data);
            return;
        }
		const htmlPromise = lieferscheinService.getLieferscheinHTML(e.data.ID);
        const html = await htmlPromise;
		setLieferscheinHTML(html);
		setLieferscheinDetailsOffen(true);
	}

    const onLieferscheinDetailsHide = () => {
		setLieferscheinDetailsOffen(false);
	}

    const timeFilterFunction = (value, filter) => {
		const [valueHour, valueMinute, valueSecond] = value.split(':').map(str => {const n = Number(str); return isNaN(n) ? undefined : n});
		const valueTime = ((valueHour ?? 0) * 60 + (valueMinute ?? 0)) * 60 + (valueSecond ?? 0);
		const [filter1, filter2] = (filter ?? '').split('-').map(str => str.trim());
		const [filter1Hour, filter1Minute, filter1Second] = (filter1 ?? '').split(':').map(str => {const n = Number(str); return isNaN(n) ? undefined : n});
		const [filter2Hour, filter2Minute, filter2Second] = (filter2 ?? filter1).split(':').map(str => {const n = Number(str); return isNaN(n) ? undefined : n});
		const filter1Time = ((filter1Hour ?? 0) * 60 + (filter1Minute ?? 0)) * 60 + (filter1Second ?? 0);
		const filter2Time = ((filter2Hour ?? 23) * 60 + (filter2Minute ?? 59)) * 60 + (filter2Second ?? 59);
		return filter1Time <= valueTime && valueTime <= filter2Time;
	};

    const datumFilter = renderDatumFilter();
    const kassenFilter = renderKassenFilter();

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button label="Kunde zuweisen" onClick={() => setKundenVisible(true)}/>
            </React.Fragment>
        );
    };

    return(
        <React.Fragment>
            <Toast ref={toastRef}/>
            <div className="grid p-component">
                <div className="col-12">
                    <DataTable ref={dt} responsive className="p-datatable-belege p-datatable-striped tableCursorPointer"
                        filterDisplay="row"
                        dataKey="id" rowHover value={lieferscheineDisplay} paginator rows={20} emptyMessage="keine Lieferscheine gefunden"
                        currentPageReportTemplate='({first} von {totalRecords})'
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[20,30,40]} scrollable scrollHeight="60vh"
                        onRowClick={onRowClicked} >
                        <Column field="Nummer" header="LS-Nr." filter sortable style={{maxWidth: "8em", width: "8em"}} showFilterMenu={false}/>
                        <Column field="Kunde" header="Kunde" filter sortable showFilterMenu={false}/>
                        <Column field="Datum" header="Datum" filter sortable filterElement={datumFilter} showFilterMenu={false}/>
                        <Column field="Zeit" header="Zeit" filter sortable style={{maxWidth: "9em", width: "9em"}} showFilterMenu={false}/>
                        <Column field="Brutto" header="Brutto" filter sortable style={{maxWidth: "10em", width: "10em"}} showFilterMenu={false}/>
                        <Column field="KassenID" header="Standort" filter sortable filterElement={kassenFilter} showFilterMenu={false}/>
                        <Column 
                            header="Aktionen" body={actionBodyTemplate} headerStyle={{maxWidth: "10em", width: '10em', textAlign: 'center'}}
                            bodyStyle={{textAlign: 'center', overflow: 'visible'}}
                            showFilterMenu={false}
                            />
                    </DataTable>
                </div>
            </div>
            <Dialog header={
                <React.Fragment><span style={{fontSize: '1.25em'}}>Lieferschein Details</span>
                </React.Fragment>
            }
            visible={LieferscheinDetailsOffen} onHide={onLieferscheinDetailsHide}>
            <div style={{fontFamily: 'monospace'}} dangerouslySetInnerHTML={{__html: lieferscheinHTML}}></div>
            </Dialog>
            <Dialog 
                visible={kundenVisible}
                onHide={() => {
                    setKundenVisible(false);
                    setSelectedLieferschein(null);
                }}
            >
                <div className="col-12">
					<DataTable value={kunden} className="p-datatable-striped" rowHover paginator rows={20} rowsPerPageOptions={[20,30,40]} scrollable scrollHeight="60vh"
                        selectionMode='single'
                        onRowClick={(e) => {
                            lieferscheinService.setKunde(selectedLieferschein, e.data.Kundennummer)
                            lieferscheinService.getFromTo(
                                datumsbereich[0],
                                datumsbereich[1])
                                .then(data => setLieferscheine(data));
                            setKundenVisible(false);
                        }}
                    >
						<Column field="Vorname" header="Vorname" sortable filter/>
						<Column field="Nachname" header="Nachname" sortable filter/>
						<Column field="EMail" header="E-Mail" sortable filter/>
						<Column field="Telefon" header="Telefonnummer" sortable filter/>
						<Column field="Handy1" header="Handynummer" sortable filter/>
						<Column field="Adresse" header="Adresse" sortable filter/>
						<Column field="Ort" header="Ort" sortable filter/>
					</DataTable>
				</div>
            </Dialog>
        </React.Fragment>

    );
}

export default Lieferscheine;