import React, { useRef, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { GlobalState } from "./GlobalState";
import { ProduzentenService } from "../service/ProduzentenService";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { AutoComplete } from 'primereact/autocomplete'

export default function Produzenten() {
	const produzentenService = new ProduzentenService();
	const history = useHistory();
	const dt = useRef(null);

	const [globalState, setGlobalState] = useContext(GlobalState);
	const [produzenten, setProduzenten] = useState([]);
	const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
	const [selectedProduzent, setSelectedProduzent] = useState();
	const [otherProduzenten, setOtherProduzenten] = useState([]);
	const [selectedOtherProduzent, setSelectedOtherProduzent] = useState();
	const [selectedName, setSelectedName] = useState('');
	const [filteredNamen, setFilteredNamen] = useState([]);
	const [selectedEmail, setSelectedEmail] = useState('');
	const [filteredEmail, setFilteredEmail] = useState([]);
	const [selectedTelNr, setSelectedTelNr] = useState('');
	const [filteredTelNr, setFilteredTelNr] = useState([]);
	const [selectedHandy1, setSelectedHandy1] = useState('');
	const [filteredHandy1, setFilteredHandy1] = useState([]);
	const [selectedOrt, setSelectedOrt] = useState('');
	const [filteredOrte, setFilteredOrte] = useState([]);

	useEffect(() => {
		setGlobalState({...globalState, page: 'Produzenten'});
		produzentenService.getAll().then(data => setProduzenten(data));
	}, []);

	const createProduzent = () => history.push('/produzent/neu');

	const editProduzent = rowData => history.push("/produzent/bearbeiten/" + rowData.ID);

	const deleteProduzent = rowData => {
		setSelectedProduzent(rowData);
		setOtherProduzenten(produzenten.filter(p => p.ID !== rowData.ID));
		setSelectedOtherProduzent()
		setDeleteDialogVisible(true);
	};

	const onDeleteConfirmed = () => {
		produzentenService.delete(selectedProduzent.ID, selectedOtherProduzent.ID).then(() => {
			produzentenService.getAll().then(data => setProduzenten(data));
			setDeleteDialogVisible(false);
		});
	}

	function onlyUnique(value, index, self) {
		return self.indexOf(value) === index;
	}

	const namenFilter = () => {
        return (
            <AutoComplete dropdown forceSelection value={selectedName} suggestions={filteredNamen} completeMethod={filterNamen}
            placeholder="Name" minLength={1}
			onChange={(e) => {dt.current.filter(e.value, 'Name', 'contains'); setSelectedName(e.value)}}
            inputStyle={{width: '100%'}}
            style={{width: '100%'}} />
        );
    }

	const filterNamen = (event) => {
        let results = produzenten.filter((lieferanten) => {
            return lieferanten.Name.toLowerCase().includes(event.query.toLowerCase());
        });
        results = results.map(function(lieferant) {
           return lieferant.Name; 
        });

		results = results.filter(onlyUnique);

        setFilteredNamen(results);
    }

	const emailFilter = () => {
        return (
            <AutoComplete dropdown forceSelection value={selectedEmail} suggestions={filteredEmail} completeMethod={filterEmail}
            placeholder="Email" minLength={1}
			onChange={(e) => {dt.current.filter(e.value, 'Email', 'contains'); setSelectedEmail(e.value)}}
            inputStyle={{width: '100%'}}
            style={{width: '100%'}} />
        );
    }

	const filterEmail = (event) => {
        let results = produzenten.filter((lieferant) => {
            return lieferant.EMail?.toLowerCase().includes(event.query.toLowerCase());
        });
        results = results.map(function(lieferant) {
           return lieferant.EMail; 
        });

		results = results.filter(onlyUnique);

        setFilteredEmail(results);
    }

	const telFilter = () => {
		return (
            <AutoComplete dropdown forceSelection value={selectedTelNr} suggestions={filteredTelNr} completeMethod={filterTelNr}
            placeholder="TelNr" minLength={1}
			onChange={(e) => {dt.current.filter(e.value, 'TelNr', 'contains'); setSelectedTelNr(e.value)}}
            inputStyle={{width: '100%'}}
            style={{width: '100%'}} />
        );
	}

	const filterTelNr = (event) => {
        let results = produzenten.filter((lieferant) => {
            return lieferant.TelNr?.toLowerCase().includes(event.query.toLowerCase());
        });
        results = results.map(function(lieferant) {
			return lieferant.TelNr; 
        });

		results = results.filter(onlyUnique);

        setFilteredTelNr(results);
    }

	const handy1Filter = () => {
		return (
            <AutoComplete dropdown forceSelection value={selectedHandy1} suggestions={filteredHandy1} completeMethod={filterHandy1}
            placeholder="Handy" minLength={1}
			onChange={(e) => {dt.current.filter(e.value, 'Handy1', 'contains');
			setSelectedHandy1(e.value)}}
            inputStyle={{width: '100%'}}
            style={{width: '100%'}} />
        );
	}

	const filterHandy1 = (event) => {
        let results = produzenten.filter((lieferant) => {
            return lieferant.Handy1?.toLowerCase().includes(event.query.toLowerCase());
        });
        results = results.map(function(lieferant) {
           return lieferant.Handy1; 
        });

		results = results.filter(onlyUnique);

        setFilteredHandy1(results);
    }

	const ortFilter = () => {
		return (
            <AutoComplete dropdown forceSelection value={selectedOrt} suggestions={filteredOrte} completeMethod={filterOrt}
            placeholder="Ort" minLength={1}
			onChange={(e) => {dt.current.filter(e.value, 'Ort', 'contains');
			setSelectedOrt(e.value)}}
            inputStyle={{width: '100%'}}
            style={{width: '100%'}} />
        );
	}

	const filterOrt = (event) => {
        let results = produzenten.filter((lieferant) => {
            return lieferant.Ort?.toLowerCase().includes(event.query.toLowerCase());
        });
        results = results.map(function(lieferant) {
			if (!results.includes(lieferant.Ort))
           		return lieferant.Ort; 
        });

		results = results.filter(onlyUnique);

        setFilteredOrte(results);
    }

	return (
		<React.Fragment>
			<div className="p-component grid">
				<div className="col-12">
					<Button label="neuer Produzent" icon="pi pi-plus-circle" onClick={createProduzent}/>
				</div>
				<div className="col-12">
					<DataTable
						ref={dt}
					    filterDisplay="row"
						value={produzenten} className="p-datatable-striped"
						rowHover paginator rows={8} rowsPerPageOptions={[8, 20,30,40]}
						>
						<Column field="Name" header="Name"
							sortable filter
							filterElement={namenFilter}
							showFilterMenu={false}/>
						<Column
							field="Ort" header="Ort" sortable filter
							filterElement={ortFilter}
							showFilterMenu={false}/>
						<Column
							field="TelNr" header="Telefon" sortable
							filter filterMatchMode="contains"
							filterElement={telFilter}
							showFilterMenu={false}/>
						<Column
							field="Handy1" header="Handy" sortable
							filterElement={handy1Filter}
							filter filterMatchMode="contains" showFilterMenu={false}/>
						<Column field="EMail" header="E-Mail"
							showFilterMenu={false}
							body={(row) => {
								var mailto = "mailto:" + row.EMail;
								return <a href={mailto}>{row.EMail}</a>;
							}}
							sortable filter
							filterElement={emailFilter}
							/>
						<Column
							body={rowData => <React.Fragment><Button tooltipOptions={{position: 'bottom'}}
							tooltip="Produzent bearbeiten" icon="pi pi-pencil" className="p-button-secondary mr-1"
							onClick={() => editProduzent(rowData)}/><Button tooltipOptions={{position: 'bottom'}}
							tooltip="Produzent löschen" icon="pi pi-trash" className="p-button-danger mr-1"
							onClick={() => deleteProduzent(rowData)}/></React.Fragment>}
							headerStyle={{width: '10em', maxWidth: '10em'}}
							bodyStyle={{width: '10em', maxWidth: '10em', textAlign: 'center'}}
							showFilterMenu={false}
							/>
					</DataTable>
				</div>
			</div>
			<Dialog visible={deleteDialogVisible} onHide={() => setDeleteDialogVisible(false)} header={'Produzent "' + selectedProduzent?.Name + '" Löschen'} footer={<Button label="Bestätigen" icon="pi pi-trash" disabled={selectedOtherProduzent === undefined || selectedOtherProduzent === null} onClick={onDeleteConfirmed}/>}>
				Bitte geben Sie an an welchen anderen Produzent<br/>
				die Produkte übertragen werden sollen.<br/>
				<br/>
				<Dropdown value={selectedOtherProduzent} options={otherProduzenten} optionLabel="Name" placeholder="Produzent" onChange={e => setSelectedOtherProduzent(e.target.value)}/>
			</Dialog>
		</React.Fragment>
	);
}