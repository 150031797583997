export const states = [
{"country":"AD","code":"AD-07","name":"Andorra la Vella"},
{"country":"AD","code":"AD-02","name":"Canillo"},
{"country":"AD","code":"AD-03","name":"Encamp"},
{"country":"AD","code":"AD-08","name":"Escaldes-Engordany"},
{"country":"AD","code":"AD-04","name":"La Massana"},
{"country":"AD","code":"AD-05","name":"Ordino"},
{"country":"AD","code":"AD-06","name":"Sant Julia de Loria"},
{"country":"AE","code":"AE-AJ","name":"'Ajman"},
{"country":"AE","code":"AE-AZ","name":"Abu Zaby"},
{"country":"AE","code":"AE-FU","name":"Al Fujayrah"},
{"country":"AE","code":"AE-SH","name":"Ash Shariqah"},
{"country":"AE","code":"AE-DU","name":"Dubayy"},
{"country":"AE","code":"AE-RK","name":"Ra's al Khaymah"},
{"country":"AE","code":"AE-UQ","name":"Umm al Qaywayn"},
{"country":"AF","code":"AF-BDS","name":"Badakhshan"},
{"country":"AF","code":"AF-BDG","name":"Badghis"},
{"country":"AF","code":"AF-BGL","name":"Baghlan"},
{"country":"AF","code":"AF-BAL","name":"Balkh"},
{"country":"AF","code":"AF-BAM","name":"Bamyan"},
{"country":"AF","code":"AF-DAY","name":"Daykundi"},
{"country":"AF","code":"AF-FRA","name":"Farah"},
{"country":"AF","code":"AF-FYB","name":"Faryab"},
{"country":"AF","code":"AF-GHA","name":"Ghazni"},
{"country":"AF","code":"AF-GHO","name":"Ghor"},
{"country":"AF","code":"AF-HEL","name":"Helmand"},
{"country":"AF","code":"AF-HER","name":"Herat"},
{"country":"AF","code":"AF-JOW","name":"Jowzjan"},
{"country":"AF","code":"AF-KAB","name":"Kabul"},
{"country":"AF","code":"AF-KAN","name":"Kandahar"},
{"country":"AF","code":"AF-KAP","name":"Kapisa"},
{"country":"AF","code":"AF-KHO","name":"Khost"},
{"country":"AF","code":"AF-KDZ","name":"Kunduz"},
{"country":"AF","code":"AF-LAG","name":"Laghman"},
{"country":"AF","code":"AF-LOG","name":"Logar"},
{"country":"AF","code":"AF-NAN","name":"Nangarhar"},
{"country":"AF","code":"AF-NIM","name":"Nimroz"},
{"country":"AF","code":"AF-PIA","name":"Paktiya"},
{"country":"AF","code":"AF-PAR","name":"Parwan"},
{"country":"AF","code":"AF-SAR","name":"Sar-e Pul"},
{"country":"AF","code":"AF-TAK","name":"Takhar"},
{"country":"AF","code":"AF-URU","name":"Uruzgan"},
{"country":"AG","code":"AG-11","name":"Redonda"},
{"country":"AG","code":"AG-03","name":"Saint George"},
{"country":"AG","code":"AG-04","name":"Saint John"},
{"country":"AG","code":"AG-06","name":"Saint Paul"},
{"country":"AG","code":"AG-07","name":"Saint Peter"},
{"country":"AG","code":"AG-08","name":"Saint Philip"},
{"country":"AI","code":"AI-XX-1","name":"Anguilla"},
{"country":"AL","code":"AL-01","name":"Berat"},
{"country":"AL","code":"AL-09","name":"Diber"},
{"country":"AL","code":"AL-02","name":"Durres"},
{"country":"AL","code":"AL-03","name":"Elbasan"},
{"country":"AL","code":"AL-04","name":"Fier"},
{"country":"AL","code":"AL-05","name":"Gjirokaster"},
{"country":"AL","code":"AL-06","name":"Korce"},
{"country":"AL","code":"AL-07","name":"Kukes"},
{"country":"AL","code":"AL-08","name":"Lezhe"},
{"country":"AL","code":"AL-10","name":"Shkoder"},
{"country":"AL","code":"AL-11","name":"Tirane"},
{"country":"AL","code":"AL-12","name":"Vlore"},
{"country":"AM","code":"AM-AG","name":"Aragacotn"},
{"country":"AM","code":"AM-AR","name":"Ararat"},
{"country":"AM","code":"AM-AV","name":"Armavir"},
{"country":"AM","code":"AM-ER","name":"Erevan"},
{"country":"AM","code":"AM-GR","name":"Gegark'unik'"},
{"country":"AM","code":"AM-KT","name":"Kotayk'"},
{"country":"AM","code":"AM-LO","name":"Lori"},
{"country":"AM","code":"AM-SH","name":"Sirak"},
{"country":"AM","code":"AM-SU","name":"Syunik'"},
{"country":"AM","code":"AM-TV","name":"Tavus"},
{"country":"AM","code":"AM-VD","name":"Vayoc Jor"},
{"country":"AO","code":"AO-BGO","name":"Bengo"},
{"country":"AO","code":"AO-BGU","name":"Benguela"},
{"country":"AO","code":"AO-BIE","name":"Bie"},
{"country":"AO","code":"AO-CAB","name":"Cabinda"},
{"country":"AO","code":"AO-CCU","name":"Cuando Cubango"},
{"country":"AO","code":"AO-CNO","name":"Cuanza-Norte"},
{"country":"AO","code":"AO-CUS","name":"Cuanza-Sul"},
{"country":"AO","code":"AO-CNN","name":"Cunene"},
{"country":"AO","code":"AO-HUA","name":"Huambo"},
{"country":"AO","code":"AO-HUI","name":"Huila"},
{"country":"AO","code":"AO-LUA","name":"Luanda"},
{"country":"AO","code":"AO-LNO","name":"Lunda-Norte"},
{"country":"AO","code":"AO-LSU","name":"Lunda-Sul"},
{"country":"AO","code":"AO-MAL","name":"Malange"},
{"country":"AO","code":"AO-MOX","name":"Moxico"},
{"country":"AO","code":"AO-NAM","name":"Namibe"},
{"country":"AO","code":"AO-UIG","name":"Uige"},
{"country":"AO","code":"AO-ZAI","name":"Zaire"},
{"country":"AQ","code":"AQ-XX-1","name":"Antarctica"},
{"country":"AR","code":"AR-B","name":"Buenos Aires"},
{"country":"AR","code":"AR-K","name":"Catamarca"},
{"country":"AR","code":"AR-H","name":"Chaco"},
{"country":"AR","code":"AR-U","name":"Chubut"},
{"country":"AR","code":"AR-C","name":"Ciudad Autonoma de Buenos Aires"},
{"country":"AR","code":"AR-X","name":"Cordoba"},
{"country":"AR","code":"AR-W","name":"Corrientes"},
{"country":"AR","code":"AR-E","name":"Entre Rios"},
{"country":"AR","code":"AR-P","name":"Formosa"},
{"country":"AR","code":"AR-Y","name":"Jujuy"},
{"country":"AR","code":"AR-L","name":"La Pampa"},
{"country":"AR","code":"AR-F","name":"La Rioja"},
{"country":"AR","code":"AR-M","name":"Mendoza"},
{"country":"AR","code":"AR-N","name":"Misiones"},
{"country":"AR","code":"AR-Q","name":"Neuquen"},
{"country":"AR","code":"AR-R","name":"Rio Negro"},
{"country":"AR","code":"AR-A","name":"Salta"},
{"country":"AR","code":"AR-J","name":"San Juan"},
{"country":"AR","code":"AR-D","name":"San Luis"},
{"country":"AR","code":"AR-Z","name":"Santa Cruz"},
{"country":"AR","code":"AR-S","name":"Santa Fe"},
{"country":"AR","code":"AR-G","name":"Santiago del Estero"},
{"country":"AR","code":"AR-V","name":"Tierra del Fuego"},
{"country":"AR","code":"AR-T","name":"Tucuman"},
{"country":"AS","code":"AS-XX-1","name":"Eastern District"},
{"country":"AS","code":"AS-XX-2","name":"Western District"},
{"country":"AT","code":"AT-1","name":"Burgenland"},
{"country":"AT","code":"AT-2","name":"Kärnten"},
{"country":"AT","code":"AT-3","name":"Niederösterreich"},
{"country":"AT","code":"AT-4","name":"Oberösterreich"},
{"country":"AT","code":"AT-5","name":"Salzburg"},
{"country":"AT","code":"AT-6","name":"Steiermark"},
{"country":"AT","code":"AT-7","name":"Tirol"},
{"country":"AT","code":"AT-8","name":"Vorarlberg"},
{"country":"AT","code":"AT-9","name":"Wien"},
{"country":"AU","code":"AU-ACT","name":"Australian Capital Territory"},
{"country":"AU","code":"AU-NSW","name":"New South Wales"},
{"country":"AU","code":"AU-NT","name":"Northern Territory"},
{"country":"AU","code":"AU-QLD","name":"Queensland"},
{"country":"AU","code":"AU-SA","name":"South Australia"},
{"country":"AU","code":"AU-TAS","name":"Tasmania"},
{"country":"AU","code":"AU-VIC","name":"Victoria"},
{"country":"AU","code":"AU-WA","name":"Western Australia"},
{"country":"AW","code":"AW-XX-1","name":"Aruba"},
{"country":"AX","code":"AX-XX-1","name":"Eckeroe"},
{"country":"AX","code":"AX-XX-2","name":"Finstroem"},
{"country":"AX","code":"AX-XX-3","name":"Hammarland"},
{"country":"AX","code":"AX-XX-4","name":"Jomala"},
{"country":"AX","code":"AX-XX-5","name":"Lemland"},
{"country":"AX","code":"AX-XX-6","name":"Mariehamn"},
{"country":"AX","code":"AX-XX-7","name":"Saltvik"},
{"country":"AX","code":"AX-XX-8","name":"Sund"},
{"country":"AZ","code":"AZ-ABS","name":"Abseron"},
{"country":"AZ","code":"AZ-AGC","name":"Agcabadi"},
{"country":"AZ","code":"AZ-AGU","name":"Agsu"},
{"country":"AZ","code":"AZ-AST","name":"Astara"},
{"country":"AZ","code":"AZ-BA","name":"Baki"},
{"country":"AZ","code":"AZ-BAL","name":"Balakan"},
{"country":"AZ","code":"AZ-BAR","name":"Barda"},
{"country":"AZ","code":"AZ-BEY","name":"Beylaqan"},
{"country":"AZ","code":"AZ-BIL","name":"Bilasuvar"},
{"country":"AZ","code":"AZ-CAL","name":"Calilabad"},
{"country":"AZ","code":"AZ-FUZ","name":"Fuzuli"},
{"country":"AZ","code":"AZ-GAD","name":"Gadabay"},
{"country":"AZ","code":"AZ-GA","name":"Ganca"},
{"country":"AZ","code":"AZ-GOR","name":"Goranboy"},
{"country":"AZ","code":"AZ-GOY","name":"Goycay"},
{"country":"AZ","code":"AZ-GYG","name":"Goygol"},
{"country":"AZ","code":"AZ-IMI","name":"Imisli"},
{"country":"AZ","code":"AZ-ISM","name":"Ismayilli"},
{"country":"AZ","code":"AZ-KUR","name":"Kurdamir"},
{"country":"AZ","code":"AZ-LA","name":"Lankaran"},
{"country":"AZ","code":"AZ-MAS","name":"Masalli"},
{"country":"AZ","code":"AZ-MI","name":"Mingacevir"},
{"country":"AZ","code":"AZ-NA","name":"Naftalan"},
{"country":"AZ","code":"AZ-NX","name":"Naxcivan"},
{"country":"AZ","code":"AZ-NEF","name":"Neftcala"},
{"country":"AZ","code":"AZ-OGU","name":"Oguz"},
{"country":"AZ","code":"AZ-QAB","name":"Qabala"},
{"country":"AZ","code":"AZ-QAX","name":"Qax"},
{"country":"AZ","code":"AZ-QAZ","name":"Qazax"},
{"country":"AZ","code":"AZ-QBA","name":"Quba"},
{"country":"AZ","code":"AZ-QUS","name":"Qusar"},
{"country":"AZ","code":"AZ-SAT","name":"Saatli"},
{"country":"AZ","code":"AZ-SAB","name":"Sabirabad"},
{"country":"AZ","code":"AZ-SAK","name":"Saki"},
{"country":"AZ","code":"AZ-SAL","name":"Salyan"},
{"country":"AZ","code":"AZ-SMI","name":"Samaxi"},
{"country":"AZ","code":"AZ-SKR","name":"Samkir"},
{"country":"AZ","code":"AZ-SMX","name":"Samux"},
{"country":"AZ","code":"AZ-SR","name":"Sirvan"},
{"country":"AZ","code":"AZ-SM","name":"Sumqayit"},
{"country":"AZ","code":"AZ-TAR","name":"Tartar"},
{"country":"AZ","code":"AZ-UCA","name":"Ucar"},
{"country":"AZ","code":"AZ-XAC","name":"Xacmaz"},
{"country":"AZ","code":"AZ-XVD","name":"Xocavand"},
{"country":"AZ","code":"AZ-YAR","name":"Yardimli"},
{"country":"AZ","code":"AZ-YEV","name":"Yevlax"},
{"country":"AZ","code":"AZ-ZAQ","name":"Zaqatala"},
{"country":"AZ","code":"AZ-ZAR","name":"Zardab"},
{"country":"BA","code":"BA-BRC","name":"Brcko distrikt"},
{"country":"BA","code":"BA-BIH","name":"Federacija Bosne i Hercegovine"},
{"country":"BA","code":"BA-SRP","name":"Republika Srpska"},
{"country":"BB","code":"BB-01","name":"Christ Church"},
{"country":"BB","code":"BB-02","name":"Saint Andrew"},
{"country":"BB","code":"BB-03","name":"Saint George"},
{"country":"BB","code":"BB-04","name":"Saint James"},
{"country":"BB","code":"BB-05","name":"Saint John"},
{"country":"BB","code":"BB-07","name":"Saint Lucy"},
{"country":"BB","code":"BB-08","name":"Saint Michael"},
{"country":"BB","code":"BB-09","name":"Saint Peter"},
{"country":"BB","code":"BB-10","name":"Saint Philip"},
{"country":"BB","code":"BB-11","name":"Saint Thomas"},
{"country":"BD","code":"BD-A","name":"Barishal"},
{"country":"BD","code":"BD-B","name":"Chattogram"},
{"country":"BD","code":"BD-C","name":"Dhaka"},
{"country":"BD","code":"BD-D","name":"Khulna"},
{"country":"BD","code":"BD-E","name":"Rajshahi"},
{"country":"BD","code":"BD-F","name":"Rangpur"},
{"country":"BD","code":"BD-G","name":"Sylhet"},
{"country":"BE","code":"BE-VAN","name":"Antwerpen"},
{"country":"BE","code":"BE-WBR","name":"Brabant wallon"},
{"country":"BE","code":"BE-BRU","name":"Brussels Hoofdstedelijk Gewest"},
{"country":"BE","code":"BE-WHT","name":"Hainaut"},
{"country":"BE","code":"BE-WLG","name":"Liege"},
{"country":"BE","code":"BE-VLI","name":"Limburg"},
{"country":"BE","code":"BE-WLX","name":"Luxembourg"},
{"country":"BE","code":"BE-WNA","name":"Namur"},
{"country":"BE","code":"BE-VOV","name":"Oost-Vlaanderen"},
{"country":"BE","code":"BE-VBR","name":"Vlaams-Brabant"},
{"country":"BE","code":"BE-VWV","name":"West-Vlaanderen"},
{"country":"BF","code":"BF-BAM","name":"Bam"},
{"country":"BF","code":"BF-BAZ","name":"Bazega"},
{"country":"BF","code":"BF-BLG","name":"Boulgou"},
{"country":"BF","code":"BF-BLK","name":"Boulkiemde"},
{"country":"BF","code":"BF-COM","name":"Comoe"},
{"country":"BF","code":"BF-GAN","name":"Ganzourgou"},
{"country":"BF","code":"BF-GNA","name":"Gnagna"},
{"country":"BF","code":"BF-GOU","name":"Gourma"},
{"country":"BF","code":"BF-HOU","name":"Houet"},
{"country":"BF","code":"BF-IOB","name":"Ioba"},
{"country":"BF","code":"BF-KAD","name":"Kadiogo"},
{"country":"BF","code":"BF-KEN","name":"Kenedougou"},
{"country":"BF","code":"BF-KMP","name":"Kompienga"},
{"country":"BF","code":"BF-KOS","name":"Kossi"},
{"country":"BF","code":"BF-KOT","name":"Kouritenga"},
{"country":"BF","code":"BF-KOW","name":"Kourweogo"},
{"country":"BF","code":"BF-LER","name":"Leraba"},
{"country":"BF","code":"BF-LOR","name":"Loroum"},
{"country":"BF","code":"BF-MOU","name":"Mouhoun"},
{"country":"BF","code":"BF-NAO","name":"Nahouri"},
{"country":"BF","code":"BF-NAM","name":"Namentenga"},
{"country":"BF","code":"BF-NAY","name":"Nayala"},
{"country":"BF","code":"BF-OUB","name":"Oubritenga"},
{"country":"BF","code":"BF-OUD","name":"Oudalan"},
{"country":"BF","code":"BF-PAS","name":"Passore"},
{"country":"BF","code":"BF-PON","name":"Poni"},
{"country":"BF","code":"BF-SNG","name":"Sanguie"},
{"country":"BF","code":"BF-SMT","name":"Sanmatenga"},
{"country":"BF","code":"BF-SEN","name":"Seno"},
{"country":"BF","code":"BF-SIS","name":"Sissili"},
{"country":"BF","code":"BF-SOM","name":"Soum"},
{"country":"BF","code":"BF-SOR","name":"Sourou"},
{"country":"BF","code":"BF-TAP","name":"Tapoa"},
{"country":"BF","code":"BF-TUI","name":"Tuy"},
{"country":"BF","code":"BF-YAT","name":"Yatenga"},
{"country":"BF","code":"BF-ZIR","name":"Ziro"},
{"country":"BF","code":"BF-ZON","name":"Zondoma"},
{"country":"BF","code":"BF-ZOU","name":"Zoundweogo"},
{"country":"BG","code":"BG-01","name":"Blagoevgrad"},
{"country":"BG","code":"BG-02","name":"Burgas"},
{"country":"BG","code":"BG-08","name":"Dobrich"},
{"country":"BG","code":"BG-07","name":"Gabrovo"},
{"country":"BG","code":"BG-26","name":"Haskovo"},
{"country":"BG","code":"BG-09","name":"Kardzhali"},
{"country":"BG","code":"BG-10","name":"Kyustendil"},
{"country":"BG","code":"BG-11","name":"Lovech"},
{"country":"BG","code":"BG-12","name":"Montana"},
{"country":"BG","code":"BG-13","name":"Pazardzhik"},
{"country":"BG","code":"BG-14","name":"Pernik"},
{"country":"BG","code":"BG-15","name":"Pleven"},
{"country":"BG","code":"BG-16","name":"Plovdiv"},
{"country":"BG","code":"BG-17","name":"Razgrad"},
{"country":"BG","code":"BG-18","name":"Ruse"},
{"country":"BG","code":"BG-27","name":"Shumen"},
{"country":"BG","code":"BG-19","name":"Silistra"},
{"country":"BG","code":"BG-20","name":"Sliven"},
{"country":"BG","code":"BG-21","name":"Smolyan"},
{"country":"BG","code":"BG-23","name":"Sofia"},
{"country":"BG","code":"BG-22","name":"Sofia (stolitsa)"},
{"country":"BG","code":"BG-24","name":"Stara Zagora"},
{"country":"BG","code":"BG-25","name":"Targovishte"},
{"country":"BG","code":"BG-03","name":"Varna"},
{"country":"BG","code":"BG-04","name":"Veliko Tarnovo"},
{"country":"BG","code":"BG-05","name":"Vidin"},
{"country":"BG","code":"BG-06","name":"Vratsa"},
{"country":"BG","code":"BG-28","name":"Yambol"},
{"country":"BH","code":"BH-13","name":"Al 'Asimah"},
{"country":"BH","code":"BH-14","name":"Al Janubiyah"},
{"country":"BH","code":"BH-15","name":"Al Muharraq"},
{"country":"BH","code":"BH-17","name":"Ash Shamaliyah"},
{"country":"BI","code":"BI-BM","name":"Bujumbura Mairie"},
{"country":"BI","code":"BI-CI","name":"Cibitoke"},
{"country":"BI","code":"BI-GI","name":"Gitega"},
{"country":"BI","code":"BI-KR","name":"Karuzi"},
{"country":"BI","code":"BI-KI","name":"Kirundo"},
{"country":"BI","code":"BI-MW","name":"Mwaro"},
{"country":"BI","code":"BI-NG","name":"Ngozi"},
{"country":"BI","code":"BI-RM","name":"Rumonge"},
{"country":"BI","code":"BI-RT","name":"Rutana"},
{"country":"BI","code":"BI-RY","name":"Ruyigi"},
{"country":"BJ","code":"BJ-AK","name":"Atacora"},
{"country":"BJ","code":"BJ-AQ","name":"Atlantique"},
{"country":"BJ","code":"BJ-BO","name":"Borgou"},
{"country":"BJ","code":"BJ-CO","name":"Collines"},
{"country":"BJ","code":"BJ-DO","name":"Donga"},
{"country":"BJ","code":"BJ-LI","name":"Littoral"},
{"country":"BJ","code":"BJ-MO","name":"Mono"},
{"country":"BJ","code":"BJ-OU","name":"Oueme"},
{"country":"BJ","code":"BJ-PL","name":"Plateau"},
{"country":"BJ","code":"BJ-ZO","name":"Zou"},
{"country":"BL","code":"BL-XX-1","name":"Saint Barthelemy"},
{"country":"BM","code":"BM-XX-1","name":"Hamilton"},
{"country":"BM","code":"BM-XX-2","name":"Saint George"},
{"country":"BN","code":"BN-BE","name":"Belait"},
{"country":"BN","code":"BN-BM","name":"Brunei-Muara"},
{"country":"BN","code":"BN-TE","name":"Temburong"},
{"country":"BN","code":"BN-TU","name":"Tutong"},
{"country":"BO","code":"BO-H","name":"Chuquisaca"},
{"country":"BO","code":"BO-C","name":"Cochabamba"},
{"country":"BO","code":"BO-B","name":"El Beni"},
{"country":"BO","code":"BO-L","name":"La Paz"},
{"country":"BO","code":"BO-O","name":"Oruro"},
{"country":"BO","code":"BO-N","name":"Pando"},
{"country":"BO","code":"BO-P","name":"Potosi"},
{"country":"BO","code":"BO-S","name":"Santa Cruz"},
{"country":"BO","code":"BO-T","name":"Tarija"},
{"country":"BQ","code":"BQ-BO","name":"Bonaire"},
{"country":"BQ","code":"BQ-SA","name":"Saba"},
{"country":"BQ","code":"BQ-SE","name":"Sint Eustatius"},
{"country":"BR","code":"BR-AC","name":"Acre"},
{"country":"BR","code":"BR-AL","name":"Alagoas"},
{"country":"BR","code":"BR-AP","name":"Amapa"},
{"country":"BR","code":"BR-AM","name":"Amazonas"},
{"country":"BR","code":"BR-BA","name":"Bahia"},
{"country":"BR","code":"BR-CE","name":"Ceara"},
{"country":"BR","code":"BR-DF","name":"Distrito Federal"},
{"country":"BR","code":"BR-ES","name":"Espirito Santo"},
{"country":"BR","code":"BR-GO","name":"Goias"},
{"country":"BR","code":"BR-MA","name":"Maranhao"},
{"country":"BR","code":"BR-MT","name":"Mato Grosso"},
{"country":"BR","code":"BR-MS","name":"Mato Grosso do Sul"},
{"country":"BR","code":"BR-MG","name":"Minas Gerais"},
{"country":"BR","code":"BR-PA","name":"Para"},
{"country":"BR","code":"BR-PB","name":"Paraiba"},
{"country":"BR","code":"BR-PR","name":"Parana"},
{"country":"BR","code":"BR-PE","name":"Pernambuco"},
{"country":"BR","code":"BR-PI","name":"Piaui"},
{"country":"BR","code":"BR-RN","name":"Rio Grande do Norte"},
{"country":"BR","code":"BR-RS","name":"Rio Grande do Sul"},
{"country":"BR","code":"BR-RJ","name":"Rio de Janeiro"},
{"country":"BR","code":"BR-RO","name":"Rondonia"},
{"country":"BR","code":"BR-RR","name":"Roraima"},
{"country":"BR","code":"BR-SC","name":"Santa Catarina"},
{"country":"BR","code":"BR-SP","name":"Sao Paulo"},
{"country":"BR","code":"BR-SE","name":"Sergipe"},
{"country":"BR","code":"BR-TO","name":"Tocantins"},
{"country":"BS","code":"BS-BP","name":"Black Point"},
{"country":"BS","code":"BS-CO","name":"Central Abaco"},
{"country":"BS","code":"BS-FP","name":"City of Freeport"},
{"country":"BS","code":"BS-EG","name":"East Grand Bahama"},
{"country":"BS","code":"BS-HI","name":"Harbour Island"},
{"country":"BS","code":"BS-LI","name":"Long Island"},
{"country":"BS","code":"BS-NP","name":"New Providence"},
{"country":"BS","code":"BS-NO","name":"North Abaco"},
{"country":"BS","code":"BS-NS","name":"North Andros"},
{"country":"BS","code":"BS-NE","name":"North Eleuthera"},
{"country":"BS","code":"BS-SE","name":"South Eleuthera"},
{"country":"BS","code":"BS-WG","name":"West Grand Bahama"},
{"country":"BT","code":"BT-33","name":"Bumthang"},
{"country":"BT","code":"BT-12","name":"Chhukha"},
{"country":"BT","code":"BT-22","name":"Dagana"},
{"country":"BT","code":"BT-GA","name":"Gasa"},
{"country":"BT","code":"BT-44","name":"Lhuentse"},
{"country":"BT","code":"BT-42","name":"Monggar"},
{"country":"BT","code":"BT-11","name":"Paro"},
{"country":"BT","code":"BT-43","name":"Pema Gatshel"},
{"country":"BT","code":"BT-23","name":"Punakha"},
{"country":"BT","code":"BT-45","name":"Samdrup Jongkhar"},
{"country":"BT","code":"BT-14","name":"Samtse"},
{"country":"BT","code":"BT-31","name":"Sarpang"},
{"country":"BT","code":"BT-15","name":"Thimphu"},
{"country":"BT","code":"BT-41","name":"Trashigang"},
{"country":"BT","code":"BT-32","name":"Trongsa"},
{"country":"BT","code":"BT-21","name":"Tsirang"},
{"country":"BT","code":"BT-24","name":"Wangdue Phodrang"},
{"country":"BV","code":"BV-XX-1","name":"Bouvet Island"},
{"country":"BW","code":"BW-CE","name":"Central"},
{"country":"BW","code":"BW-CH","name":"Chobe"},
{"country":"BW","code":"BW-GH","name":"Ghanzi"},
{"country":"BW","code":"BW-KG","name":"Kgalagadi"},
{"country":"BW","code":"BW-KL","name":"Kgatleng"},
{"country":"BW","code":"BW-KW","name":"Kweneng"},
{"country":"BW","code":"BW-NE","name":"North East"},
{"country":"BW","code":"BW-NW","name":"North West"},
{"country":"BW","code":"BW-SE","name":"South East"},
{"country":"BW","code":"BW-SO","name":"Southern"},
{"country":"BY","code":"BY-BR","name":"Brestskaya voblasts'"},
{"country":"BY","code":"BY-HO","name":"Homyel'skaya voblasts'"},
{"country":"BY","code":"BY-HM","name":"Horad Minsk"},
{"country":"BY","code":"BY-HR","name":"Hrodzyenskaya voblasts'"},
{"country":"BY","code":"BY-MA","name":"Mahilyowskaya voblasts'"},
{"country":"BY","code":"BY-MI","name":"Minskaya voblasts'"},
{"country":"BY","code":"BY-VI","name":"Vitsyebskaya voblasts'"},
{"country":"BZ","code":"BZ-BZ","name":"Belize"},
{"country":"BZ","code":"BZ-CY","name":"Cayo"},
{"country":"BZ","code":"BZ-CZL","name":"Corozal"},
{"country":"BZ","code":"BZ-OW","name":"Orange Walk"},
{"country":"BZ","code":"BZ-SC","name":"Stann Creek"},
{"country":"BZ","code":"BZ-TOL","name":"Toledo"},
{"country":"CA","code":"CA-AB","name":"Alberta"},
{"country":"CA","code":"CA-BC","name":"British Columbia"},
{"country":"CA","code":"CA-MB","name":"Manitoba"},
{"country":"CA","code":"CA-NB","name":"New Brunswick"},
{"country":"CA","code":"CA-NL","name":"Newfoundland and Labrador"},
{"country":"CA","code":"CA-NT","name":"Northwest Territories"},
{"country":"CA","code":"CA-NS","name":"Nova Scotia"},
{"country":"CA","code":"CA-NU","name":"Nunavut"},
{"country":"CA","code":"CA-ON","name":"Ontario"},
{"country":"CA","code":"CA-PE","name":"Prince Edward Island"},
{"country":"CA","code":"CA-QC","name":"Quebec"},
{"country":"CA","code":"CA-SK","name":"Saskatchewan"},
{"country":"CA","code":"CA-YT","name":"Yukon"},
{"country":"CC","code":"CC-XX-1","name":"Cocos (Keeling) Islands"},
{"country":"CD","code":"CD-EQ","name":"Equateur"},
{"country":"CD","code":"CD-HK","name":"Haut-Katanga"},
{"country":"CD","code":"CD-HL","name":"Haut-Lomami"},
{"country":"CD","code":"CD-IT","name":"Ituri"},
{"country":"CD","code":"CD-KC","name":"Kasai Central"},
{"country":"CD","code":"CD-KE","name":"Kasai Oriental"},
{"country":"CD","code":"CD-KN","name":"Kinshasa"},
{"country":"CD","code":"CD-BC","name":"Kongo Central"},
{"country":"CD","code":"CD-KG","name":"Kwango"},
{"country":"CD","code":"CD-KL","name":"Kwilu"},
{"country":"CD","code":"CD-LU","name":"Lualaba"},
{"country":"CD","code":"CD-NK","name":"Nord-Kivu"},
{"country":"CD","code":"CD-SA","name":"Sankuru"},
{"country":"CD","code":"CD-SK","name":"Sud-Kivu"},
{"country":"CD","code":"CD-TA","name":"Tanganyika"},
{"country":"CD","code":"CD-TO","name":"Tshopo"},
{"country":"CD","code":"CD-TU","name":"Tshuapa"},
{"country":"CF","code":"CF-BB","name":"Bamingui-Bangoran"},
{"country":"CF","code":"CF-BGF","name":"Bangui"},
{"country":"CF","code":"CF-KB","name":"Gribingui"},
{"country":"CF","code":"CF-HM","name":"Haut-Mbomou"},
{"country":"CF","code":"CF-KG","name":"Kemo-Gribingui"},
{"country":"CF","code":"CF-NM","name":"Nana-Mambere"},
{"country":"CF","code":"CF-UK","name":"Ouaka"},
{"country":"CF","code":"CF-AC","name":"Ouham"},
{"country":"CF","code":"CF-OP","name":"Ouham-Pende"},
{"country":"CF","code":"CF-VK","name":"Vakaga"},
{"country":"CG","code":"CG-11","name":"Bouenza"},
{"country":"CG","code":"CG-BZV","name":"Brazzaville"},
{"country":"CG","code":"CG-8","name":"Cuvette"},
{"country":"CG","code":"CG-9","name":"Niari"},
{"country":"CG","code":"CG-16","name":"Pointe-Noire"},
{"country":"CG","code":"CG-13","name":"Sangha"},
{"country":"CH","code":"CH-AG","name":"Aargau"},
{"country":"CH","code":"CH-AR","name":"Appenzell Ausserrhoden"},
{"country":"CH","code":"CH-AI","name":"Appenzell Innerrhoden"},
{"country":"CH","code":"CH-BL","name":"Basel-Landschaft"},
{"country":"CH","code":"CH-BS","name":"Basel-Stadt"},
{"country":"CH","code":"CH-BE","name":"Bern"},
{"country":"CH","code":"CH-FR","name":"Fribourg"},
{"country":"CH","code":"CH-GE","name":"Geneve"},
{"country":"CH","code":"CH-GL","name":"Glarus"},
{"country":"CH","code":"CH-GR","name":"Graubunden"},
{"country":"CH","code":"CH-JU","name":"Jura"},
{"country":"CH","code":"CH-LU","name":"Luzern"},
{"country":"CH","code":"CH-NE","name":"Neuchatel"},
{"country":"CH","code":"CH-NW","name":"Nidwalden"},
{"country":"CH","code":"CH-OW","name":"Obwalden"},
{"country":"CH","code":"CH-SG","name":"Sankt Gallen"},
{"country":"CH","code":"CH-SH","name":"Schaffhausen"},
{"country":"CH","code":"CH-SZ","name":"Schwyz"},
{"country":"CH","code":"CH-SO","name":"Solothurn"},
{"country":"CH","code":"CH-TG","name":"Thurgau"},
{"country":"CH","code":"CH-TI","name":"Ticino"},
{"country":"CH","code":"CH-UR","name":"Uri"},
{"country":"CH","code":"CH-VS","name":"Valais"},
{"country":"CH","code":"CH-VD","name":"Vaud"},
{"country":"CH","code":"CH-ZG","name":"Zug"},
{"country":"CH","code":"CH-ZH","name":"Zurich"},
{"country":"CI","code":"CI-AB","name":"Abidjan"},
{"country":"CI","code":"CI-BS","name":"Bas-Sassandra"},
{"country":"CI","code":"CI-CM","name":"Comoe"},
{"country":"CI","code":"CI-DN","name":"Denguele"},
{"country":"CI","code":"CI-GD","name":"Goh-Djiboua"},
{"country":"CI","code":"CI-LC","name":"Lacs"},
{"country":"CI","code":"CI-LG","name":"Lagunes"},
{"country":"CI","code":"CI-MG","name":"Montagnes"},
{"country":"CI","code":"CI-SM","name":"Sassandra-Marahoue"},
{"country":"CI","code":"CI-SV","name":"Savanes"},
{"country":"CI","code":"CI-VB","name":"Vallee du Bandama"},
{"country":"CI","code":"CI-WR","name":"Woroba"},
{"country":"CI","code":"CI-YM","name":"Yamoussoukro"},
{"country":"CI","code":"CI-ZZ","name":"Zanzan"},
{"country":"CK","code":"CK-XX-1","name":"Cook Islands"},
{"country":"CL","code":"CL-AI","name":"Aisen del General Carlos Ibanez del Campo"},
{"country":"CL","code":"CL-AN","name":"Antofagasta"},
{"country":"CL","code":"CL-AP","name":"Arica y Parinacota"},
{"country":"CL","code":"CL-AT","name":"Atacama"},
{"country":"CL","code":"CL-BI","name":"Biobio"},
{"country":"CL","code":"CL-CO","name":"Coquimbo"},
{"country":"CL","code":"CL-AR","name":"La Araucania"},
{"country":"CL","code":"CL-LI","name":"Libertador General Bernardo O'Higgins"},
{"country":"CL","code":"CL-LL","name":"Los Lagos"},
{"country":"CL","code":"CL-LR","name":"Los Rios"},
{"country":"CL","code":"CL-MA","name":"Magallanes"},
{"country":"CL","code":"CL-ML","name":"Maule"},
{"country":"CL","code":"CL-NB","name":"Nuble"},
{"country":"CL","code":"CL-RM","name":"Region Metropolitana de Santiago"},
{"country":"CL","code":"CL-TA","name":"Tarapaca"},
{"country":"CL","code":"CL-VS","name":"Valparaiso"},
{"country":"CM","code":"CM-AD","name":"Adamaoua"},
{"country":"CM","code":"CM-CE","name":"Centre"},
{"country":"CM","code":"CM-ES","name":"Est"},
{"country":"CM","code":"CM-EN","name":"Extreme-Nord"},
{"country":"CM","code":"CM-LT","name":"Littoral"},
{"country":"CM","code":"CM-NO","name":"Nord"},
{"country":"CM","code":"CM-NW","name":"Nord-Ouest"},
{"country":"CM","code":"CM-OU","name":"Ouest"},
{"country":"CM","code":"CM-SU","name":"Sud"},
{"country":"CM","code":"CM-SW","name":"Sud-Ouest"},
{"country":"CN","code":"CN-AH","name":"Anhui"},
{"country":"CN","code":"CN-BJ","name":"Beijing"},
{"country":"CN","code":"CN-CQ","name":"Chongqing"},
{"country":"CN","code":"CN-FJ","name":"Fujian"},
{"country":"CN","code":"CN-GS","name":"Gansu"},
{"country":"CN","code":"CN-GD","name":"Guangdong"},
{"country":"CN","code":"CN-GX","name":"Guangxi Zhuangzu"},
{"country":"CN","code":"CN-GZ","name":"Guizhou"},
{"country":"CN","code":"CN-HI","name":"Hainan"},
{"country":"CN","code":"CN-HE","name":"Hebei"},
{"country":"CN","code":"CN-HL","name":"Heilongjiang"},
{"country":"CN","code":"CN-HA","name":"Henan"},
{"country":"CN","code":"CN-HB","name":"Hubei"},
{"country":"CN","code":"CN-HN","name":"Hunan"},
{"country":"CN","code":"CN-JS","name":"Jiangsu"},
{"country":"CN","code":"CN-JX","name":"Jiangxi"},
{"country":"CN","code":"CN-JL","name":"Jilin"},
{"country":"CN","code":"CN-LN","name":"Liaoning"},
{"country":"CN","code":"CN-NM","name":"Nei Mongol"},
{"country":"CN","code":"CN-NX","name":"Ningxia Huizu"},
{"country":"CN","code":"CN-QH","name":"Qinghai"},
{"country":"CN","code":"CN-SN","name":"Shaanxi"},
{"country":"CN","code":"CN-SD","name":"Shandong"},
{"country":"CN","code":"CN-SH","name":"Shanghai"},
{"country":"CN","code":"CN-SX","name":"Shanxi"},
{"country":"CN","code":"CN-SC","name":"Sichuan"},
{"country":"CN","code":"CN-TJ","name":"Tianjin"},
{"country":"CN","code":"CN-XJ","name":"Xinjiang Uygur"},
{"country":"CN","code":"CN-XZ","name":"Xizang"},
{"country":"CN","code":"CN-YN","name":"Yunnan"},
{"country":"CN","code":"CN-ZJ","name":"Zhejiang"},
{"country":"CO","code":"CO-AMA","name":"Amazonas"},
{"country":"CO","code":"CO-ANT","name":"Antioquia"},
{"country":"CO","code":"CO-ARA","name":"Arauca"},
{"country":"CO","code":"CO-ATL","name":"Atlantico"},
{"country":"CO","code":"CO-BOL","name":"Bolivar"},
{"country":"CO","code":"CO-BOY","name":"Boyaca"},
{"country":"CO","code":"CO-CAL","name":"Caldas"},
{"country":"CO","code":"CO-CAQ","name":"Caqueta"},
{"country":"CO","code":"CO-CAS","name":"Casanare"},
{"country":"CO","code":"CO-CAU","name":"Cauca"},
{"country":"CO","code":"CO-CES","name":"Cesar"},
{"country":"CO","code":"CO-CHO","name":"Choco"},
{"country":"CO","code":"CO-COR","name":"Cordoba"},
{"country":"CO","code":"CO-CUN","name":"Cundinamarca"},
{"country":"CO","code":"CO-DC","name":"Distrito Capital de Bogota"},
{"country":"CO","code":"CO-GUA","name":"Guainia"},
{"country":"CO","code":"CO-GUV","name":"Guaviare"},
{"country":"CO","code":"CO-HUI","name":"Huila"},
{"country":"CO","code":"CO-LAG","name":"La Guajira"},
{"country":"CO","code":"CO-MAG","name":"Magdalena"},
{"country":"CO","code":"CO-MET","name":"Meta"},
{"country":"CO","code":"CO-NAR","name":"Narino"},
{"country":"CO","code":"CO-NSA","name":"Norte de Santander"},
{"country":"CO","code":"CO-PUT","name":"Putumayo"},
{"country":"CO","code":"CO-QUI","name":"Quindio"},
{"country":"CO","code":"CO-RIS","name":"Risaralda"},
{"country":"CO","code":"CO-SAP","name":"San Andres, Providencia y Santa Catalina"},
{"country":"CO","code":"CO-SAN","name":"Santander"},
{"country":"CO","code":"CO-SUC","name":"Sucre"},
{"country":"CO","code":"CO-TOL","name":"Tolima"},
{"country":"CO","code":"CO-VAC","name":"Valle del Cauca"},
{"country":"CO","code":"CO-VID","name":"Vichada"},
{"country":"CR","code":"CR-A","name":"Alajuela"},
{"country":"CR","code":"CR-C","name":"Cartago"},
{"country":"CR","code":"CR-G","name":"Guanacaste"},
{"country":"CR","code":"CR-H","name":"Heredia"},
{"country":"CR","code":"CR-L","name":"Limon"},
{"country":"CR","code":"CR-P","name":"Puntarenas"},
{"country":"CR","code":"CR-SJ","name":"San Jose"},
{"country":"CU","code":"CU-15","name":"Artemisa"},
{"country":"CU","code":"CU-09","name":"Camaguey"},
{"country":"CU","code":"CU-08","name":"Ciego de Avila"},
{"country":"CU","code":"CU-06","name":"Cienfuegos"},
{"country":"CU","code":"CU-12","name":"Granma"},
{"country":"CU","code":"CU-14","name":"Guantanamo"},
{"country":"CU","code":"CU-11","name":"Holguin"},
{"country":"CU","code":"CU-03","name":"La Habana"},
{"country":"CU","code":"CU-10","name":"Las Tunas"},
{"country":"CU","code":"CU-04","name":"Matanzas"},
{"country":"CU","code":"CU-16","name":"Mayabeque"},
{"country":"CU","code":"CU-01","name":"Pinar del Rio"},
{"country":"CU","code":"CU-07","name":"Sancti Spiritus"},
{"country":"CU","code":"CU-13","name":"Santiago de Cuba"},
{"country":"CU","code":"CU-05","name":"Villa Clara"},
{"country":"CV","code":"CV-BV","name":"Boa Vista"},
{"country":"CV","code":"CV-BR","name":"Brava"},
{"country":"CV","code":"CV-MO","name":"Mosteiros"},
{"country":"CV","code":"CV-PN","name":"Porto Novo"},
{"country":"CV","code":"CV-PR","name":"Praia"},
{"country":"CV","code":"CV-RS","name":"Ribeira Grande de Santiago"},
{"country":"CV","code":"CV-SL","name":"Sal"},
{"country":"CV","code":"CV-CR","name":"Santa Cruz"},
{"country":"CV","code":"CV-SD","name":"Sao Domingos"},
{"country":"CV","code":"CV-SO","name":"Sao Lourenco dos Orgaos"},
{"country":"CV","code":"CV-SV","name":"Sao Vicente"},
{"country":"CV","code":"CV-TA","name":"Tarrafal"},
{"country":"CV","code":"CV-TS","name":"Tarrafal de Sao Nicolau"},
{"country":"CW","code":"CW-XX-1","name":"Curacao"},
{"country":"CX","code":"CX-XX-1","name":"Christmas Island"},
{"country":"CY","code":"CY-04","name":"Ammochostos"},
{"country":"CY","code":"CY-06","name":"Keryneia"},
{"country":"CY","code":"CY-03","name":"Larnaka"},
{"country":"CY","code":"CY-01","name":"Lefkosia"},
{"country":"CY","code":"CY-02","name":"Lemesos"},
{"country":"CY","code":"CY-05","name":"Pafos"},
{"country":"CZ","code":"CZ-31","name":"Jihocesky kraj"},
{"country":"CZ","code":"CZ-64","name":"Jihomoravsky kraj"},
{"country":"CZ","code":"CZ-41","name":"Karlovarsky kraj"},
{"country":"CZ","code":"CZ-63","name":"Kraj Vysocina"},
{"country":"CZ","code":"CZ-52","name":"Kralovehradecky kraj"},
{"country":"CZ","code":"CZ-51","name":"Liberecky kraj"},
{"country":"CZ","code":"CZ-80","name":"Moravskoslezsky kraj"},
{"country":"CZ","code":"CZ-71","name":"Olomoucky kraj"},
{"country":"CZ","code":"CZ-53","name":"Pardubicky kraj"},
{"country":"CZ","code":"CZ-32","name":"Plzensky kraj"},
{"country":"CZ","code":"CZ-10","name":"Praha, Hlavni mesto"},
{"country":"CZ","code":"CZ-20","name":"Stredocesky kraj"},
{"country":"CZ","code":"CZ-42","name":"Ustecky kraj"},
{"country":"CZ","code":"CZ-72","name":"Zlinsky kraj"},
{"country":"DE","code":"DE-BW","name":"Baden-Württemberg"},
{"country":"DE","code":"DE-BY","name":"Bayern"},
{"country":"DE","code":"DE-BE","name":"Berlin"},
{"country":"DE","code":"DE-BB","name":"Brandenburg"},
{"country":"DE","code":"DE-HB","name":"Bremen"},
{"country":"DE","code":"DE-HH","name":"Hamburg"},
{"country":"DE","code":"DE-HE","name":"Hessen"},
{"country":"DE","code":"DE-MV","name":"Mecklenburg-Vorpommern"},
{"country":"DE","code":"DE-NI","name":"Niedersachsen"},
{"country":"DE","code":"DE-NW","name":"Nordrhein-Westfalen"},
{"country":"DE","code":"DE-RP","name":"Rheinland-Pfalz"},
{"country":"DE","code":"DE-SL","name":"Saarland"},
{"country":"DE","code":"DE-SN","name":"Sachsen"},
{"country":"DE","code":"DE-ST","name":"Sachsen-Anhalt"},
{"country":"DE","code":"DE-SH","name":"Schleswig-Holstein"},
{"country":"DE","code":"DE-TH","name":"Thüringen"},
{"country":"DJ","code":"DJ-AR","name":"Arta"},
{"country":"DJ","code":"DJ-DJ","name":"Djibouti"},
{"country":"DK","code":"DK-84","name":"Hovedstaden"},
{"country":"DK","code":"DK-82","name":"Midtjylland"},
{"country":"DK","code":"DK-81","name":"Nordjylland"},
{"country":"DK","code":"DK-85","name":"Sjaelland"},
{"country":"DK","code":"DK-83","name":"Syddanmark"},
{"country":"DM","code":"DM-02","name":"Saint Andrew"},
{"country":"DM","code":"DM-04","name":"Saint George"},
{"country":"DM","code":"DM-05","name":"Saint John"},
{"country":"DM","code":"DM-06","name":"Saint Joseph"},
{"country":"DM","code":"DM-07","name":"Saint Luke"},
{"country":"DM","code":"DM-09","name":"Saint Patrick"},
{"country":"DM","code":"DM-10","name":"Saint Paul"},
{"country":"DO","code":"DO-02","name":"Azua"},
{"country":"DO","code":"DO-03","name":"Baoruco"},
{"country":"DO","code":"DO-04","name":"Barahona"},
{"country":"DO","code":"DO-05","name":"Dajabon"},
{"country":"DO","code":"DO-01","name":"Distrito Nacional (Santo Domingo)"},
{"country":"DO","code":"DO-06","name":"Duarte"},
{"country":"DO","code":"DO-08","name":"El Seibo"},
{"country":"DO","code":"DO-07","name":"Elias Pina"},
{"country":"DO","code":"DO-09","name":"Espaillat"},
{"country":"DO","code":"DO-30","name":"Hato Mayor"},
{"country":"DO","code":"DO-19","name":"Hermanas Mirabal"},
{"country":"DO","code":"DO-10","name":"Independencia"},
{"country":"DO","code":"DO-11","name":"La Altagracia"},
{"country":"DO","code":"DO-12","name":"La Romana"},
{"country":"DO","code":"DO-13","name":"La Vega"},
{"country":"DO","code":"DO-14","name":"Maria Trinidad Sanchez"},
{"country":"DO","code":"DO-28","name":"Monsenor Nouel"},
{"country":"DO","code":"DO-15","name":"Monte Cristi"},
{"country":"DO","code":"DO-29","name":"Monte Plata"},
{"country":"DO","code":"DO-17","name":"Peravia"},
{"country":"DO","code":"DO-18","name":"Puerto Plata"},
{"country":"DO","code":"DO-20","name":"Samana"},
{"country":"DO","code":"DO-21","name":"San Cristobal"},
{"country":"DO","code":"DO-31","name":"San Jose de Ocoa"},
{"country":"DO","code":"DO-22","name":"San Juan"},
{"country":"DO","code":"DO-23","name":"San Pedro de Macoris"},
{"country":"DO","code":"DO-24","name":"Sanchez Ramirez"},
{"country":"DO","code":"DO-25","name":"Santiago"},
{"country":"DO","code":"DO-26","name":"Santiago Rodriguez"},
{"country":"DO","code":"DO-27","name":"Valverde"},
{"country":"DZ","code":"DZ-01","name":"Adrar"},
{"country":"DZ","code":"DZ-44","name":"Ain Defla"},
{"country":"DZ","code":"DZ-46","name":"Ain Temouchent"},
{"country":"DZ","code":"DZ-16","name":"Alger"},
{"country":"DZ","code":"DZ-23","name":"Annaba"},
{"country":"DZ","code":"DZ-05","name":"Batna"},
{"country":"DZ","code":"DZ-08","name":"Bechar"},
{"country":"DZ","code":"DZ-06","name":"Bejaia"},
{"country":"DZ","code":"DZ-07","name":"Biskra"},
{"country":"DZ","code":"DZ-09","name":"Blida"},
{"country":"DZ","code":"DZ-34","name":"Bordj Bou Arreridj"},
{"country":"DZ","code":"DZ-10","name":"Bouira"},
{"country":"DZ","code":"DZ-35","name":"Boumerdes"},
{"country":"DZ","code":"DZ-02","name":"Chlef"},
{"country":"DZ","code":"DZ-25","name":"Constantine"},
{"country":"DZ","code":"DZ-17","name":"Djelfa"},
{"country":"DZ","code":"DZ-32","name":"El Bayadh"},
{"country":"DZ","code":"DZ-39","name":"El Oued"},
{"country":"DZ","code":"DZ-36","name":"El Tarf"},
{"country":"DZ","code":"DZ-47","name":"Ghardaia"},
{"country":"DZ","code":"DZ-24","name":"Guelma"},
{"country":"DZ","code":"DZ-33","name":"Illizi"},
{"country":"DZ","code":"DZ-18","name":"Jijel"},
{"country":"DZ","code":"DZ-40","name":"Khenchela"},
{"country":"DZ","code":"DZ-03","name":"Laghouat"},
{"country":"DZ","code":"DZ-28","name":"M'sila"},
{"country":"DZ","code":"DZ-29","name":"Mascara"},
{"country":"DZ","code":"DZ-26","name":"Medea"},
{"country":"DZ","code":"DZ-43","name":"Mila"},
{"country":"DZ","code":"DZ-27","name":"Mostaganem"},
{"country":"DZ","code":"DZ-45","name":"Naama"},
{"country":"DZ","code":"DZ-31","name":"Oran"},
{"country":"DZ","code":"DZ-30","name":"Ouargla"},
{"country":"DZ","code":"DZ-04","name":"Oum el Bouaghi"},
{"country":"DZ","code":"DZ-48","name":"Relizane"},
{"country":"DZ","code":"DZ-20","name":"Saida"},
{"country":"DZ","code":"DZ-19","name":"Setif"},
{"country":"DZ","code":"DZ-22","name":"Sidi Bel Abbes"},
{"country":"DZ","code":"DZ-21","name":"Skikda"},
{"country":"DZ","code":"DZ-41","name":"Souk Ahras"},
{"country":"DZ","code":"DZ-11","name":"Tamanrasset"},
{"country":"DZ","code":"DZ-12","name":"Tebessa"},
{"country":"DZ","code":"DZ-14","name":"Tiaret"},
{"country":"DZ","code":"DZ-37","name":"Tindouf"},
{"country":"DZ","code":"DZ-42","name":"Tipaza"},
{"country":"DZ","code":"DZ-38","name":"Tissemsilt"},
{"country":"DZ","code":"DZ-15","name":"Tizi Ouzou"},
{"country":"DZ","code":"DZ-13","name":"Tlemcen"},
{"country":"EC","code":"EC-A","name":"Azuay"},
{"country":"EC","code":"EC-B","name":"Bolivar"},
{"country":"EC","code":"EC-F","name":"Canar"},
{"country":"EC","code":"EC-C","name":"Carchi"},
{"country":"EC","code":"EC-H","name":"Chimborazo"},
{"country":"EC","code":"EC-X","name":"Cotopaxi"},
{"country":"EC","code":"EC-O","name":"El Oro"},
{"country":"EC","code":"EC-E","name":"Esmeraldas"},
{"country":"EC","code":"EC-W","name":"Galapagos"},
{"country":"EC","code":"EC-G","name":"Guayas"},
{"country":"EC","code":"EC-I","name":"Imbabura"},
{"country":"EC","code":"EC-L","name":"Loja"},
{"country":"EC","code":"EC-R","name":"Los Rios"},
{"country":"EC","code":"EC-M","name":"Manabi"},
{"country":"EC","code":"EC-S","name":"Morona Santiago"},
{"country":"EC","code":"EC-N","name":"Napo"},
{"country":"EC","code":"EC-D","name":"Orellana"},
{"country":"EC","code":"EC-Y","name":"Pastaza"},
{"country":"EC","code":"EC-P","name":"Pichincha"},
{"country":"EC","code":"EC-SE","name":"Santa Elena"},
{"country":"EC","code":"EC-SD","name":"Santo Domingo de los Tsachilas"},
{"country":"EC","code":"EC-U","name":"Sucumbios"},
{"country":"EC","code":"EC-T","name":"Tungurahua"},
{"country":"EC","code":"EC-Z","name":"Zamora Chinchipe"},
{"country":"EE","code":"EE-37","name":"Harjumaa"},
{"country":"EE","code":"EE-39","name":"Hiiumaa"},
{"country":"EE","code":"EE-45","name":"Ida-Virumaa"},
{"country":"EE","code":"EE-52","name":"Jarvamaa"},
{"country":"EE","code":"EE-50","name":"Jogevamaa"},
{"country":"EE","code":"EE-60","name":"Laane-Virumaa"},
{"country":"EE","code":"EE-56","name":"Laanemaa"},
{"country":"EE","code":"EE-68","name":"Parnumaa"},
{"country":"EE","code":"EE-64","name":"Polvamaa"},
{"country":"EE","code":"EE-71","name":"Raplamaa"},
{"country":"EE","code":"EE-74","name":"Saaremaa"},
{"country":"EE","code":"EE-79","name":"Tartumaa"},
{"country":"EE","code":"EE-81","name":"Valgamaa"},
{"country":"EE","code":"EE-84","name":"Viljandimaa"},
{"country":"EE","code":"EE-87","name":"Vorumaa"},
{"country":"EG","code":"EG-DK","name":"Ad Daqahliyah"},
{"country":"EG","code":"EG-BA","name":"Al Bahr al Ahmar"},
{"country":"EG","code":"EG-BH","name":"Al Buhayrah"},
{"country":"EG","code":"EG-FYM","name":"Al Fayyum"},
{"country":"EG","code":"EG-GH","name":"Al Gharbiyah"},
{"country":"EG","code":"EG-ALX","name":"Al Iskandariyah"},
{"country":"EG","code":"EG-IS","name":"Al Isma'iliyah"},
{"country":"EG","code":"EG-GZ","name":"Al Jizah"},
{"country":"EG","code":"EG-MNF","name":"Al Minufiyah"},
{"country":"EG","code":"EG-MN","name":"Al Minya"},
{"country":"EG","code":"EG-C","name":"Al Qahirah"},
{"country":"EG","code":"EG-KB","name":"Al Qalyubiyah"},
{"country":"EG","code":"EG-LX","name":"Al Uqsur"},
{"country":"EG","code":"EG-WAD","name":"Al Wadi al Jadid"},
{"country":"EG","code":"EG-SUZ","name":"As Suways"},
{"country":"EG","code":"EG-SHR","name":"Ash Sharqiyah"},
{"country":"EG","code":"EG-ASN","name":"Aswan"},
{"country":"EG","code":"EG-AST","name":"Asyut"},
{"country":"EG","code":"EG-BNS","name":"Bani Suwayf"},
{"country":"EG","code":"EG-PTS","name":"Bur Sa'id"},
{"country":"EG","code":"EG-DT","name":"Dumyat"},
{"country":"EG","code":"EG-JS","name":"Janub Sina'"},
{"country":"EG","code":"EG-KFS","name":"Kafr ash Shaykh"},
{"country":"EG","code":"EG-MT","name":"Matruh"},
{"country":"EG","code":"EG-KN","name":"Qina"},
{"country":"EG","code":"EG-SIN","name":"Shamal Sina'"},
{"country":"EG","code":"EG-SHG","name":"Suhaj"},
{"country":"EH","code":"EH-XX-1","name":"Western Sahara"},
{"country":"ER","code":"ER-MA","name":"Al Awsat"},
{"country":"ER","code":"ER-DK","name":"Janubi al Bahri al Ahmar"},
{"country":"ER","code":"ER-SK","name":"Shimali al Bahri al Ahmar"},
{"country":"ES","code":"ES-AN","name":"Andalucia"},
{"country":"ES","code":"ES-AR","name":"Aragon"},
{"country":"ES","code":"ES-AS","name":"Asturias, Principado de"},
{"country":"ES","code":"ES-CN","name":"Canarias"},
{"country":"ES","code":"ES-CB","name":"Cantabria"},
{"country":"ES","code":"ES-CL","name":"Castilla y Leon"},
{"country":"ES","code":"ES-CM","name":"Castilla-La Mancha"},
{"country":"ES","code":"ES-CT","name":"Catalunya"},
{"country":"ES","code":"ES-CE","name":"Ceuta"},
{"country":"ES","code":"ES-EX","name":"Extremadura"},
{"country":"ES","code":"ES-GA","name":"Galicia"},
{"country":"ES","code":"ES-IB","name":"Illes Balears"},
{"country":"ES","code":"ES-RI","name":"La Rioja"},
{"country":"ES","code":"ES-MD","name":"Madrid, Comunidad de"},
{"country":"ES","code":"ES-ML","name":"Melilla"},
{"country":"ES","code":"ES-MC","name":"Murcia, Region de"},
{"country":"ES","code":"ES-NC","name":"Navarra, Comunidad Foral de"},
{"country":"ES","code":"ES-PV","name":"Pais Vasco"},
{"country":"ES","code":"ES-VC","name":"Valenciana, Comunidad"},
{"country":"ET","code":"ET-AA","name":"Adis Abeba"},
{"country":"ET","code":"ET-AF","name":"Afar"},
{"country":"ET","code":"ET-AM","name":"Amara"},
{"country":"ET","code":"ET-BE","name":"Binshangul Gumuz"},
{"country":"ET","code":"ET-DD","name":"Dire Dawa"},
{"country":"ET","code":"ET-GA","name":"Gambela Hizboch"},
{"country":"ET","code":"ET-HA","name":"Hareri Hizb"},
{"country":"ET","code":"ET-OR","name":"Oromiya"},
{"country":"ET","code":"ET-SO","name":"Sumale"},
{"country":"ET","code":"ET-TI","name":"Tigray"},
{"country":"ET","code":"ET-SN","name":"YeDebub Biheroch Bihereseboch na Hizboch"},
{"country":"FI","code":"FI-02","name":"Etela-Karjala"},
{"country":"FI","code":"FI-03","name":"Etela-Pohjanmaa"},
{"country":"FI","code":"FI-04","name":"Etela-Savo"},
{"country":"FI","code":"FI-05","name":"Kainuu"},
{"country":"FI","code":"FI-06","name":"Kanta-Hame"},
{"country":"FI","code":"FI-07","name":"Keski-Pohjanmaa"},
{"country":"FI","code":"FI-08","name":"Keski-Suomi"},
{"country":"FI","code":"FI-09","name":"Kymenlaakso"},
{"country":"FI","code":"FI-10","name":"Lappi"},
{"country":"FI","code":"FI-16","name":"Paijat-Hame"},
{"country":"FI","code":"FI-11","name":"Pirkanmaa"},
{"country":"FI","code":"FI-12","name":"Pohjanmaa"},
{"country":"FI","code":"FI-13","name":"Pohjois-Karjala"},
{"country":"FI","code":"FI-14","name":"Pohjois-Pohjanmaa"},
{"country":"FI","code":"FI-15","name":"Pohjois-Savo"},
{"country":"FI","code":"FI-17","name":"Satakunta"},
{"country":"FI","code":"FI-18","name":"Uusimaa"},
{"country":"FI","code":"FI-19","name":"Varsinais-Suomi"},
{"country":"FJ","code":"FJ-C","name":"Central"},
{"country":"FJ","code":"FJ-E","name":"Eastern"},
{"country":"FJ","code":"FJ-N","name":"Northern"},
{"country":"FJ","code":"FJ-R","name":"Rotuma"},
{"country":"FJ","code":"FJ-W","name":"Western"},
{"country":"FK","code":"FK-XX-1","name":"Falkland Islands (Malvinas)"},
{"country":"FM","code":"FM-TRK","name":"Chuuk"},
{"country":"FM","code":"FM-KSA","name":"Kosrae"},
{"country":"FM","code":"FM-PNI","name":"Pohnpei"},
{"country":"FM","code":"FM-YAP","name":"Yap"},
{"country":"FO","code":"FO-XX-1","name":"Eysturoy"},
{"country":"FO","code":"FO-XX-2","name":"Nordoyar"},
{"country":"FO","code":"FO-XX-3","name":"Streymoy"},
{"country":"FO","code":"FO-XX-4","name":"Suduroy"},
{"country":"FO","code":"FO-XX-5","name":"Vagar"},
{"country":"FR","code":"FR-ARA","name":"Auvergne-Rhone-Alpes"},
{"country":"FR","code":"FR-BFC","name":"Bourgogne-Franche-Comte"},
{"country":"FR","code":"FR-BRE","name":"Bretagne"},
{"country":"FR","code":"FR-CVL","name":"Centre-Val de Loire"},
{"country":"FR","code":"FR-20R","name":"Corse"},
{"country":"FR","code":"FR-GES","name":"Grand-Est"},
{"country":"FR","code":"FR-HDF","name":"Hauts-de-France"},
{"country":"FR","code":"FR-IDF","name":"Ile-de-France"},
{"country":"FR","code":"FR-NOR","name":"Normandie"},
{"country":"FR","code":"FR-NAQ","name":"Nouvelle-Aquitaine"},
{"country":"FR","code":"FR-OCC","name":"Occitanie"},
{"country":"FR","code":"FR-PDL","name":"Pays-de-la-Loire"},
{"country":"FR","code":"FR-PAC","name":"Provence-Alpes-Cote-d'Azur"},
{"country":"GA","code":"GA-1","name":"Estuaire"},
{"country":"GA","code":"GA-2","name":"Haut-Ogooue"},
{"country":"GA","code":"GA-4","name":"Ngounie"},
{"country":"GA","code":"GA-5","name":"Nyanga"},
{"country":"GA","code":"GA-8","name":"Ogooue-Maritime"},
{"country":"GA","code":"GA-9","name":"Woleu-Ntem"},
{"country":"GB","code":"GB-ENG","name":"England"},
{"country":"GB","code":"GB-NIR","name":"Northern Ireland"},
{"country":"GB","code":"GB-SCT","name":"Scotland"},
{"country":"GB","code":"GB-WLS","name":"Wales"},
{"country":"GD","code":"GD-01","name":"Saint Andrew"},
{"country":"GD","code":"GD-02","name":"Saint David"},
{"country":"GD","code":"GD-03","name":"Saint George"},
{"country":"GD","code":"GD-04","name":"Saint John"},
{"country":"GD","code":"GD-05","name":"Saint Mark"},
{"country":"GD","code":"GD-06","name":"Saint Patrick"},
{"country":"GD","code":"GD-10","name":"Southern Grenadine Islands"},
{"country":"GE","code":"GE-AB","name":"Abkhazia"},
{"country":"GE","code":"GE-AJ","name":"Ajaria"},
{"country":"GE","code":"GE-GU","name":"Guria"},
{"country":"GE","code":"GE-IM","name":"Imereti"},
{"country":"GE","code":"GE-KA","name":"K'akheti"},
{"country":"GE","code":"GE-KK","name":"Kvemo Kartli"},
{"country":"GE","code":"GE-MM","name":"Mtskheta-Mtianeti"},
{"country":"GE","code":"GE-RL","name":"Rach'a-Lechkhumi-Kvemo Svaneti"},
{"country":"GE","code":"GE-SZ","name":"Samegrelo-Zemo Svaneti"},
{"country":"GE","code":"GE-SJ","name":"Samtskhe-Javakheti"},
{"country":"GE","code":"GE-SK","name":"Shida Kartli"},
{"country":"GE","code":"GE-TB","name":"Tbilisi"},
{"country":"GF","code":"GF-XX-1","name":"Guyane"},
{"country":"GG","code":"GG-XX-1","name":"Guernsey"},
{"country":"GH","code":"GH-AF","name":"Ahafo"},
{"country":"GH","code":"GH-AH","name":"Ashanti"},
{"country":"GH","code":"GH-BO","name":"Bono"},
{"country":"GH","code":"GH-BE","name":"Bono East"},
{"country":"GH","code":"GH-CP","name":"Central"},
{"country":"GH","code":"GH-EP","name":"Eastern"},
{"country":"GH","code":"GH-AA","name":"Greater Accra"},
{"country":"GH","code":"GH-NP","name":"Northern"},
{"country":"GH","code":"GH-UE","name":"Upper East"},
{"country":"GH","code":"GH-UW","name":"Upper West"},
{"country":"GH","code":"GH-TV","name":"Volta"},
{"country":"GH","code":"GH-WP","name":"Western"},
{"country":"GI","code":"GI-XX-1","name":"Gibraltar"},
{"country":"GL","code":"GL-AV","name":"Avannaata Kommunia"},
{"country":"GL","code":"GL-KU","name":"Kommune Kujalleq"},
{"country":"GL","code":"GL-QT","name":"Kommune Qeqertalik"},
{"country":"GL","code":"GL-SM","name":"Kommuneqarfik Sermersooq"},
{"country":"GL","code":"GL-QE","name":"Qeqqata Kommunia"},
{"country":"GM","code":"GM-B","name":"Banjul"},
{"country":"GM","code":"GM-M","name":"Central River"},
{"country":"GM","code":"GM-L","name":"Lower River"},
{"country":"GM","code":"GM-N","name":"North Bank"},
{"country":"GM","code":"GM-U","name":"Upper River"},
{"country":"GM","code":"GM-W","name":"Western"},
{"country":"GN","code":"GN-BF","name":"Boffa"},
{"country":"GN","code":"GN-B","name":"Boke"},
{"country":"GN","code":"GN-C","name":"Conakry"},
{"country":"GN","code":"GN-CO","name":"Coyah"},
{"country":"GN","code":"GN-DB","name":"Dabola"},
{"country":"GN","code":"GN-DU","name":"Dubreka"},
{"country":"GN","code":"GN-K","name":"Kankan"},
{"country":"GN","code":"GN-L","name":"Labe"},
{"country":"GN","code":"GN-LA","name":"Labe"},
{"country":"GN","code":"GN-MC","name":"Macenta"},
{"country":"GN","code":"GN-N","name":"Nzerekore"},
{"country":"GN","code":"GN-SI","name":"Siguiri"},
{"country":"GP","code":"GP-XX-1","name":"Guadeloupe"},
{"country":"GQ","code":"GQ-BN","name":"Bioko Norte"},
{"country":"GQ","code":"GQ-KN","name":"Kie-Ntem"},
{"country":"GQ","code":"GQ-LI","name":"Litoral"},
{"country":"GQ","code":"GQ-WN","name":"Wele-Nzas"},
{"country":"GR","code":"GR-A","name":"Anatoliki Makedonia kai Thraki"},
{"country":"GR","code":"GR-I","name":"Attiki"},
{"country":"GR","code":"GR-G","name":"Dytiki Ellada"},
{"country":"GR","code":"GR-C","name":"Dytiki Makedonia"},
{"country":"GR","code":"GR-F","name":"Ionia Nisia"},
{"country":"GR","code":"GR-D","name":"Ipeiros"},
{"country":"GR","code":"GR-B","name":"Kentriki Makedonia"},
{"country":"GR","code":"GR-M","name":"Kriti"},
{"country":"GR","code":"GR-L","name":"Notio Aigaio"},
{"country":"GR","code":"GR-J","name":"Peloponnisos"},
{"country":"GR","code":"GR-H","name":"Sterea Ellada"},
{"country":"GR","code":"GR-E","name":"Thessalia"},
{"country":"GR","code":"GR-K","name":"Voreio Aigaio"},
{"country":"GS","code":"GS-XX-1","name":"South Georgia and the South Sandwich Islands"},
{"country":"GT","code":"GT-16","name":"Alta Verapaz"},
{"country":"GT","code":"GT-15","name":"Baja Verapaz"},
{"country":"GT","code":"GT-04","name":"Chimaltenango"},
{"country":"GT","code":"GT-20","name":"Chiquimula"},
{"country":"GT","code":"GT-02","name":"El Progreso"},
{"country":"GT","code":"GT-05","name":"Escuintla"},
{"country":"GT","code":"GT-01","name":"Guatemala"},
{"country":"GT","code":"GT-13","name":"Huehuetenango"},
{"country":"GT","code":"GT-18","name":"Izabal"},
{"country":"GT","code":"GT-21","name":"Jalapa"},
{"country":"GT","code":"GT-22","name":"Jutiapa"},
{"country":"GT","code":"GT-17","name":"Peten"},
{"country":"GT","code":"GT-09","name":"Quetzaltenango"},
{"country":"GT","code":"GT-14","name":"Quiche"},
{"country":"GT","code":"GT-11","name":"Retalhuleu"},
{"country":"GT","code":"GT-03","name":"Sacatepequez"},
{"country":"GT","code":"GT-12","name":"San Marcos"},
{"country":"GT","code":"GT-06","name":"Santa Rosa"},
{"country":"GT","code":"GT-07","name":"Solola"},
{"country":"GT","code":"GT-10","name":"Suchitepequez"},
{"country":"GT","code":"GT-08","name":"Totonicapan"},
{"country":"GT","code":"GT-19","name":"Zacapa"},
{"country":"GU","code":"GU-XX-1","name":"Agana Heights"},
{"country":"GU","code":"GU-XX-2","name":"Agat"},
{"country":"GU","code":"GU-XX-3","name":"Barrigada"},
{"country":"GU","code":"GU-XX-4","name":"Chalan Pago-Ordot"},
{"country":"GU","code":"GU-XX-5","name":"Dededo"},
{"country":"GU","code":"GU-XX-6","name":"Hagatna"},
{"country":"GU","code":"GU-XX-7","name":"Inarajan"},
{"country":"GU","code":"GU-XX-8","name":"Mangilao"},
{"country":"GU","code":"GU-XX-9","name":"Mongmong-Toto-Maite"},
{"country":"GU","code":"GU-XX-10","name":"Piti"},
{"country":"GU","code":"GU-XX-11","name":"Santa Rita"},
{"country":"GU","code":"GU-XX-12","name":"Sinajana"},
{"country":"GU","code":"GU-XX-13","name":"Talofofo"},
{"country":"GU","code":"GU-XX-14","name":"Tamuning-Tumon-Harmon"},
{"country":"GU","code":"GU-XX-15","name":"Yigo"},
{"country":"GU","code":"GU-XX-16","name":"Yona"},
{"country":"GW","code":"GW-BS","name":"Bissau"},
{"country":"GW","code":"GW-GA","name":"Gabu"},
{"country":"GY","code":"GY-CU","name":"Cuyuni-Mazaruni"},
{"country":"GY","code":"GY-DE","name":"Demerara-Mahaica"},
{"country":"GY","code":"GY-EB","name":"East Berbice-Corentyne"},
{"country":"GY","code":"GY-ES","name":"Essequibo Islands-West Demerara"},
{"country":"GY","code":"GY-MA","name":"Mahaica-Berbice"},
{"country":"GY","code":"GY-PT","name":"Potaro-Siparuni"},
{"country":"GY","code":"GY-UD","name":"Upper Demerara-Berbice"},
{"country":"HK","code":"HK-XX-1","name":"Hong Kong"},
{"country":"HM","code":"HM-XX-1","name":"Heard Island and McDonald Islands"},
{"country":"HN","code":"HN-AT","name":"Atlantida"},
{"country":"HN","code":"HN-CH","name":"Choluteca"},
{"country":"HN","code":"HN-CL","name":"Colon"},
{"country":"HN","code":"HN-CM","name":"Comayagua"},
{"country":"HN","code":"HN-CP","name":"Copan"},
{"country":"HN","code":"HN-CR","name":"Cortes"},
{"country":"HN","code":"HN-EP","name":"El Paraiso"},
{"country":"HN","code":"HN-FM","name":"Francisco Morazan"},
{"country":"HN","code":"HN-GD","name":"Gracias a Dios"},
{"country":"HN","code":"HN-IN","name":"Intibuca"},
{"country":"HN","code":"HN-IB","name":"Islas de la Bahia"},
{"country":"HN","code":"HN-LP","name":"La Paz"},
{"country":"HN","code":"HN-LE","name":"Lempira"},
{"country":"HN","code":"HN-OC","name":"Ocotepeque"},
{"country":"HN","code":"HN-OL","name":"Olancho"},
{"country":"HN","code":"HN-SB","name":"Santa Barbara"},
{"country":"HN","code":"HN-VA","name":"Valle"},
{"country":"HN","code":"HN-YO","name":"Yoro"},
{"country":"HR","code":"HR-07","name":"Bjelovarsko-bilogorska zupanija"},
{"country":"HR","code":"HR-12","name":"Brodsko-posavska zupanija"},
{"country":"HR","code":"HR-19","name":"Dubrovacko-neretvanska zupanija"},
{"country":"HR","code":"HR-21","name":"Grad Zagreb"},
{"country":"HR","code":"HR-18","name":"Istarska zupanija"},
{"country":"HR","code":"HR-04","name":"Karlovacka zupanija"},
{"country":"HR","code":"HR-06","name":"Koprivnicko-krizevacka zupanija"},
{"country":"HR","code":"HR-02","name":"Krapinsko-zagorska zupanija"},
{"country":"HR","code":"HR-09","name":"Licko-senjska zupanija"},
{"country":"HR","code":"HR-20","name":"Medimurska zupanija"},
{"country":"HR","code":"HR-14","name":"Osjecko-baranjska zupanija"},
{"country":"HR","code":"HR-11","name":"Pozesko-slavonska zupanija"},
{"country":"HR","code":"HR-08","name":"Primorsko-goranska zupanija"},
{"country":"HR","code":"HR-15","name":"Sibensko-kninska zupanija"},
{"country":"HR","code":"HR-03","name":"Sisacko-moslavacka zupanija"},
{"country":"HR","code":"HR-17","name":"Splitsko-dalmatinska zupanija"},
{"country":"HR","code":"HR-05","name":"Varazdinska zupanija"},
{"country":"HR","code":"HR-10","name":"Viroviticko-podravska zupanija"},
{"country":"HR","code":"HR-16","name":"Vukovarsko-srijemska zupanija"},
{"country":"HR","code":"HR-13","name":"Zadarska zupanija"},
{"country":"HR","code":"HR-01","name":"Zagrebacka zupanija"},
{"country":"HT","code":"HT-AR","name":"Artibonite"},
{"country":"HT","code":"HT-CE","name":"Centre"},
{"country":"HT","code":"HT-GA","name":"Grande'Anse"},
{"country":"HT","code":"HT-NI","name":"Nippes"},
{"country":"HT","code":"HT-ND","name":"Nord"},
{"country":"HT","code":"HT-OU","name":"Ouest"},
{"country":"HT","code":"HT-SD","name":"Sud"},
{"country":"HT","code":"HT-SE","name":"Sud-Est"},
{"country":"HU","code":"HU-BK","name":"Bacs-Kiskun"},
{"country":"HU","code":"HU-BA","name":"Baranya"},
{"country":"HU","code":"HU-BE","name":"Bekes"},
{"country":"HU","code":"HU-BZ","name":"Borsod-Abauj-Zemplen"},
{"country":"HU","code":"HU-BU","name":"Budapest"},
{"country":"HU","code":"HU-CS","name":"Csongrad-Csanad"},
{"country":"HU","code":"HU-FE","name":"Fejer"},
{"country":"HU","code":"HU-GS","name":"Gyor-Moson-Sopron"},
{"country":"HU","code":"HU-HB","name":"Hajdu-Bihar"},
{"country":"HU","code":"HU-HE","name":"Heves"},
{"country":"HU","code":"HU-JN","name":"Jasz-Nagykun-Szolnok"},
{"country":"HU","code":"HU-KE","name":"Komarom-Esztergom"},
{"country":"HU","code":"HU-NO","name":"Nograd"},
{"country":"HU","code":"HU-PE","name":"Pest"},
{"country":"HU","code":"HU-SO","name":"Somogy"},
{"country":"HU","code":"HU-SZ","name":"Szabolcs-Szatmar-Bereg"},
{"country":"HU","code":"HU-TO","name":"Tolna"},
{"country":"HU","code":"HU-VA","name":"Vas"},
{"country":"HU","code":"HU-VE","name":"Veszprem"},
{"country":"HU","code":"HU-ZA","name":"Zala"},
{"country":"ID","code":"ID-AC","name":"Aceh"},
{"country":"ID","code":"ID-BA","name":"Bali"},
{"country":"ID","code":"ID-BT","name":"Banten"},
{"country":"ID","code":"ID-BE","name":"Bengkulu"},
{"country":"ID","code":"ID-GO","name":"Gorontalo"},
{"country":"ID","code":"ID-JK","name":"Jakarta Raya"},
{"country":"ID","code":"ID-JA","name":"Jambi"},
{"country":"ID","code":"ID-JB","name":"Jawa Barat"},
{"country":"ID","code":"ID-JT","name":"Jawa Tengah"},
{"country":"ID","code":"ID-JI","name":"Jawa Timur"},
{"country":"ID","code":"ID-KB","name":"Kalimantan Barat"},
{"country":"ID","code":"ID-KS","name":"Kalimantan Selatan"},
{"country":"ID","code":"ID-KT","name":"Kalimantan Tengah"},
{"country":"ID","code":"ID-KI","name":"Kalimantan Timur"},
{"country":"ID","code":"ID-KU","name":"Kalimantan Utara"},
{"country":"ID","code":"ID-BB","name":"Kepulauan Bangka Belitung"},
{"country":"ID","code":"ID-KR","name":"Kepulauan Riau"},
{"country":"ID","code":"ID-LA","name":"Lampung"},
{"country":"ID","code":"ID-ML","name":"Maluku"},
{"country":"ID","code":"ID-MU","name":"Maluku Utara"},
{"country":"ID","code":"ID-NB","name":"Nusa Tenggara Barat"},
{"country":"ID","code":"ID-NT","name":"Nusa Tenggara Timur"},
{"country":"ID","code":"ID-PP","name":"Papua"},
{"country":"ID","code":"ID-PB","name":"Papua Barat"},
{"country":"ID","code":"ID-RI","name":"Riau"},
{"country":"ID","code":"ID-SR","name":"Sulawesi Barat"},
{"country":"ID","code":"ID-SN","name":"Sulawesi Selatan"},
{"country":"ID","code":"ID-ST","name":"Sulawesi Tengah"},
{"country":"ID","code":"ID-SG","name":"Sulawesi Tenggara"},
{"country":"ID","code":"ID-SA","name":"Sulawesi Utara"},
{"country":"ID","code":"ID-SB","name":"Sumatera Barat"},
{"country":"ID","code":"ID-SS","name":"Sumatera Selatan"},
{"country":"ID","code":"ID-SU","name":"Sumatera Utara"},
{"country":"ID","code":"ID-YO","name":"Yogyakarta"},
{"country":"IE","code":"IE-CW","name":"Carlow"},
{"country":"IE","code":"IE-CN","name":"Cavan"},
{"country":"IE","code":"IE-CE","name":"Clare"},
{"country":"IE","code":"IE-CO","name":"Cork"},
{"country":"IE","code":"IE-DL","name":"Donegal"},
{"country":"IE","code":"IE-D","name":"Dublin"},
{"country":"IE","code":"IE-G","name":"Galway"},
{"country":"IE","code":"IE-KY","name":"Kerry"},
{"country":"IE","code":"IE-KE","name":"Kildare"},
{"country":"IE","code":"IE-KK","name":"Kilkenny"},
{"country":"IE","code":"IE-LS","name":"Laois"},
{"country":"IE","code":"IE-LM","name":"Leitrim"},
{"country":"IE","code":"IE-LK","name":"Limerick"},
{"country":"IE","code":"IE-LD","name":"Longford"},
{"country":"IE","code":"IE-LH","name":"Louth"},
{"country":"IE","code":"IE-MO","name":"Mayo"},
{"country":"IE","code":"IE-MH","name":"Meath"},
{"country":"IE","code":"IE-MN","name":"Monaghan"},
{"country":"IE","code":"IE-OY","name":"Offaly"},
{"country":"IE","code":"IE-RN","name":"Roscommon"},
{"country":"IE","code":"IE-SO","name":"Sligo"},
{"country":"IE","code":"IE-TA","name":"Tipperary"},
{"country":"IE","code":"IE-WD","name":"Waterford"},
{"country":"IE","code":"IE-WH","name":"Westmeath"},
{"country":"IE","code":"IE-WX","name":"Wexford"},
{"country":"IE","code":"IE-WW","name":"Wicklow"},
{"country":"IL","code":"IL-D","name":"HaDarom"},
{"country":"IL","code":"IL-M","name":"HaMerkaz"},
{"country":"IL","code":"IL-Z","name":"HaTsafon"},
{"country":"IL","code":"IL-HA","name":"Hefa"},
{"country":"IL","code":"IL-TA","name":"Tel Aviv"},
{"country":"IL","code":"IL-JM","name":"Yerushalayim"},
{"country":"IM","code":"IM-XX-1","name":"Isle of Man"},
{"country":"IN","code":"IN-AN","name":"Andaman and Nicobar Islands"},
{"country":"IN","code":"IN-AP","name":"Andhra Pradesh"},
{"country":"IN","code":"IN-AR","name":"Arunachal Pradesh"},
{"country":"IN","code":"IN-AS","name":"Assam"},
{"country":"IN","code":"IN-BR","name":"Bihar"},
{"country":"IN","code":"IN-CH","name":"Chandigarh"},
{"country":"IN","code":"IN-CT","name":"Chhattisgarh"},
{"country":"IN","code":"IN-DN","name":"Dadra and Nagar Haveli"},
{"country":"IN","code":"IN-DH","name":"Dadra and Nagar Haveli and Daman and Diu"},
{"country":"IN","code":"IN-DL","name":"Delhi"},
{"country":"IN","code":"IN-GA","name":"Goa"},
{"country":"IN","code":"IN-GJ","name":"Gujarat"},
{"country":"IN","code":"IN-HR","name":"Haryana"},
{"country":"IN","code":"IN-HP","name":"Himachal Pradesh"},
{"country":"IN","code":"IN-JK","name":"Jammu and Kashmir"},
{"country":"IN","code":"IN-JH","name":"Jharkhand"},
{"country":"IN","code":"IN-KA","name":"Karnataka"},
{"country":"IN","code":"IN-KL","name":"Kerala"},
{"country":"IN","code":"IN-LD","name":"Lakshadweep"},
{"country":"IN","code":"IN-MP","name":"Madhya Pradesh"},
{"country":"IN","code":"IN-MH","name":"Maharashtra"},
{"country":"IN","code":"IN-MN","name":"Manipur"},
{"country":"IN","code":"IN-ML","name":"Meghalaya"},
{"country":"IN","code":"IN-MZ","name":"Mizoram"},
{"country":"IN","code":"IN-NL","name":"Nagaland"},
{"country":"IN","code":"IN-OR","name":"Odisha"},
{"country":"IN","code":"IN-PY","name":"Puducherry"},
{"country":"IN","code":"IN-PB","name":"Punjab"},
{"country":"IN","code":"IN-RJ","name":"Rajasthan"},
{"country":"IN","code":"IN-SK","name":"Sikkim"},
{"country":"IN","code":"IN-TN","name":"Tamil Nadu"},
{"country":"IN","code":"IN-TG","name":"Telangana"},
{"country":"IN","code":"IN-TR","name":"Tripura"},
{"country":"IN","code":"IN-UP","name":"Uttar Pradesh"},
{"country":"IN","code":"IN-UT","name":"Uttarakhand"},
{"country":"IN","code":"IN-WB","name":"West Bengal"},
{"country":"IO","code":"IO-XX-1","name":"British Indian Ocean Territory"},
{"country":"IQ","code":"IQ-AN","name":"Al Anbar"},
{"country":"IQ","code":"IQ-BA","name":"Al Basrah"},
{"country":"IQ","code":"IQ-MU","name":"Al Muthanna"},
{"country":"IQ","code":"IQ-QA","name":"Al Qadisiyah"},
{"country":"IQ","code":"IQ-NA","name":"An Najaf"},
{"country":"IQ","code":"IQ-AR","name":"Arbil"},
{"country":"IQ","code":"IQ-SU","name":"As Sulaymaniyah"},
{"country":"IQ","code":"IQ-BB","name":"Babil"},
{"country":"IQ","code":"IQ-BG","name":"Baghdad"},
{"country":"IQ","code":"IQ-DA","name":"Dahuk"},
{"country":"IQ","code":"IQ-DQ","name":"Dhi Qar"},
{"country":"IQ","code":"IQ-DI","name":"Diyala"},
{"country":"IQ","code":"IQ-KA","name":"Karbala'"},
{"country":"IQ","code":"IQ-KI","name":"Kirkuk"},
{"country":"IQ","code":"IQ-MA","name":"Maysan"},
{"country":"IQ","code":"IQ-NI","name":"Ninawa"},
{"country":"IQ","code":"IQ-SD","name":"Salah ad Din"},
{"country":"IQ","code":"IQ-WA","name":"Wasit"},
{"country":"IR","code":"IR-30","name":"Alborz"},
{"country":"IR","code":"IR-24","name":"Ardabil"},
{"country":"IR","code":"IR-04","name":"Azarbayjan-e Gharbi"},
{"country":"IR","code":"IR-03","name":"Azarbayjan-e Sharqi"},
{"country":"IR","code":"IR-18","name":"Bushehr"},
{"country":"IR","code":"IR-14","name":"Chahar Mahal va Bakhtiari"},
{"country":"IR","code":"IR-10","name":"Esfahan"},
{"country":"IR","code":"IR-07","name":"Fars"},
{"country":"IR","code":"IR-01","name":"Gilan"},
{"country":"IR","code":"IR-27","name":"Golestan"},
{"country":"IR","code":"IR-13","name":"Hamadan"},
{"country":"IR","code":"IR-22","name":"Hormozgan"},
{"country":"IR","code":"IR-16","name":"Ilam"},
{"country":"IR","code":"IR-08","name":"Kerman"},
{"country":"IR","code":"IR-05","name":"Kermanshah"},
{"country":"IR","code":"IR-29","name":"Khorasan-e Jonubi"},
{"country":"IR","code":"IR-09","name":"Khorasan-e Razavi"},
{"country":"IR","code":"IR-28","name":"Khorasan-e Shomali"},
{"country":"IR","code":"IR-06","name":"Khuzestan"},
{"country":"IR","code":"IR-17","name":"Kohgiluyeh va Bowyer Ahmad"},
{"country":"IR","code":"IR-12","name":"Kordestan"},
{"country":"IR","code":"IR-15","name":"Lorestan"},
{"country":"IR","code":"IR-00","name":"Markazi"},
{"country":"IR","code":"IR-02","name":"Mazandaran"},
{"country":"IR","code":"IR-26","name":"Qazvin"},
{"country":"IR","code":"IR-25","name":"Qom"},
{"country":"IR","code":"IR-20","name":"Semnan"},
{"country":"IR","code":"IR-11","name":"Sistan va Baluchestan"},
{"country":"IR","code":"IR-23","name":"Tehran"},
{"country":"IR","code":"IR-21","name":"Yazd"},
{"country":"IR","code":"IR-19","name":"Zanjan"},
{"country":"IS","code":"IS-7","name":"Austurland"},
{"country":"IS","code":"IS-1","name":"Hofudborgarsvaedi"},
{"country":"IS","code":"IS-6","name":"Nordurland eystra"},
{"country":"IS","code":"IS-5","name":"Nordurland vestra"},
{"country":"IS","code":"IS-8","name":"Sudurland"},
{"country":"IS","code":"IS-2","name":"Sudurnes"},
{"country":"IS","code":"IS-4","name":"Vestfirdir"},
{"country":"IS","code":"IS-3","name":"Vesturland"},
{"country":"IT","code":"IT-65","name":"Abruzzo"},
{"country":"IT","code":"IT-77","name":"Basilicata"},
{"country":"IT","code":"IT-78","name":"Calabria"},
{"country":"IT","code":"IT-72","name":"Campania"},
{"country":"IT","code":"IT-45","name":"Emilia-Romagna"},
{"country":"IT","code":"IT-36","name":"Friuli-Venezia Giulia"},
{"country":"IT","code":"IT-62","name":"Lazio"},
{"country":"IT","code":"IT-42","name":"Liguria"},
{"country":"IT","code":"IT-25","name":"Lombardia"},
{"country":"IT","code":"IT-57","name":"Marche"},
{"country":"IT","code":"IT-67","name":"Molise"},
{"country":"IT","code":"IT-21","name":"Piemonte"},
{"country":"IT","code":"IT-75","name":"Puglia"},
{"country":"IT","code":"IT-88","name":"Sardegna"},
{"country":"IT","code":"IT-82","name":"Sicilia"},
{"country":"IT","code":"IT-52","name":"Toscana"},
{"country":"IT","code":"IT-32","name":"Trentino-Alto Adige"},
{"country":"IT","code":"IT-55","name":"Umbria"},
{"country":"IT","code":"IT-23","name":"Valle d'Aosta"},
{"country":"IT","code":"IT-34","name":"Veneto"},
{"country":"JE","code":"JE-XX-1","name":"Jersey"},
{"country":"JM","code":"JM-13","name":"Clarendon"},
{"country":"JM","code":"JM-09","name":"Hanover"},
{"country":"JM","code":"JM-01","name":"Kingston"},
{"country":"JM","code":"JM-12","name":"Manchester"},
{"country":"JM","code":"JM-04","name":"Portland"},
{"country":"JM","code":"JM-02","name":"Saint Andrew"},
{"country":"JM","code":"JM-06","name":"Saint Ann"},
{"country":"JM","code":"JM-14","name":"Saint Catherine"},
{"country":"JM","code":"JM-11","name":"Saint Elizabeth"},
{"country":"JM","code":"JM-08","name":"Saint James"},
{"country":"JM","code":"JM-05","name":"Saint Mary"},
{"country":"JM","code":"JM-03","name":"Saint Thomas"},
{"country":"JM","code":"JM-07","name":"Trelawny"},
{"country":"JM","code":"JM-10","name":"Westmoreland"},
{"country":"JO","code":"JO-AJ","name":"'Ajlun"},
{"country":"JO","code":"JO-AQ","name":"Al 'Aqabah"},
{"country":"JO","code":"JO-AM","name":"Al 'Asimah"},
{"country":"JO","code":"JO-BA","name":"Al Balqa'"},
{"country":"JO","code":"JO-KA","name":"Al Karak"},
{"country":"JO","code":"JO-MA","name":"Al Mafraq"},
{"country":"JO","code":"JO-AT","name":"At Tafilah"},
{"country":"JO","code":"JO-AZ","name":"Az Zarqa'"},
{"country":"JO","code":"JO-IR","name":"Irbid"},
{"country":"JO","code":"JO-JA","name":"Jarash"},
{"country":"JO","code":"JO-MN","name":"Ma'an"},
{"country":"JO","code":"JO-MD","name":"Madaba"},
{"country":"JP","code":"JP-23","name":"Aichi"},
{"country":"JP","code":"JP-05","name":"Akita"},
{"country":"JP","code":"JP-02","name":"Aomori"},
{"country":"JP","code":"JP-12","name":"Chiba"},
{"country":"JP","code":"JP-38","name":"Ehime"},
{"country":"JP","code":"JP-18","name":"Fukui"},
{"country":"JP","code":"JP-40","name":"Fukuoka"},
{"country":"JP","code":"JP-07","name":"Fukushima"},
{"country":"JP","code":"JP-21","name":"Gifu"},
{"country":"JP","code":"JP-10","name":"Gunma"},
{"country":"JP","code":"JP-34","name":"Hiroshima"},
{"country":"JP","code":"JP-01","name":"Hokkaido"},
{"country":"JP","code":"JP-28","name":"Hyogo"},
{"country":"JP","code":"JP-08","name":"Ibaraki"},
{"country":"JP","code":"JP-17","name":"Ishikawa"},
{"country":"JP","code":"JP-03","name":"Iwate"},
{"country":"JP","code":"JP-37","name":"Kagawa"},
{"country":"JP","code":"JP-46","name":"Kagoshima"},
{"country":"JP","code":"JP-14","name":"Kanagawa"},
{"country":"JP","code":"JP-39","name":"Kochi"},
{"country":"JP","code":"JP-43","name":"Kumamoto"},
{"country":"JP","code":"JP-26","name":"Kyoto"},
{"country":"JP","code":"JP-24","name":"Mie"},
{"country":"JP","code":"JP-04","name":"Miyagi"},
{"country":"JP","code":"JP-45","name":"Miyazaki"},
{"country":"JP","code":"JP-20","name":"Nagano"},
{"country":"JP","code":"JP-42","name":"Nagasaki"},
{"country":"JP","code":"JP-29","name":"Nara"},
{"country":"JP","code":"JP-15","name":"Niigata"},
{"country":"JP","code":"JP-44","name":"Oita"},
{"country":"JP","code":"JP-33","name":"Okayama"},
{"country":"JP","code":"JP-47","name":"Okinawa"},
{"country":"JP","code":"JP-27","name":"Osaka"},
{"country":"JP","code":"JP-41","name":"Saga"},
{"country":"JP","code":"JP-11","name":"Saitama"},
{"country":"JP","code":"JP-25","name":"Shiga"},
{"country":"JP","code":"JP-32","name":"Shimane"},
{"country":"JP","code":"JP-22","name":"Shizuoka"},
{"country":"JP","code":"JP-09","name":"Tochigi"},
{"country":"JP","code":"JP-36","name":"Tokushima"},
{"country":"JP","code":"JP-13","name":"Tokyo"},
{"country":"JP","code":"JP-31","name":"Tottori"},
{"country":"JP","code":"JP-16","name":"Toyama"},
{"country":"JP","code":"JP-30","name":"Wakayama"},
{"country":"JP","code":"JP-06","name":"Yamagata"},
{"country":"JP","code":"JP-35","name":"Yamaguchi"},
{"country":"JP","code":"JP-19","name":"Yamanashi"},
{"country":"KE","code":"KE-01","name":"Baringo"},
{"country":"KE","code":"KE-02","name":"Bomet"},
{"country":"KE","code":"KE-03","name":"Bungoma"},
{"country":"KE","code":"KE-04","name":"Busia"},
{"country":"KE","code":"KE-05","name":"Elgeyo/Marakwet"},
{"country":"KE","code":"KE-06","name":"Embu"},
{"country":"KE","code":"KE-07","name":"Garissa"},
{"country":"KE","code":"KE-08","name":"Homa Bay"},
{"country":"KE","code":"KE-09","name":"Isiolo"},
{"country":"KE","code":"KE-10","name":"Kajiado"},
{"country":"KE","code":"KE-11","name":"Kakamega"},
{"country":"KE","code":"KE-12","name":"Kericho"},
{"country":"KE","code":"KE-13","name":"Kiambu"},
{"country":"KE","code":"KE-14","name":"Kilifi"},
{"country":"KE","code":"KE-15","name":"Kirinyaga"},
{"country":"KE","code":"KE-16","name":"Kisii"},
{"country":"KE","code":"KE-17","name":"Kisumu"},
{"country":"KE","code":"KE-18","name":"Kitui"},
{"country":"KE","code":"KE-19","name":"Kwale"},
{"country":"KE","code":"KE-20","name":"Laikipia"},
{"country":"KE","code":"KE-21","name":"Lamu"},
{"country":"KE","code":"KE-22","name":"Machakos"},
{"country":"KE","code":"KE-23","name":"Makueni"},
{"country":"KE","code":"KE-24","name":"Mandera"},
{"country":"KE","code":"KE-25","name":"Marsabit"},
{"country":"KE","code":"KE-26","name":"Meru"},
{"country":"KE","code":"KE-27","name":"Migori"},
{"country":"KE","code":"KE-28","name":"Mombasa"},
{"country":"KE","code":"KE-29","name":"Murang'a"},
{"country":"KE","code":"KE-30","name":"Nairobi City"},
{"country":"KE","code":"KE-31","name":"Nakuru"},
{"country":"KE","code":"KE-32","name":"Nandi"},
{"country":"KE","code":"KE-33","name":"Narok"},
{"country":"KE","code":"KE-34","name":"Nyamira"},
{"country":"KE","code":"KE-35","name":"Nyandarua"},
{"country":"KE","code":"KE-36","name":"Nyeri"},
{"country":"KE","code":"KE-37","name":"Samburu"},
{"country":"KE","code":"KE-38","name":"Siaya"},
{"country":"KE","code":"KE-39","name":"Taita/Taveta"},
{"country":"KE","code":"KE-40","name":"Tana River"},
{"country":"KE","code":"KE-41","name":"Tharaka-Nithi"},
{"country":"KE","code":"KE-42","name":"Trans Nzoia"},
{"country":"KE","code":"KE-43","name":"Turkana"},
{"country":"KE","code":"KE-44","name":"Uasin Gishu"},
{"country":"KE","code":"KE-45","name":"Vihiga"},
{"country":"KE","code":"KE-46","name":"Wajir"},
{"country":"KE","code":"KE-47","name":"West Pokot"},
{"country":"KG","code":"KG-B","name":"Batken"},
{"country":"KG","code":"KG-GB","name":"Bishkek Shaary"},
{"country":"KG","code":"KG-C","name":"Chuy"},
{"country":"KG","code":"KG-J","name":"Jalal-Abad"},
{"country":"KG","code":"KG-N","name":"Naryn"},
{"country":"KG","code":"KG-GO","name":"Osh Shaary"},
{"country":"KG","code":"KG-T","name":"Talas"},
{"country":"KG","code":"KG-Y","name":"Ysyk-Kol"},
{"country":"KH","code":"KH-2","name":"Baat Dambang"},
{"country":"KH","code":"KH-1","name":"Banteay Mean Choay"},
{"country":"KH","code":"KH-23","name":"Kaeb"},
{"country":"KH","code":"KH-3","name":"Kampong Chaam"},
{"country":"KH","code":"KH-4","name":"Kampong Chhnang"},
{"country":"KH","code":"KH-5","name":"Kampong Spueu"},
{"country":"KH","code":"KH-6","name":"Kampong Thum"},
{"country":"KH","code":"KH-7","name":"Kampot"},
{"country":"KH","code":"KH-8","name":"Kandaal"},
{"country":"KH","code":"KH-10","name":"Kracheh"},
{"country":"KH","code":"KH-11","name":"Mondol Kiri"},
{"country":"KH","code":"KH-24","name":"Pailin"},
{"country":"KH","code":"KH-12","name":"Phnom Penh"},
{"country":"KH","code":"KH-15","name":"Pousaat"},
{"country":"KH","code":"KH-18","name":"Preah Sihanouk"},
{"country":"KH","code":"KH-14","name":"Prey Veaeng"},
{"country":"KH","code":"KH-16","name":"Rotanak Kiri"},
{"country":"KH","code":"KH-17","name":"Siem Reab"},
{"country":"KH","code":"KH-19","name":"Stueng Traeng"},
{"country":"KH","code":"KH-20","name":"Svaay Rieng"},
{"country":"KH","code":"KH-21","name":"Taakaev"},
{"country":"KI","code":"KI-G","name":"Gilbert Islands"},
{"country":"KM","code":"KM-G","name":"Grande Comore"},
{"country":"KM","code":"KM-M","name":"Moheli"},
{"country":"KN","code":"KN-01","name":"Christ Church Nichola Town"},
{"country":"KN","code":"KN-02","name":"Saint Anne Sandy Point"},
{"country":"KN","code":"KN-03","name":"Saint George Basseterre"},
{"country":"KN","code":"KN-05","name":"Saint James Windward"},
{"country":"KN","code":"KN-06","name":"Saint John Capisterre"},
{"country":"KN","code":"KN-07","name":"Saint John Figtree"},
{"country":"KN","code":"KN-08","name":"Saint Mary Cayon"},
{"country":"KN","code":"KN-09","name":"Saint Paul Capisterre"},
{"country":"KN","code":"KN-10","name":"Saint Paul Charlestown"},
{"country":"KN","code":"KN-11","name":"Saint Peter Basseterre"},
{"country":"KN","code":"KN-12","name":"Saint Thomas Lowland"},
{"country":"KN","code":"KN-13","name":"Saint Thomas Middle Island"},
{"country":"KN","code":"KN-15","name":"Trinity Palmetto Point"},
{"country":"KP","code":"KP-01","name":"P'yongyang"},
{"country":"KR","code":"KR-26","name":"Busan-gwangyeoksi"},
{"country":"KR","code":"KR-43","name":"Chungcheongbuk-do"},
{"country":"KR","code":"KR-44","name":"Chungcheongnam-do"},
{"country":"KR","code":"KR-27","name":"Daegu-gwangyeoksi"},
{"country":"KR","code":"KR-30","name":"Daejeon-gwangyeoksi"},
{"country":"KR","code":"KR-42","name":"Gangwon-do"},
{"country":"KR","code":"KR-29","name":"Gwangju-gwangyeoksi"},
{"country":"KR","code":"KR-41","name":"Gyeonggi-do"},
{"country":"KR","code":"KR-47","name":"Gyeongsangbuk-do"},
{"country":"KR","code":"KR-48","name":"Gyeongsangnam-do"},
{"country":"KR","code":"KR-28","name":"Incheon-gwangyeoksi"},
{"country":"KR","code":"KR-49","name":"Jeju-teukbyeoljachido"},
{"country":"KR","code":"KR-45","name":"Jeollabuk-do"},
{"country":"KR","code":"KR-46","name":"Jeollanam-do"},
{"country":"KR","code":"KR-11","name":"Seoul-teukbyeolsi"},
{"country":"KR","code":"KR-31","name":"Ulsan-gwangyeoksi"},
{"country":"KW","code":"KW-KU","name":"Al 'Asimah"},
{"country":"KW","code":"KW-AH","name":"Al Ahmadi"},
{"country":"KW","code":"KW-FA","name":"Al Farwaniyah"},
{"country":"KW","code":"KW-JA","name":"Al Jahra'"},
{"country":"KW","code":"KW-HA","name":"Hawalli"},
{"country":"KW","code":"KW-MU","name":"Mubarak al Kabir"},
{"country":"KY","code":"KY-XX-1","name":"Cayman Islands"},
{"country":"KZ","code":"KZ-ALA","name":"Almaty"},
{"country":"KZ","code":"KZ-ALM","name":"Almaty oblysy"},
{"country":"KZ","code":"KZ-AKM","name":"Aqmola oblysy"},
{"country":"KZ","code":"KZ-AKT","name":"Aqtobe oblysy"},
{"country":"KZ","code":"KZ-ATY","name":"Atyrau oblysy"},
{"country":"KZ","code":"KZ-ZAP","name":"Batys Qazaqstan oblysy"},
{"country":"KZ","code":"KZ-MAN","name":"Mangghystau oblysy"},
{"country":"KZ","code":"KZ-AST","name":"Nur-Sultan"},
{"country":"KZ","code":"KZ-YUZ","name":"Ongtustik Qazaqstan oblysy"},
{"country":"KZ","code":"KZ-PAV","name":"Pavlodar oblysy"},
{"country":"KZ","code":"KZ-KAR","name":"Qaraghandy oblysy"},
{"country":"KZ","code":"KZ-KUS","name":"Qostanay oblysy"},
{"country":"KZ","code":"KZ-KZY","name":"Qyzylorda oblysy"},
{"country":"KZ","code":"KZ-VOS","name":"Shyghys Qazaqstan oblysy"},
{"country":"KZ","code":"KZ-SHY","name":"Shymkent"},
{"country":"KZ","code":"KZ-SEV","name":"Soltustik Qazaqstan oblysy"},
{"country":"KZ","code":"KZ-ZHA","name":"Zhambyl oblysy"},
{"country":"LA","code":"LA-AT","name":"Attapu"},
{"country":"LA","code":"LA-BL","name":"Bolikhamxai"},
{"country":"LA","code":"LA-CH","name":"Champasak"},
{"country":"LA","code":"LA-HO","name":"Houaphan"},
{"country":"LA","code":"LA-KH","name":"Khammouan"},
{"country":"LA","code":"LA-OU","name":"Oudomxai"},
{"country":"LA","code":"LA-PH","name":"Phongsali"},
{"country":"LA","code":"LA-SV","name":"Savannakhet"},
{"country":"LA","code":"LA-VI","name":"Viangchan"},
{"country":"LA","code":"LA-XA","name":"Xaignabouli"},
{"country":"LA","code":"LA-XE","name":"Xekong"},
{"country":"LA","code":"LA-XI","name":"Xiangkhouang"},
{"country":"LB","code":"LB-AK","name":"Aakkar"},
{"country":"LB","code":"LB-BH","name":"Baalbek-Hermel"},
{"country":"LB","code":"LB-BI","name":"Beqaa"},
{"country":"LB","code":"LB-BA","name":"Beyrouth"},
{"country":"LB","code":"LB-AS","name":"Liban-Nord"},
{"country":"LB","code":"LB-JA","name":"Liban-Sud"},
{"country":"LB","code":"LB-JL","name":"Mont-Liban"},
{"country":"LB","code":"LB-NA","name":"Nabatiye"},
{"country":"LC","code":"LC-01","name":"Anse la Raye"},
{"country":"LC","code":"LC-02","name":"Castries"},
{"country":"LC","code":"LC-03","name":"Choiseul"},
{"country":"LC","code":"LC-05","name":"Dennery"},
{"country":"LC","code":"LC-06","name":"Gros Islet"},
{"country":"LC","code":"LC-07","name":"Laborie"},
{"country":"LC","code":"LC-08","name":"Micoud"},
{"country":"LC","code":"LC-10","name":"Soufriere"},
{"country":"LC","code":"LC-11","name":"Vieux Fort"},
{"country":"LI","code":"LI-01","name":"Balzers"},
{"country":"LI","code":"LI-02","name":"Eschen"},
{"country":"LI","code":"LI-03","name":"Gamprin"},
{"country":"LI","code":"LI-04","name":"Mauren"},
{"country":"LI","code":"LI-05","name":"Planken"},
{"country":"LI","code":"LI-06","name":"Ruggell"},
{"country":"LI","code":"LI-07","name":"Schaan"},
{"country":"LI","code":"LI-09","name":"Triesen"},
{"country":"LI","code":"LI-10","name":"Triesenberg"},
{"country":"LI","code":"LI-11","name":"Vaduz"},
{"country":"LK","code":"LK-2","name":"Central Province"},
{"country":"LK","code":"LK-5","name":"Eastern Province"},
{"country":"LK","code":"LK-7","name":"North Central Province"},
{"country":"LK","code":"LK-6","name":"North Western Province"},
{"country":"LK","code":"LK-4","name":"Northern Province"},
{"country":"LK","code":"LK-9","name":"Sabaragamuwa Province"},
{"country":"LK","code":"LK-3","name":"Southern Province"},
{"country":"LK","code":"LK-8","name":"Uva Province"},
{"country":"LK","code":"LK-1","name":"Western Province"},
{"country":"LR","code":"LR-BM","name":"Bomi"},
{"country":"LR","code":"LR-GB","name":"Grand Bassa"},
{"country":"LR","code":"LR-GG","name":"Grand Gedeh"},
{"country":"LR","code":"LR-MG","name":"Margibi"},
{"country":"LR","code":"LR-MO","name":"Montserrado"},
{"country":"LR","code":"LR-NI","name":"Nimba"},
{"country":"LR","code":"LR-SI","name":"Sinoe"},
{"country":"LS","code":"LS-D","name":"Berea"},
{"country":"LS","code":"LS-B","name":"Botha-Bothe"},
{"country":"LS","code":"LS-C","name":"Leribe"},
{"country":"LS","code":"LS-E","name":"Mafeteng"},
{"country":"LS","code":"LS-A","name":"Maseru"},
{"country":"LS","code":"LS-F","name":"Mohale's Hoek"},
{"country":"LS","code":"LS-J","name":"Mokhotlong"},
{"country":"LS","code":"LS-H","name":"Qacha's Nek"},
{"country":"LS","code":"LS-G","name":"Quthing"},
{"country":"LS","code":"LS-K","name":"Thaba-Tseka"},
{"country":"LT","code":"LT-AL","name":"Alytaus apskritis"},
{"country":"LT","code":"LT-KU","name":"Kauno apskritis"},
{"country":"LT","code":"LT-KL","name":"Klaipedos apskritis"},
{"country":"LT","code":"LT-MR","name":"Marijampoles apskritis"},
{"country":"LT","code":"LT-PN","name":"Panevezio apskritis"},
{"country":"LT","code":"LT-SA","name":"Siauliu apskritis"},
{"country":"LT","code":"LT-TA","name":"Taurages apskritis"},
{"country":"LT","code":"LT-TE","name":"Telsiu apskritis"},
{"country":"LT","code":"LT-UT","name":"Utenos apskritis"},
{"country":"LT","code":"LT-VL","name":"Vilniaus apskritis"},
{"country":"LU","code":"LU-CA","name":"Capellen"},
{"country":"LU","code":"LU-CL","name":"Clervaux"},
{"country":"LU","code":"LU-DI","name":"Diekirch"},
{"country":"LU","code":"LU-EC","name":"Echternach"},
{"country":"LU","code":"LU-ES","name":"Esch-sur-Alzette"},
{"country":"LU","code":"LU-GR","name":"Grevenmacher"},
{"country":"LU","code":"LU-LU","name":"Luxembourg"},
{"country":"LU","code":"LU-ME","name":"Mersch"},
{"country":"LU","code":"LU-RD","name":"Redange"},
{"country":"LU","code":"LU-RM","name":"Remich"},
{"country":"LU","code":"LU-VD","name":"Vianden"},
{"country":"LU","code":"LU-WI","name":"Wiltz"},
{"country":"LV","code":"LV-011","name":"Adazu novads"},
{"country":"LV","code":"LV-002","name":"Aizkraukles novads"},
{"country":"LV","code":"LV-007","name":"Aluksnes novads"},
{"country":"LV","code":"LV-111","name":"Augsdaugavas novads"},
{"country":"LV","code":"LV-015","name":"Balvu novads"},
{"country":"LV","code":"LV-016","name":"Bauskas novads"},
{"country":"LV","code":"LV-022","name":"Cesu novads"},
{"country":"LV","code":"LV-DGV","name":"Daugavpils"},
{"country":"LV","code":"LV-112","name":"Dienvidkurzemes novads"},
{"country":"LV","code":"LV-026","name":"Dobeles novads"},
{"country":"LV","code":"LV-033","name":"Gulbenes novads"},
{"country":"LV","code":"LV-042","name":"Jekabpils novads"},
{"country":"LV","code":"LV-JEL","name":"Jelgava"},
{"country":"LV","code":"LV-041","name":"Jelgavas novads"},
{"country":"LV","code":"LV-JUR","name":"Jurmala"},
{"country":"LV","code":"LV-052","name":"Kekavas novads"},
{"country":"LV","code":"LV-047","name":"Kraslavas novads"},
{"country":"LV","code":"LV-050","name":"Kuldigas novads"},
{"country":"LV","code":"LV-LPX","name":"Liepaja"},
{"country":"LV","code":"LV-054","name":"Limbazu novads"},
{"country":"LV","code":"LV-056","name":"Livanu novads"},
{"country":"LV","code":"LV-058","name":"Ludzas novads"},
{"country":"LV","code":"LV-059","name":"Madonas novads"},
{"country":"LV","code":"LV-062","name":"Marupes novads"},
{"country":"LV","code":"LV-067","name":"Ogres novads"},
{"country":"LV","code":"LV-068","name":"Olaines novads"},
{"country":"LV","code":"LV-073","name":"Preilu novads"},
{"country":"LV","code":"LV-077","name":"Rezeknes novads"},
{"country":"LV","code":"LV-RIX","name":"Riga"},
{"country":"LV","code":"LV-080","name":"Ropazu novads"},
{"country":"LV","code":"LV-087","name":"Salaspils novads"},
{"country":"LV","code":"LV-088","name":"Saldus novads"},
{"country":"LV","code":"LV-089","name":"Saulkrastu novads"},
{"country":"LV","code":"LV-091","name":"Siguldas novads"},
{"country":"LV","code":"LV-094","name":"Smiltenes novads"},
{"country":"LV","code":"LV-097","name":"Talsu novads"},
{"country":"LV","code":"LV-099","name":"Tukuma novads"},
{"country":"LV","code":"LV-101","name":"Valkas novads"},
{"country":"LV","code":"LV-113","name":"Valmieras novads"},
{"country":"LV","code":"LV-102","name":"Varaklanu novads"},
{"country":"LV","code":"LV-106","name":"Ventspils novads"},
{"country":"LY","code":"LY-BU","name":"Al Butnan"},
{"country":"LY","code":"LY-JA","name":"Al Jabal al Akhdar"},
{"country":"LY","code":"LY-JG","name":"Al Jabal al Gharbi"},
{"country":"LY","code":"LY-JI","name":"Al Jafarah"},
{"country":"LY","code":"LY-JU","name":"Al Jufrah"},
{"country":"LY","code":"LY-KF","name":"Al Kufrah"},
{"country":"LY","code":"LY-MJ","name":"Al Marj"},
{"country":"LY","code":"LY-MB","name":"Al Marqab"},
{"country":"LY","code":"LY-WA","name":"Al Wahat"},
{"country":"LY","code":"LY-NQ","name":"An Nuqat al Khams"},
{"country":"LY","code":"LY-ZA","name":"Az Zawiyah"},
{"country":"LY","code":"LY-BA","name":"Banghazi"},
{"country":"LY","code":"LY-DR","name":"Darnah"},
{"country":"LY","code":"LY-MI","name":"Misratah"},
{"country":"LY","code":"LY-NL","name":"Nalut"},
{"country":"LY","code":"LY-SB","name":"Sabha"},
{"country":"LY","code":"LY-SR","name":"Surt"},
{"country":"LY","code":"LY-TB","name":"Tarabulus"},
{"country":"LY","code":"LY-WS","name":"Wadi ash Shati'"},
{"country":"MA","code":"MA-05","name":"Beni-Mellal-Khenifra"},
{"country":"MA","code":"MA-06","name":"Casablanca-Settat"},
{"country":"MA","code":"MA-08","name":"Draa-Tafilalet"},
{"country":"MA","code":"MA-03","name":"Fes- Meknes"},
{"country":"MA","code":"MA-10","name":"Guelmim-Oued Noun (EH-partial)"},
{"country":"MA","code":"MA-02","name":"L'Oriental"},
{"country":"MA","code":"MA-11","name":"Laayoune-Sakia El Hamra (EH-partial)"},
{"country":"MA","code":"MA-07","name":"Marrakech-Safi"},
{"country":"MA","code":"MA-04","name":"Rabat-Sale-Kenitra"},
{"country":"MA","code":"MA-09","name":"Souss-Massa"},
{"country":"MA","code":"MA-01","name":"Tanger-Tetouan-Al Hoceima"},
{"country":"MC","code":"MC-FO","name":"Fontvieille"},
{"country":"MC","code":"MC-CO","name":"La Condamine"},
{"country":"MC","code":"MC-MO","name":"Monaco-Ville"},
{"country":"MC","code":"MC-MC","name":"Monte-Carlo"},
{"country":"MC","code":"MC-SR","name":"Saint-Roman"},
{"country":"MD","code":"MD-AN","name":"Anenii Noi"},
{"country":"MD","code":"MD-BA","name":"Balti"},
{"country":"MD","code":"MD-BS","name":"Basarabeasca"},
{"country":"MD","code":"MD-BD","name":"Bender"},
{"country":"MD","code":"MD-BR","name":"Briceni"},
{"country":"MD","code":"MD-CA","name":"Cahul"},
{"country":"MD","code":"MD-CL","name":"Calarasi"},
{"country":"MD","code":"MD-CT","name":"Cantemir"},
{"country":"MD","code":"MD-CS","name":"Causeni"},
{"country":"MD","code":"MD-CU","name":"Chisinau"},
{"country":"MD","code":"MD-CM","name":"Cimislia"},
{"country":"MD","code":"MD-CR","name":"Criuleni"},
{"country":"MD","code":"MD-DO","name":"Donduseni"},
{"country":"MD","code":"MD-DR","name":"Drochia"},
{"country":"MD","code":"MD-DU","name":"Dubasari"},
{"country":"MD","code":"MD-ED","name":"Edinet"},
{"country":"MD","code":"MD-FA","name":"Falesti"},
{"country":"MD","code":"MD-FL","name":"Floresti"},
{"country":"MD","code":"MD-GA","name":"Gagauzia, Unitatea teritoriala autonoma"},
{"country":"MD","code":"MD-GL","name":"Glodeni"},
{"country":"MD","code":"MD-HI","name":"Hincesti"},
{"country":"MD","code":"MD-IA","name":"Ialoveni"},
{"country":"MD","code":"MD-LE","name":"Leova"},
{"country":"MD","code":"MD-NI","name":"Nisporeni"},
{"country":"MD","code":"MD-OC","name":"Ocnita"},
{"country":"MD","code":"MD-OR","name":"Orhei"},
{"country":"MD","code":"MD-RE","name":"Rezina"},
{"country":"MD","code":"MD-RI","name":"Riscani"},
{"country":"MD","code":"MD-SI","name":"Singerei"},
{"country":"MD","code":"MD-SD","name":"Soldanesti"},
{"country":"MD","code":"MD-SO","name":"Soroca"},
{"country":"MD","code":"MD-SV","name":"Stefan Voda"},
{"country":"MD","code":"MD-SN","name":"Stinga Nistrului, unitatea teritoriala din"},
{"country":"MD","code":"MD-ST","name":"Straseni"},
{"country":"MD","code":"MD-TA","name":"Taraclia"},
{"country":"MD","code":"MD-TE","name":"Telenesti"},
{"country":"MD","code":"MD-UN","name":"Ungheni"},
{"country":"ME","code":"ME-01","name":"Andrijevica"},
{"country":"ME","code":"ME-02","name":"Bar"},
{"country":"ME","code":"ME-03","name":"Berane"},
{"country":"ME","code":"ME-04","name":"Bijelo Polje"},
{"country":"ME","code":"ME-05","name":"Budva"},
{"country":"ME","code":"ME-06","name":"Cetinje"},
{"country":"ME","code":"ME-07","name":"Danilovgrad"},
{"country":"ME","code":"ME-08","name":"Herceg-Novi"},
{"country":"ME","code":"ME-10","name":"Kotor"},
{"country":"ME","code":"ME-12","name":"Niksic"},
{"country":"ME","code":"ME-13","name":"Plav"},
{"country":"ME","code":"ME-14","name":"Pljevlja"},
{"country":"ME","code":"ME-15","name":"Pluzine"},
{"country":"ME","code":"ME-16","name":"Podgorica"},
{"country":"ME","code":"ME-17","name":"Rozaje"},
{"country":"ME","code":"ME-19","name":"Tivat"},
{"country":"ME","code":"ME-24","name":"Tuzi"},
{"country":"ME","code":"ME-20","name":"Ulcinj"},
{"country":"ME","code":"ME-21","name":"Zabljak"},
{"country":"MF","code":"MF-XX-1","name":"Saint Martin (French Part)"},
{"country":"MG","code":"MG-T","name":"Antananarivo"},
{"country":"MG","code":"MG-D","name":"Antsiranana"},
{"country":"MG","code":"MG-F","name":"Fianarantsoa"},
{"country":"MG","code":"MG-M","name":"Mahajanga"},
{"country":"MG","code":"MG-A","name":"Toamasina"},
{"country":"MG","code":"MG-U","name":"Toliara"},
{"country":"MH","code":"MH-KWA","name":"Kwajalein"},
{"country":"MH","code":"MH-MAJ","name":"Majuro"},
{"country":"MK","code":"MK-802","name":"Aracinovo"},
{"country":"MK","code":"MK-201","name":"Berovo"},
{"country":"MK","code":"MK-501","name":"Bitola"},
{"country":"MK","code":"MK-401","name":"Bogdanci"},
{"country":"MK","code":"MK-601","name":"Bogovinje"},
{"country":"MK","code":"MK-402","name":"Bosilovo"},
{"country":"MK","code":"MK-602","name":"Brvenica"},
{"country":"MK","code":"MK-803","name":"Butel"},
{"country":"MK","code":"MK-109","name":"Caska"},
{"country":"MK","code":"MK-814","name":"Centar"},
{"country":"MK","code":"MK-210","name":"Cesinovo-Oblesevo"},
{"country":"MK","code":"MK-816","name":"Cucer Sandevo"},
{"country":"MK","code":"MK-303","name":"Debar"},
{"country":"MK","code":"MK-203","name":"Delcevo"},
{"country":"MK","code":"MK-502","name":"Demir Hisar"},
{"country":"MK","code":"MK-406","name":"Dojran"},
{"country":"MK","code":"MK-503","name":"Dolneni"},
{"country":"MK","code":"MK-804","name":"Gazi Baba"},
{"country":"MK","code":"MK-405","name":"Gevgelija"},
{"country":"MK","code":"MK-604","name":"Gostivar"},
{"country":"MK","code":"MK-102","name":"Gradsko"},
{"country":"MK","code":"MK-807","name":"Ilinden"},
{"country":"MK","code":"MK-606","name":"Jegunovce"},
{"country":"MK","code":"MK-205","name":"Karbinci"},
{"country":"MK","code":"MK-104","name":"Kavadarci"},
{"country":"MK","code":"MK-307","name":"Kicevo"},
{"country":"MK","code":"MK-809","name":"Kisela Voda"},
{"country":"MK","code":"MK-206","name":"Kocani"},
{"country":"MK","code":"MK-701","name":"Kratovo"},
{"country":"MK","code":"MK-702","name":"Kriva Palanka"},
{"country":"MK","code":"MK-505","name":"Krusevo"},
{"country":"MK","code":"MK-703","name":"Kumanovo"},
{"country":"MK","code":"MK-704","name":"Lipkovo"},
{"country":"MK","code":"MK-105","name":"Lozovo"},
{"country":"MK","code":"MK-207","name":"Makedonska Kamenica"},
{"country":"MK","code":"MK-308","name":"Makedonski Brod"},
{"country":"MK","code":"MK-607","name":"Mavrovo i Rostusa"},
{"country":"MK","code":"MK-506","name":"Mogila"},
{"country":"MK","code":"MK-106","name":"Negotino"},
{"country":"MK","code":"MK-507","name":"Novaci"},
{"country":"MK","code":"MK-408","name":"Novo Selo"},
{"country":"MK","code":"MK-310","name":"Ohrid"},
{"country":"MK","code":"MK-208","name":"Pehcevo"},
{"country":"MK","code":"MK-810","name":"Petrovec"},
{"country":"MK","code":"MK-311","name":"Plasnica"},
{"country":"MK","code":"MK-508","name":"Prilep"},
{"country":"MK","code":"MK-209","name":"Probistip"},
{"country":"MK","code":"MK-409","name":"Radovis"},
{"country":"MK","code":"MK-705","name":"Rankovce"},
{"country":"MK","code":"MK-509","name":"Resen"},
{"country":"MK","code":"MK-107","name":"Rosoman"},
{"country":"MK","code":"MK-811","name":"Saraj"},
{"country":"MK","code":"MK-812","name":"Sopiste"},
{"country":"MK","code":"MK-211","name":"Stip"},
{"country":"MK","code":"MK-312","name":"Struga"},
{"country":"MK","code":"MK-410","name":"Strumica"},
{"country":"MK","code":"MK-813","name":"Studenicani"},
{"country":"MK","code":"MK-108","name":"Sveti Nikole"},
{"country":"MK","code":"MK-608","name":"Tearce"},
{"country":"MK","code":"MK-609","name":"Tetovo"},
{"country":"MK","code":"MK-403","name":"Valandovo"},
{"country":"MK","code":"MK-404","name":"Vasilevo"},
{"country":"MK","code":"MK-101","name":"Veles"},
{"country":"MK","code":"MK-301","name":"Vevcani"},
{"country":"MK","code":"MK-202","name":"Vinica"},
{"country":"MK","code":"MK-603","name":"Vrapciste"},
{"country":"MK","code":"MK-806","name":"Zelenikovo"},
{"country":"MK","code":"MK-605","name":"Zelino"},
{"country":"ML","code":"ML-BKO","name":"Bamako"},
{"country":"ML","code":"ML-7","name":"Gao"},
{"country":"ML","code":"ML-1","name":"Kayes"},
{"country":"ML","code":"ML-8","name":"Kidal"},
{"country":"ML","code":"ML-2","name":"Koulikoro"},
{"country":"ML","code":"ML-5","name":"Mopti"},
{"country":"ML","code":"ML-4","name":"Segou"},
{"country":"ML","code":"ML-3","name":"Sikasso"},
{"country":"ML","code":"ML-6","name":"Tombouctou"},
{"country":"MM","code":"MM-07","name":"Ayeyarwady"},
{"country":"MM","code":"MM-02","name":"Bago"},
{"country":"MM","code":"MM-14","name":"Chin"},
{"country":"MM","code":"MM-11","name":"Kachin"},
{"country":"MM","code":"MM-12","name":"Kayah"},
{"country":"MM","code":"MM-13","name":"Kayin"},
{"country":"MM","code":"MM-03","name":"Magway"},
{"country":"MM","code":"MM-04","name":"Mandalay"},
{"country":"MM","code":"MM-15","name":"Mon"},
{"country":"MM","code":"MM-18","name":"Nay Pyi Taw"},
{"country":"MM","code":"MM-16","name":"Rakhine"},
{"country":"MM","code":"MM-01","name":"Sagaing"},
{"country":"MM","code":"MM-17","name":"Shan"},
{"country":"MM","code":"MM-05","name":"Tanintharyi"},
{"country":"MM","code":"MM-06","name":"Yangon"},
{"country":"MN","code":"MN-071","name":"Bayan-Olgiy"},
{"country":"MN","code":"MN-037","name":"Darhan uul"},
{"country":"MN","code":"MN-061","name":"Dornod"},
{"country":"MN","code":"MN-063","name":"Dornogovi"},
{"country":"MN","code":"MN-065","name":"Govi-Altay"},
{"country":"MN","code":"MN-043","name":"Hovd"},
{"country":"MN","code":"MN-035","name":"Orhon"},
{"country":"MN","code":"MN-055","name":"Ovorhangay"},
{"country":"MN","code":"MN-049","name":"Selenge"},
{"country":"MN","code":"MN-047","name":"Tov"},
{"country":"MN","code":"MN-1","name":"Ulaanbaatar"},
{"country":"MO","code":"MO-XX-1","name":"Macao"},
{"country":"MP","code":"MP-XX-1","name":"Northern Mariana Islands"},
{"country":"MQ","code":"MQ-XX-1","name":"Martinique"},
{"country":"MR","code":"MR-07","name":"Adrar"},
{"country":"MR","code":"MR-03","name":"Assaba"},
{"country":"MR","code":"MR-05","name":"Brakna"},
{"country":"MR","code":"MR-08","name":"Dakhlet Nouadhibou"},
{"country":"MR","code":"MR-04","name":"Gorgol"},
{"country":"MR","code":"MR-10","name":"Guidimaka"},
{"country":"MR","code":"MR-01","name":"Hodh ech Chargui"},
{"country":"MR","code":"MR-02","name":"Hodh el Gharbi"},
{"country":"MR","code":"MR-12","name":"Inchiri"},
{"country":"MR","code":"MR-13","name":"Nouakchott Ouest"},
{"country":"MR","code":"MR-09","name":"Tagant"},
{"country":"MR","code":"MR-11","name":"Tiris Zemmour"},
{"country":"MR","code":"MR-06","name":"Trarza"},
{"country":"MS","code":"MS-XX-1","name":"Saint Anthony"},
{"country":"MS","code":"MS-XX-2","name":"Saint Peter"},
{"country":"MT","code":"MT-01","name":"Attard"},
{"country":"MT","code":"MT-02","name":"Balzan"},
{"country":"MT","code":"MT-03","name":"Birgu"},
{"country":"MT","code":"MT-04","name":"Birkirkara"},
{"country":"MT","code":"MT-05","name":"Birzebbuga"},
{"country":"MT","code":"MT-06","name":"Bormla"},
{"country":"MT","code":"MT-07","name":"Dingli"},
{"country":"MT","code":"MT-08","name":"Fgura"},
{"country":"MT","code":"MT-09","name":"Floriana"},
{"country":"MT","code":"MT-10","name":"Fontana"},
{"country":"MT","code":"MT-14","name":"Gharb"},
{"country":"MT","code":"MT-15","name":"Gharghur"},
{"country":"MT","code":"MT-16","name":"Ghasri"},
{"country":"MT","code":"MT-17","name":"Ghaxaq"},
{"country":"MT","code":"MT-11","name":"Gudja"},
{"country":"MT","code":"MT-12","name":"Gzira"},
{"country":"MT","code":"MT-18","name":"Hamrun"},
{"country":"MT","code":"MT-19","name":"Iklin"},
{"country":"MT","code":"MT-20","name":"Isla"},
{"country":"MT","code":"MT-21","name":"Kalkara"},
{"country":"MT","code":"MT-22","name":"Kercem"},
{"country":"MT","code":"MT-23","name":"Kirkop"},
{"country":"MT","code":"MT-24","name":"Lija"},
{"country":"MT","code":"MT-25","name":"Luqa"},
{"country":"MT","code":"MT-26","name":"Marsa"},
{"country":"MT","code":"MT-27","name":"Marsaskala"},
{"country":"MT","code":"MT-28","name":"Marsaxlokk"},
{"country":"MT","code":"MT-29","name":"Mdina"},
{"country":"MT","code":"MT-30","name":"Mellieha"},
{"country":"MT","code":"MT-31","name":"Mgarr"},
{"country":"MT","code":"MT-32","name":"Mosta"},
{"country":"MT","code":"MT-33","name":"Mqabba"},
{"country":"MT","code":"MT-34","name":"Msida"},
{"country":"MT","code":"MT-35","name":"Mtarfa"},
{"country":"MT","code":"MT-36","name":"Munxar"},
{"country":"MT","code":"MT-37","name":"Nadur"},
{"country":"MT","code":"MT-38","name":"Naxxar"},
{"country":"MT","code":"MT-39","name":"Paola"},
{"country":"MT","code":"MT-40","name":"Pembroke"},
{"country":"MT","code":"MT-41","name":"Pieta"},
{"country":"MT","code":"MT-42","name":"Qala"},
{"country":"MT","code":"MT-43","name":"Qormi"},
{"country":"MT","code":"MT-45","name":"Rabat Gozo"},
{"country":"MT","code":"MT-46","name":"Rabat Malta"},
{"country":"MT","code":"MT-49","name":"Saint John"},
{"country":"MT","code":"MT-48","name":"Saint Julian's"},
{"country":"MT","code":"MT-53","name":"Saint Lucia's"},
{"country":"MT","code":"MT-51","name":"Saint Paul's Bay"},
{"country":"MT","code":"MT-52","name":"Sannat"},
{"country":"MT","code":"MT-54","name":"Santa Venera"},
{"country":"MT","code":"MT-55","name":"Siggiewi"},
{"country":"MT","code":"MT-56","name":"Sliema"},
{"country":"MT","code":"MT-57","name":"Swieqi"},
{"country":"MT","code":"MT-58","name":"Ta' Xbiex"},
{"country":"MT","code":"MT-59","name":"Tarxien"},
{"country":"MT","code":"MT-60","name":"Valletta"},
{"country":"MT","code":"MT-61","name":"Xaghra"},
{"country":"MT","code":"MT-62","name":"Xewkija"},
{"country":"MT","code":"MT-63","name":"Xghajra"},
{"country":"MT","code":"MT-64","name":"Zabbar"},
{"country":"MT","code":"MT-65","name":"Zebbug Gozo"},
{"country":"MT","code":"MT-67","name":"Zejtun"},
{"country":"MT","code":"MT-68","name":"Zurrieq"},
{"country":"MU","code":"MU-BL","name":"Black River"},
{"country":"MU","code":"MU-FL","name":"Flacq"},
{"country":"MU","code":"MU-GP","name":"Grand Port"},
{"country":"MU","code":"MU-MO","name":"Moka"},
{"country":"MU","code":"MU-PA","name":"Pamplemousses"},
{"country":"MU","code":"MU-PW","name":"Plaines Wilhems"},
{"country":"MU","code":"MU-PL","name":"Port Louis"},
{"country":"MU","code":"MU-RR","name":"Riviere du Rempart"},
{"country":"MU","code":"MU-RO","name":"Rodrigues Islands"},
{"country":"MU","code":"MU-SA","name":"Savanne"},
{"country":"MV","code":"MV-01","name":"Addu City"},
{"country":"MV","code":"MV-03","name":"Faadhippolhu"},
{"country":"MV","code":"MV-04","name":"Felidhu Atoll"},
{"country":"MV","code":"MV-05","name":"Hahdhunmathi"},
{"country":"MV","code":"MV-MLE","name":"Male"},
{"country":"MV","code":"MV-12","name":"Mulaku Atoll"},
{"country":"MV","code":"MV-13","name":"North Maalhosmadulu"},
{"country":"MV","code":"MV-00","name":"South Ari Atoll"},
{"country":"MV","code":"MV-28","name":"South Huvadhu Atoll"},
{"country":"MV","code":"MV-20","name":"South Maalhosmadulu"},
{"country":"MV","code":"MV-25","name":"South Miladhunmadulu"},
{"country":"MV","code":"MV-17","name":"South Nilandhe Atoll"},
{"country":"MW","code":"MW-BA","name":"Balaka"},
{"country":"MW","code":"MW-BL","name":"Blantyre"},
{"country":"MW","code":"MW-CK","name":"Chikwawa"},
{"country":"MW","code":"MW-CR","name":"Chiradzulu"},
{"country":"MW","code":"MW-DE","name":"Dedza"},
{"country":"MW","code":"MW-DO","name":"Dowa"},
{"country":"MW","code":"MW-KR","name":"Karonga"},
{"country":"MW","code":"MW-LI","name":"Lilongwe"},
{"country":"MW","code":"MW-MH","name":"Machinga"},
{"country":"MW","code":"MW-MG","name":"Mangochi"},
{"country":"MW","code":"MW-MW","name":"Mwanza"},
{"country":"MW","code":"MW-MZ","name":"Mzimba"},
{"country":"MW","code":"MW-NE","name":"Neno"},
{"country":"MW","code":"MW-NK","name":"Nkhotakota"},
{"country":"MW","code":"MW-PH","name":"Phalombe"},
{"country":"MW","code":"MW-SA","name":"Salima"},
{"country":"MW","code":"MW-TH","name":"Thyolo"},
{"country":"MW","code":"MW-ZO","name":"Zomba"},
{"country":"MX","code":"MX-AGU","name":"Aguascalientes"},
{"country":"MX","code":"MX-BCN","name":"Baja California"},
{"country":"MX","code":"MX-BCS","name":"Baja California Sur"},
{"country":"MX","code":"MX-CAM","name":"Campeche"},
{"country":"MX","code":"MX-CHP","name":"Chiapas"},
{"country":"MX","code":"MX-CHH","name":"Chihuahua"},
{"country":"MX","code":"MX-CMX","name":"Ciudad de Mexico"},
{"country":"MX","code":"MX-COA","name":"Coahuila de Zaragoza"},
{"country":"MX","code":"MX-COL","name":"Colima"},
{"country":"MX","code":"MX-DUR","name":"Durango"},
{"country":"MX","code":"MX-GUA","name":"Guanajuato"},
{"country":"MX","code":"MX-GRO","name":"Guerrero"},
{"country":"MX","code":"MX-HID","name":"Hidalgo"},
{"country":"MX","code":"MX-JAL","name":"Jalisco"},
{"country":"MX","code":"MX-MEX","name":"Mexico"},
{"country":"MX","code":"MX-MIC","name":"Michoacan de Ocampo"},
{"country":"MX","code":"MX-MOR","name":"Morelos"},
{"country":"MX","code":"MX-NAY","name":"Nayarit"},
{"country":"MX","code":"MX-NLE","name":"Nuevo Leon"},
{"country":"MX","code":"MX-OAX","name":"Oaxaca"},
{"country":"MX","code":"MX-PUE","name":"Puebla"},
{"country":"MX","code":"MX-QUE","name":"Queretaro"},
{"country":"MX","code":"MX-ROO","name":"Quintana Roo"},
{"country":"MX","code":"MX-SLP","name":"San Luis Potosi"},
{"country":"MX","code":"MX-SIN","name":"Sinaloa"},
{"country":"MX","code":"MX-SON","name":"Sonora"},
{"country":"MX","code":"MX-TAB","name":"Tabasco"},
{"country":"MX","code":"MX-TAM","name":"Tamaulipas"},
{"country":"MX","code":"MX-TLA","name":"Tlaxcala"},
{"country":"MX","code":"MX-VER","name":"Veracruz de Ignacio de la Llave"},
{"country":"MX","code":"MX-YUC","name":"Yucatan"},
{"country":"MX","code":"MX-ZAC","name":"Zacatecas"},
{"country":"MY","code":"MY-01","name":"Johor"},
{"country":"MY","code":"MY-02","name":"Kedah"},
{"country":"MY","code":"MY-03","name":"Kelantan"},
{"country":"MY","code":"MY-04","name":"Melaka"},
{"country":"MY","code":"MY-05","name":"Negeri Sembilan"},
{"country":"MY","code":"MY-06","name":"Pahang"},
{"country":"MY","code":"MY-08","name":"Perak"},
{"country":"MY","code":"MY-09","name":"Perlis"},
{"country":"MY","code":"MY-07","name":"Pulau Pinang"},
{"country":"MY","code":"MY-12","name":"Sabah"},
{"country":"MY","code":"MY-13","name":"Sarawak"},
{"country":"MY","code":"MY-10","name":"Selangor"},
{"country":"MY","code":"MY-11","name":"Terengganu"},
{"country":"MY","code":"MY-14","name":"Wilayah Persekutuan Kuala Lumpur"},
{"country":"MY","code":"MY-15","name":"Wilayah Persekutuan Labuan"},
{"country":"MY","code":"MY-16","name":"Wilayah Persekutuan Putrajaya"},
{"country":"MZ","code":"MZ-P","name":"Cabo Delgado"},
{"country":"MZ","code":"MZ-G","name":"Gaza"},
{"country":"MZ","code":"MZ-I","name":"Inhambane"},
{"country":"MZ","code":"MZ-B","name":"Manica"},
{"country":"MZ","code":"MZ-L","name":"Maputo"},
{"country":"MZ","code":"MZ-N","name":"Nampula"},
{"country":"MZ","code":"MZ-A","name":"Niassa"},
{"country":"MZ","code":"MZ-S","name":"Sofala"},
{"country":"MZ","code":"MZ-T","name":"Tete"},
{"country":"MZ","code":"MZ-Q","name":"Zambezia"},
{"country":"NA","code":"NA-ER","name":"Erongo"},
{"country":"NA","code":"NA-HA","name":"Hardap"},
{"country":"NA","code":"NA-KA","name":"Karas"},
{"country":"NA","code":"NA-KE","name":"Kavango East"},
{"country":"NA","code":"NA-KW","name":"Kavango West"},
{"country":"NA","code":"NA-KH","name":"Khomas"},
{"country":"NA","code":"NA-KU","name":"Kunene"},
{"country":"NA","code":"NA-OW","name":"Ohangwena"},
{"country":"NA","code":"NA-OH","name":"Omaheke"},
{"country":"NA","code":"NA-OS","name":"Omusati"},
{"country":"NA","code":"NA-ON","name":"Oshana"},
{"country":"NA","code":"NA-OT","name":"Oshikoto"},
{"country":"NA","code":"NA-OD","name":"Otjozondjupa"},
{"country":"NA","code":"NA-CA","name":"Zambezi"},
{"country":"NC","code":"NC-XX-1","name":"Province Nord"},
{"country":"NC","code":"NC-XX-2","name":"Province Sud"},
{"country":"NE","code":"NE-1","name":"Agadez"},
{"country":"NE","code":"NE-2","name":"Diffa"},
{"country":"NE","code":"NE-3","name":"Dosso"},
{"country":"NE","code":"NE-8","name":"Niamey"},
{"country":"NE","code":"NE-5","name":"Tahoua"},
{"country":"NE","code":"NE-6","name":"Tillaberi"},
{"country":"NE","code":"NE-7","name":"Zinder"},
{"country":"NF","code":"NF-XX-1","name":"Norfolk Island"},
{"country":"NG","code":"NG-AB","name":"Abia"},
{"country":"NG","code":"NG-FC","name":"Abuja Federal Capital Territory"},
{"country":"NG","code":"NG-AD","name":"Adamawa"},
{"country":"NG","code":"NG-AK","name":"Akwa Ibom"},
{"country":"NG","code":"NG-AN","name":"Anambra"},
{"country":"NG","code":"NG-BA","name":"Bauchi"},
{"country":"NG","code":"NG-BY","name":"Bayelsa"},
{"country":"NG","code":"NG-BE","name":"Benue"},
{"country":"NG","code":"NG-BO","name":"Borno"},
{"country":"NG","code":"NG-CR","name":"Cross River"},
{"country":"NG","code":"NG-DE","name":"Delta"},
{"country":"NG","code":"NG-EB","name":"Ebonyi"},
{"country":"NG","code":"NG-ED","name":"Edo"},
{"country":"NG","code":"NG-EK","name":"Ekiti"},
{"country":"NG","code":"NG-EN","name":"Enugu"},
{"country":"NG","code":"NG-GO","name":"Gombe"},
{"country":"NG","code":"NG-IM","name":"Imo"},
{"country":"NG","code":"NG-JI","name":"Jigawa"},
{"country":"NG","code":"NG-KD","name":"Kaduna"},
{"country":"NG","code":"NG-KN","name":"Kano"},
{"country":"NG","code":"NG-KT","name":"Katsina"},
{"country":"NG","code":"NG-KE","name":"Kebbi"},
{"country":"NG","code":"NG-KO","name":"Kogi"},
{"country":"NG","code":"NG-KW","name":"Kwara"},
{"country":"NG","code":"NG-LA","name":"Lagos"},
{"country":"NG","code":"NG-NA","name":"Nasarawa"},
{"country":"NG","code":"NG-NI","name":"Niger"},
{"country":"NG","code":"NG-OG","name":"Ogun"},
{"country":"NG","code":"NG-ON","name":"Ondo"},
{"country":"NG","code":"NG-OS","name":"Osun"},
{"country":"NG","code":"NG-OY","name":"Oyo"},
{"country":"NG","code":"NG-PL","name":"Plateau"},
{"country":"NG","code":"NG-RI","name":"Rivers"},
{"country":"NG","code":"NG-SO","name":"Sokoto"},
{"country":"NG","code":"NG-TA","name":"Taraba"},
{"country":"NG","code":"NG-YO","name":"Yobe"},
{"country":"NG","code":"NG-ZA","name":"Zamfara"},
{"country":"NI","code":"NI-BO","name":"Boaco"},
{"country":"NI","code":"NI-CA","name":"Carazo"},
{"country":"NI","code":"NI-CI","name":"Chinandega"},
{"country":"NI","code":"NI-CO","name":"Chontales"},
{"country":"NI","code":"NI-AN","name":"Costa Caribe Norte"},
{"country":"NI","code":"NI-AS","name":"Costa Caribe Sur"},
{"country":"NI","code":"NI-ES","name":"Esteli"},
{"country":"NI","code":"NI-GR","name":"Granada"},
{"country":"NI","code":"NI-JI","name":"Jinotega"},
{"country":"NI","code":"NI-LE","name":"Leon"},
{"country":"NI","code":"NI-MD","name":"Madriz"},
{"country":"NI","code":"NI-MN","name":"Managua"},
{"country":"NI","code":"NI-MS","name":"Masaya"},
{"country":"NI","code":"NI-MT","name":"Matagalpa"},
{"country":"NI","code":"NI-NS","name":"Nueva Segovia"},
{"country":"NI","code":"NI-SJ","name":"Rio San Juan"},
{"country":"NI","code":"NI-RI","name":"Rivas"},
{"country":"NL","code":"NL-DR","name":"Drenthe"},
{"country":"NL","code":"NL-FL","name":"Flevoland"},
{"country":"NL","code":"NL-FR","name":"Fryslan"},
{"country":"NL","code":"NL-GE","name":"Gelderland"},
{"country":"NL","code":"NL-GR","name":"Groningen"},
{"country":"NL","code":"NL-LI","name":"Limburg"},
{"country":"NL","code":"NL-NB","name":"Noord-Brabant"},
{"country":"NL","code":"NL-NH","name":"Noord-Holland"},
{"country":"NL","code":"NL-OV","name":"Overijssel"},
{"country":"NL","code":"NL-UT","name":"Utrecht"},
{"country":"NL","code":"NL-ZE","name":"Zeeland"},
{"country":"NL","code":"NL-ZH","name":"Zuid-Holland"},
{"country":"NO","code":"NO-42","name":"Agder"},
{"country":"NO","code":"NO-34","name":"Innlandet"},
{"country":"NO","code":"NO-15","name":"More og Romsdal"},
{"country":"NO","code":"NO-18","name":"Nordland"},
{"country":"NO","code":"NO-03","name":"Oslo"},
{"country":"NO","code":"NO-11","name":"Rogaland"},
{"country":"NO","code":"NO-54","name":"Troms og Finnmark"},
{"country":"NO","code":"NO-50","name":"Trondelag"},
{"country":"NO","code":"NO-38","name":"Vestfold og Telemark"},
{"country":"NO","code":"NO-46","name":"Vestland"},
{"country":"NO","code":"NO-30","name":"Viken"},
{"country":"NP","code":"NP-BA","name":"Bagmati"},
{"country":"NP","code":"NP-BH","name":"Bheri"},
{"country":"NP","code":"NP-DH","name":"Dhawalagiri"},
{"country":"NP","code":"NP-GA","name":"Gandaki"},
{"country":"NP","code":"NP-JA","name":"Janakpur"},
{"country":"NP","code":"NP-KA","name":"Karnali"},
{"country":"NP","code":"NP-KO","name":"Kosi"},
{"country":"NP","code":"NP-LU","name":"Lumbini"},
{"country":"NP","code":"NP-MA","name":"Mahakali"},
{"country":"NP","code":"NP-ME","name":"Mechi"},
{"country":"NP","code":"NP-NA","name":"Narayani"},
{"country":"NP","code":"NP-RA","name":"Rapti"},
{"country":"NP","code":"NP-SA","name":"Sagarmatha"},
{"country":"NP","code":"NP-SE","name":"Seti"},
{"country":"NR","code":"NR-01","name":"Aiwo"},
{"country":"NR","code":"NR-03","name":"Anetan"},
{"country":"NR","code":"NR-14","name":"Yaren"},
{"country":"NU","code":"NU-XX-1","name":"Niue"},
{"country":"NZ","code":"NZ-AUK","name":"Auckland"},
{"country":"NZ","code":"NZ-BOP","name":"Bay of Plenty"},
{"country":"NZ","code":"NZ-CAN","name":"Canterbury"},
{"country":"NZ","code":"NZ-CIT","name":"Chatham Islands Territory"},
{"country":"NZ","code":"NZ-GIS","name":"Gisborne"},
{"country":"NZ","code":"NZ-HKB","name":"Hawke's Bay"},
{"country":"NZ","code":"NZ-MWT","name":"Manawatu-Wanganui"},
{"country":"NZ","code":"NZ-MBH","name":"Marlborough"},
{"country":"NZ","code":"NZ-NSN","name":"Nelson"},
{"country":"NZ","code":"NZ-NTL","name":"Northland"},
{"country":"NZ","code":"NZ-OTA","name":"Otago"},
{"country":"NZ","code":"NZ-STL","name":"Southland"},
{"country":"NZ","code":"NZ-TKI","name":"Taranaki"},
{"country":"NZ","code":"NZ-TAS","name":"Tasman"},
{"country":"NZ","code":"NZ-WKO","name":"Waikato"},
{"country":"NZ","code":"NZ-WGN","name":"Wellington"},
{"country":"NZ","code":"NZ-WTC","name":"West Coast"},
{"country":"OM","code":"OM-DA","name":"Ad Dakhiliyah"},
{"country":"OM","code":"OM-BU","name":"Al Buraymi"},
{"country":"OM","code":"OM-WU","name":"Al Wusta"},
{"country":"OM","code":"OM-ZA","name":"Az Zahirah"},
{"country":"OM","code":"OM-BJ","name":"Janub al Batinah"},
{"country":"OM","code":"OM-SJ","name":"Janub ash Sharqiyah"},
{"country":"OM","code":"OM-MA","name":"Masqat"},
{"country":"OM","code":"OM-MU","name":"Musandam"},
{"country":"OM","code":"OM-BS","name":"Shamal al Batinah"},
{"country":"OM","code":"OM-SS","name":"Shamal ash Sharqiyah"},
{"country":"OM","code":"OM-ZU","name":"Zufar"},
{"country":"PA","code":"PA-1","name":"Bocas del Toro"},
{"country":"PA","code":"PA-4","name":"Chiriqui"},
{"country":"PA","code":"PA-2","name":"Cocle"},
{"country":"PA","code":"PA-3","name":"Colon"},
{"country":"PA","code":"PA-5","name":"Darien"},
{"country":"PA","code":"PA-KY","name":"Guna Yala"},
{"country":"PA","code":"PA-6","name":"Herrera"},
{"country":"PA","code":"PA-7","name":"Los Santos"},
{"country":"PA","code":"PA-NB","name":"Ngobe-Bugle"},
{"country":"PA","code":"PA-8","name":"Panama"},
{"country":"PA","code":"PA-9","name":"Veraguas"},
{"country":"PE","code":"PE-AMA","name":"Amazonas"},
{"country":"PE","code":"PE-ANC","name":"Ancash"},
{"country":"PE","code":"PE-APU","name":"Apurimac"},
{"country":"PE","code":"PE-ARE","name":"Arequipa"},
{"country":"PE","code":"PE-AYA","name":"Ayacucho"},
{"country":"PE","code":"PE-CAJ","name":"Cajamarca"},
{"country":"PE","code":"PE-CUS","name":"Cusco"},
{"country":"PE","code":"PE-CAL","name":"El Callao"},
{"country":"PE","code":"PE-HUV","name":"Huancavelica"},
{"country":"PE","code":"PE-HUC","name":"Huanuco"},
{"country":"PE","code":"PE-ICA","name":"Ica"},
{"country":"PE","code":"PE-JUN","name":"Junin"},
{"country":"PE","code":"PE-LAL","name":"La Libertad"},
{"country":"PE","code":"PE-LAM","name":"Lambayeque"},
{"country":"PE","code":"PE-LIM","name":"Lima"},
{"country":"PE","code":"PE-LOR","name":"Loreto"},
{"country":"PE","code":"PE-MDD","name":"Madre de Dios"},
{"country":"PE","code":"PE-MOQ","name":"Moquegua"},
{"country":"PE","code":"PE-PAS","name":"Pasco"},
{"country":"PE","code":"PE-PIU","name":"Piura"},
{"country":"PE","code":"PE-PUN","name":"Puno"},
{"country":"PE","code":"PE-SAM","name":"San Martin"},
{"country":"PE","code":"PE-TAC","name":"Tacna"},
{"country":"PE","code":"PE-TUM","name":"Tumbes"},
{"country":"PE","code":"PE-UCA","name":"Ucayali"},
{"country":"PF","code":"PF-XX-1","name":"Iles Australes"},
{"country":"PF","code":"PF-XX-2","name":"Iles Marquises"},
{"country":"PF","code":"PF-XX-3","name":"Iles Sous-le-Vent"},
{"country":"PF","code":"PF-XX-4","name":"Iles Tuamotu-Gambier"},
{"country":"PF","code":"PF-XX-5","name":"Iles du Vent"},
{"country":"PG","code":"PG-NSB","name":"Bougainville"},
{"country":"PG","code":"PG-CPM","name":"Central"},
{"country":"PG","code":"PG-CPK","name":"Chimbu"},
{"country":"PG","code":"PG-EBR","name":"East New Britain"},
{"country":"PG","code":"PG-EHG","name":"Eastern Highlands"},
{"country":"PG","code":"PG-MPM","name":"Madang"},
{"country":"PG","code":"PG-MRL","name":"Manus"},
{"country":"PG","code":"PG-MBA","name":"Milne Bay"},
{"country":"PG","code":"PG-MPL","name":"Morobe"},
{"country":"PG","code":"PG-NCD","name":"National Capital District (Port Moresby)"},
{"country":"PG","code":"PG-SHM","name":"Southern Highlands"},
{"country":"PG","code":"PG-WBK","name":"West New Britain"},
{"country":"PG","code":"PG-SAN","name":"West Sepik"},
{"country":"PG","code":"PG-WPD","name":"Western"},
{"country":"PG","code":"PG-WHM","name":"Western Highlands"},
{"country":"PH","code":"PH-ABR","name":"Abra"},
{"country":"PH","code":"PH-AGN","name":"Agusan del Norte"},
{"country":"PH","code":"PH-AGS","name":"Agusan del Sur"},
{"country":"PH","code":"PH-AKL","name":"Aklan"},
{"country":"PH","code":"PH-ALB","name":"Albay"},
{"country":"PH","code":"PH-ANT","name":"Antique"},
{"country":"PH","code":"PH-APA","name":"Apayao"},
{"country":"PH","code":"PH-AUR","name":"Aurora"},
{"country":"PH","code":"PH-BAS","name":"Basilan"},
{"country":"PH","code":"PH-BAN","name":"Bataan"},
{"country":"PH","code":"PH-BTN","name":"Batanes"},
{"country":"PH","code":"PH-BTG","name":"Batangas"},
{"country":"PH","code":"PH-BEN","name":"Benguet"},
{"country":"PH","code":"PH-BIL","name":"Biliran"},
{"country":"PH","code":"PH-BOH","name":"Bohol"},
{"country":"PH","code":"PH-BUK","name":"Bukidnon"},
{"country":"PH","code":"PH-BUL","name":"Bulacan"},
{"country":"PH","code":"PH-CAG","name":"Cagayan"},
{"country":"PH","code":"PH-CAN","name":"Camarines Norte"},
{"country":"PH","code":"PH-CAS","name":"Camarines Sur"},
{"country":"PH","code":"PH-CAM","name":"Camiguin"},
{"country":"PH","code":"PH-CAP","name":"Capiz"},
{"country":"PH","code":"PH-CAT","name":"Catanduanes"},
{"country":"PH","code":"PH-CAV","name":"Cavite"},
{"country":"PH","code":"PH-CEB","name":"Cebu"},
{"country":"PH","code":"PH-NCO","name":"Cotabato"},
{"country":"PH","code":"PH-DAO","name":"Davao Oriental"},
{"country":"PH","code":"PH-COM","name":"Davao de Oro"},
{"country":"PH","code":"PH-DAV","name":"Davao del Norte"},
{"country":"PH","code":"PH-DAS","name":"Davao del Sur"},
{"country":"PH","code":"PH-DIN","name":"Dinagat Islands"},
{"country":"PH","code":"PH-EAS","name":"Eastern Samar"},
{"country":"PH","code":"PH-GUI","name":"Guimaras"},
{"country":"PH","code":"PH-IFU","name":"Ifugao"},
{"country":"PH","code":"PH-ILN","name":"Ilocos Norte"},
{"country":"PH","code":"PH-ILS","name":"Ilocos Sur"},
{"country":"PH","code":"PH-ILI","name":"Iloilo"},
{"country":"PH","code":"PH-ISA","name":"Isabela"},
{"country":"PH","code":"PH-KAL","name":"Kalinga"},
{"country":"PH","code":"PH-LUN","name":"La Union"},
{"country":"PH","code":"PH-LAG","name":"Laguna"},
{"country":"PH","code":"PH-LAN","name":"Lanao del Norte"},
{"country":"PH","code":"PH-LAS","name":"Lanao del Sur"},
{"country":"PH","code":"PH-LEY","name":"Leyte"},
{"country":"PH","code":"PH-MAG","name":"Maguindanao"},
{"country":"PH","code":"PH-MAD","name":"Marinduque"},
{"country":"PH","code":"PH-MAS","name":"Masbate"},
{"country":"PH","code":"PH-MDC","name":"Mindoro Occidental"},
{"country":"PH","code":"PH-MDR","name":"Mindoro Oriental"},
{"country":"PH","code":"PH-MSC","name":"Misamis Occidental"},
{"country":"PH","code":"PH-MSR","name":"Misamis Oriental"},
{"country":"PH","code":"PH-MOU","name":"Mountain Province"},
{"country":"PH","code":"PH-00","name":"National Capital Region"},
{"country":"PH","code":"PH-NEC","name":"Negros Occidental"},
{"country":"PH","code":"PH-NER","name":"Negros Oriental"},
{"country":"PH","code":"PH-NSA","name":"Northern Samar"},
{"country":"PH","code":"PH-NUE","name":"Nueva Ecija"},
{"country":"PH","code":"PH-NUV","name":"Nueva Vizcaya"},
{"country":"PH","code":"PH-PLW","name":"Palawan"},
{"country":"PH","code":"PH-PAM","name":"Pampanga"},
{"country":"PH","code":"PH-PAN","name":"Pangasinan"},
{"country":"PH","code":"PH-QUE","name":"Quezon"},
{"country":"PH","code":"PH-QUI","name":"Quirino"},
{"country":"PH","code":"PH-RIZ","name":"Rizal"},
{"country":"PH","code":"PH-ROM","name":"Romblon"},
{"country":"PH","code":"PH-WSA","name":"Samar"},
{"country":"PH","code":"PH-SAR","name":"Sarangani"},
{"country":"PH","code":"PH-SIG","name":"Siquijor"},
{"country":"PH","code":"PH-SOR","name":"Sorsogon"},
{"country":"PH","code":"PH-SCO","name":"South Cotabato"},
{"country":"PH","code":"PH-SLE","name":"Southern Leyte"},
{"country":"PH","code":"PH-SUK","name":"Sultan Kudarat"},
{"country":"PH","code":"PH-SLU","name":"Sulu"},
{"country":"PH","code":"PH-SUN","name":"Surigao del Norte"},
{"country":"PH","code":"PH-SUR","name":"Surigao del Sur"},
{"country":"PH","code":"PH-TAR","name":"Tarlac"},
{"country":"PH","code":"PH-TAW","name":"Tawi-Tawi"},
{"country":"PH","code":"PH-ZMB","name":"Zambales"},
{"country":"PH","code":"PH-ZSI","name":"Zamboanga Sibugay"},
{"country":"PH","code":"PH-ZAN","name":"Zamboanga del Norte"},
{"country":"PH","code":"PH-ZAS","name":"Zamboanga del Sur"},
{"country":"PK","code":"PK-JK","name":"Azad Jammu and Kashmir"},
{"country":"PK","code":"PK-BA","name":"Balochistan"},
{"country":"PK","code":"PK-GB","name":"Gilgit-Baltistan"},
{"country":"PK","code":"PK-IS","name":"Islamabad"},
{"country":"PK","code":"PK-KP","name":"Khyber Pakhtunkhwa"},
{"country":"PK","code":"PK-PB","name":"Punjab"},
{"country":"PK","code":"PK-SD","name":"Sindh"},
{"country":"PL","code":"PL-02","name":"Dolnoslaskie"},
{"country":"PL","code":"PL-04","name":"Kujawsko-pomorskie"},
{"country":"PL","code":"PL-10","name":"Lodzkie"},
{"country":"PL","code":"PL-06","name":"Lubelskie"},
{"country":"PL","code":"PL-08","name":"Lubuskie"},
{"country":"PL","code":"PL-12","name":"Malopolskie"},
{"country":"PL","code":"PL-14","name":"Mazowieckie"},
{"country":"PL","code":"PL-16","name":"Opolskie"},
{"country":"PL","code":"PL-18","name":"Podkarpackie"},
{"country":"PL","code":"PL-20","name":"Podlaskie"},
{"country":"PL","code":"PL-22","name":"Pomorskie"},
{"country":"PL","code":"PL-24","name":"Slaskie"},
{"country":"PL","code":"PL-26","name":"Swietokrzyskie"},
{"country":"PL","code":"PL-28","name":"Warminsko-mazurskie"},
{"country":"PL","code":"PL-30","name":"Wielkopolskie"},
{"country":"PL","code":"PL-32","name":"Zachodniopomorskie"},
{"country":"PM","code":"PM-XX-1","name":"Saint Pierre and Miquelon"},
{"country":"PN","code":"PN-XX-1","name":"Pitcairn"},
{"country":"PR","code":"PR-XX-1","name":"Adjuntas"},
{"country":"PR","code":"PR-XX-2","name":"Aguada"},
{"country":"PR","code":"PR-XX-3","name":"Aguadilla"},
{"country":"PR","code":"PR-XX-4","name":"Aguas Buenas"},
{"country":"PR","code":"PR-XX-5","name":"Aibonito"},
{"country":"PR","code":"PR-XX-6","name":"Anasco"},
{"country":"PR","code":"PR-XX-7","name":"Arecibo"},
{"country":"PR","code":"PR-XX-8","name":"Arroyo"},
{"country":"PR","code":"PR-XX-9","name":"Barceloneta"},
{"country":"PR","code":"PR-XX-10","name":"Barranquitas"},
{"country":"PR","code":"PR-XX-11","name":"Bayamon"},
{"country":"PR","code":"PR-XX-12","name":"Cabo Rojo"},
{"country":"PR","code":"PR-XX-13","name":"Caguas"},
{"country":"PR","code":"PR-XX-14","name":"Camuy"},
{"country":"PR","code":"PR-XX-15","name":"Canovanas"},
{"country":"PR","code":"PR-XX-16","name":"Carolina"},
{"country":"PR","code":"PR-XX-17","name":"Catano"},
{"country":"PR","code":"PR-XX-18","name":"Cayey"},
{"country":"PR","code":"PR-XX-19","name":"Ceiba"},
{"country":"PR","code":"PR-XX-20","name":"Ciales"},
{"country":"PR","code":"PR-XX-21","name":"Cidra"},
{"country":"PR","code":"PR-XX-22","name":"Coamo"},
{"country":"PR","code":"PR-XX-23","name":"Comerio"},
{"country":"PR","code":"PR-XX-24","name":"Corozal"},
{"country":"PR","code":"PR-XX-25","name":"Culebra"},
{"country":"PR","code":"PR-XX-26","name":"Dorado"},
{"country":"PR","code":"PR-XX-27","name":"Fajardo"},
{"country":"PR","code":"PR-XX-28","name":"Florida"},
{"country":"PR","code":"PR-XX-29","name":"Guanica"},
{"country":"PR","code":"PR-XX-30","name":"Guayama"},
{"country":"PR","code":"PR-XX-31","name":"Guayanilla"},
{"country":"PR","code":"PR-XX-32","name":"Guaynabo"},
{"country":"PR","code":"PR-XX-33","name":"Gurabo"},
{"country":"PR","code":"PR-XX-34","name":"Hatillo"},
{"country":"PR","code":"PR-XX-35","name":"Hormigueros"},
{"country":"PR","code":"PR-XX-36","name":"Humacao"},
{"country":"PR","code":"PR-XX-37","name":"Isabela"},
{"country":"PR","code":"PR-XX-38","name":"Juana Diaz"},
{"country":"PR","code":"PR-XX-39","name":"Lajas"},
{"country":"PR","code":"PR-XX-40","name":"Lares"},
{"country":"PR","code":"PR-XX-41","name":"Las Marias"},
{"country":"PR","code":"PR-XX-42","name":"Las Piedras"},
{"country":"PR","code":"PR-XX-43","name":"Loiza"},
{"country":"PR","code":"PR-XX-44","name":"Luquillo"},
{"country":"PR","code":"PR-XX-45","name":"Manati"},
{"country":"PR","code":"PR-XX-46","name":"Maunabo"},
{"country":"PR","code":"PR-XX-47","name":"Mayaguez"},
{"country":"PR","code":"PR-XX-48","name":"Moca"},
{"country":"PR","code":"PR-XX-49","name":"Morovis"},
{"country":"PR","code":"PR-XX-50","name":"Municipio de Jayuya"},
{"country":"PR","code":"PR-XX-51","name":"Municipio de Juncos"},
{"country":"PR","code":"PR-XX-52","name":"Naguabo"},
{"country":"PR","code":"PR-XX-53","name":"Naranjito"},
{"country":"PR","code":"PR-XX-54","name":"Patillas"},
{"country":"PR","code":"PR-XX-55","name":"Penuelas"},
{"country":"PR","code":"PR-XX-56","name":"Ponce"},
{"country":"PR","code":"PR-XX-57","name":"Quebradillas"},
{"country":"PR","code":"PR-XX-58","name":"Rincon"},
{"country":"PR","code":"PR-XX-59","name":"Rio Grande"},
{"country":"PR","code":"PR-XX-60","name":"Sabana Grande"},
{"country":"PR","code":"PR-XX-61","name":"Salinas"},
{"country":"PR","code":"PR-XX-62","name":"San German"},
{"country":"PR","code":"PR-XX-63","name":"San Juan"},
{"country":"PR","code":"PR-XX-64","name":"San Lorenzo"},
{"country":"PR","code":"PR-XX-65","name":"San Sebastian"},
{"country":"PR","code":"PR-XX-66","name":"Santa Isabel Municipio"},
{"country":"PR","code":"PR-XX-67","name":"Toa Alta"},
{"country":"PR","code":"PR-XX-68","name":"Toa Baja"},
{"country":"PR","code":"PR-XX-69","name":"Trujillo Alto"},
{"country":"PR","code":"PR-XX-70","name":"Utuado"},
{"country":"PR","code":"PR-XX-71","name":"Vega Alta"},
{"country":"PR","code":"PR-XX-72","name":"Vega Baja"},
{"country":"PR","code":"PR-XX-73","name":"Vieques"},
{"country":"PR","code":"PR-XX-74","name":"Villalba"},
{"country":"PR","code":"PR-XX-75","name":"Yabucoa"},
{"country":"PR","code":"PR-XX-76","name":"Yauco"},
{"country":"PS","code":"PS-BTH","name":"Bethlehem"},
{"country":"PS","code":"PS-DEB","name":"Deir El Balah"},
{"country":"PS","code":"PS-GZA","name":"Gaza"},
{"country":"PS","code":"PS-HBN","name":"Hebron"},
{"country":"PS","code":"PS-JEN","name":"Jenin"},
{"country":"PS","code":"PS-JRH","name":"Jericho and Al Aghwar"},
{"country":"PS","code":"PS-JEM","name":"Jerusalem"},
{"country":"PS","code":"PS-KYS","name":"Khan Yunis"},
{"country":"PS","code":"PS-NBS","name":"Nablus"},
{"country":"PS","code":"PS-QQA","name":"Qalqilya"},
{"country":"PS","code":"PS-RFH","name":"Rafah"},
{"country":"PS","code":"PS-RBH","name":"Ramallah"},
{"country":"PS","code":"PS-SLT","name":"Salfit"},
{"country":"PS","code":"PS-TBS","name":"Tubas"},
{"country":"PS","code":"PS-TKM","name":"Tulkarm"},
{"country":"PT","code":"PT-01","name":"Aveiro"},
{"country":"PT","code":"PT-02","name":"Beja"},
{"country":"PT","code":"PT-03","name":"Braga"},
{"country":"PT","code":"PT-04","name":"Braganca"},
{"country":"PT","code":"PT-05","name":"Castelo Branco"},
{"country":"PT","code":"PT-06","name":"Coimbra"},
{"country":"PT","code":"PT-07","name":"Evora"},
{"country":"PT","code":"PT-08","name":"Faro"},
{"country":"PT","code":"PT-09","name":"Guarda"},
{"country":"PT","code":"PT-10","name":"Leiria"},
{"country":"PT","code":"PT-11","name":"Lisboa"},
{"country":"PT","code":"PT-12","name":"Portalegre"},
{"country":"PT","code":"PT-13","name":"Porto"},
{"country":"PT","code":"PT-30","name":"Regiao Autonoma da Madeira"},
{"country":"PT","code":"PT-20","name":"Regiao Autonoma dos Acores"},
{"country":"PT","code":"PT-14","name":"Santarem"},
{"country":"PT","code":"PT-15","name":"Setubal"},
{"country":"PT","code":"PT-16","name":"Viana do Castelo"},
{"country":"PT","code":"PT-17","name":"Vila Real"},
{"country":"PT","code":"PT-18","name":"Viseu"},
{"country":"PW","code":"PW-004","name":"Airai"},
{"country":"PW","code":"PW-100","name":"Kayangel"},
{"country":"PW","code":"PW-150","name":"Koror"},
{"country":"PW","code":"PW-212","name":"Melekeok"},
{"country":"PW","code":"PW-214","name":"Ngaraard"},
{"country":"PW","code":"PW-222","name":"Ngardmau"},
{"country":"PY","code":"PY-10","name":"Alto Parana"},
{"country":"PY","code":"PY-13","name":"Amambay"},
{"country":"PY","code":"PY-ASU","name":"Asuncion"},
{"country":"PY","code":"PY-19","name":"Boqueron"},
{"country":"PY","code":"PY-5","name":"Caaguazu"},
{"country":"PY","code":"PY-6","name":"Caazapa"},
{"country":"PY","code":"PY-14","name":"Canindeyu"},
{"country":"PY","code":"PY-11","name":"Central"},
{"country":"PY","code":"PY-1","name":"Concepcion"},
{"country":"PY","code":"PY-3","name":"Cordillera"},
{"country":"PY","code":"PY-4","name":"Guaira"},
{"country":"PY","code":"PY-7","name":"Itapua"},
{"country":"PY","code":"PY-8","name":"Misiones"},
{"country":"PY","code":"PY-12","name":"Neembucu"},
{"country":"PY","code":"PY-9","name":"Paraguari"},
{"country":"PY","code":"PY-15","name":"Presidente Hayes"},
{"country":"PY","code":"PY-2","name":"San Pedro"},
{"country":"QA","code":"QA-DA","name":"Ad Dawhah"},
{"country":"QA","code":"QA-KH","name":"Al Khawr wa adh Dhakhirah"},
{"country":"QA","code":"QA-WA","name":"Al Wakrah"},
{"country":"QA","code":"QA-RA","name":"Ar Rayyan"},
{"country":"QA","code":"QA-MS","name":"Ash Shamal"},
{"country":"QA","code":"QA-ZA","name":"Az Za'ayin"},
{"country":"QA","code":"QA-US","name":"Umm Salal"},
{"country":"RE","code":"RE-XX-1","name":"Reunion"},
{"country":"RO","code":"RO-AB","name":"Alba"},
{"country":"RO","code":"RO-AR","name":"Arad"},
{"country":"RO","code":"RO-AG","name":"Argeș"},
{"country":"RO","code":"RO-BC","name":"Bacău"},
{"country":"RO","code":"RO-BH","name":"Bihor"},
{"country":"RO","code":"RO-BN","name":"Bistrița-Năsăud"},
{"country":"RO","code":"RO-BT","name":"Botoșani"},
{"country":"RO","code":"RO-BR","name":"Brăila"},
{"country":"RO","code":"RO-BV","name":"Brașov"},
{"country":"RO","code":"RO-B","name":"București"},
{"country":"RO","code":"RO-BZ","name":"Buzău"},
{"country":"RO","code":"RO-CL","name":"Călărași"},
{"country":"RO","code":"RO-CS","name":"Caraș-Severin"},
{"country":"RO","code":"RO-CJ","name":"Cluj"},
{"country":"RO","code":"RO-CT","name":"Constanța"},
{"country":"RO","code":"RO-CV","name":"Covasna"},
{"country":"RO","code":"RO-DB","name":"Dâmbovița"},
{"country":"RO","code":"RO-DJ","name":"Dolj"},
{"country":"RO","code":"RO-GL","name":"Galați"},
{"country":"RO","code":"RO-GR","name":"Giurgiu"},
{"country":"RO","code":"RO-GJ","name":"Gorj"},
{"country":"RO","code":"RO-HR","name":"Harghita"},
{"country":"RO","code":"RO-HD","name":"Hunedoara"},
{"country":"RO","code":"RO-IL","name":"Ialomița"},
{"country":"RO","code":"RO-IS","name":"Iași"},
{"country":"RO","code":"RO-IF","name":"Ilfov"},
{"country":"RO","code":"RO-MM","name":"Maramureș"},
{"country":"RO","code":"RO-MH","name":"Mehedinți"},
{"country":"RO","code":"RO-MS","name":"Mureș"},
{"country":"RO","code":"RO-NT","name":"Neamț"},
{"country":"RO","code":"RO-OT","name":"Olt"},
{"country":"RO","code":"RO-PH","name":"Prahova"},
{"country":"RO","code":"RO-SJ","name":"Sălaj"},
{"country":"RO","code":"RO-SM","name":"Satu Mare"},
{"country":"RO","code":"RO-SB","name":"Sibiu"},
{"country":"RO","code":"RO-SV","name":"Suceava"},
{"country":"RO","code":"RO-TR","name":"Teleorman"},
{"country":"RO","code":"RO-TM","name":"Timiș"},
{"country":"RO","code":"RO-TL","name":"Tulcea"},
{"country":"RO","code":"RO-VL","name":"Vâlcea"},
{"country":"RO","code":"RO-VS","name":"Vaslui"},
{"country":"RO","code":"RO-VN","name":"Vrancea"},
{"country":"RS","code":"RS-00","name":"Beograd"},
{"country":"RS","code":"RS-14","name":"Borski okrug"},
{"country":"RS","code":"RS-11","name":"Branicevski okrug"},
{"country":"RS","code":"RS-23","name":"Jablanicki okrug"},
{"country":"RS","code":"RS-06","name":"Juznobacki okrug"},
{"country":"RS","code":"RS-04","name":"Juznobanatski okrug"},
{"country":"RS","code":"RS-09","name":"Kolubarski okrug"},
{"country":"RS","code":"RS-28","name":"Kosovsko-Mitrovacki okrug"},
{"country":"RS","code":"RS-08","name":"Macvanski okrug"},
{"country":"RS","code":"RS-17","name":"Moravicki okrug"},
{"country":"RS","code":"RS-20","name":"Nisavski okrug"},
{"country":"RS","code":"RS-24","name":"Pcinjski okrug"},
{"country":"RS","code":"RS-26","name":"Pecki okrug"},
{"country":"RS","code":"RS-22","name":"Pirotski okrug"},
{"country":"RS","code":"RS-10","name":"Podunavski okrug"},
{"country":"RS","code":"RS-13","name":"Pomoravski okrug"},
{"country":"RS","code":"RS-27","name":"Prizrenski okrug"},
{"country":"RS","code":"RS-19","name":"Rasinski okrug"},
{"country":"RS","code":"RS-18","name":"Raski okrug"},
{"country":"RS","code":"RS-01","name":"Severnobacki okrug"},
{"country":"RS","code":"RS-03","name":"Severnobanatski okrug"},
{"country":"RS","code":"RS-02","name":"Srednjebanatski okrug"},
{"country":"RS","code":"RS-07","name":"Sremski okrug"},
{"country":"RS","code":"RS-12","name":"Sumadijski okrug"},
{"country":"RS","code":"RS-21","name":"Toplicki okrug"},
{"country":"RS","code":"RS-15","name":"Zajecarski okrug"},
{"country":"RS","code":"RS-05","name":"Zapadnobacki okrug"},
{"country":"RS","code":"RS-16","name":"Zlatiborski okrug"},
{"country":"RU","code":"RU-AD","name":"Adygeya, Respublika"},
{"country":"RU","code":"RU-AL","name":"Altay, Respublika"},
{"country":"RU","code":"RU-ALT","name":"Altayskiy kray"},
{"country":"RU","code":"RU-AMU","name":"Amurskaya oblast'"},
{"country":"RU","code":"RU-ARK","name":"Arkhangel'skaya oblast'"},
{"country":"RU","code":"RU-AST","name":"Astrakhanskaya oblast'"},
{"country":"RU","code":"RU-BA","name":"Bashkortostan, Respublika"},
{"country":"RU","code":"RU-BEL","name":"Belgorodskaya oblast'"},
{"country":"RU","code":"RU-BRY","name":"Bryanskaya oblast'"},
{"country":"RU","code":"RU-BU","name":"Buryatiya, Respublika"},
{"country":"RU","code":"RU-CE","name":"Chechenskaya Respublika"},
{"country":"RU","code":"RU-CHE","name":"Chelyabinskaya oblast'"},
{"country":"RU","code":"RU-CHU","name":"Chukotskiy avtonomnyy okrug"},
{"country":"RU","code":"RU-CU","name":"Chuvashskaya Respublika"},
{"country":"RU","code":"RU-DA","name":"Dagestan, Respublika"},
{"country":"RU","code":"RU-IN","name":"Ingushetiya, Respublika"},
{"country":"RU","code":"RU-IRK","name":"Irkutskaya oblast'"},
{"country":"RU","code":"RU-IVA","name":"Ivanovskaya oblast'"},
{"country":"RU","code":"RU-KB","name":"Kabardino-Balkarskaya Respublika"},
{"country":"RU","code":"RU-KGD","name":"Kaliningradskaya oblast'"},
{"country":"RU","code":"RU-KL","name":"Kalmykiya, Respublika"},
{"country":"RU","code":"RU-KLU","name":"Kaluzhskaya oblast'"},
{"country":"RU","code":"RU-KAM","name":"Kamchatskiy kray"},
{"country":"RU","code":"RU-KC","name":"Karachayevo-Cherkesskaya Respublika"},
{"country":"RU","code":"RU-KR","name":"Kareliya, Respublika"},
{"country":"RU","code":"RU-KEM","name":"Kemerovskaya oblast'"},
{"country":"RU","code":"RU-KHA","name":"Khabarovskiy kray"},
{"country":"RU","code":"RU-KK","name":"Khakasiya, Respublika"},
{"country":"RU","code":"RU-KHM","name":"Khanty-Mansiyskiy avtonomnyy okrug"},
{"country":"RU","code":"RU-KIR","name":"Kirovskaya oblast'"},
{"country":"RU","code":"RU-KO","name":"Komi, Respublika"},
{"country":"RU","code":"RU-KOS","name":"Kostromskaya oblast'"},
{"country":"RU","code":"RU-KDA","name":"Krasnodarskiy kray"},
{"country":"RU","code":"RU-KYA","name":"Krasnoyarskiy kray"},
{"country":"RU","code":"RU-KGN","name":"Kurganskaya oblast'"},
{"country":"RU","code":"RU-KRS","name":"Kurskaya oblast'"},
{"country":"RU","code":"RU-LEN","name":"Leningradskaya oblast'"},
{"country":"RU","code":"RU-LIP","name":"Lipetskaya oblast'"},
{"country":"RU","code":"RU-MAG","name":"Magadanskaya oblast'"},
{"country":"RU","code":"RU-ME","name":"Mariy El, Respublika"},
{"country":"RU","code":"RU-MO","name":"Mordoviya, Respublika"},
{"country":"RU","code":"RU-MOS","name":"Moskovskaya oblast'"},
{"country":"RU","code":"RU-MOW","name":"Moskva"},
{"country":"RU","code":"RU-MUR","name":"Murmanskaya oblast'"},
{"country":"RU","code":"RU-NEN","name":"Nenetskiy avtonomnyy okrug"},
{"country":"RU","code":"RU-NIZ","name":"Nizhegorodskaya oblast'"},
{"country":"RU","code":"RU-NGR","name":"Novgorodskaya oblast'"},
{"country":"RU","code":"RU-NVS","name":"Novosibirskaya oblast'"},
{"country":"RU","code":"RU-OMS","name":"Omskaya oblast'"},
{"country":"RU","code":"RU-ORE","name":"Orenburgskaya oblast'"},
{"country":"RU","code":"RU-ORL","name":"Orlovskaya oblast'"},
{"country":"RU","code":"RU-PNZ","name":"Penzenskaya oblast'"},
{"country":"RU","code":"RU-PER","name":"Permskiy kray"},
{"country":"RU","code":"RU-PRI","name":"Primorskiy kray"},
{"country":"RU","code":"RU-PSK","name":"Pskovskaya oblast'"},
{"country":"RU","code":"RU-ROS","name":"Rostovskaya oblast'"},
{"country":"RU","code":"RU-RYA","name":"Ryazanskaya oblast'"},
{"country":"RU","code":"RU-SA","name":"Saha, Respublika"},
{"country":"RU","code":"RU-SAK","name":"Sakhalinskaya oblast'"},
{"country":"RU","code":"RU-SAM","name":"Samarskaya oblast'"},
{"country":"RU","code":"RU-SPE","name":"Sankt-Peterburg"},
{"country":"RU","code":"RU-SAR","name":"Saratovskaya oblast'"},
{"country":"RU","code":"RU-SE","name":"Severnaya Osetiya, Respublika"},
{"country":"RU","code":"RU-SMO","name":"Smolenskaya oblast'"},
{"country":"RU","code":"RU-STA","name":"Stavropol'skiy kray"},
{"country":"RU","code":"RU-SVE","name":"Sverdlovskaya oblast'"},
{"country":"RU","code":"RU-TAM","name":"Tambovskaya oblast'"},
{"country":"RU","code":"RU-TA","name":"Tatarstan, Respublika"},
{"country":"RU","code":"RU-TOM","name":"Tomskaya oblast'"},
{"country":"RU","code":"RU-TUL","name":"Tul'skaya oblast'"},
{"country":"RU","code":"RU-TVE","name":"Tverskaya oblast'"},
{"country":"RU","code":"RU-TYU","name":"Tyumenskaya oblast'"},
{"country":"RU","code":"RU-TY","name":"Tyva, Respublika"},
{"country":"RU","code":"RU-UD","name":"Udmurtskaya Respublika"},
{"country":"RU","code":"RU-ULY","name":"Ul'yanovskaya oblast'"},
{"country":"RU","code":"RU-VLA","name":"Vladimirskaya oblast'"},
{"country":"RU","code":"RU-VGG","name":"Volgogradskaya oblast'"},
{"country":"RU","code":"RU-VLG","name":"Vologodskaya oblast'"},
{"country":"RU","code":"RU-VOR","name":"Voronezhskaya oblast'"},
{"country":"RU","code":"RU-YAN","name":"Yamalo-Nenetskiy avtonomnyy okrug"},
{"country":"RU","code":"RU-YAR","name":"Yaroslavskaya oblast'"},
{"country":"RU","code":"RU-YEV","name":"Yevreyskaya avtonomnaya oblast'"},
{"country":"RU","code":"RU-ZAB","name":"Zabaykal'skiy kray"},
{"country":"RW","code":"RW-02","name":"Est"},
{"country":"RW","code":"RW-03","name":"Nord"},
{"country":"RW","code":"RW-04","name":"Ouest"},
{"country":"RW","code":"RW-05","name":"Sud"},
{"country":"RW","code":"RW-01","name":"Ville de Kigali"},
{"country":"SA","code":"SA-14","name":"'Asir"},
{"country":"SA","code":"SA-11","name":"Al Bahah"},
{"country":"SA","code":"SA-08","name":"Al Hudud ash Shamaliyah"},
{"country":"SA","code":"SA-12","name":"Al Jawf"},
{"country":"SA","code":"SA-03","name":"Al Madinah al Munawwarah"},
{"country":"SA","code":"SA-05","name":"Al Qasim"},
{"country":"SA","code":"SA-01","name":"Ar Riyad"},
{"country":"SA","code":"SA-04","name":"Ash Sharqiyah"},
{"country":"SA","code":"SA-06","name":"Ha'il"},
{"country":"SA","code":"SA-09","name":"Jazan"},
{"country":"SA","code":"SA-02","name":"Makkah al Mukarramah"},
{"country":"SA","code":"SA-10","name":"Najran"},
{"country":"SA","code":"SA-07","name":"Tabuk"},
{"country":"SB","code":"SB-CH","name":"Choiseul"},
{"country":"SB","code":"SB-GU","name":"Guadalcanal"},
{"country":"SB","code":"SB-WE","name":"Western"},
{"country":"SC","code":"SC-02","name":"Anse Boileau"},
{"country":"SC","code":"SC-05","name":"Anse Royale"},
{"country":"SC","code":"SC-01","name":"Anse aux Pins"},
{"country":"SC","code":"SC-06","name":"Baie Lazare"},
{"country":"SC","code":"SC-07","name":"Baie Sainte Anne"},
{"country":"SC","code":"SC-08","name":"Beau Vallon"},
{"country":"SC","code":"SC-10","name":"Bel Ombre"},
{"country":"SC","code":"SC-11","name":"Cascade"},
{"country":"SC","code":"SC-16","name":"English River"},
{"country":"SC","code":"SC-13","name":"Grand Anse Mahe"},
{"country":"SC","code":"SC-14","name":"Grand Anse Praslin"},
{"country":"SC","code":"SC-15","name":"La Digue"},
{"country":"SC","code":"SC-20","name":"Pointe Larue"},
{"country":"SC","code":"SC-23","name":"Takamaka"},
{"country":"SD","code":"SD-NB","name":"Blue Nile"},
{"country":"SD","code":"SD-DC","name":"Central Darfur"},
{"country":"SD","code":"SD-GD","name":"Gedaref"},
{"country":"SD","code":"SD-GZ","name":"Gezira"},
{"country":"SD","code":"SD-KA","name":"Kassala"},
{"country":"SD","code":"SD-KH","name":"Khartoum"},
{"country":"SD","code":"SD-DN","name":"North Darfur"},
{"country":"SD","code":"SD-KN","name":"North Kordofan"},
{"country":"SD","code":"SD-NO","name":"Northern"},
{"country":"SD","code":"SD-RS","name":"Red Sea"},
{"country":"SD","code":"SD-NR","name":"River Nile"},
{"country":"SD","code":"SD-SI","name":"Sennar"},
{"country":"SD","code":"SD-DS","name":"South Darfur"},
{"country":"SD","code":"SD-KS","name":"South Kordofan"},
{"country":"SD","code":"SD-DW","name":"West Darfur"},
{"country":"SD","code":"SD-GK","name":"West Kordofan"},
{"country":"SD","code":"SD-NW","name":"White Nile"},
{"country":"SE","code":"SE-K","name":"Blekinge lan"},
{"country":"SE","code":"SE-W","name":"Dalarnas lan"},
{"country":"SE","code":"SE-X","name":"Gavleborgs lan"},
{"country":"SE","code":"SE-I","name":"Gotlands lan"},
{"country":"SE","code":"SE-N","name":"Hallands lan"},
{"country":"SE","code":"SE-Z","name":"Jamtlands lan"},
{"country":"SE","code":"SE-F","name":"Jonkopings lan"},
{"country":"SE","code":"SE-H","name":"Kalmar lan"},
{"country":"SE","code":"SE-G","name":"Kronobergs lan"},
{"country":"SE","code":"SE-BD","name":"Norrbottens lan"},
{"country":"SE","code":"SE-T","name":"Orebro lan"},
{"country":"SE","code":"SE-E","name":"Ostergotlands lan"},
{"country":"SE","code":"SE-M","name":"Skane lan"},
{"country":"SE","code":"SE-D","name":"Sodermanlands lan"},
{"country":"SE","code":"SE-AB","name":"Stockholms lan"},
{"country":"SE","code":"SE-C","name":"Uppsala lan"},
{"country":"SE","code":"SE-S","name":"Varmlands lan"},
{"country":"SE","code":"SE-AC","name":"Vasterbottens lan"},
{"country":"SE","code":"SE-Y","name":"Vasternorrlands lan"},
{"country":"SE","code":"SE-U","name":"Vastmanlands lan"},
{"country":"SE","code":"SE-O","name":"Vastra Gotalands lan"},
{"country":"SG","code":"SG-XX-1","name":"Singapore"},
{"country":"SH","code":"SH-HL","name":"Saint Helena"},
{"country":"SI","code":"SI-001","name":"Ajdovscina"},
{"country":"SI","code":"SI-213","name":"Ankaran"},
{"country":"SI","code":"SI-195","name":"Apace"},
{"country":"SI","code":"SI-002","name":"Beltinci"},
{"country":"SI","code":"SI-148","name":"Benedikt"},
{"country":"SI","code":"SI-149","name":"Bistrica ob Sotli"},
{"country":"SI","code":"SI-003","name":"Bled"},
{"country":"SI","code":"SI-150","name":"Bloke"},
{"country":"SI","code":"SI-004","name":"Bohinj"},
{"country":"SI","code":"SI-005","name":"Borovnica"},
{"country":"SI","code":"SI-006","name":"Bovec"},
{"country":"SI","code":"SI-151","name":"Braslovce"},
{"country":"SI","code":"SI-007","name":"Brda"},
{"country":"SI","code":"SI-009","name":"Brezice"},
{"country":"SI","code":"SI-008","name":"Brezovica"},
{"country":"SI","code":"SI-152","name":"Cankova"},
{"country":"SI","code":"SI-011","name":"Celje"},
{"country":"SI","code":"SI-012","name":"Cerklje na Gorenjskem"},
{"country":"SI","code":"SI-013","name":"Cerknica"},
{"country":"SI","code":"SI-014","name":"Cerkno"},
{"country":"SI","code":"SI-196","name":"Cirkulane"},
{"country":"SI","code":"SI-015","name":"Crensovci"},
{"country":"SI","code":"SI-017","name":"Crnomelj"},
{"country":"SI","code":"SI-018","name":"Destrnik"},
{"country":"SI","code":"SI-019","name":"Divaca"},
{"country":"SI","code":"SI-154","name":"Dobje"},
{"country":"SI","code":"SI-020","name":"Dobrepolje"},
{"country":"SI","code":"SI-155","name":"Dobrna"},
{"country":"SI","code":"SI-021","name":"Dobrova-Polhov Gradec"},
{"country":"SI","code":"SI-156","name":"Dobrovnik"},
{"country":"SI","code":"SI-023","name":"Domzale"},
{"country":"SI","code":"SI-024","name":"Dornava"},
{"country":"SI","code":"SI-025","name":"Dravograd"},
{"country":"SI","code":"SI-026","name":"Duplek"},
{"country":"SI","code":"SI-207","name":"Gorje"},
{"country":"SI","code":"SI-029","name":"Gornja Radgona"},
{"country":"SI","code":"SI-031","name":"Gornji Petrovci"},
{"country":"SI","code":"SI-158","name":"Grad"},
{"country":"SI","code":"SI-032","name":"Grosuplje"},
{"country":"SI","code":"SI-159","name":"Hajdina"},
{"country":"SI","code":"SI-160","name":"Hoce-Slivnica"},
{"country":"SI","code":"SI-161","name":"Hodos"},
{"country":"SI","code":"SI-162","name":"Horjul"},
{"country":"SI","code":"SI-034","name":"Hrastnik"},
{"country":"SI","code":"SI-035","name":"Hrpelje-Kozina"},
{"country":"SI","code":"SI-036","name":"Idrija"},
{"country":"SI","code":"SI-037","name":"Ig"},
{"country":"SI","code":"SI-038","name":"Ilirska Bistrica"},
{"country":"SI","code":"SI-039","name":"Ivancna Gorica"},
{"country":"SI","code":"SI-040","name":"Izola"},
{"country":"SI","code":"SI-041","name":"Jesenice"},
{"country":"SI","code":"SI-042","name":"Jursinci"},
{"country":"SI","code":"SI-043","name":"Kamnik"},
{"country":"SI","code":"SI-044","name":"Kanal"},
{"country":"SI","code":"SI-045","name":"Kidricevo"},
{"country":"SI","code":"SI-046","name":"Kobarid"},
{"country":"SI","code":"SI-047","name":"Kobilje"},
{"country":"SI","code":"SI-048","name":"Kocevje"},
{"country":"SI","code":"SI-049","name":"Komen"},
{"country":"SI","code":"SI-164","name":"Komenda"},
{"country":"SI","code":"SI-050","name":"Koper"},
{"country":"SI","code":"SI-197","name":"Kosanjevica na Krki"},
{"country":"SI","code":"SI-165","name":"Kostel"},
{"country":"SI","code":"SI-052","name":"Kranj"},
{"country":"SI","code":"SI-053","name":"Kranjska Gora"},
{"country":"SI","code":"SI-166","name":"Krizevci"},
{"country":"SI","code":"SI-054","name":"Krsko"},
{"country":"SI","code":"SI-055","name":"Kungota"},
{"country":"SI","code":"SI-056","name":"Kuzma"},
{"country":"SI","code":"SI-057","name":"Lasko"},
{"country":"SI","code":"SI-058","name":"Lenart"},
{"country":"SI","code":"SI-059","name":"Lendava"},
{"country":"SI","code":"SI-060","name":"Litija"},
{"country":"SI","code":"SI-061","name":"Ljubljana"},
{"country":"SI","code":"SI-063","name":"Ljutomer"},
{"country":"SI","code":"SI-208","name":"Log-Dragomer"},
{"country":"SI","code":"SI-064","name":"Logatec"},
{"country":"SI","code":"SI-065","name":"Loska dolina"},
{"country":"SI","code":"SI-066","name":"Loski Potok"},
{"country":"SI","code":"SI-167","name":"Lovrenc na Pohorju"},
{"country":"SI","code":"SI-067","name":"Luce"},
{"country":"SI","code":"SI-068","name":"Lukovica"},
{"country":"SI","code":"SI-069","name":"Majsperk"},
{"country":"SI","code":"SI-198","name":"Makole"},
{"country":"SI","code":"SI-070","name":"Maribor"},
{"country":"SI","code":"SI-168","name":"Markovci"},
{"country":"SI","code":"SI-071","name":"Medvode"},
{"country":"SI","code":"SI-072","name":"Menges"},
{"country":"SI","code":"SI-073","name":"Metlika"},
{"country":"SI","code":"SI-074","name":"Mezica"},
{"country":"SI","code":"SI-169","name":"Miklavz na Dravskem polju"},
{"country":"SI","code":"SI-075","name":"Miren-Kostanjevica"},
{"country":"SI","code":"SI-212","name":"Mirna"},
{"country":"SI","code":"SI-170","name":"Mirna Pec"},
{"country":"SI","code":"SI-076","name":"Mislinja"},
{"country":"SI","code":"SI-199","name":"Mokronog-Trebelno"},
{"country":"SI","code":"SI-077","name":"Moravce"},
{"country":"SI","code":"SI-079","name":"Mozirje"},
{"country":"SI","code":"SI-080","name":"Murska Sobota"},
{"country":"SI","code":"SI-081","name":"Muta"},
{"country":"SI","code":"SI-082","name":"Naklo"},
{"country":"SI","code":"SI-083","name":"Nazarje"},
{"country":"SI","code":"SI-084","name":"Nova Gorica"},
{"country":"SI","code":"SI-085","name":"Novo Mesto"},
{"country":"SI","code":"SI-086","name":"Odranci"},
{"country":"SI","code":"SI-171","name":"Oplotnica"},
{"country":"SI","code":"SI-087","name":"Ormoz"},
{"country":"SI","code":"SI-090","name":"Piran"},
{"country":"SI","code":"SI-091","name":"Pivka"},
{"country":"SI","code":"SI-092","name":"Podcetrtek"},
{"country":"SI","code":"SI-172","name":"Podlehnik"},
{"country":"SI","code":"SI-200","name":"Poljcane"},
{"country":"SI","code":"SI-173","name":"Polzela"},
{"country":"SI","code":"SI-094","name":"Postojna"},
{"country":"SI","code":"SI-174","name":"Prebold"},
{"country":"SI","code":"SI-095","name":"Preddvor"},
{"country":"SI","code":"SI-175","name":"Prevalje"},
{"country":"SI","code":"SI-096","name":"Ptuj"},
{"country":"SI","code":"SI-097","name":"Puconci"},
{"country":"SI","code":"SI-098","name":"Race-Fram"},
{"country":"SI","code":"SI-099","name":"Radece"},
{"country":"SI","code":"SI-100","name":"Radenci"},
{"country":"SI","code":"SI-101","name":"Radlje ob Dravi"},
{"country":"SI","code":"SI-102","name":"Radovljica"},
{"country":"SI","code":"SI-103","name":"Ravne na Koroskem"},
{"country":"SI","code":"SI-176","name":"Razkrizje"},
{"country":"SI","code":"SI-209","name":"Recica ob Savinji"},
{"country":"SI","code":"SI-201","name":"Rence-Vogrsko"},
{"country":"SI","code":"SI-104","name":"Ribnica"},
{"country":"SI","code":"SI-106","name":"Rogaska Slatina"},
{"country":"SI","code":"SI-105","name":"Rogasovci"},
{"country":"SI","code":"SI-108","name":"Ruse"},
{"country":"SI","code":"SI-033","name":"Salovci"},
{"country":"SI","code":"SI-109","name":"Semic"},
{"country":"SI","code":"SI-183","name":"Sempeter-Vrtojba"},
{"country":"SI","code":"SI-117","name":"Sencur"},
{"country":"SI","code":"SI-118","name":"Sentilj"},
{"country":"SI","code":"SI-119","name":"Sentjernej"},
{"country":"SI","code":"SI-120","name":"Sentjur"},
{"country":"SI","code":"SI-211","name":"Sentrupert"},
{"country":"SI","code":"SI-110","name":"Sevnica"},
{"country":"SI","code":"SI-111","name":"Sezana"},
{"country":"SI","code":"SI-121","name":"Skocjan"},
{"country":"SI","code":"SI-122","name":"Skofja Loka"},
{"country":"SI","code":"SI-123","name":"Skofljica"},
{"country":"SI","code":"SI-112","name":"Slovenj Gradec"},
{"country":"SI","code":"SI-113","name":"Slovenska Bistrica"},
{"country":"SI","code":"SI-114","name":"Slovenske Konjice"},
{"country":"SI","code":"SI-124","name":"Smarje pri Jelsah"},
{"country":"SI","code":"SI-206","name":"Smarjeske Toplice"},
{"country":"SI","code":"SI-125","name":"Smartno ob Paki"},
{"country":"SI","code":"SI-194","name":"Smartno pri Litiji"},
{"country":"SI","code":"SI-179","name":"Sodrazica"},
{"country":"SI","code":"SI-180","name":"Solcava"},
{"country":"SI","code":"SI-126","name":"Sostanj"},
{"country":"SI","code":"SI-115","name":"Starse"},
{"country":"SI","code":"SI-127","name":"Store"},
{"country":"SI","code":"SI-203","name":"Straza"},
{"country":"SI","code":"SI-204","name":"Sveta Trojica v Slovenskih goricah"},
{"country":"SI","code":"SI-182","name":"Sveti Andraz v Slovenskih Goricah"},
{"country":"SI","code":"SI-116","name":"Sveti Jurij ob Scavnici"},
{"country":"SI","code":"SI-210","name":"Sveti Jurij v Slovenskih goricah"},
{"country":"SI","code":"SI-205","name":"Sveti Tomaz"},
{"country":"SI","code":"SI-184","name":"Tabor"},
{"country":"SI","code":"SI-010","name":"Tisina"},
{"country":"SI","code":"SI-128","name":"Tolmin"},
{"country":"SI","code":"SI-129","name":"Trbovlje"},
{"country":"SI","code":"SI-130","name":"Trebnje"},
{"country":"SI","code":"SI-185","name":"Trnovska Vas"},
{"country":"SI","code":"SI-131","name":"Trzic"},
{"country":"SI","code":"SI-186","name":"Trzin"},
{"country":"SI","code":"SI-132","name":"Turnisce"},
{"country":"SI","code":"SI-133","name":"Velenje"},
{"country":"SI","code":"SI-187","name":"Velika Polana"},
{"country":"SI","code":"SI-134","name":"Velike Lasce"},
{"country":"SI","code":"SI-188","name":"Verzej"},
{"country":"SI","code":"SI-135","name":"Videm"},
{"country":"SI","code":"SI-136","name":"Vipava"},
{"country":"SI","code":"SI-137","name":"Vitanje"},
{"country":"SI","code":"SI-138","name":"Vodice"},
{"country":"SI","code":"SI-139","name":"Vojnik"},
{"country":"SI","code":"SI-189","name":"Vransko"},
{"country":"SI","code":"SI-140","name":"Vrhnika"},
{"country":"SI","code":"SI-141","name":"Vuzenica"},
{"country":"SI","code":"SI-142","name":"Zagorje ob Savi"},
{"country":"SI","code":"SI-190","name":"Zalec"},
{"country":"SI","code":"SI-143","name":"Zavrc"},
{"country":"SI","code":"SI-146","name":"Zelezniki"},
{"country":"SI","code":"SI-191","name":"Zetale"},
{"country":"SI","code":"SI-147","name":"Ziri"},
{"country":"SI","code":"SI-144","name":"Zrece"},
{"country":"SI","code":"SI-193","name":"Zuzemberk"},
{"country":"SJ","code":"SJ-XX-1","name":"Svalbard and Jan Mayen"},
{"country":"SK","code":"SK-BC","name":"Banskobystricky kraj"},
{"country":"SK","code":"SK-BL","name":"Bratislavsky kraj"},
{"country":"SK","code":"SK-KI","name":"Kosicky kraj"},
{"country":"SK","code":"SK-NI","name":"Nitriansky kraj"},
{"country":"SK","code":"SK-PV","name":"Presovsky kraj"},
{"country":"SK","code":"SK-TC","name":"Trenciansky kraj"},
{"country":"SK","code":"SK-TA","name":"Trnavsky kraj"},
{"country":"SK","code":"SK-ZI","name":"Zilinsky kraj"},
{"country":"SL","code":"SL-E","name":"Eastern"},
{"country":"SL","code":"SL-N","name":"Northern"},
{"country":"SL","code":"SL-S","name":"Southern"},
{"country":"SL","code":"SL-W","name":"Western Area"},
{"country":"SM","code":"SM-07","name":"Citta di San Marino"},
{"country":"SM","code":"SM-03","name":"Domagnano"},
{"country":"SM","code":"SM-04","name":"Faetano"},
{"country":"SM","code":"SM-09","name":"Serravalle"},
{"country":"SN","code":"SN-DK","name":"Dakar"},
{"country":"SN","code":"SN-DB","name":"Diourbel"},
{"country":"SN","code":"SN-FK","name":"Fatick"},
{"country":"SN","code":"SN-KA","name":"Kaffrine"},
{"country":"SN","code":"SN-KL","name":"Kaolack"},
{"country":"SN","code":"SN-KE","name":"Kedougou"},
{"country":"SN","code":"SN-KD","name":"Kolda"},
{"country":"SN","code":"SN-LG","name":"Louga"},
{"country":"SN","code":"SN-MT","name":"Matam"},
{"country":"SN","code":"SN-SL","name":"Saint-Louis"},
{"country":"SN","code":"SN-SE","name":"Sedhiou"},
{"country":"SN","code":"SN-TC","name":"Tambacounda"},
{"country":"SN","code":"SN-TH","name":"Thies"},
{"country":"SN","code":"SN-ZG","name":"Ziguinchor"},
{"country":"SO","code":"SO-AW","name":"Awdal"},
{"country":"SO","code":"SO-BN","name":"Banaadir"},
{"country":"SO","code":"SO-BR","name":"Bari"},
{"country":"SO","code":"SO-GA","name":"Galguduud"},
{"country":"SO","code":"SO-JH","name":"Jubbada Hoose"},
{"country":"SO","code":"SO-MU","name":"Mudug"},
{"country":"SO","code":"SO-NU","name":"Nugaal"},
{"country":"SO","code":"SO-SH","name":"Shabeellaha Hoose"},
{"country":"SO","code":"SO-TO","name":"Togdheer"},
{"country":"SO","code":"SO-WO","name":"Woqooyi Galbeed"},
{"country":"SR","code":"SR-BR","name":"Brokopondo"},
{"country":"SR","code":"SR-CM","name":"Commewijne"},
{"country":"SR","code":"SR-NI","name":"Nickerie"},
{"country":"SR","code":"SR-PR","name":"Para"},
{"country":"SR","code":"SR-PM","name":"Paramaribo"},
{"country":"SR","code":"SR-SI","name":"Sipaliwini"},
{"country":"SR","code":"SR-WA","name":"Wanica"},
{"country":"SS","code":"SS-EC","name":"Central Equatoria"},
{"country":"SS","code":"SS-EE","name":"Eastern Equatoria"},
{"country":"SS","code":"SS-JG","name":"Jonglei"},
{"country":"SS","code":"SS-LK","name":"Lakes"},
{"country":"SS","code":"SS-BN","name":"Northern Bahr el Ghazal"},
{"country":"SS","code":"SS-NU","name":"Upper Nile"},
{"country":"SS","code":"SS-EW","name":"Western Equatoria"},
{"country":"ST","code":"ST-01","name":"Agua Grande"},
{"country":"SV","code":"SV-AH","name":"Ahuachapan"},
{"country":"SV","code":"SV-CA","name":"Cabanas"},
{"country":"SV","code":"SV-CH","name":"Chalatenango"},
{"country":"SV","code":"SV-CU","name":"Cuscatlan"},
{"country":"SV","code":"SV-LI","name":"La Libertad"},
{"country":"SV","code":"SV-PA","name":"La Paz"},
{"country":"SV","code":"SV-UN","name":"La Union"},
{"country":"SV","code":"SV-MO","name":"Morazan"},
{"country":"SV","code":"SV-SM","name":"San Miguel"},
{"country":"SV","code":"SV-SS","name":"San Salvador"},
{"country":"SV","code":"SV-SV","name":"San Vicente"},
{"country":"SV","code":"SV-SA","name":"Santa Ana"},
{"country":"SV","code":"SV-SO","name":"Sonsonate"},
{"country":"SV","code":"SV-US","name":"Usulutan"},
{"country":"SX","code":"SX-XX-1","name":"Sint Maarten (Dutch Part)"},
{"country":"SY","code":"SY-HA","name":"Al Hasakah"},
{"country":"SY","code":"SY-LA","name":"Al Ladhiqiyah"},
{"country":"SY","code":"SY-QU","name":"Al Qunaytirah"},
{"country":"SY","code":"SY-RA","name":"Ar Raqqah"},
{"country":"SY","code":"SY-SU","name":"As Suwayda'"},
{"country":"SY","code":"SY-DR","name":"Dar'a"},
{"country":"SY","code":"SY-DY","name":"Dayr az Zawr"},
{"country":"SY","code":"SY-DI","name":"Dimashq"},
{"country":"SY","code":"SY-HL","name":"Halab"},
{"country":"SY","code":"SY-HM","name":"Hamah"},
{"country":"SY","code":"SY-HI","name":"Hims"},
{"country":"SY","code":"SY-ID","name":"Idlib"},
{"country":"SY","code":"SY-RD","name":"Rif Dimashq"},
{"country":"SY","code":"SY-TA","name":"Tartus"},
{"country":"SZ","code":"SZ-HH","name":"Hhohho"},
{"country":"SZ","code":"SZ-LU","name":"Lubombo"},
{"country":"SZ","code":"SZ-MA","name":"Manzini"},
{"country":"TC","code":"TC-XX-1","name":"Turks and Caicos Islands"},
{"country":"TD","code":"TD-BG","name":"Bahr el Ghazal"},
{"country":"TD","code":"TD-CB","name":"Chari-Baguirmi"},
{"country":"TD","code":"TD-GR","name":"Guera"},
{"country":"TD","code":"TD-LO","name":"Logone-Occidental"},
{"country":"TD","code":"TD-ME","name":"Mayo-Kebbi-Est"},
{"country":"TD","code":"TD-OD","name":"Ouaddai"},
{"country":"TD","code":"TD-ND","name":"Ville de Ndjamena"},
{"country":"TF","code":"TF-XX-1","name":"French Southern Territories"},
{"country":"TG","code":"TG-C","name":"Centrale"},
{"country":"TG","code":"TG-K","name":"Kara"},
{"country":"TG","code":"TG-M","name":"Maritime"},
{"country":"TG","code":"TG-P","name":"Plateaux"},
{"country":"TH","code":"TH-37","name":"Amnat Charoen"},
{"country":"TH","code":"TH-15","name":"Ang Thong"},
{"country":"TH","code":"TH-38","name":"Bueng Kan"},
{"country":"TH","code":"TH-31","name":"Buri Ram"},
{"country":"TH","code":"TH-24","name":"Chachoengsao"},
{"country":"TH","code":"TH-18","name":"Chai Nat"},
{"country":"TH","code":"TH-36","name":"Chaiyaphum"},
{"country":"TH","code":"TH-22","name":"Chanthaburi"},
{"country":"TH","code":"TH-50","name":"Chiang Mai"},
{"country":"TH","code":"TH-57","name":"Chiang Rai"},
{"country":"TH","code":"TH-20","name":"Chon Buri"},
{"country":"TH","code":"TH-86","name":"Chumphon"},
{"country":"TH","code":"TH-46","name":"Kalasin"},
{"country":"TH","code":"TH-62","name":"Kamphaeng Phet"},
{"country":"TH","code":"TH-71","name":"Kanchanaburi"},
{"country":"TH","code":"TH-40","name":"Khon Kaen"},
{"country":"TH","code":"TH-81","name":"Krabi"},
{"country":"TH","code":"TH-10","name":"Krung Thep Maha Nakhon"},
{"country":"TH","code":"TH-52","name":"Lampang"},
{"country":"TH","code":"TH-51","name":"Lamphun"},
{"country":"TH","code":"TH-42","name":"Loei"},
{"country":"TH","code":"TH-16","name":"Lop Buri"},
{"country":"TH","code":"TH-58","name":"Mae Hong Son"},
{"country":"TH","code":"TH-44","name":"Maha Sarakham"},
{"country":"TH","code":"TH-49","name":"Mukdahan"},
{"country":"TH","code":"TH-26","name":"Nakhon Nayok"},
{"country":"TH","code":"TH-73","name":"Nakhon Pathom"},
{"country":"TH","code":"TH-48","name":"Nakhon Phanom"},
{"country":"TH","code":"TH-30","name":"Nakhon Ratchasima"},
{"country":"TH","code":"TH-60","name":"Nakhon Sawan"},
{"country":"TH","code":"TH-80","name":"Nakhon Si Thammarat"},
{"country":"TH","code":"TH-55","name":"Nan"},
{"country":"TH","code":"TH-96","name":"Narathiwat"},
{"country":"TH","code":"TH-39","name":"Nong Bua Lam Phu"},
{"country":"TH","code":"TH-43","name":"Nong Khai"},
{"country":"TH","code":"TH-12","name":"Nonthaburi"},
{"country":"TH","code":"TH-13","name":"Pathum Thani"},
{"country":"TH","code":"TH-94","name":"Pattani"},
{"country":"TH","code":"TH-82","name":"Phangnga"},
{"country":"TH","code":"TH-93","name":"Phatthalung"},
{"country":"TH","code":"TH-56","name":"Phayao"},
{"country":"TH","code":"TH-67","name":"Phetchabun"},
{"country":"TH","code":"TH-76","name":"Phetchaburi"},
{"country":"TH","code":"TH-66","name":"Phichit"},
{"country":"TH","code":"TH-65","name":"Phitsanulok"},
{"country":"TH","code":"TH-14","name":"Phra Nakhon Si Ayutthaya"},
{"country":"TH","code":"TH-54","name":"Phrae"},
{"country":"TH","code":"TH-83","name":"Phuket"},
{"country":"TH","code":"TH-25","name":"Prachin Buri"},
{"country":"TH","code":"TH-77","name":"Prachuap Khiri Khan"},
{"country":"TH","code":"TH-85","name":"Ranong"},
{"country":"TH","code":"TH-70","name":"Ratchaburi"},
{"country":"TH","code":"TH-21","name":"Rayong"},
{"country":"TH","code":"TH-45","name":"Roi Et"},
{"country":"TH","code":"TH-27","name":"Sa Kaeo"},
{"country":"TH","code":"TH-47","name":"Sakon Nakhon"},
{"country":"TH","code":"TH-11","name":"Samut Prakan"},
{"country":"TH","code":"TH-74","name":"Samut Sakhon"},
{"country":"TH","code":"TH-75","name":"Samut Songkhram"},
{"country":"TH","code":"TH-19","name":"Saraburi"},
{"country":"TH","code":"TH-91","name":"Satun"},
{"country":"TH","code":"TH-33","name":"Si Sa Ket"},
{"country":"TH","code":"TH-17","name":"Sing Buri"},
{"country":"TH","code":"TH-90","name":"Songkhla"},
{"country":"TH","code":"TH-64","name":"Sukhothai"},
{"country":"TH","code":"TH-72","name":"Suphan Buri"},
{"country":"TH","code":"TH-84","name":"Surat Thani"},
{"country":"TH","code":"TH-32","name":"Surin"},
{"country":"TH","code":"TH-63","name":"Tak"},
{"country":"TH","code":"TH-92","name":"Trang"},
{"country":"TH","code":"TH-23","name":"Trat"},
{"country":"TH","code":"TH-34","name":"Ubon Ratchathani"},
{"country":"TH","code":"TH-41","name":"Udon Thani"},
{"country":"TH","code":"TH-61","name":"Uthai Thani"},
{"country":"TH","code":"TH-53","name":"Uttaradit"},
{"country":"TH","code":"TH-95","name":"Yala"},
{"country":"TH","code":"TH-35","name":"Yasothon"},
{"country":"TJ","code":"TJ-DU","name":"Dushanbe"},
{"country":"TJ","code":"TJ-KT","name":"Khatlon"},
{"country":"TJ","code":"TJ-RA","name":"Nohiyahoi Tobei Jumhuri"},
{"country":"TJ","code":"TJ-SU","name":"Sughd"},
{"country":"TK","code":"TK-XX-1","name":"Tokelau"},
{"country":"TL","code":"TL-AN","name":"Ainaro"},
{"country":"TL","code":"TL-BO","name":"Bobonaro"},
{"country":"TL","code":"TL-CO","name":"Cova Lima"},
{"country":"TL","code":"TL-DI","name":"Dili"},
{"country":"TL","code":"TL-LI","name":"Liquica"},
{"country":"TM","code":"TM-A","name":"Ahal"},
{"country":"TM","code":"TM-B","name":"Balkan"},
{"country":"TM","code":"TM-D","name":"Dasoguz"},
{"country":"TM","code":"TM-L","name":"Lebap"},
{"country":"TM","code":"TM-M","name":"Mary"},
{"country":"TN","code":"TN-31","name":"Beja"},
{"country":"TN","code":"TN-13","name":"Ben Arous"},
{"country":"TN","code":"TN-23","name":"Bizerte"},
{"country":"TN","code":"TN-81","name":"Gabes"},
{"country":"TN","code":"TN-71","name":"Gafsa"},
{"country":"TN","code":"TN-32","name":"Jendouba"},
{"country":"TN","code":"TN-41","name":"Kairouan"},
{"country":"TN","code":"TN-42","name":"Kasserine"},
{"country":"TN","code":"TN-73","name":"Kebili"},
{"country":"TN","code":"TN-12","name":"L'Ariana"},
{"country":"TN","code":"TN-14","name":"La Manouba"},
{"country":"TN","code":"TN-33","name":"Le Kef"},
{"country":"TN","code":"TN-53","name":"Mahdia"},
{"country":"TN","code":"TN-82","name":"Medenine"},
{"country":"TN","code":"TN-52","name":"Monastir"},
{"country":"TN","code":"TN-21","name":"Nabeul"},
{"country":"TN","code":"TN-61","name":"Sfax"},
{"country":"TN","code":"TN-43","name":"Sidi Bouzid"},
{"country":"TN","code":"TN-34","name":"Siliana"},
{"country":"TN","code":"TN-51","name":"Sousse"},
{"country":"TN","code":"TN-83","name":"Tataouine"},
{"country":"TN","code":"TN-72","name":"Tozeur"},
{"country":"TN","code":"TN-11","name":"Tunis"},
{"country":"TN","code":"TN-22","name":"Zaghouan"},
{"country":"TO","code":"TO-02","name":"Ha'apai"},
{"country":"TO","code":"TO-03","name":"Niuas"},
{"country":"TO","code":"TO-04","name":"Tongatapu"},
{"country":"TR","code":"TR-01","name":"Adana"},
{"country":"TR","code":"TR-02","name":"Adiyaman"},
{"country":"TR","code":"TR-03","name":"Afyonkarahisar"},
{"country":"TR","code":"TR-04","name":"Agri"},
{"country":"TR","code":"TR-68","name":"Aksaray"},
{"country":"TR","code":"TR-05","name":"Amasya"},
{"country":"TR","code":"TR-06","name":"Ankara"},
{"country":"TR","code":"TR-07","name":"Antalya"},
{"country":"TR","code":"TR-75","name":"Ardahan"},
{"country":"TR","code":"TR-08","name":"Artvin"},
{"country":"TR","code":"TR-09","name":"Aydin"},
{"country":"TR","code":"TR-10","name":"Balikesir"},
{"country":"TR","code":"TR-74","name":"Bartin"},
{"country":"TR","code":"TR-72","name":"Batman"},
{"country":"TR","code":"TR-69","name":"Bayburt"},
{"country":"TR","code":"TR-11","name":"Bilecik"},
{"country":"TR","code":"TR-12","name":"Bingol"},
{"country":"TR","code":"TR-13","name":"Bitlis"},
{"country":"TR","code":"TR-14","name":"Bolu"},
{"country":"TR","code":"TR-15","name":"Burdur"},
{"country":"TR","code":"TR-16","name":"Bursa"},
{"country":"TR","code":"TR-17","name":"Canakkale"},
{"country":"TR","code":"TR-18","name":"Cankiri"},
{"country":"TR","code":"TR-19","name":"Corum"},
{"country":"TR","code":"TR-20","name":"Denizli"},
{"country":"TR","code":"TR-21","name":"Diyarbakir"},
{"country":"TR","code":"TR-81","name":"Duzce"},
{"country":"TR","code":"TR-22","name":"Edirne"},
{"country":"TR","code":"TR-23","name":"Elazig"},
{"country":"TR","code":"TR-24","name":"Erzincan"},
{"country":"TR","code":"TR-25","name":"Erzurum"},
{"country":"TR","code":"TR-26","name":"Eskisehir"},
{"country":"TR","code":"TR-27","name":"Gaziantep"},
{"country":"TR","code":"TR-28","name":"Giresun"},
{"country":"TR","code":"TR-29","name":"Gumushane"},
{"country":"TR","code":"TR-30","name":"Hakkari"},
{"country":"TR","code":"TR-31","name":"Hatay"},
{"country":"TR","code":"TR-76","name":"Igdir"},
{"country":"TR","code":"TR-32","name":"Isparta"},
{"country":"TR","code":"TR-34","name":"Istanbul"},
{"country":"TR","code":"TR-35","name":"Izmir"},
{"country":"TR","code":"TR-46","name":"Kahramanmaras"},
{"country":"TR","code":"TR-78","name":"Karabuk"},
{"country":"TR","code":"TR-70","name":"Karaman"},
{"country":"TR","code":"TR-36","name":"Kars"},
{"country":"TR","code":"TR-37","name":"Kastamonu"},
{"country":"TR","code":"TR-38","name":"Kayseri"},
{"country":"TR","code":"TR-79","name":"Kilis"},
{"country":"TR","code":"TR-71","name":"Kirikkale"},
{"country":"TR","code":"TR-39","name":"Kirklareli"},
{"country":"TR","code":"TR-40","name":"Kirsehir"},
{"country":"TR","code":"TR-41","name":"Kocaeli"},
{"country":"TR","code":"TR-42","name":"Konya"},
{"country":"TR","code":"TR-43","name":"Kutahya"},
{"country":"TR","code":"TR-44","name":"Malatya"},
{"country":"TR","code":"TR-45","name":"Manisa"},
{"country":"TR","code":"TR-47","name":"Mardin"},
{"country":"TR","code":"TR-33","name":"Mersin"},
{"country":"TR","code":"TR-48","name":"Mugla"},
{"country":"TR","code":"TR-49","name":"Mus"},
{"country":"TR","code":"TR-50","name":"Nevsehir"},
{"country":"TR","code":"TR-51","name":"Nigde"},
{"country":"TR","code":"TR-52","name":"Ordu"},
{"country":"TR","code":"TR-80","name":"Osmaniye"},
{"country":"TR","code":"TR-53","name":"Rize"},
{"country":"TR","code":"TR-54","name":"Sakarya"},
{"country":"TR","code":"TR-55","name":"Samsun"},
{"country":"TR","code":"TR-63","name":"Sanliurfa"},
{"country":"TR","code":"TR-56","name":"Siirt"},
{"country":"TR","code":"TR-57","name":"Sinop"},
{"country":"TR","code":"TR-73","name":"Sirnak"},
{"country":"TR","code":"TR-58","name":"Sivas"},
{"country":"TR","code":"TR-59","name":"Tekirdag"},
{"country":"TR","code":"TR-60","name":"Tokat"},
{"country":"TR","code":"TR-61","name":"Trabzon"},
{"country":"TR","code":"TR-62","name":"Tunceli"},
{"country":"TR","code":"TR-64","name":"Usak"},
{"country":"TR","code":"TR-65","name":"Van"},
{"country":"TR","code":"TR-77","name":"Yalova"},
{"country":"TR","code":"TR-66","name":"Yozgat"},
{"country":"TR","code":"TR-67","name":"Zonguldak"},
{"country":"TT","code":"TT-ARI","name":"Arima"},
{"country":"TT","code":"TT-CHA","name":"Chaguanas"},
{"country":"TT","code":"TT-CTT","name":"Couva-Tabaquite-Talparo"},
{"country":"TT","code":"TT-DMN","name":"Diego Martin"},
{"country":"TT","code":"TT-MRC","name":"Mayaro-Rio Claro"},
{"country":"TT","code":"TT-PED","name":"Penal-Debe"},
{"country":"TT","code":"TT-PTF","name":"Point Fortin"},
{"country":"TT","code":"TT-POS","name":"Port of Spain"},
{"country":"TT","code":"TT-PRT","name":"Princes Town"},
{"country":"TT","code":"TT-SFO","name":"San Fernando"},
{"country":"TT","code":"TT-SJL","name":"San Juan-Laventille"},
{"country":"TT","code":"TT-SGE","name":"Sangre Grande"},
{"country":"TT","code":"TT-SIP","name":"Siparia"},
{"country":"TT","code":"TT-TOB","name":"Tobago"},
{"country":"TT","code":"TT-TUP","name":"Tunapuna-Piarco"},
{"country":"TV","code":"TV-FUN","name":"Funafuti"},
{"country":"TW","code":"TW-CHA","name":"Changhua"},
{"country":"TW","code":"TW-CYQ","name":"Chiayi"},
{"country":"TW","code":"TW-HSQ","name":"Hsinchu"},
{"country":"TW","code":"TW-HUA","name":"Hualien"},
{"country":"TW","code":"TW-KHH","name":"Kaohsiung"},
{"country":"TW","code":"TW-KEE","name":"Keelung"},
{"country":"TW","code":"TW-KIN","name":"Kinmen"},
{"country":"TW","code":"TW-LIE","name":"Lienchiang"},
{"country":"TW","code":"TW-MIA","name":"Miaoli"},
{"country":"TW","code":"TW-NAN","name":"Nantou"},
{"country":"TW","code":"TW-NWT","name":"New Taipei"},
{"country":"TW","code":"TW-PEN","name":"Penghu"},
{"country":"TW","code":"TW-PIF","name":"Pingtung"},
{"country":"TW","code":"TW-TXG","name":"Taichung"},
{"country":"TW","code":"TW-TNN","name":"Tainan"},
{"country":"TW","code":"TW-TPE","name":"Taipei"},
{"country":"TW","code":"TW-TTT","name":"Taitung"},
{"country":"TW","code":"TW-TAO","name":"Taoyuan"},
{"country":"TW","code":"TW-ILA","name":"Yilan"},
{"country":"TW","code":"TW-YUN","name":"Yunlin"},
{"country":"TZ","code":"TZ-01","name":"Arusha"},
{"country":"TZ","code":"TZ-02","name":"Dar es Salaam"},
{"country":"TZ","code":"TZ-03","name":"Dodoma"},
{"country":"TZ","code":"TZ-27","name":"Geita"},
{"country":"TZ","code":"TZ-04","name":"Iringa"},
{"country":"TZ","code":"TZ-05","name":"Kagera"},
{"country":"TZ","code":"TZ-06","name":"Kaskazini Pemba"},
{"country":"TZ","code":"TZ-07","name":"Kaskazini Unguja"},
{"country":"TZ","code":"TZ-28","name":"Katavi"},
{"country":"TZ","code":"TZ-08","name":"Kigoma"},
{"country":"TZ","code":"TZ-09","name":"Kilimanjaro"},
{"country":"TZ","code":"TZ-11","name":"Kusini Unguja"},
{"country":"TZ","code":"TZ-12","name":"Lindi"},
{"country":"TZ","code":"TZ-26","name":"Manyara"},
{"country":"TZ","code":"TZ-13","name":"Mara"},
{"country":"TZ","code":"TZ-14","name":"Mbeya"},
{"country":"TZ","code":"TZ-15","name":"Mjini Magharibi"},
{"country":"TZ","code":"TZ-16","name":"Morogoro"},
{"country":"TZ","code":"TZ-17","name":"Mtwara"},
{"country":"TZ","code":"TZ-18","name":"Mwanza"},
{"country":"TZ","code":"TZ-29","name":"Njombe"},
{"country":"TZ","code":"TZ-19","name":"Pwani"},
{"country":"TZ","code":"TZ-20","name":"Rukwa"},
{"country":"TZ","code":"TZ-21","name":"Ruvuma"},
{"country":"TZ","code":"TZ-22","name":"Shinyanga"},
{"country":"TZ","code":"TZ-30","name":"Simiyu"},
{"country":"TZ","code":"TZ-23","name":"Singida"},
{"country":"TZ","code":"TZ-31","name":"Songwe"},
{"country":"TZ","code":"TZ-24","name":"Tabora"},
{"country":"TZ","code":"TZ-25","name":"Tanga"},
{"country":"UA","code":"UA-43","name":"Avtonomna Respublika Krym"},
{"country":"UA","code":"UA-71","name":"Cherkaska oblast"},
{"country":"UA","code":"UA-74","name":"Chernihivska oblast"},
{"country":"UA","code":"UA-77","name":"Chernivetska oblast"},
{"country":"UA","code":"UA-12","name":"Dnipropetrovska oblast"},
{"country":"UA","code":"UA-14","name":"Donetska oblast"},
{"country":"UA","code":"UA-26","name":"Ivano-Frankivska oblast"},
{"country":"UA","code":"UA-63","name":"Kharkivska oblast"},
{"country":"UA","code":"UA-65","name":"Khersonska oblast"},
{"country":"UA","code":"UA-68","name":"Khmelnytska oblast"},
{"country":"UA","code":"UA-35","name":"Kirovohradska oblast"},
{"country":"UA","code":"UA-30","name":"Kyiv"},
{"country":"UA","code":"UA-32","name":"Kyivska oblast"},
{"country":"UA","code":"UA-09","name":"Luhanska oblast"},
{"country":"UA","code":"UA-46","name":"Lvivska oblast"},
{"country":"UA","code":"UA-48","name":"Mykolaivska oblast"},
{"country":"UA","code":"UA-51","name":"Odeska oblast"},
{"country":"UA","code":"UA-53","name":"Poltavska oblast"},
{"country":"UA","code":"UA-56","name":"Rivnenska oblast"},
{"country":"UA","code":"UA-40","name":"Sevastopol"},
{"country":"UA","code":"UA-59","name":"Sumska oblast"},
{"country":"UA","code":"UA-61","name":"Ternopilska oblast"},
{"country":"UA","code":"UA-05","name":"Vinnytska oblast"},
{"country":"UA","code":"UA-07","name":"Volynska oblast"},
{"country":"UA","code":"UA-21","name":"Zakarpatska oblast"},
{"country":"UA","code":"UA-23","name":"Zaporizka oblast"},
{"country":"UA","code":"UA-18","name":"Zhytomyrska oblast"},
{"country":"UG","code":"UG-314","name":"Abim"},
{"country":"UG","code":"UG-301","name":"Adjumani"},
{"country":"UG","code":"UG-322","name":"Agago"},
{"country":"UG","code":"UG-323","name":"Alebtong"},
{"country":"UG","code":"UG-315","name":"Amolatar"},
{"country":"UG","code":"UG-324","name":"Amudat"},
{"country":"UG","code":"UG-216","name":"Amuria"},
{"country":"UG","code":"UG-316","name":"Amuru"},
{"country":"UG","code":"UG-302","name":"Apac"},
{"country":"UG","code":"UG-303","name":"Arua"},
{"country":"UG","code":"UG-217","name":"Budaka"},
{"country":"UG","code":"UG-218","name":"Bududa"},
{"country":"UG","code":"UG-201","name":"Bugiri"},
{"country":"UG","code":"UG-420","name":"Buhweju"},
{"country":"UG","code":"UG-117","name":"Buikwe"},
{"country":"UG","code":"UG-219","name":"Bukedea"},
{"country":"UG","code":"UG-118","name":"Bukomansibi"},
{"country":"UG","code":"UG-220","name":"Bukwo"},
{"country":"UG","code":"UG-225","name":"Bulambuli"},
{"country":"UG","code":"UG-401","name":"Bundibugyo"},
{"country":"UG","code":"UG-402","name":"Bushenyi"},
{"country":"UG","code":"UG-202","name":"Busia"},
{"country":"UG","code":"UG-221","name":"Butaleja"},
{"country":"UG","code":"UG-120","name":"Buvuma"},
{"country":"UG","code":"UG-226","name":"Buyende"},
{"country":"UG","code":"UG-317","name":"Dokolo"},
{"country":"UG","code":"UG-121","name":"Gomba"},
{"country":"UG","code":"UG-304","name":"Gulu"},
{"country":"UG","code":"UG-403","name":"Hoima"},
{"country":"UG","code":"UG-417","name":"Ibanda"},
{"country":"UG","code":"UG-203","name":"Iganga"},
{"country":"UG","code":"UG-418","name":"Isingiro"},
{"country":"UG","code":"UG-204","name":"Jinja"},
{"country":"UG","code":"UG-318","name":"Kaabong"},
{"country":"UG","code":"UG-404","name":"Kabale"},
{"country":"UG","code":"UG-405","name":"Kabarole"},
{"country":"UG","code":"UG-213","name":"Kaberamaido"},
{"country":"UG","code":"UG-101","name":"Kalangala"},
{"country":"UG","code":"UG-222","name":"Kaliro"},
{"country":"UG","code":"UG-122","name":"Kalungu"},
{"country":"UG","code":"UG-102","name":"Kampala"},
{"country":"UG","code":"UG-205","name":"Kamuli"},
{"country":"UG","code":"UG-413","name":"Kamwenge"},
{"country":"UG","code":"UG-206","name":"Kapchorwa"},
{"country":"UG","code":"UG-406","name":"Kasese"},
{"country":"UG","code":"UG-207","name":"Katakwi"},
{"country":"UG","code":"UG-112","name":"Kayunga"},
{"country":"UG","code":"UG-407","name":"Kibaale"},
{"country":"UG","code":"UG-103","name":"Kiboga"},
{"country":"UG","code":"UG-227","name":"Kibuku"},
{"country":"UG","code":"UG-419","name":"Kiruhura"},
{"country":"UG","code":"UG-421","name":"Kiryandongo"},
{"country":"UG","code":"UG-408","name":"Kisoro"},
{"country":"UG","code":"UG-305","name":"Kitgum"},
{"country":"UG","code":"UG-319","name":"Koboko"},
{"country":"UG","code":"UG-306","name":"Kotido"},
{"country":"UG","code":"UG-208","name":"Kumi"},
{"country":"UG","code":"UG-228","name":"Kween"},
{"country":"UG","code":"UG-123","name":"Kyankwanzi"},
{"country":"UG","code":"UG-422","name":"Kyegegwa"},
{"country":"UG","code":"UG-415","name":"Kyenjojo"},
{"country":"UG","code":"UG-326","name":"Lamwo"},
{"country":"UG","code":"UG-307","name":"Lira"},
{"country":"UG","code":"UG-229","name":"Luuka"},
{"country":"UG","code":"UG-104","name":"Luwero"},
{"country":"UG","code":"UG-124","name":"Lwengo"},
{"country":"UG","code":"UG-114","name":"Lyantonde"},
{"country":"UG","code":"UG-223","name":"Manafwa"},
{"country":"UG","code":"UG-105","name":"Masaka"},
{"country":"UG","code":"UG-409","name":"Masindi"},
{"country":"UG","code":"UG-214","name":"Mayuge"},
{"country":"UG","code":"UG-209","name":"Mbale"},
{"country":"UG","code":"UG-410","name":"Mbarara"},
{"country":"UG","code":"UG-423","name":"Mitooma"},
{"country":"UG","code":"UG-115","name":"Mityana"},
{"country":"UG","code":"UG-308","name":"Moroto"},
{"country":"UG","code":"UG-309","name":"Moyo"},
{"country":"UG","code":"UG-106","name":"Mpigi"},
{"country":"UG","code":"UG-107","name":"Mubende"},
{"country":"UG","code":"UG-108","name":"Mukono"},
{"country":"UG","code":"UG-311","name":"Nakapiripirit"},
{"country":"UG","code":"UG-116","name":"Nakaseke"},
{"country":"UG","code":"UG-109","name":"Nakasongola"},
{"country":"UG","code":"UG-230","name":"Namayingo"},
{"country":"UG","code":"UG-224","name":"Namutumba"},
{"country":"UG","code":"UG-327","name":"Napak"},
{"country":"UG","code":"UG-310","name":"Nebbi"},
{"country":"UG","code":"UG-231","name":"Ngora"},
{"country":"UG","code":"UG-411","name":"Ntungamo"},
{"country":"UG","code":"UG-328","name":"Nwoya"},
{"country":"UG","code":"UG-321","name":"Oyam"},
{"country":"UG","code":"UG-312","name":"Pader"},
{"country":"UG","code":"UG-210","name":"Pallisa"},
{"country":"UG","code":"UG-110","name":"Rakai"},
{"country":"UG","code":"UG-425","name":"Rubirizi"},
{"country":"UG","code":"UG-412","name":"Rukungiri"},
{"country":"UG","code":"UG-111","name":"Sembabule"},
{"country":"UG","code":"UG-232","name":"Serere"},
{"country":"UG","code":"UG-426","name":"Sheema"},
{"country":"UG","code":"UG-215","name":"Sironko"},
{"country":"UG","code":"UG-211","name":"Soroti"},
{"country":"UG","code":"UG-212","name":"Tororo"},
{"country":"UG","code":"UG-113","name":"Wakiso"},
{"country":"UG","code":"UG-313","name":"Yumbe"},
{"country":"UG","code":"UG-330","name":"Zombo"},
{"country":"UM","code":"UM-95","name":"Palmyra Atoll"},
{"country":"US","code":"US-AL","name":"Alabama"},
{"country":"US","code":"US-AK","name":"Alaska"},
{"country":"US","code":"US-AZ","name":"Arizona"},
{"country":"US","code":"US-AR","name":"Arkansas"},
{"country":"US","code":"US-CA","name":"California"},
{"country":"US","code":"US-CO","name":"Colorado"},
{"country":"US","code":"US-CT","name":"Connecticut"},
{"country":"US","code":"US-DE","name":"Delaware"},
{"country":"US","code":"US-DC","name":"District of Columbia"},
{"country":"US","code":"US-FL","name":"Florida"},
{"country":"US","code":"US-GA","name":"Georgia"},
{"country":"US","code":"US-HI","name":"Hawaii"},
{"country":"US","code":"US-ID","name":"Idaho"},
{"country":"US","code":"US-IL","name":"Illinois"},
{"country":"US","code":"US-IN","name":"Indiana"},
{"country":"US","code":"US-IA","name":"Iowa"},
{"country":"US","code":"US-KS","name":"Kansas"},
{"country":"US","code":"US-KY","name":"Kentucky"},
{"country":"US","code":"US-LA","name":"Louisiana"},
{"country":"US","code":"US-ME","name":"Maine"},
{"country":"US","code":"US-MD","name":"Maryland"},
{"country":"US","code":"US-MA","name":"Massachusetts"},
{"country":"US","code":"US-MI","name":"Michigan"},
{"country":"US","code":"US-MN","name":"Minnesota"},
{"country":"US","code":"US-MS","name":"Mississippi"},
{"country":"US","code":"US-MO","name":"Missouri"},
{"country":"US","code":"US-MT","name":"Montana"},
{"country":"US","code":"US-NE","name":"Nebraska"},
{"country":"US","code":"US-NV","name":"Nevada"},
{"country":"US","code":"US-NH","name":"New Hampshire"},
{"country":"US","code":"US-NJ","name":"New Jersey"},
{"country":"US","code":"US-NM","name":"New Mexico"},
{"country":"US","code":"US-NY","name":"New York"},
{"country":"US","code":"US-NC","name":"North Carolina"},
{"country":"US","code":"US-ND","name":"North Dakota"},
{"country":"US","code":"US-OH","name":"Ohio"},
{"country":"US","code":"US-OK","name":"Oklahoma"},
{"country":"US","code":"US-OR","name":"Oregon"},
{"country":"US","code":"US-PA","name":"Pennsylvania"},
{"country":"US","code":"US-RI","name":"Rhode Island"},
{"country":"US","code":"US-SC","name":"South Carolina"},
{"country":"US","code":"US-SD","name":"South Dakota"},
{"country":"US","code":"US-TN","name":"Tennessee"},
{"country":"US","code":"US-TX","name":"Texas"},
{"country":"US","code":"US-UT","name":"Utah"},
{"country":"US","code":"US-VT","name":"Vermont"},
{"country":"US","code":"US-VA","name":"Virginia"},
{"country":"US","code":"US-WA","name":"Washington"},
{"country":"US","code":"US-WV","name":"West Virginia"},
{"country":"US","code":"US-WI","name":"Wisconsin"},
{"country":"US","code":"US-WY","name":"Wyoming"},
{"country":"UY","code":"UY-AR","name":"Artigas"},
{"country":"UY","code":"UY-CA","name":"Canelones"},
{"country":"UY","code":"UY-CL","name":"Cerro Largo"},
{"country":"UY","code":"UY-CO","name":"Colonia"},
{"country":"UY","code":"UY-DU","name":"Durazno"},
{"country":"UY","code":"UY-FS","name":"Flores"},
{"country":"UY","code":"UY-FD","name":"Florida"},
{"country":"UY","code":"UY-LA","name":"Lavalleja"},
{"country":"UY","code":"UY-MA","name":"Maldonado"},
{"country":"UY","code":"UY-MO","name":"Montevideo"},
{"country":"UY","code":"UY-PA","name":"Paysandu"},
{"country":"UY","code":"UY-RN","name":"Rio Negro"},
{"country":"UY","code":"UY-RV","name":"Rivera"},
{"country":"UY","code":"UY-RO","name":"Rocha"},
{"country":"UY","code":"UY-SA","name":"Salto"},
{"country":"UY","code":"UY-SJ","name":"San Jose"},
{"country":"UY","code":"UY-SO","name":"Soriano"},
{"country":"UY","code":"UY-TA","name":"Tacuarembo"},
{"country":"UY","code":"UY-TT","name":"Treinta y Tres"},
{"country":"UZ","code":"UZ-AN","name":"Andijon"},
{"country":"UZ","code":"UZ-BU","name":"Buxoro"},
{"country":"UZ","code":"UZ-FA","name":"Farg'ona"},
{"country":"UZ","code":"UZ-JI","name":"Jizzax"},
{"country":"UZ","code":"UZ-NG","name":"Namangan"},
{"country":"UZ","code":"UZ-NW","name":"Navoiy"},
{"country":"UZ","code":"UZ-QA","name":"Qashqadaryo"},
{"country":"UZ","code":"UZ-QR","name":"Qoraqalpog'iston Respublikasi"},
{"country":"UZ","code":"UZ-SA","name":"Samarqand"},
{"country":"UZ","code":"UZ-SI","name":"Sirdaryo"},
{"country":"UZ","code":"UZ-SU","name":"Surxondaryo"},
{"country":"UZ","code":"UZ-TK","name":"Toshkent"},
{"country":"UZ","code":"UZ-XO","name":"Xorazm"},
{"country":"VA","code":"VA-XX-1","name":"Vatican City"},
{"country":"VC","code":"VC-01","name":"Charlotte"},
{"country":"VC","code":"VC-06","name":"Grenadines"},
{"country":"VC","code":"VC-04","name":"Saint George"},
{"country":"VC","code":"VC-05","name":"Saint Patrick"},
{"country":"VE","code":"VE-Z","name":"Amazonas"},
{"country":"VE","code":"VE-B","name":"Anzoategui"},
{"country":"VE","code":"VE-C","name":"Apure"},
{"country":"VE","code":"VE-D","name":"Aragua"},
{"country":"VE","code":"VE-E","name":"Barinas"},
{"country":"VE","code":"VE-F","name":"Bolivar"},
{"country":"VE","code":"VE-G","name":"Carabobo"},
{"country":"VE","code":"VE-H","name":"Cojedes"},
{"country":"VE","code":"VE-Y","name":"Delta Amacuro"},
{"country":"VE","code":"VE-A","name":"Distrito Capital"},
{"country":"VE","code":"VE-I","name":"Falcon"},
{"country":"VE","code":"VE-J","name":"Guarico"},
{"country":"VE","code":"VE-X","name":"La Guaira"},
{"country":"VE","code":"VE-K","name":"Lara"},
{"country":"VE","code":"VE-L","name":"Merida"},
{"country":"VE","code":"VE-M","name":"Miranda"},
{"country":"VE","code":"VE-N","name":"Monagas"},
{"country":"VE","code":"VE-O","name":"Nueva Esparta"},
{"country":"VE","code":"VE-P","name":"Portuguesa"},
{"country":"VE","code":"VE-R","name":"Sucre"},
{"country":"VE","code":"VE-S","name":"Tachira"},
{"country":"VE","code":"VE-T","name":"Trujillo"},
{"country":"VE","code":"VE-U","name":"Yaracuy"},
{"country":"VE","code":"VE-V","name":"Zulia"},
{"country":"VG","code":"VG-XX-1","name":"Virgin Islands, British"},
{"country":"VI","code":"VI-XX-1","name":"Virgin Islands, U.S."},
{"country":"VN","code":"VN-44","name":"An Giang"},
{"country":"VN","code":"VN-43","name":"Ba Ria - Vung Tau"},
{"country":"VN","code":"VN-54","name":"Bac Giang"},
{"country":"VN","code":"VN-53","name":"Bac Kan"},
{"country":"VN","code":"VN-55","name":"Bac Lieu"},
{"country":"VN","code":"VN-56","name":"Bac Ninh"},
{"country":"VN","code":"VN-50","name":"Ben Tre"},
{"country":"VN","code":"VN-31","name":"Binh Dinh"},
{"country":"VN","code":"VN-57","name":"Binh Duong"},
{"country":"VN","code":"VN-58","name":"Binh Phuoc"},
{"country":"VN","code":"VN-40","name":"Binh Thuan"},
{"country":"VN","code":"VN-59","name":"Ca Mau"},
{"country":"VN","code":"VN-CT","name":"Can Tho"},
{"country":"VN","code":"VN-04","name":"Cao Bang"},
{"country":"VN","code":"VN-DN","name":"Da Nang"},
{"country":"VN","code":"VN-33","name":"Dak Lak"},
{"country":"VN","code":"VN-72","name":"Dak Nong"},
{"country":"VN","code":"VN-71","name":"Dien Bien"},
{"country":"VN","code":"VN-39","name":"Dong Nai"},
{"country":"VN","code":"VN-45","name":"Dong Thap"},
{"country":"VN","code":"VN-30","name":"Gia Lai"},
{"country":"VN","code":"VN-03","name":"Ha Giang"},
{"country":"VN","code":"VN-63","name":"Ha Nam"},
{"country":"VN","code":"VN-HN","name":"Ha Noi"},
{"country":"VN","code":"VN-23","name":"Ha Tinh"},
{"country":"VN","code":"VN-61","name":"Hai Duong"},
{"country":"VN","code":"VN-HP","name":"Hai Phong"},
{"country":"VN","code":"VN-73","name":"Hau Giang"},
{"country":"VN","code":"VN-SG","name":"Ho Chi Minh"},
{"country":"VN","code":"VN-14","name":"Hoa Binh"},
{"country":"VN","code":"VN-66","name":"Hung Yen"},
{"country":"VN","code":"VN-34","name":"Khanh Hoa"},
{"country":"VN","code":"VN-47","name":"Kien Giang"},
{"country":"VN","code":"VN-28","name":"Kon Tum"},
{"country":"VN","code":"VN-01","name":"Lai Chau"},
{"country":"VN","code":"VN-35","name":"Lam Dong"},
{"country":"VN","code":"VN-09","name":"Lang Son"},
{"country":"VN","code":"VN-02","name":"Lao Cai"},
{"country":"VN","code":"VN-41","name":"Long An"},
{"country":"VN","code":"VN-67","name":"Nam Dinh"},
{"country":"VN","code":"VN-22","name":"Nghe An"},
{"country":"VN","code":"VN-18","name":"Ninh Binh"},
{"country":"VN","code":"VN-36","name":"Ninh Thuan"},
{"country":"VN","code":"VN-68","name":"Phu Tho"},
{"country":"VN","code":"VN-32","name":"Phu Yen"},
{"country":"VN","code":"VN-24","name":"Quang Binh"},
{"country":"VN","code":"VN-27","name":"Quang Nam"},
{"country":"VN","code":"VN-29","name":"Quang Ngai"},
{"country":"VN","code":"VN-13","name":"Quang Ninh"},
{"country":"VN","code":"VN-25","name":"Quang Tri"},
{"country":"VN","code":"VN-52","name":"Soc Trang"},
{"country":"VN","code":"VN-05","name":"Son La"},
{"country":"VN","code":"VN-37","name":"Tay Ninh"},
{"country":"VN","code":"VN-20","name":"Thai Binh"},
{"country":"VN","code":"VN-69","name":"Thai Nguyen"},
{"country":"VN","code":"VN-21","name":"Thanh Hoa"},
{"country":"VN","code":"VN-26","name":"Thua Thien-Hue"},
{"country":"VN","code":"VN-46","name":"Tien Giang"},
{"country":"VN","code":"VN-51","name":"Tra Vinh"},
{"country":"VN","code":"VN-07","name":"Tuyen Quang"},
{"country":"VN","code":"VN-49","name":"Vinh Long"},
{"country":"VN","code":"VN-70","name":"Vinh Phuc"},
{"country":"VN","code":"VN-06","name":"Yen Bai"},
{"country":"VU","code":"VU-SEE","name":"Shefa"},
{"country":"VU","code":"VU-TAE","name":"Tafea"},
{"country":"VU","code":"VU-TOB","name":"Torba"},
{"country":"WF","code":"WF-SG","name":"Sigave"},
{"country":"WF","code":"WF-UV","name":"Uvea"},
{"country":"WS","code":"WS-AT","name":"Atua"},
{"country":"WS","code":"WS-FA","name":"Fa'asaleleaga"},
{"country":"WS","code":"WS-TU","name":"Tuamasaga"},
{"country":"YE","code":"YE-AD","name":"'Adan"},
{"country":"YE","code":"YE-AM","name":"'Amran"},
{"country":"YE","code":"YE-AB","name":"Abyan"},
{"country":"YE","code":"YE-DA","name":"Ad Dali'"},
{"country":"YE","code":"YE-BA","name":"Al Bayda'"},
{"country":"YE","code":"YE-HU","name":"Al Hudaydah"},
{"country":"YE","code":"YE-SA","name":"Amanat al 'Asimah"},
{"country":"YE","code":"YE-DH","name":"Dhamar"},
{"country":"YE","code":"YE-HD","name":"Hadramawt"},
{"country":"YE","code":"YE-HJ","name":"Hajjah"},
{"country":"YE","code":"YE-IB","name":"Ibb"},
{"country":"YE","code":"YE-LA","name":"Lahij"},
{"country":"YE","code":"YE-MA","name":"Ma'rib"},
{"country":"YE","code":"YE-SD","name":"Sa'dah"},
{"country":"YE","code":"YE-SN","name":"San'a'"},
{"country":"YE","code":"YE-SH","name":"Shabwah"},
{"country":"YE","code":"YE-TA","name":"Ta'izz"},
{"country":"YT","code":"YT-XX-1","name":"Bandraboua"},
{"country":"YT","code":"YT-XX-2","name":"Bandrele"},
{"country":"YT","code":"YT-XX-3","name":"Mamoudzou"},
{"country":"YT","code":"YT-XX-4","name":"Ouangani"},
{"country":"YT","code":"YT-XX-5","name":"Pamandzi"},
{"country":"YT","code":"YT-XX-6","name":"Sada"},
{"country":"ZA","code":"ZA-EC","name":"Eastern Cape"},
{"country":"ZA","code":"ZA-FS","name":"Free State"},
{"country":"ZA","code":"ZA-GP","name":"Gauteng"},
{"country":"ZA","code":"ZA-KZN","name":"Kwazulu-Natal"},
{"country":"ZA","code":"ZA-LP","name":"Limpopo"},
{"country":"ZA","code":"ZA-MP","name":"Mpumalanga"},
{"country":"ZA","code":"ZA-NW","name":"North-West"},
{"country":"ZA","code":"ZA-NC","name":"Northern Cape"},
{"country":"ZA","code":"ZA-WC","name":"Western Cape"},
{"country":"ZM","code":"ZM-02","name":"Central"},
{"country":"ZM","code":"ZM-08","name":"Copperbelt"},
{"country":"ZM","code":"ZM-03","name":"Eastern"},
{"country":"ZM","code":"ZM-04","name":"Luapula"},
{"country":"ZM","code":"ZM-09","name":"Lusaka"},
{"country":"ZM","code":"ZM-10","name":"Muchinga"},
{"country":"ZM","code":"ZM-06","name":"North-Western"},
{"country":"ZM","code":"ZM-05","name":"Northern"},
{"country":"ZM","code":"ZM-07","name":"Southern"},
{"country":"ZM","code":"ZM-01","name":"Western"},
{"country":"ZW","code":"ZW-BU","name":"Bulawayo"},
{"country":"ZW","code":"ZW-HA","name":"Harare"},
{"country":"ZW","code":"ZW-MA","name":"Manicaland"},
{"country":"ZW","code":"ZW-MC","name":"Mashonaland Central"},
{"country":"ZW","code":"ZW-ME","name":"Mashonaland East"},
{"country":"ZW","code":"ZW-MW","name":"Mashonaland West"},
{"country":"ZW","code":"ZW-MV","name":"Masvingo"},
{"country":"ZW","code":"ZW-MN","name":"Matabeleland North"},
{"country":"ZW","code":"ZW-MS","name":"Matabeleland South"},
{"country":"ZW","code":"ZW-MI","name":"Midlands"}]