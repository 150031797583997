import axios from 'axios';
import { GetAxiosConfig } from './AuthService';
import CONFIG from 'config';

export class UmsatzService {
	async getDashboardData(kassen) {
		try {
			return axios.post(CONFIG.ServerUrl + '/Umsatz/DashboardData', kassen, await GetAxiosConfig()).then(res => res.data);
		} catch (e) {

		}
	}
	
	async getUmsaetzeVonBis(kassen, von, bis) {
		try {
			return axios.post(CONFIG.ServerUrl + '/Umsaetze/From/' + von.toISOString() + '/To/' + bis.toISOString(), kassen, await GetAxiosConfig()).then(res => res.data).catch(e => console.log(e));
		} catch (e) {

		}
	}

	async getUmsaetzeTag(kassen, day) {
		return axios.post(CONFIG.ServerUrl + '/Umsaetze2/' + day.toISOString(), kassen, await GetAxiosConfig()).then(res => res.data);
	}

	async getJahresumsaetze(kassen) {
		return axios.post(CONFIG.ServerUrl + '/Jahresumsaetze', kassen, await GetAxiosConfig()).then(res => res.data);
	}

	async getMonatsumsaetze(kassen, monate) {
		return axios.post(CONFIG.ServerUrl + '/Monatsumsaetze/' + monate, kassen, await GetAxiosConfig()).then(res => res.data);
	}

	async getWochenumsaetze(kassen, wochen) {
		return axios.post(CONFIG.ServerUrl + '/Wochenumsaetze/' + wochen, kassen, await GetAxiosConfig()).then(res => res.data);
	}

	async getUmsaetzeTag2(day) {
		return axios.get(CONFIG.ServerUrl + '/Umsaetze3/' + day.toISOString(), await GetAxiosConfig()).then(res => res.data);
	}

	async getTagesumsatzKommentar(kasse, day) {
		return axios.get(CONFIG.ServerUrl + '/TagesumsatzKommentar/' + kasse + '/' + day.toISOString(), await GetAxiosConfig()).then(res => res.data);
	}

	async getTagesumsatzKommentare(day) {
		return axios.get(CONFIG.ServerUrl + '/TagesumsatzKommentare/' + day.toISOString(), await GetAxiosConfig()).then(res => res.data);
	}

	async setTagesumsatzKommentar(kasse, day, kommentar) {
		return axios.post(CONFIG.ServerUrl + '/TagesumsatzKommentar/' + kasse + '/' + day.toISOString(), kommentar, await GetAxiosConfig()).then(res => res.data);
	}

	async getTagesumsaetze(from, to) {
		return axios.get(CONFIG.ServerUrl + '/Tagesumsaetze/From/' + from.toISOString() + '/To/' + to.toISOString(), await GetAxiosConfig()).then(res => res.data);
	}
}