const seperator = ";";
export function createCsv(data, keys) {
	if (!Array.isArray(data)) throw new Error("CSV Error");
	if (!Array.isArray(keys)) throw new Error("CSV Error");
	for (const key of keys) if (typeof(key) !== "string") throw new Error("CSV Error");
	let output = "";
	for (const row of data) {
		const values = [];
		if (typeof(row) !== "object" || Array.isArray(row)) throw new Error("CSV Error");
		for (const key of keys) {
			const value = row[key]?.toString() ?? "";
			if (/[\r\n"]/.test(value) || value.includes(seperator)) {
				values.push("\"" + value.replace(/"/g, "\"\"") + "\"");
			} else {
				values.push(value);
			}
		}
		output += values.join(seperator) + "\r\n";
	}
	return output;
}