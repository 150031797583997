import React, { useContext, useEffect, useState, useRef } from "react";
import { GlobalState } from "./GlobalState";
import { KassenService } from "../service/KassenService";
import { ExportService } from "../service/ExportService";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";

export default function Befuellungsliste(props) {
	const kassenService = new KassenService();
	const exportService = new ExportService();
	
	const [globalState, setGlobalState] = useContext(GlobalState);
	const [kassen, setKassen] = useState([]);
	const [selectedKassen, setSelectedKassen] = useState({});
	const [loading, setLoading] = useState(false);

	const toastRef = useRef(null);

	useEffect(() => {
		setGlobalState({...globalState, page: props.type});
		kassenService.getKassen().then(data => setKassen(data));
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setGlobalState({...globalState, page: props.type});
		// eslint-disable-next-line
	}, [props.type]);

	const onErstelleBerichtClick = () => {
		setLoading(true);

		let kassenIds = Object.entries(selectedKassen).filter(kasse => kasse[1]).map(kasse => kasse[0]);

		exportService.downloadBefuellungsliste(props.type, kassenIds).then(() => {
			setLoading(false);
		}).catch(() => {
			setLoading(false);
			toastRef?.current?.show({severity:"error", summary: "Fehler", detail:"Exportieren fehlgeschlagen", life: 2000});
		});
	};

	return <React.Fragment>
		<Toast ref={toastRef}/>
		<div className="grid p-component">
			<div className="col">
				<div className="field">Standorte:</div>
				{kassen.map((kassa) => <React.Fragment>
					<div className="field">
						<Checkbox inputId={kassa.KassenID} checked={selectedKassen[kassa.KassenID]} onChange={e => setSelectedKassen({...selectedKassen, [kassa.KassenID]: e.target.checked})}/>
						<label htmlFor={kassa.KassenID} className="mb-0 ml-1">{kassa.KassenID}</label>
					</div>
				</React.Fragment>)}
				<Button label={"Erstelle " + props.type} icon="pi pi-check" onClick={onErstelleBerichtClick} disabled={loading}/>
				{loading ? <i className="pi pi-spin pi-spinner ml-2" style={{fontSize: "2rem"}}></i> : <React.Fragment></React.Fragment>}
			</div>
		</div>
	</React.Fragment>;
}