import React, {useState, useEffect} from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { KassenService } from '../service/KassenService';
import { addDE } from './LocalDE';


export function RabattAuswahlDialog(props) {
    const [kassen, setKassen] = useState([]);
    const kassenService = new KassenService();
    const minDate = new Date();

    useEffect(() => {
        kassenService.getKassen().then(data => {
            setKassen(data.map(a => a.KassenID));
            props.onKassaChange({value: data[0].KassenID});
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const renderFooter = () => {
        return (
            <div>
                <Button label="Abbrechen" icon="pi pi-times" onClick={() => {
                    props.setVisible(false);
                    props.onAbbrechen();
                }} className="p-button-text" />
                {/* <Button label="Ok" icon="pi pi-check" onClick={onDialogOk} autoFocus /> */}
            </div>
        );
    }

    addDE();

    const DropdownWidht = '20em';

    const gruende = [
        "MHD",
        "Kundenanwerbung",
        "Beschädigt",
        "Abverkauf",
        "Sonstiges",
    ]

    return (
        <React.Fragment>
            <Dialog
                visible={props.visible}
                onHide={props.onHide}
                header="Rabatt erstellen"
                footer={renderFooter}
                style={{width: '700px'}}
            >
                <div className='grid'>
                    <div className='col-12'>
                        <div className='grid'>
                            <div className='col-2'>
                                Grund:
                            </div>
                            <div className='col-10'>
                                <Dropdown
                                    inputId="lieferant"
                                    value={props.Grund}
                                    options={gruende}
                                    onChange={props.onGrundChange}
                                    style={{width: DropdownWidht}}
                                    filter={true} filterPlaceholder="Wähle Grund aus"
                                />
                            </div>
                        </div>
                    </div>
                    {props.Grund === 'MHD' &&
                    <div className='col-12'>
                        <div className='grid'>
                            <div className='col-2'>
                                Datum:
                            </div>
                            <div className='col-10'>
                                <Calendar id="datum" className="column-filter"
                                    minDate={minDate}
                                    value={props.Verderbdatum} onChange={props.onVerderbDatumChange}
                                    locale="de"
                                    showIcon={true} 
                                    style={{width: DropdownWidht}}/>
                                </div>
                        </div>
                    </div>
                    }
                    <div className='col-12'>
                        <div className='grid'>
                            <div className='col-2'>
                                Kassen:
                            </div>
                            <div className='col-10'>
                            <Dropdown inputId="kassa" value={props.Kassa} options={kassen} onChange={props.onKassaChange} style={{width: DropdownWidht}}
                                filter={true} filterPlaceholder="Wähle Kassa aus"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='col-1'>
                        Rabatt:
                        </div>
                        <div className='col-11'>
                            <Button className='mr-1' label="10%" onClick={() => props.buttonClick(10)} />
                            <Button className='mr-1' label="20%" onClick={() => props.buttonClick(20)} />
                            <Button className='mr-1' label="25%" onClick={() => props.buttonClick(25)} />
                            <Button className='mr-1' label="30%" onClick={() => props.buttonClick(30)} />
                            <Button className='mr-1' label="40%" onClick={() => props.buttonClick(40)} />
                            <Button className='mr-1' label="50%" onClick={() => props.buttonClick(50)}  />
                            <Button className='mr-1' label="60%" onClick={() => props.buttonClick(60)} />
                            <Button className='' label="70%" onClick={() => props.buttonClick(70)} />
                        </div>
                    </div>
                </div>
            </Dialog>
        </React.Fragment>
    );
}

export default RabattAuswahlDialog;