import axios from 'axios';
import { GetAxiosConfig } from './AuthService';
import CONFIG from 'config';

export class WarenbestandService {
	async getAll() {
		//return axios.get('assets/demo/data/warenbestand.json').then(res => res.data);
		return axios.get(CONFIG.ServerUrl + '/Warenbestand2', await GetAxiosConfig()).then(res => res.data);
	}

	async getDetails(id) {
		/*return axios.get('assets/demo/data/warenbestandDetails.json').then(res => {
			for (const row of res.data) {
				if(row.Id === id) {
					return row;
				}
			}
		});*/
		return axios.get(CONFIG.ServerUrl + '/Warenbestand/Details/' + id + '/10', await GetAxiosConfig()).then(res => res.data);
	}

	async getBestand(url, produkt) {
		if(!url.startsWith('http')) url = 'http://' + url;
		return axios.get(url + '/Warenbestand/' + produkt, await GetAxiosConfig()).then(res => res.data);
	}

	async getBestaende(produkt, kasse) {
		return axios.get(CONFIG.ServerUrl + '/Bestaende/' + produkt + '/' + kasse, await GetAxiosConfig()).then(res => res.data);
	}

	async getVerlauf(produkt, kasse, from, to) {
		return axios.get(CONFIG.ServerUrl + '/Warenbestand/' + produkt + '/' + kasse + '/From/' + from.toISOString() + '/To/' + to.toISOString(), await GetAxiosConfig()).then(res => res.data).catch();
	}

	async warenbestandDetailsTable(from, to) {
		return axios.get(CONFIG.ServerUrl + '/WarenbestandDetails/Table/From/' + from.toISOString() + '/To/' + to.toISOString(), await GetAxiosConfig()).then(res => res.data);
	}

	async warenbestandDetailsVerlauf(produkt, from, to) {
		return axios.get(CONFIG.ServerUrl + '/WarenbestandDetails/Verlauf/' + produkt + '/From/' + from.toISOString() + '/To/' + to.toISOString(), await GetAxiosConfig()).then(res => res.data);
	}

	async warenbestandDetailsVerbrauch(produkt, from, to) {
		return axios.get(CONFIG.ServerUrl + '/WarenbestandDetails/Verbrauch/' + produkt + '/From/' + from.toISOString() + '/To/' + to.toISOString(), await GetAxiosConfig()).then(res => res.data);
	}
}