import React, { useRef, useState, useEffect, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { KassenService } from '../service/KassenService';
import { BelegeService } from "../service/BelegeService";
import { MultiSelect } from 'primereact/multiselect';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import { Chart } from 'primereact/chart';
import { addDE } from './LocalDE';
import { GlobalState } from './GlobalState';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ExportService } from '../service/ExportService';
import { ZoomPopup } from './ZoomPopup';
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { FilterService } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode } from 'primereact/api';
import { formatCurrency } from '../helper/Util';

let kennzahlenDate = new Date();

function Belege(props) {
	const belegArten = {0: "Gutschein", 1: 'Bar', 3: 'Bankomat', 4: 'Unbekannt', 5: 'Startbeleg', 6: 'Nullbeleg'};
	const belegArten2 = [{Id: 0, Bezeichnung: 'Gutschein'}, {Id: 1, Bezeichnung: 'Bar'}, {Id: 3, Bezeichnung: 'Bankomat'}, {Id: 5, Bezeichnung: 'Startbeleg'}, {Id: 6, Bezeichnung: 'Nullbeleg'}];

	const [datumsbereich, setDatumsbereich] = useState([new Date(0), new Date(0)]);
	const [selectedKassen, setSelectedKassen] = useState(null);
	const [selectedBelegArten, setSelectedBelegArten] = useState([]);
	const [kassen, setKassen] = useState([]);
	const [belege, setBelege] = useState([]);
	const [belegeDisplay, setBelegeDisplay] = useState([]);
	const [belegeKennzahlen, setBelegeKennzahlen] = useState({});
	const [belegDetailsOffen, setBelegDetailsOffen] = useState(false);
	const [selectedBeleg, setSelectedBeleg] = useState({});
	//const [unternehmensInfo, setUnternehmensInfo] = useState({});
	const [globalState, setGlobalState] = useContext(GlobalState);
	const [showSpinner, setShowSpinner] = useState(false);
	const [showKennzahlenSpinner, setShowKennzahlenSpinner] = useState(false);
	//const [qrCode, setQrCode] = useState(null);
	const [belegHtml, setBelegHtml] = useState('');
	const [stornoBeleg, setStornoBeleg] = useState(null);
	const [stornoGrund, setStornoGrund] = useState("");
	const [doppelteBelege, setDoppelteBelege] = useState([]);
	const [zeit, setZeit] = useState(null);
	const [zeitOption, setZeitOption] = useState(null);
	const [doppelteBelegeAnzeigen, setDoppelteBelegeAnzeigen] = useState(false);
	const [selectedBelegNr, setSelectedBelegNr] = useState(null);
	const [selectedBrutto, setSelectedBrutto] = useState(null);
	const [selectedStorno, setSelectedStorno] = useState(null);
	const kassenService = new KassenService();
	const belegeService = new BelegeService();
	const exportService = new ExportService();
	const dt = useRef(null);
	const toastRef = useRef(null);
	const barBankomatBelegeRef = useRef(null);
	const lineChartRef = useRef(null);
	const herkunftRef = useRef(null);
	const [filters, setFilters] = useState({
		'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'BelegNr': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Belegart': { value: null, matchMode: FilterMatchMode.IN },
        'Datum': { value: null, matchMode: FilterMatchMode.EQUALS },
        'Zeit': { value: null, matchMode: "timeFilter" },
        'Brutto': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'KassenID': { value: null, matchMode: FilterMatchMode.IN },
		'Storno': { value: null, matchMode: FilterMatchMode.IN }
    });

	FilterService.register("timeFilter", (a, b) => {
		if (a === null || a === undefined || zeit === null || zeit === undefined || zeit.length === 0)
			return true;
		let value = a;
		let filter = zeit;
		const [valueHour, valueMinute, valueSecond] = value.split(':').map(str => {const n = Number(str); return isNaN(n) ? undefined : n});
		const valueTime = ((valueHour ?? 0) * 60 + (valueMinute ?? 0)) * 60 + (valueSecond ?? 0);
		const [filter1, filter2] = (filter ?? '').split('-').map(str => str.trim());
		const [filter1Hour, filter1Minute, filter1Second] = (filter1 ?? '').split(':').map(str => {const n = Number(str); return isNaN(n) ? undefined : n});
		const [filter2Hour, filter2Minute, filter2Second] = (filter2 ?? filter1).split(':').map(str => {const n = Number(str); return isNaN(n) ? undefined : n});
		const filter1Time = ((filter1Hour ?? 0) * 60 + (filter1Minute ?? 0)) * 60 + (filter1Second ?? 0);
		const filter2Time = ((filter2Hour ?? 23) * 60 + (filter2Minute ?? 59)) * 60 + (filter2Second ?? 59);
		return filter1Time <= valueTime && valueTime <= filter2Time;
	});

	addDE();


	const download = (url, name) => {
		const a = window.document.createElement('a');
		a.href = url;
		a.download = name;
		window.document.body.append(a);
		a.click();
		a.remove();
	};
	
	function updateKennzahlen(von, bis) {
		if (von === undefined || von === null || bis === undefined || bis === null) return;

		let kassenIds = selectedKassen;
		if (kassenIds === undefined || kassenIds === null || kassenIds.length === 0) kassenIds = kassen.map(kasse => kasse.KassenID);
		setBelegeKennzahlen({});
		setShowKennzahlenSpinner(true);
		const startDate = new Date();
		belegeService.getKennzahlen(kassenIds, von, bis).then(data => {
			setShowKennzahlenSpinner(false);
			if (startDate.getTime() > kennzahlenDate.getTime()) {
				kennzahlenDate = startDate;
				setBelegeKennzahlen(data)
			}
		}).catch(() => {
			setShowKennzahlenSpinner(false);
		});
	}

	const onDatumsbereichChange = e => {
		setDatumsbereich(e.target.value);
		if(e.target.value !== undefined && e.target.value !== null && e.target.value.length === 2 && e.target.value[0] !== undefined && e.target.value[0] !== null && e.target.value[1] !== undefined && e.target.value[1] !== null && !isNaN(e.target.value[0]) && !isNaN(e.target.value[1])) {
			belegeService.getBelegeFromTo(e.target.value[0], e.target.value[1]).then(data => setBelege(data));
		}
	}

	const onRowClicked = async e => {
		const dataPromise = belegeService.getDetails(e.data.ID);
		//const qrCodePromise = belegeService.getQrCode(e.data.ID);
		const htmlPromise = exportService.belegHtml(e.data.ID);
		const data = await dataPromise;
		//const qrCode = await qrCodePromise;
		const html = await htmlPromise;
		setSelectedBeleg(data);
		//setQrCode(URL.createObjectURL(qrCode));
		setBelegHtml(html);
		setBelegDetailsOffen(true);
	}

	const onBelegDetailsHide = () => {
		setSelectedBeleg({});
		//URL.revokeObjectURL(qrCode);
		//setQrCode(null);
		setBelegDetailsOffen(false);
	}

	const belegeExportieren = () => {
		if(datumsbereich === undefined || datumsbereich === null || datumsbereich[0] === undefined || datumsbereich[0] === null || datumsbereich[1] === undefined || datumsbereich[1] === null) return;

		let kassenIds = selectedKassen;
		if (kassenIds === undefined || kassenIds === null || kassenIds.length === 0) kassenIds = kassen.map(kasse => kasse.KassenID);
		
		setShowSpinner(true);
		exportService.downloadBericht(datumsbereich[0], datumsbereich[1], kassenIds, "Individualbericht").then(() => {
			setShowSpinner(false);
		}).catch(() => {
			setShowSpinner(false);
			toastRef?.current?.show({severity:'error', summary: 'Fehler', detail:'Exportieren fehlgeschlagen', life: 2000});
		});
	}

	function GetDatumsVerlauf(von, bis) {
		if (von === undefined || von === null || bis === undefined || bis === null) return [];

		const year = von.getFullYear();
		const month = von.getMonth();
		const day = von.getDate();
		const count = Math.round((bis - von) / (24 * 60 * 60 * 1000)) + 1;
		const output = [];
		for (let offset = 0; offset < count; offset++) {
			output.push(new Date(year, month, day + offset).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}));
		}
		return output;
	}

	const downloadBeleg = () => {
		exportService.downloadBeleg(selectedBeleg.ID);
	}

	const onStornierenClick = (rowData, e) => {
		e.stopPropagation();
		setStornoGrund("doppelte Bezahlung");
		setStornoBeleg(rowData);
	}

	const stornieren = () => {
		const url = kassen.filter(k => k.KassenID === stornoBeleg.KassenID)[0]?.url;
		if (url === undefined || url === null) return;
		belegeService.Storno(url, stornoBeleg.KassenID, stornoBeleg.BelegNr, stornoGrund).then(() => {
			onDatumsbereichChange({target: {value: datumsbereich}});
		}).catch(() => {
			toastRef?.current?.show({severity:'error', summary: 'Fehler', detail:'Der Beleg wurde bereits storniert. Synchronisieren Sie bitte um die Änderung zu sehen.', life: 5000});
		}).finally(() => {
			setStornoBeleg(null);
		});
	}

	useEffect(() => {
		setGlobalState({...globalState, page: 'Belege'});
		kassenService.getKassen().then(data => setKassen(data));
		//belegeService.getUnternehmensInfo().then(data => setUnternehmensInfo(data));
		onDatumsbereichChange({target: {value: [new Date(), new Date()]}});
		belegeService.GetDoppelte().then(setDoppelteBelege);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		changed();
		// eslint-disable-next-line
	}, [belege, doppelteBelege, doppelteBelegeAnzeigen]);

	const changed = () => {
		let belege2;
		if (doppelteBelegeAnzeigen) {
			belege2 = doppelteBelege;
		} else {
			belege2 = belege;
		}
		let _belegeDisplay = belege2.map(beleg => ({...beleg, Belegart: belegArten[beleg.Belegart], Datum: new Date(beleg.Datum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}), Zeit: new Date(beleg.Datum).toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit', second: undefined }), Brutto: beleg.Brutto.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), Retour: beleg.Retour ?? 0}));
		setBelegeDisplay(_belegeDisplay);
	}

	useEffect(() => {
		updateKennzahlen(datumsbereich?.[0], datumsbereich?.[1]);
		// eslint-disable-next-line
	}, [selectedKassen, belege]);

	const renderBelegartFilter = () => {
		return (
			<MultiSelect className="column-filter" value={selectedBelegArten} options={belegArten2}
				onChange={e => { setSelectedBelegArten(e.target.value); dt.current.filter(e.target.value, 'Belegart', 'in'); }}
				placeholder="Alle" optionLabel="Bezeichnung" optionValue="Bezeichnung" maxSelectedLabels={1}/>
		);
	};

	const renderDatumFilter = () => {
		return (
			<Calendar
				id="datumsbereich"
				className="column-filter"
				disabled={doppelteBelegeAnzeigen}
				value={datumsbereich}
				onChange={onDatumsbereichChange}
				locale="de"
				showIcon={true}
				selectionMode="range"/>
		);
	};

	const zeitFilter = (option) => {
		return <InputText value={zeit}
		onChange={(e) => {
			let _filters = {...filters};
			_filters['Zeit'].value = e.target.value;
			setFilters(_filters);
			setZeit(e.target.value);
			setZeitOption(option);
		}}
		/>
	}

	useEffect(() => {
		zeitOption?.filterCallback(zeit);
		zeitOption?.filterApplyCallback();
		// eslint-disable-next-line
	}, [zeit])

	const renderKassenFilter = () => {
		return (
			<MultiSelect className="column-filter" value={selectedKassen} options={kassen} onChange={e => { setSelectedKassen(e.target.value); dt.current.filter(e.target.value, 'KassenID', 'in'); }} placeholder="Alle" optionLabel="KassenID" optionValue="KassenID" maxSelectedLabels={3}/>
		);
	};

	const renderBelegNrFilter = () => {
		return (
			<InputText value={selectedBelegNr} onChange={(e) => {
				dt.current.filter(e.target.value, 'BelegNr', 'contains');
				setSelectedBelegNr(e.target.value);
			}}>
			</InputText>
		);
	}

	const renderBruttoFilter = () => {
		return (
			<InputText value={selectedBrutto} onChange={(e) => {
				dt.current.filter(e.target.value, 'Brutto', 'contains');
				setSelectedBrutto(e.target.value);
			}}>
			</InputText>
		);
	}

	const stornoFilter = () => {
		return (
			<Dropdown value={selectedStorno}
				onChange={(e) => {
					console.log(e.target.value);
					dt.current.filter(e.target.value, 'Storno', 'equals');
					setSelectedStorno(e.target.value);
				}}
				options={[{label: "storniert", value: true}, {label: "nicht sto.", value: false}]}
				optionLabel="label"
				optionValue='value'
			/>
		);
	}

	const belegartFilter = renderBelegartFilter();
	const datumFilter = renderDatumFilter();
	const kassenFilter = renderKassenFilter();
	
	useEffect(() => {
		console.log(belegeDisplay);
	}, [belegeDisplay]);

	return(
		<React.Fragment>
			<Toast ref={toastRef}/>
			<div className="grid p-component">
				<div className="col-6">
					<Checkbox inputId="doppelteBelegeAnzeigen" checked={doppelteBelegeAnzeigen} onChange={e => setDoppelteBelegeAnzeigen(e.checked)}/>
					<label htmlFor="doppelteBelegeAnzeigen" className="ml-1">Doppelte Belege Anzeigen</label>
				</div>
				<div className="col-6" style={{textAlign: 'right'}}>
					{showSpinner ? <i className="pi pi-spin pi-spinner mr-2" style={{fontSize: '2rem'}}></i> : <React.Fragment></React.Fragment>}
					<Button label="Belege exportieren" icon="pi pi-download" onClick={belegeExportieren}/>
				</div>
				<div className="col-12">
					<DataTable
						value={belegeDisplay}
						filterDisplay="row"
						filters={filters}
						ref={dt} className="p-datatable-belege p-datatable-striped tableCursorPointer"
						dataKey="ID" rowHover paginator rows={20}
						emptyMessage="keine Belege gefunden"
						currentPageReportTemplate='({first} von {totalRecords})'
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
						rowsPerPageOptions={[20,30,40]}
						onRowClick={onRowClicked} scrollable scrollHeight="60vh"
						onFilter={(e) => setFilters(e.filters)}
						>
						<Column
							field="BelegNr" header="BelegNr" filter sortable
							style={{maxWidth: "9em", width: "9em"}} showFilterMenu={false}
							filterElement={renderBelegNrFilter}
							onFilterClear={() => setSelectedBelegNr(null)}
							/>
						<Column field="Belegart" header="Belegart" filter sortable style={{maxWidth: "18em", width: "18em"}} filterElement={belegartFilter} showFilterMenu={false}
							onFilterClear={() => setSelectedBelegArten(null)}
						/>
						<Column field="Datum" header="Datum"
							style={{maxWidth: "22em", width: "22em"}}
							filter sortable filterElement={datumFilter} showFilterMenu={false}
							/>
						<Column field="Zeit" header="Zeit" filter sortable style={{maxWidth: "10em", width: "10em"}}
							filterElement={zeitFilter} showFilterMenu={false}
							onFilterClear={() => setZeit(null)}
							/>
						<Column
							field="Brutto" header="Brutto" sortable
							style={{maxWidth: "12em", width: "12em"}}
							body={row => {
								if (row.Brutto === undefined || row.Brutto === null)
									return;
								return formatCurrency(row.Brutto);
							}}
							filter
							filterElement={renderBruttoFilter}
							showFilterMenu={false}
							onFilterClear={() => setSelectedBrutto(null)}
							/>
						<Column field="KassenID" header="Standort" filter
							sortable filterElement={kassenFilter}
							showFilterMenu={false}
							onFilterClear={() => setSelectedKassen(null)}
							/>
						<Column field="Storno" header="Storno" filter sortable
							filterElement={stornoFilter}
							style={{textAlign: "center", width: "12em", maxWidth:"12em"}}
							body={rowData => rowData.Storno ? <React.Fragment>Storniert</React.Fragment> : <Button icon="pi pi-times" label="Stornieren"
							onClick={e => onStornierenClick(rowData, e)}/>}
							showFilterMenu={false}
							onFilterClear={() => {setSelectedStorno(null);}}
							/>
					</DataTable>
				</div>
			</div>
			<Divider />
			<div className="grid p-component" style={{position: 'relative'}}>
				{
					showKennzahlenSpinner ? <div style={{position: 'absolute', width: '5em', height: '5em', left: 'calc(50% - 2.5em)', top: 'calc(50% - 2.5em)'}}><i className="pi pi-spin pi-spinner mr-2" style={{fontSize: '2rem'}}></i></div> : <React.Fragment></React.Fragment>
				}
				<div className="col-12 md:col-6 lg:col-3">
					<table>
						<tr>
							<th style={{textAlign: "left"}}>Anzahl Belege:</th>
							<td style={{textAlign: "right"}}>{isNaN(belegeKennzahlen.AnzahlBelegeGutschein + belegeKennzahlen.AnzahlBelegeBar + belegeKennzahlen.AnzahlBelegeBankomat) ? '' : belegeKennzahlen.AnzahlBelegeGutschein + belegeKennzahlen.AnzahlBelegeBar + belegeKennzahlen.AnzahlBelegeBankomat}</td>
						</tr>
						<tr>
							<th style={{textAlign: "left"}}>Anzahl Belege Gutschein:</th>
							<td style={{textAlign: "right"}}>{belegeKennzahlen.AnzahlBelegeGutschein}</td>
						</tr>
						<tr>
							<th style={{textAlign: "left"}}>Anzahl Belege Bar:</th>
							<td style={{textAlign: "right"}}>{belegeKennzahlen.AnzahlBelegeBar}</td>
						</tr>
						<tr>
							<th style={{textAlign: "left"}}>Anzahl Belege Bankomat:</th>
							<td style={{textAlign: "right"}}>{belegeKennzahlen.AnzahlBelegeBankomat}</td>
						</tr>
					</table>
					<ZoomPopup header="Bar/Bankomatbelege" footer={<Button label="Download" icon="pi pi-image" onClick={() => download(barBankomatBelegeRef.current.getBase64Image(), 'BarBankomatbelege.png')}/>}>
						<Chart ref={barBankomatBelegeRef} type="pie" className="mt-4" data={{labels: ["Bankomat Belege", "Bar Belege"], datasets: [{data: [belegeKennzahlen.AnzahlBelegeBankomat, belegeKennzahlen.AnzahlBelegeBar], backgroundColor: ["#0e5ea2", "#498d37"]}]}} options={{animation: {duration: 0}, plugins: {legend: {position: 'bottom', reverse: true}}}}/>
					</ZoomPopup>
				</div>
				<div className="col-12 md:col-6 lg:col-6">
					<table>
						<tr>
							<th style={{textAlign: "left"}}>Brutto Umsatz:</th>
							<td>&euro;<span style={{float: 'right', marginLeft: '0.5em'}}>{belegeKennzahlen.BruttoUmsatz?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></td>
						</tr>
						<tr>
							<th style={{textAlign: "left"}}>&Oslash; Umsatz:</th>
							<td>&euro;<span style={{float: 'right', marginLeft: '0.5em'}}>{isNaN(belegeKennzahlen.BruttoUmsatz / (belegeKennzahlen.AnzahlBelegeGutschein + belegeKennzahlen.AnzahlBelegeBar + belegeKennzahlen.AnzahlBelegeBankomat)) ? '' : (belegeKennzahlen.BruttoUmsatz / (belegeKennzahlen.AnzahlBelegeGutschein + belegeKennzahlen.AnzahlBelegeBar + belegeKennzahlen.AnzahlBelegeBankomat)).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></td>
						</tr>
						<tr>
							<th style={{textAlign: "left"}}>Netto Umsatz:</th>
							<td>&euro;<span style={{float: 'right', marginLeft: '0.5em'}}>{belegeKennzahlen.NettoUmsatz?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></td>
						</tr>
						<tr>
							<th style={{textAlign: "left"}}>EK:</th>
							<td>&euro;<span style={{float: 'right', marginLeft: '0.5em'}}>{belegeKennzahlen.EK?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></td>
						</tr>
						<tr>
							<th style={{textAlign: "left"}}>DB:</th>
							<td>&euro;<span style={{float: 'right', marginLeft: '0.5em'}}>{belegeKennzahlen.DB?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></td>
						</tr>
					</table>
					<ZoomPopup header="Netto Umsatz/EK/DB" footer={<Button label="Download" icon="pi pi-image" onClick={() => download(lineChartRef.current.getBase64Image(), 'NettoUmsatzEkDb.png')}/>}>
						<Chart ref={lineChartRef} type="line" className="mt-4" data={{labels: GetDatumsVerlauf(datumsbereich?.[0], datumsbereich?.[1]), datasets: [{label: "Netto Umsatz", data: belegeKennzahlen.NettoUmsatzVerlauf, fill: false, borderColor: "#229954", tension: 0.3}, {label: "EK", data: belegeKennzahlen.EKVerlauf, fill: false, borderColor: "#E67E22", tension: 0.3}, {label: "DB", data: belegeKennzahlen.DBVerlauf, fill: false, borderColor: "#21618C", tension: 0.3}]}} options={{animation: {duration: 0}, plugins: {legend: {position: 'bottom'}}}}/>
					</ZoomPopup>
				</div>
				<div className="col-12 md:col-6 lg:col-3">
					<table>
						<tr>
							<th style={{textAlign: "left"}}>Bestes Produkt:</th>
							<td>{belegeKennzahlen.BestesProdukt}</td>
						</tr>
						<tr>
							<th style={{textAlign: "left"}}>Anteil Regional:</th>
							<td>{belegeKennzahlen.AnteilRegional?.toLocaleString('de', { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
						</tr>
						<tr>
							<th style={{textAlign: "left"}}>Anteil nicht Regional:</th>
							<td>{belegeKennzahlen.AnteilRegional === undefined || belegeKennzahlen.AnteilRegional === null ? '' : (1 - belegeKennzahlen.AnteilRegional).toLocaleString('de', { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
						</tr>
					</table>
					<ZoomPopup header="Herkunft" footer={<Button label="Download" icon="pi pi-image" onClick={() => download(herkunftRef.current.getBase64Image(), 'Herkunft.png')}/>}>
						<Chart ref={herkunftRef} type="pie" className="mt-4" data={{labels: ["Regional", "nicht Regional"], datasets: [{data: [belegeKennzahlen.AnteilRegional, belegeKennzahlen.AnteilRegional === undefined || belegeKennzahlen.AnteilRegional === null ? null : (1 - belegeKennzahlen.AnteilRegional)], backgroundColor: ["#F1C40F", "#E53935"]}]}} options={{animation: {duration: 0}, plugins: {legend: {position: 'bottom', reverse: true}}}}/>
					</ZoomPopup>
				</div>
			</div>
			<Dialog header={<React.Fragment><span style={{fontSize: '1.25em'}}>Beleg Details</span>
				<Button style={{marginLeft: '1em', marginRight: '1em', float: 'right'}} icon="pi pi-file-pdf" label="Download" onClick={downloadBeleg}/>
				{selectedBeleg?.Storno === true && <React.Fragment><br/>Stornierungs-Grund:<br/>{selectedBeleg?.StornoGrund}</React.Fragment>}</React.Fragment>}
				visible={belegDetailsOffen} onHide={onBelegDetailsHide}>
				<div style={{fontFamily: 'monospace'}} dangerouslySetInnerHTML={{__html: belegHtml}}></div>
				{/*<img alt="Logo" src={CONFIG.LogoBeleg} style={{width: '10em', marginLeft: 'calc(50% - 5em)'}}/>
				<div style={{textAlign: "center"}} className="mb-4">
					{unternehmensInfo.Firmenname}<br/>
					In {unternehmensInfo.Firmenadresse}<br/>
					Tel.: {unternehmensInfo.Telefonnummer}<br/>
					Homepage: {unternehmensInfo.Homepage}<br/>
					E-Mail: {unternehmensInfo.Email}<br/>
					{unternehmensInfo.UIDLabelText}{unternehmensInfo.UID}<br/>
					Standort: {selectedBeleg.KassenID}
				</div>
				<table style={{width: "100%", borderCollapse: "collapse"}} className="mb-4 tablePadding">
					<tr>
						<td style={{borderBottomStyle: "dashed", borderBottomWidth: "thin", borderTopStyle: "dashed", borderTopWidth: "thin", fontWeight: "500"}}>Stk</td>
						<td style={{borderBottomStyle: "dashed", borderBottomWidth: "thin", borderTopStyle: "dashed", borderTopWidth: "thin", fontWeight: "500"}}>Artikel</td>
						<td style={{textAlign: "right", borderBottomStyle: "dashed", borderBottomWidth: "thin", borderTopStyle: "dashed", borderTopWidth: "thin", fontWeight: "500"}}>Preis</td>
						<td style={{textAlign: "right", borderBottomStyle: "dashed", borderBottomWidth: "thin", borderTopStyle: "dashed", borderTopWidth: "thin", fontWeight: "500"}}>Total</td>
						<td style={{textAlign: "right", borderBottomStyle: "dashed", borderBottomWidth: "thin", borderTopStyle: "dashed", borderTopWidth: "thin", fontWeight: "500"}}></td>
					</tr>
					{
						selectedBeleg.Waren?.map(ware => <tr>
							<td>{ware.Menge}</td>
							<td>{ware.Produkttext === undefined || ware.Produkttext === null || ware.Produkttext === '' ? ware.Bezeichnung : ware.Produkttext}</td>
							<td style={{textAlign: "right"}}>{ware.BruttoStueckPreis?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
							<td style={{textAlign: "right"}}>{(ware.Menge * ware.BruttoStueckPreis).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
							<td>{ware.SteuersatzChar}</td>
						</tr>)
					}
				</table>
				<table style={{width: "100%", borderCollapse: "collapse"}} className="mb-4 tablePadding">
					<tr>
						<td style={{borderBottomStyle: "dashed", borderBottomWidth: "thin", borderTopStyle: "dashed", borderTopWidth: "thin", fontWeight: "500"}}>Total</td>
						<td style={{textAlign: "right", borderBottomStyle: "dashed", borderBottomWidth: "thin", borderTopStyle: "dashed", borderTopWidth: "thin", fontWeight: "500"}}>EUR</td>
						<td style={{textAlign: "right", borderBottomStyle: "dashed", borderBottomWidth: "thin", borderTopStyle: "dashed", borderTopWidth: "thin", fontWeight: "500"}}>{selectedBeleg.Waren?.reduce((sum, ware) => sum + ware.BruttoStueckPreis * ware.Menge, 0).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
					</tr>
					<tr>
						<td>Netto</td>
						<td style={{textAlign: "right"}}>EUR</td>
						<td style={{textAlign: "right"}}>{selectedBeleg.Waren?.reduce((sum, ware) => sum + (ware.BruttoStueckPreis * ware.Menge) / (1 + ware.Steuersatz), 0).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
					</tr>
					{
						Object.entries(selectedBeleg.Waren?.reduce((steuersaetze, ware) => {
							(steuersaetze[ware.SteuersatzChar] = steuersaetze[ware.SteuersatzChar] ?? []).push(ware);
							return steuersaetze;
						}, {}) ?? {}).map(([char, waren]) => [char, waren]).sort((a, b) => a[0].localeCompare(b[0])).map(([char, waren]) => <tr>
							<td>{char}: Steuer {(waren[0].Steuersatz * 100).toLocaleString('de', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}%</td>
							<td style={{textAlign: "right"}}>EUR</td>
							<td style={{textAlign: "right"}}>{(waren.reduce((sum, ware) => sum + ((ware.BruttoStueckPreis * ware.Menge) / (1 + waren[0].Steuersatz)) * waren[0].Steuersatz, 0)).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
						</tr>)
					}
				</table>
				<table style={{width: "100%", borderCollapse: "collapse"}} className="tablePadding">
					<tr>
						<td style={{borderTopStyle: "dashed", borderTopWidth: "thin"}}>
							{new Date(selectedBeleg.Datum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}) + " "}
							{new Date(selectedBeleg.Datum).toLocaleTimeString('de', {hour: "2-digit", minute: "2-digit", second: undefined}) + " "}
							Beleg-Nr: {selectedBeleg.BelegNr}
						</td>
					</tr>
				</table>
				{selectedBeleg.BezahlMethode === undefined || selectedBeleg.BezahlMethode === null || selectedBeleg.BezahlMethode === '' ? <React.Fragment></React.Fragment> : <div style={{whiteSpace: "pre-wrap"}} className="mt-4 mb-4">
					Bezahlung<br/>
					{selectedBeleg.BezahlMethode}<br/>
					<br/>
					<table>
						<tr>
							<td>Genehmigungs-Nr.</td>
							<td style={{textAlign: 'right'}}>{selectedBeleg.GenehmigungsNr}</td>
						</tr>
						<tr>
							<td>Terminal-ID</td>
							<td style={{textAlign: 'right'}}>{selectedBeleg.TerminalID}</td>
						</tr>
						<tr>
							<td>Pos-Info</td>
							<td style={{textAlign: 'right'}}>{selectedBeleg.PosInfo}</td>
						</tr>
					</table>
					<br/>
					EMV-Daten {selectedBeleg.EmvDaten}
				</div>}
				<div style={{textAlign: "center", borderTopStyle: "dashed", borderTopWidth: "thin"}}>
					Herzlichen Dank für Ihren Einkauf!
					{unternehmensInfo.Facebook === undefined || unternehmensInfo.Facebook === null || unternehmensInfo.Facebook === '' ? <React.Fragment></React.Fragment> : <React.Fragment><br/><br/>Hat dir dein Besuch gefallen?<br/>Dann freuen wir uns über ein<br/>Gefällt mir auf<br/><span style={{fontWeight: '500'}}>{unternehmensInfo.Facebook}</span></React.Fragment>}
				</div>
				<img alt="QrCode" src={qrCode} style={{width: '10em', marginLeft: 'calc(50% - 5em)'}}/>*/}
			</Dialog>
			<Dialog visible={stornoBeleg !== undefined && stornoBeleg !== null} onHide={() => setStornoBeleg(null)} header="Bitte geben Sie den Grund an." footer={<Button icon="pi pi-undo" label="Stornieren" onClick={stornieren}/>}>
				{ stornoBeleg?.Belegart === "Bankomat" &&
					<>
						<span style={{color: "#f00"}}>Sind Sie sich sicher, dass Sie einen Bankomat-Beleg stornieren wollen?<br/>Sie müssen den Betrag manuell rückerstatten.</span>
						<br/>
					</>
				}
				<InputText value={stornoGrund} onChange={e => setStornoGrund(e.target.value)}/>
			</Dialog>
		</React.Fragment>
	);
}

export default Belege;