import React, { useState, useEffect, useRef, useContext } from 'react';
import { KassenService } from '../service/KassenService';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { addDE } from './LocalDE';
import { GlobalState } from './GlobalState';
import { ExportService } from '../service/ExportService';
import { Toast } from 'primereact/toast';

function Tagesberichte(props) {
	const [kassen, setKassen] = useState([]);
	const [selectedKassen, setSelectedKassen] = useState([]);
	const [berichtDatum, setBerichtDatum] = useState(null);
	const [globalState, setGlobalState] = useContext(GlobalState);
	const [showSpinner, setShowSpinner] = useState(false);
	const kassenService = new KassenService();
	const exportService = new ExportService();

	const toastRef = useRef(null);

	addDE();

	const onErstelleBerichtClick = () => {
		if(berichtDatum === undefined || berichtDatum === null) return;

		setShowSpinner(true);
		exportService.downloadBericht(berichtDatum, berichtDatum, Object.entries(selectedKassen).filter(kasse => kasse[1]).map(kasse => kasse[0]),
			berichtDatum.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }).split('.').join('') + "_Tagesbericht").then(() => {
			setShowSpinner(false);
		}).catch((e) => {
			console.log(e);
			setShowSpinner(false);
			toastRef?.current?.show({severity:'error', summary: 'Fehler', detail:'Exportieren fehlgeschlagen', life: 2000});
		});
	};

	useEffect(() => {
		setGlobalState({...globalState, page: 'Tagesberichte'});
		kassenService.getKassen().then(data => setKassen(data));
	}, []);

	return (
		<React.Fragment>
			<Toast ref={toastRef}/>
			<div className="grid p-component">
				<div className="col">
					<div className="field">Standorte:</div>
					{kassen.map((kassa) => <React.Fragment>
						<div className="field">
							<Checkbox inputId={kassa.KassenID} checked={selectedKassen[kassa.KassenID]} onChange={e => setSelectedKassen({...selectedKassen, [kassa.KassenID]: e.target.checked})}/>
							<label htmlFor={kassa.KassenID} className="mb-0 ml-1">{kassa.KassenID}</label>
						</div>
					</React.Fragment>)}
					<Calendar value={berichtDatum} onChange={(e) => setBerichtDatum(e.value)} showWeek={true} showIcon={true} locale="de"/><br/><br/>
					<Button label="Erstelle Bericht" icon="pi pi-check" onClick={onErstelleBerichtClick}/>
					{showSpinner ? <i className="pi pi-spin pi-spinner ml-2" style={{fontSize: '2rem'}}></i> : <React.Fragment></React.Fragment>}
				</div>
			</div>
		</React.Fragment>
	);
}
export default Tagesberichte;