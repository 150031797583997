import { AutoComplete } from "primereact/autocomplete";
import { useState } from "react";

export function CustomAutoComplete(props) {
	const [suggestions, setSuggestions] = useState();

	const completeMethod = e => {
		setSuggestions(props.options?.filter(option => props.filter(e.query, option)));
	};

	return <AutoComplete dropdown forceSelection field={props.field} placeholder={props.placeholder} value={props.value} onChange={props.onChange} suggestions={suggestions} completeMethod={completeMethod}/>
}