import axios from 'axios';
import { GetAxiosConfig } from './AuthService';
import CONFIG from 'config';

export class LogService {
    async getLastLogs() {
        //return axios.get('assets/demo/data/belege.json').then(res => res.data);
        return axios.get(CONFIG.ServerUrl + '/Logs/Last/Size/20/Page/0', await GetAxiosConfig()).then(res => res.data);
    }

    async getLogVonBis(kassen, von, bis) {
        if (bis === null)
            bis = von;
		//return axios.get('assets/demo/data/umsatz.json').then(res => res.data);
		return axios.post(CONFIG.ServerUrl + '/Logs/Auswertung/From/' + von.toISOString() + '/To/' + bis.toISOString(), kassen, await GetAxiosConfig()).then(res => res.data);
	}

    async getLogMonthVonBis(kassen, von, bis) {
		return axios.post(CONFIG.ServerUrl + '/Logs/Monatsauswertung/From/' + von.toISOString() + '/To/' + bis.toISOString(), kassen, await GetAxiosConfig()).then(res => res.data);
	}

    async getLogsVonBis(von, bis) {
        try {
            return axios.get(CONFIG.ServerUrl + '/Logs/From/' + von.toISOString() + '/To/' + bis.toISOString(), await GetAxiosConfig()).then(res => res.data).catch(e => console.log(e));
        } catch (e) {
            console.log(e);
        }
    }
}