import { addLocale } from 'primereact/api';

export function addDE() {
	addLocale("de", {
		firstDayOfWeek: 1,
		dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
		dayNamesShort: ["So", "Mon", "Di", "Mi", "Don", "Fr", "Sa"],
		dayNamesMin: ["So", "Mon", "Di", "Mi", "Don", "Fr", "Sa"],
		monthNames: ["Jänner", "Februar", "März", "April", "May", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
		monthNamesShort: ["Jan", "Feb", "Mär", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
		today: 'Heute',
		clear: '',
		dateFormat: 'dd.mm.yy',
		weekHeader: 'Woche'
	});
}