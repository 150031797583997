import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalState } from "./GlobalState";
import { KategorienService } from "../service/KategorienService";
import { ImportService } from "../service/ImportService";
import { ImageService } from "../service/ImageService";
import { SettingsService } from "../service/SettingsService";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { PopupMenu } from "./PopupMenu";
import { ImageSelector } from "./ImageSelector.tsx";
import {Checkbox} from 'primereact/checkbox';

import CONFIG from 'config';

export default function Kategorien() {
	const inputWidth = "15rem";

	const kategorienService = new KategorienService();
	const importService = new ImportService();
	const imageService = new ImageService();
	const settingsService = new SettingsService();

	const [globalState, setGlobalState] = useContext(GlobalState);
	const [kategorienTree, setKategorienTree] = useState();
	const [kategorien, setKategorien] = useState([]);
	const [kategorieBearbeitenDialogOpen, setKategorieBearbeitenDialogOpen] = useState(false);
	const [kategorie, setKategorie] = useState();
	const [bildData, setBildData] = useState("");
	const [settings, setSettings] = useState(null);

	const imageSelectorRef = useRef();

	useEffect(() => {
		setGlobalState({...globalState, page: "Kategorien"});
		loadKategorien();
		settingsService.getSettings().then(data => {console.log(data);setSettings(data)});
	}, []);

	function convertToTree(kategorien) {
		return kategorien.map(k => ({
			key: k.ID,
			data: {
				ID: k.ID,
				ParentKategorieID: k.ParentKategorieID,
				Bezeichnung: k.Bezeichnung,
				BildID: k.BildID
			},
			children: convertToTree(k.Children)
		}));
	}

	function loadKategorien() {
		kategorienService.GetKategorien().then(kategorien => setKategorienTree(convertToTree(kategorien)));
		kategorienService.GetKategorienFlat().then(setKategorien);
	}

	function getPossibleParentKategorien(id) {
		if (id === undefined || id === null) return kategorien;

		let notAllowedKategorien = [id];
		let kategorienToTest = [id];
		while (kategorienToTest.length > 0) {
			const kategorieId = kategorienToTest.pop();
			const children = kategorien.filter(k => k.ParentKategorieID === kategorieId).map(k => k.ID);
			notAllowedKategorien = notAllowedKategorien.concat(children);
			kategorienToTest = kategorienToTest.concat(children);
		}
		return kategorien.filter(k => !notAllowedKategorien.includes(k.ID));
	}

	const onKategorieErstellenClick = () => {
		setKategorie({});
		setBildData(null);
		setKategorieBearbeitenDialogOpen(true);
	};

	const onKategorieBearbeitenClick = rowData => {
		kategorienService.GetKategorie(rowData.ID).then(kategorie => {
			setKategorie(kategorie);
			if (kategorie.BildID !== undefined && kategorie.BildID !== null) {
				imageService.getImage(kategorie.BildID).then(image => {
					setBildData("data:image;base64," + image?.Data);
				}).catch(() => {
					setBildData(null);
				}).finally(() => {
					setKategorieBearbeitenDialogOpen(true);
				});
			} else {
				setBildData(null);
				setKategorieBearbeitenDialogOpen(true);
			}
		});
	}

	const onSpeichernClick = () => {
		kategorienService.SetKategorie(kategorie).then(async id => {
			console.log(imageSelectorRef);
			importService.upload(await imageSelectorRef.current.getBlob(), "bild", CONFIG.ServerUrl + '/AnzeigeKategorie/SetBild/' + id);
			setKategorieBearbeitenDialogOpen(false);
			loadKategorien();
		});
	};

	const onLoeschenClick = rowData => {
		kategorienService.DeleteKategorie(rowData.ID).then(() => loadKategorien());
	};

	return (
		<React.Fragment>
			<div className="p-component grid">
				<div className="col-12" style={{textAlign: "right"}}>
					<Button label="Kategorie erstellen" icon="pi pi-plus-circle" onClick={onKategorieErstellenClick}/>
				</div>
				<div className="col-12">
					<TreeTable value={kategorienTree}>
						<Column field="Bezeichnung" header="Bezeichnung" expander body={row => <React.Fragment>{row.data.Bezeichnung}<PopupMenu label="Aktion auswählen" icon="pi pi-pencil" style={{float: "right"}} model={[
							{ label: "Bearbeiten", icon: "pi pi-pencil", command: () => onKategorieBearbeitenClick(row.data) },
							{ label: "Unterkategorie erstellen", icon: "pi pi-plus-circle", command: () => { onKategorieErstellenClick(); setKategorie(kategorie => ({...kategorie, ParentKategorieID: row.data.ID})); } },
							{ label: "Löschen", icon: "pi pi-trash", command: () => onLoeschenClick(row.data) }
						]}/></React.Fragment>}/>
					</TreeTable>
				</div>
			</div>
			<Dialog visible={kategorieBearbeitenDialogOpen} onHide={() => setKategorieBearbeitenDialogOpen(false)} header={kategorie?.ID === undefined || kategorie?.ID === null ? "Kategorie erstellen" : "Kategorie bearbeiten"} footer={<Button label="Speichern"icon="pi pi-save" onClick={onSpeichernClick} disabled={kategorie?.Bezeichnung === undefined || kategorie?.Bezeichnung === null || kategorie?.Bezeichnung === ""}/>}>
				<div className="grid">
					<div className="col-8">
						<div className="field grid">
							<label htmlFor="bezeichnung" className="col-12"><strong>Bezeichnung</strong></label>
							<div className="col-12">
								<InputText value={kategorie?.Bezeichnung} id="bezeichnung" onChange={e => setKategorie({...kategorie, Bezeichnung: e.target.value})} className={kategorie?.Bezeichnung === undefined || kategorie?.Bezeichnung === null || kategorie?.Bezeichnung === "" ? "p-invalid" : ""} style={{width: inputWidth}}/>
							</div>
						</div>
						<div className="field grid">
							<div className="col-12">
							<label htmlFor="bezeichnunganzeigen" >Bezeichnung Anzeigen&nbsp;&nbsp;</label>
								<Checkbox id="bezeichnunganzeigen" checked={kategorie?.BezeichnungAnzeigen} onChange={e => setKategorie({...kategorie, BezeichnungAnzeigen: e.checked})} />
							</div>
						</div>
						{settings?.BedienmodusAktiviert &&
						<div className="field grid">
							<div className="col-12">
								<label htmlFor="bedienmodus">nur im Bedienmodus anzeigen&nbsp;&nbsp;</label>
								<Checkbox id="bedienmodus" checked={kategorie?.Bedienmodus} onChange={e => setKategorie({...kategorie, Bedienmodus: e.checked})}  />
							</div>
						</div>
						}
						<div className="field grid">
							<label htmlFor="bild" className="col-12"><strong>vordefinierte Bilder</strong></label>
							<div className="col-12">
								<ImageSelector ref={imageSelectorRef} chooseOptions={{label: "Wähle", icon: "pi pi-fw pi-upload"}} options={["/assets/kategorien/Brot_und_Gebäck.jpg", "/assets/kategorien/Fleisch.jpg", "/assets/kategorien/Gemüse.jpg", "/assets/kategorien/Obst.jpg"]} allowOther/>
							</div>
						</div>
						<div className="field grid">
							<label htmlFor="parent" className="col-12"><strong>Übergeordnete Kategorie</strong></label>
							<div className="col-12">
								<Dropdown inputId="parent" value={kategorie?.ParentKategorieID} options={getPossibleParentKategorien(kategorie?.ID)} optionLabel="Bezeichnung" optionValue="ID" onChange={e => setKategorie({...kategorie, ParentKategorieID: e.value})} showClear style={{width: inputWidth}}/>
							</div>
						</div>
					</div>
					<div className="col-4">
						{bildData !== undefined && bildData !== null && <img src={bildData} alt="bild" style={{height: "15rem"}}/>}
					</div>
				</div>
			</Dialog>
		</React.Fragment>
	);
}