import axios from 'axios';
import { GetAxiosConfig } from './AuthService';
import CONFIG from 'config';

export class KassenService {
	async getKassen() {
		return axios.get(CONFIG.ServerUrl + '/Kassen', await GetAxiosConfig()).then(res => res.data);
	}

	async getKassenBankomatwerteEinstellungen() {
		return axios.get(CONFIG.ServerUrl + '/Kassen/Bankomatwerte', await GetAxiosConfig()).then(res => res.data);
	}

	async updateKassenBankomatwerteEinstellungen(kassen)
	{
		return axios.post(CONFIG.ServerUrl + '/Kassen/Bankomatwerte/Update', kassen, await GetAxiosConfig()).then(res => res.data);
	}

	async getDetails(url, id) {
		if(!url.startsWith('http')) url = 'http://' + url;
		return axios.get(url + '/Kassen/Details/' + id, await GetAxiosConfig()).then(res => res.data);
	}

	async addKasse(id, artikelKasse, bestaendeKasse) {
		return axios.get(CONFIG.ServerUrl + '/Kasse/Add/' + id + '/' + artikelKasse + '/' + bestaendeKasse, await GetAxiosConfig()).then(res => res.data);
	}

	async addBerichteEmails(url, emails) {
		if(!url.startsWith('http')) url = 'http://' + url;
		return axios.post(url + '/Kasse/AddBerichteEmails', emails, await GetAxiosConfig()).then(res => res.data);
	}
	
	async addWochenberichtEmails(url, emails) {
		if(!url.startsWith('http')) url = 'http://' + url;
		return axios.post(url + '/Kasse/AddWochenberichtEmails', emails, await GetAxiosConfig()).then(res => res.data);
	}

	async removeBerichteEmails(url, emails) {
		if(!url.startsWith('http')) url = 'http://' + url;
		return axios.post(url + '/Kasse/RemoveBerichteEmails', emails, await GetAxiosConfig()).then(res => res.data);
	}

	async removeWochenberichtEmails(url, emails) {
		if(!url.startsWith('http')) url = 'http://' + url;
		return axios.post(url + '/Kasse/RemoveWochenberichtEmails', emails, await GetAxiosConfig()).then(res => res.data);
	}
}