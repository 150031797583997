import React, { useState, useEffect, useRef, useContext } from 'react';
import { GutscheinService } from '../service/GutscheinServiceAlt';
import { ExportService } from '../service/ExportService';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';
import { TreeTable } from 'primereact/treetable';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { GlobalState } from './GlobalState';

import CONFIG from 'config';

function GutscheineAlt() {
    const [gutscheine, setGutscheine] = useState([]);
    const [filteredGutscheine, setFilteredGutscheine] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [filteredBarcode, setFilterdBarcode] = useState([]);
    const [selectedBarcode, setSelectedBarcode] = useState(null);
    const [mengeGutscheine, setMengeGutscheine] = useState(1);
    const [wertGutscheine, setWertGutscheine] = useState(1);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [globalState, setGlobalState] = useContext(GlobalState);
    const [showSpinner, setShowSpinner] = useState(false);
    const gutscheinService = new GutscheinService();
    const exportService = new ExportService();
    let dt = useRef(null);

    useEffect(() => {
        setGlobalState({...globalState, page: 'Gutscheine'});
        let gutscheinPromise = gutscheinService.getAll();
        gutscheinPromise.then(data => setGutscheine(data));
        gutscheinPromise.then(data => setFilteredGutscheine(data));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const renderHeader = () => {
        return (
            <div>
                <div  className="p-datatable-globalfilter-container">
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Globale Suche" />
                </div>
            </div>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <Button type="button" icon="pi pi-print" className="p-button-secondary" onClick={() => onPrintClick(rowData)}></Button>
        );
    };

    const onPrintClick = (e) => {
        print(e);
    }

    const print = (e) => {
        console.log(e);
        try {
            try {
                window.dymo.label.framework.init(function() {
                    console.log(window.dymo.label.framework.checkEnvironment());
                    var label = window.dymo.label.framework.openLabelFile(CONFIG.FrontendServerUrl +'/assets/gutschein.label');
                    var printers = window.dymo.label.framework.getPrinters();
                    console.log(printers);
                    if (printers.length == 0)
                    {
                            alert("No DYMO printers are installed. Install DYMO printers.");
                            return;
                    }
                    label.setObjectText("TEXT", "Gutschein " + e.data.Betrag + " €");
                    label.setObjectText("Barcode", e.data.Barcode);
                    console.log(label);
                    var printParams = '<?xml version="1.0" encoding="utf-8"?>\
                        <LabelWriterPrintParams>\
                            <Copies>1</Copies>\
                        </LabelWriterPrintParams>';
                    label.print(printers[0].name, printParams);
                });
            }
            catch(err)
            {
                console.log(err.message);
                alert("Fehler beim Etiketten drucken.");
            };
            
        } catch(err)
        {
            console.log(err.message);
            alert("Fehler beim Etiketten drucken.");
        }   
    }

    const filterBarcode = (event) => {
        setTimeout(() => {
            let results = filteredGutscheine.filter((gutschein) => {
                return gutschein.Barcode.includes(event.query);
            });
            results = results.map(function(gutschein) {
               return gutschein.Barcode; 
            });
            setFilterdBarcode(results);
        }, 250);
    }

    const onBarcodeChange = (event) => {
        dt.current.filter(event.value, 'Barcode', 'contains');
        setSelectedBarcode(event.value);
    }
    
    const renderBarcodeFilter = () => {
        return (
            <AutoComplete value={selectedBarcode} suggestions={filteredBarcode} completeMethod={filterBarcode}
            placeholder="Bezeichnung" minLength={1} onChange={onBarcodeChange} 
            inputStyle={{width: '100%'}}
            style={{width: '100%'}}/>
        );
    }

    const onGutscheinNeuClick = (e) => {
        gutscheinService.create(mengeGutscheine, wertGutscheine).then(updateGutscheine);
    }

    const updateGutscheine = (e) => {
        let gutscheinPromise = gutscheinService.getAll();
        gutscheinPromise.then(data => setGutscheine(data));
        gutscheinPromise.then(data => setFilteredGutscheine(data));
        setDialogVisible(false);
    }

    const footer = (
        <div>
            <Button label="Ok" icon="pi pi-check" onClick={onGutscheinNeuClick} />
            <Button label="Abbrechen" icon="pi pi-times" onClick={(e) => {setDialogVisible(false)}} />
        </div>
    );

    const renderErstelltFilter = () => {
        return (
            <Calendar locale="de" selectionMode="range" readOnlyInput onChange={onErstelltChange}></Calendar>
        );
    }

    const onErstelltChange = (e) => {
        dt.current.filter(e.value, 'ausgestellt', 'custom');
    }

    const filterDate = (value, filter) => {
        var date1 = new Date(value);
        var date2 = new Date(filter);

        if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
            return true;
        }

        if (value === undefined || value === null) {
            return false;
        }
        
        if (date1.getDay() == date2.getDay() && date1.getMonth() == date2.getMonth() && date1.getFullYear() == date2.getFullYear())
            return true;
        else
            return false;
    }

    const formatDate = (date) => {
        let month = date.getMonth() + 1;
        let day = date.getDate();

        if (month < 10) {
            month = '0' + month;
        }

        if (day < 10) {
            day = '0' + day;
        }

        return date.getFullYear() + '-' + month + '-' + day;
    }

    addLocale("de", {
        firstDayOfWeek: 1,
        dayNames: ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"],
        dayNamesShort: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
        dayNamesMin: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
        monthNames: ["Jänner", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktoboer", "November", "Dezember"],
        monthNamesShort: ["Jän", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
        today: 'Heute',
        clear: 'Zurücksetzen',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Woche'
    });

    const onExportClick = () => {
        setShowSpinner(true)
        exportService.downloadGutscheinListe().finally(() => setShowSpinner(false));
    };

    const header = renderHeader();
    const barcodeFilter = renderBarcodeFilter();
    const erstelltFilter = renderErstelltFilter();

    return (
        <React.Fragment>
            <div className="grid">
                <div className="col-12" style={{textAlign: 'right'}}>
                    {showSpinner ? <i className="pi pi-spin pi-spinner mr-2" style={{fontSize: '2rem'}}></i> : <React.Fragment></React.Fragment>}
                    <Button label="Gutscheine exportieren" icon="pi pi-download" onClick={onExportClick}/>
                </div>
                <div className="col-12">
                    <TreeTable ref={dt} value={gutscheine} key={gutscheine.keys} paginator rows={30}>
                        <Column field="Barcode" header="Barcode" expander></Column>
                        <Column field="Betrag" header="Betrag"></Column>
                        <Column field="ausgestellt" header="erstellt" sortable filter filterPlaceholder="Datum eingeben" header="erstellt" filterMatchMode="custom" filterElement={erstelltFilter} filterFunction={filterDate}></Column>
                        <Column field="eingelöst" header="eingelöst"></Column>
                        <Column body={actionBodyTemplate} headerStyle={{width: '8em', textAlign: 'center'}} bodyStyle={{textAlign: 'center', overflow: 'visible'}} />
                    </TreeTable>
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    <Button label="Neuer Gutschein" onClick={() => setDialogVisible(true)} style={{marginRight: '10px'}}/>
                </div>
            </div>
            <Dialog header="Wert und Menge?" visible={dialogVisible} style={{ width: '30vw' }} footer={footer} onHide={() => setDialogVisible(false)} >
                <div className="p-d-block">
                    Menge: <InputNumber value={mengeGutscheine} onValueChange={(e) => setMengeGutscheine(e.target.value)} mode="decimal" showButtons buttonLayout="vertical" style={{width: '6em'}}
                    decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                    Wert: <InputNumber value={wertGutscheine} onValueChange={(e) => setWertGutscheine(e.target.value)} mode="decimal" showButtons buttonLayout="vertical" style={{width: '6em'}}
                    decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                </div>
            </Dialog>
        </React.Fragment>
    );
};

export default GutscheineAlt;