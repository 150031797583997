import React, { useRef, useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GlobalState } from './GlobalState';
import { KundenService } from '../service/KundenService';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { addDE } from './LocalDE';

import CONFIG from 'config';

export default function Kunde(props) {
	const labelWidth = '8em';

	const kundenService = new KundenService();
	const history = useHistory();
	const { id } = useParams();
	const toastRef = useRef(null);

	const [globalState, setGlobalState] = useContext(GlobalState);
	const [kunde, setKunde] = useState({VK: 1});

	addDE();

	function onKundeLoad(kunde) {
		kunde.Aktenkundigkeit = new Date(kunde.Aktenkundigkeit);
		setKunde(kunde);
	}

	const onSpeichernClick = () => {
		if(kunde.Vorname === undefined || kunde.Vorname === null || kunde.Vorname === '' || kunde.Nachname === undefined || kunde.Nachname === null || kunde.Nachname === '') {
			toastRef.current.show({severity:'error', summary: 'Der Kunde konnte nicht gespeichert werden', detail:'Nicht alle Pflichtfelder wurden ausgefüllt.', life: 3000});
			return;
		}
		kundenService.set(kunde).then(() => {
			toastRef.current.show({severity:'success', summary: 'Erfolgreich', detail:'Kunde gespeichert.', life: 2000});
			setTimeout(() => history.goBack(), 1000);
		}).catch(() => {
			toastRef.current.show({severity:'error', summary: 'Der Kunde konnte nicht gespeichert werden', life: 3000});
		});
	}

	useEffect(() => {
		setGlobalState({...globalState, page: props.action === 'bearbeiten' ? 'Kunden > bearbeiten' : 'Kunden > anlegen'});
		if (props.action === 'bearbeiten') {
			kundenService.getFromId(Number(id)).then(onKundeLoad);
		}
	}, []);

	return (
		<React.Fragment>
			<Toast ref={toastRef}/>
			<div className="p-component grid">
				<div className="col-12">
					<div className="field grid">
						<Button label="Speichern" icon="pi pi-save" style={{marginRight: '.5em'}} onClick={onSpeichernClick} />
						<Button label="Abbrechen" icon="pi pi-times" onClick={() => history.goBack()} />
					</div>
				</div>
				<div className="col-12">
					<div className="field grid">
						<label htmlFor="KundenNr" className="col-fixed" style={{width: labelWidth}}>Kunden-Nr.</label>
						<div className="col">
							<InputText id="KundenNr" value={kunde.Kundennummer} disabled/>
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="vorname" className="col-fixed" style={{width: labelWidth}}>Vorname</label>
						<div className="col">
							<InputText value={kunde.Vorname} id="vorname" onChange={e => setKunde({...kunde, Vorname: e.target.value})} className={kunde.Vorname === undefined || kunde.Vorname === null || kunde.Vorname === '' ? 'p-invalid' : ''}/>
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="nachname" className="col-fixed" style={{width: labelWidth}}>Nachname</label>
						<div className="col">
							<InputText value={kunde.Nachname} id="nachname" onChange={e => setKunde({...kunde, Nachname: e.target.value})} className={kunde.Nachname === undefined || kunde.Nachname === null || kunde.Nachname === '' ? 'p-invalid' : ''}/>
						</div>
					</div>
				</div>
				<div className="col-12">
					<Accordion activeIndex={0}>
						<AccordionTab header="Allgemein">
							<div className="field grid">
								<label htmlFor="aktenkundigkeit" className="col-fixed" style={{width: labelWidth}}>Aktenkundigkeit</label>
								<div className="col">
									<Calendar inputId="aktenkundigkeit" value={kunde.Aktenkundigkeit} showIcon={true} locale="de" onChange={e => setKunde({...kunde, Aktenkundigkeit: e.target.value})}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="anrede" className="col-fixed" style={{width: labelWidth}}>Anrede</label>
								<div className="col">
									<InputText value={kunde.Anrede} id="anrede" onChange={e => setKunde({...kunde, Anrede: e.target.value})}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="titel" className="col-fixed" style={{width: labelWidth}}>Titel</label>
								<div className="col">
									<InputText value={kunde.Titel} id="titel" onChange={e => setKunde({...kunde, Titel: e.target.value})}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="uid" className="col-fixed" style={{width: labelWidth}}>UID</label>
								<div className="col">
									<InputText value={kunde.UID} id="uid" onChange={e => setKunde({...kunde, UID: e.target.value})}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="firmenbuchnr" className="col-fixed" style={{width: labelWidth}}>FirmenbuchNr</label>
								<div className="col">
									<InputText value={kunde.FirmenbuchNr} id="firmenbuch" onChange={e => setKunde({...kunde, FirmenbuchNr: e.target.value})}/>
								</div>
							</div>
						</AccordionTab>
						<AccordionTab header="Adresse">
							<div className="field grid">
								<label htmlFor="strasse" className="col-fixed" style={{width: labelWidth}}>Straße</label>
								<div className="col">
									<InputText value={kunde.Adresse} id="strasse" type="text" style={{width: '20em'}} onChange={e => setKunde({...kunde, Adresse: e.target.value})}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="plz" className="col-fixed" style={{width: 'calc(' + labelWidth + ' / 4)'}}>PLZ</label>
								<label htmlFor="ort" className="col-fixed" style={{width: 'calc((' + labelWidth + ' / 4) * 3)'}}>Ort</label>
								<div className="col">
									<InputText value={kunde.PLZ} id="plz" type="text" style={{width: '5em'}} onChange={e => setKunde({...kunde, PLZ: e.target.value})}/>
									<InputText value={kunde.Ort} id="ort" type="text" style={{width: '15em'}} onChange={e => setKunde({...kunde, Ort: e.target.value})}/>
								</div>
							</div>
						</AccordionTab>
						<AccordionTab header="Kontakt">
							<div className="field grid">
								<label htmlFor="telefon" className="col-fixed" style={{width: labelWidth}}>Telefon</label>
								<div className="col">
									<InputText value={kunde.Telefon} id="telefon" type="text" style={{width: '20em'}} onChange={e => setKunde({...kunde, Telefon: e.target.value})}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="fax" className="col-fixed" style={{width: labelWidth}}>Fax</label>
								<div className="col">
									<InputText value={kunde.Fax} id="fax" type="text" style={{width: '20em'}} onChange={e => setKunde({...kunde, Fax: e.target.value})}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="handy" className="col-fixed" style={{width: labelWidth}}>Handy</label>
								<div className="col">
									<InputText value={kunde.Handy1} id="handy" type="text" style={{width: '20em'}} onChange={e => setKunde({...kunde, Handy1: e.target.value})}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="email" className="col-fixed" style={{width: labelWidth}}>E-Mail</label>
								<div className="col">
									<InputText value={kunde.EMail} id="email" type="text" style={{width: '20em'}} onChange={e => setKunde({...kunde, EMail: e.target.value})}/>
								</div>
							</div>
						</AccordionTab>
						{ CONFIG.KundenWeiterAktiviert && <AccordionTab header="Weiteres">
							<div className="field grid">
								<label htmlFor="vk" className="col-fixed" style={{width: labelWidth}}>VK</label>
								<div className="col">
									<Dropdown value={kunde.VK} options={[
										{label: 'VK1', value: 1},
										{label: 'VK2', value: 2},
										{label: 'VK3', value: 3}
									]} onChange={e => setKunde({...kunde, VK: e.value})}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="barcode" className="col-fixed" style={{width: labelWidth}}>Barcode</label>
								<div className="col">
									<InputText value={kunde.Barcode} id="barcode" onChange={e => setKunde({...kunde, Barcode: e.target.value})}/>
								</div>
							</div>
						</AccordionTab>
						}
					</Accordion>
				</div>
			</div>
		</React.Fragment>
	);
}