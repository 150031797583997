import axios from "axios";
import { GetAxiosConfig } from "./AuthService";
import CONFIG from 'config';

export class KategorienService {
	async GetKategorien() {
		return axios.get(CONFIG.ServerUrl + "/AnzeigeKategorien", await GetAxiosConfig()).then(res => res.data);
	}

	async GetKategorienFlat() {
		return axios.get(CONFIG.ServerUrl + "/AnzeigeKategorien/Flat", await GetAxiosConfig()).then(res => res.data);
	}

	async GetKategorie(id) {
		return axios.get(CONFIG.ServerUrl + "/AnzeigeKategorie/" + id, await GetAxiosConfig()).then(res => res.data);
	}

	async SetKategorie(kategorie) {
		return axios.post(CONFIG.ServerUrl + "/AnzeigeKategorie/Set", kategorie, await GetAxiosConfig()).then(res => res.data);
	}

	async DeleteKategorie(id) {
		return axios.get(CONFIG.ServerUrl + "/AnzeigeKategorie/Delete/" + id, await GetAxiosConfig()).then(res => res.data);
	}
}