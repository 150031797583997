import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalState } from "./GlobalState";
import { GutscheinService } from "../service/GutscheinService";
import { createCsv } from "../helper/csv";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { confirmDialog } from "primereact/confirmdialog";
import { PopupMenu } from "./PopupMenu";
import { Chart } from "primereact/chart";

export default function Gutscheine() {
	const gutscheinService = new GutscheinService();

	const [globalState, setGlobalState] = useContext(GlobalState);
	const [loading, setLoading] = useState(false);
	const [gutscheinAnzahl, setGutscheinAnzahl] = useState(0);
	const [gutscheine, setGutscheine] = useState([]);
	const [lazyParams, setLazyParams] = useState({
		first: 0,
		rows: 20,
		page: 0
	});
	const [idFilter, setIdFilter] = useState([null, null]);
	const [betragFilter, setBetragFilter] = useState([null, null]);
	const [gesperrtFilter, setGesperrtFilter] = useState(null);
	const [bearbeitenDialogOffen, setBearbeitenDialogOffen] = useState(false);
	const [bearbeitenVon, setBearbeitenVon] = useState(null);
	const [bearbeitenBis, setBearbeitenBis] = useState(null);
	const [transferBetragDialogOffen, setTransferBetragDialogOffen] = useState(false);
	const [transferBetragGutscheinVon, setTransferBetragGutscheinVon] = useState(null);
	const [transferBetragGutscheinZu, setTransferBetragGutscheinZu] = useState(null);
	const [transferBetragBetrag, setTransferBetragBetrag] = useState(null);
	const [transferBetragBetragMax, setTransferBetragBetragMax] = useState(null);
	const [selectedGutschein, setSelectedGutschein] = useState(null);
	const [gutscheinBuchungen, setGutscheinBuchungen] = useState(null);
	const [gutscheinGesamtAnzahl, setGutscheinGesamtAnzahl] = useState(0);
	const [gutscheinAktiviertAnzahl, setGutscheinAktiviertAnzahl] = useState(0);
	const [wertAufgeladen, setWertAufgeladen] = useState(0);
	const [wertAusgegeben, setWertAusgegeben] = useState(0);

	const dtRef = useRef(null);

	useEffect(() => {
		setGlobalState({...globalState, page: "Gutscheine"});
		gutscheinService.GetCount().then(setGutscheinGesamtAnzahl);
		gutscheinService.GetAnzahlAktiviert().then(setGutscheinAktiviertAnzahl);
		gutscheinService.GetBetragSum().then(setWertAufgeladen);
		gutscheinService.GetBetragAusgegeben().then(setWertAusgegeben);
	}, []);

	function loadGutscheine() {
		setLoading(true);
		gutscheinService.ReadLazy(lazyParams).then(async data => {
			setGutscheinAnzahl(data.Count);
			const ersteAufladungen = await gutscheinService.GetErsteAufladungen(data.Data.map(g => g.Id));
			const ablaufdatum = await gutscheinService.GetAblaufdatum(data.Data.map(g => g.Id));
			setGutscheine(
				data.Data.map((g, i) => ({...g,
					ErsteAufladung: ersteAufladungen[i] === undefined || ersteAufladungen[i] === null ? null : new Date(ersteAufladungen[i]),
					Ablaufdatum: ablaufdatum[i] === undefined || ablaufdatum[i] === null ? null : new Date(ablaufdatum[i])})));
			setLoading(false);
		});
	}

	function exportGutscheine(gutscheine) {
		const csv = createCsv([{Id: "Id", Barcode: "Barcode", AufladeBarcode: "Auflade Barcode"}].concat(gutscheine), ["Id", "Barcode", "AufladeBarcode"]);
		const a = window.document.createElement("a");
		a.href = window.URL.createObjectURL(new Blob([csv], { type: "text/csv" }));
		a.download = "Gutscheine.csv";
		window.document.body.append(a);
		a.click();
		a.remove();
		window.URL.revokeObjectURL(a.href);
	}

	useEffect(() => {
		loadGutscheine();
	}, [lazyParams]);

	const onPage = e => {
		setLazyParams({...lazyParams, ...e});
	};

	const onFilter = e => {
		setLazyParams({...lazyParams, ...e, first: 0, page: 0});
	};

	const onExportierenClick = () => {
		gutscheinService.ReadRange(bearbeitenVon, bearbeitenBis).then(gutscheine => {
			exportGutscheine(gutscheine);
		});
	};

	const onSperrenClick = () => {
		gutscheinService.UpdateGesperrt(bearbeitenVon, bearbeitenBis, true).then(loadGutscheine);
	};

	const onEntsperrenClick = () => {
		gutscheinService.UpdateGesperrt(bearbeitenVon, bearbeitenBis, false).then(loadGutscheine);
	};

	const onBetragUebertragenClick = (id, maxBetrag) => {
		setTransferBetragGutscheinVon(id);
		setTransferBetragBetrag(maxBetrag);
		setTransferBetragBetragMax(maxBetrag);
		setTransferBetragDialogOffen(true);
	};

	const onBetragUebertragenBestaetigenClick = () => {
		if (transferBetragGutscheinVon === undefined || transferBetragGutscheinVon === null || transferBetragGutscheinZu === undefined || transferBetragGutscheinZu === null || transferBetragBetrag === undefined || transferBetragBetrag === null || transferBetragBetrag === 0) return;
		setTransferBetragDialogOffen(false);
		gutscheinService.TransferBetrag(transferBetragGutscheinVon, transferBetragGutscheinZu, transferBetragBetrag).then(loadGutscheine);
	};

	const onLoeschenClick = () => {
		confirmDialog({
			message: bearbeitenVon === bearbeitenBis ? "Sicher, dass Sie diesen Gutschein dauerhaft löschen wollen?" : "Sicher, dass Sie diese Gutscheine dauerhaft löschen wollen?",
			header: "Bestätigung",
			icon: "pi pi-exclamation-triangle",
			accept: () => gutscheinService.DeleteRange(bearbeitenVon, bearbeitenBis).then(loadGutscheine)
		});
	};

	const onBuchungenClick = gutschein => {
		setSelectedGutschein(gutschein);
		gutscheinService.GetGutscheinBuchungen(gutschein.Id).then(buchungen => {
			setGutscheinBuchungen(buchungen.map(buchung => ({...buchung, Zeit: new Date(Date.parse(buchung.Zeit))})));
		});
	};

	const idFilterElement = <React.Fragment>
		<InputNumber
			value={idFilter[0]}
			onValueChange={e => setIdFilter([e.value, idFilter[1]])}
			min={0} style={{width: "50%"}} inputStyle={{width: "100%"}}
			onChange={e => dtRef.current.filter([e.value, idFilter[1]], "Id", "in")}
			mode="decimal"
			useGrouping={false}
			placeholder="von"
			/>
		<InputNumber
			value={idFilter[1]}
			onValueChange={e => setIdFilter([idFilter[0], e.value])}
			min={0} style={{width: "50%"}}
			inputStyle={{width: "100%"}}
			onChange={e => dtRef.current.filter([idFilter[0], e.value], "Id", "in")}
			mode="decimal"
			useGrouping={false}
			placeholder="bis"
			/>
	</React.Fragment>;

	const betragFilterElement =
		<React.Fragment>
			<InputNumber
				value={betragFilter[0]}
				onValueChange={e => setBetragFilter([e.value, betragFilter[1]])}
				mode="decimal"
				minFractionDigits={2}
				maxFractionDigits={2}
				prefix="€ "
				style={{width: "50%"}}
				inputStyle={{width: "100%"}}
				onChange={e => {
					dtRef.current.filter([e.value, betragFilter[1]], "Betrag", "in");
				}}
				placeholder="von"
				/>
			<InputNumber
				value={betragFilter[1]}
				onValueChange={e => setBetragFilter([betragFilter[0], e.value])}
				mode="decimal" minFractionDigits={2} maxFractionDigits={2} prefix="€ " style={{width: "50%"}}
				inputStyle={{width: "100%"}}
				onChange={e => {
					dtRef.current.filter([betragFilter[0], e.value], "Betrag", "in");
				}}
				placeholder="bis"/>
		</React.Fragment>;

	const gesperrtFilterElement =
		<div style={{textAlign: "center"}}>
			<TriStateCheckbox
				value={gesperrtFilter} onChange={e => { setGesperrtFilter(e.value);
					dtRef.current.filter(e.value, "Gesperrt", "equals"); }}/>
		</div>

	const onSort = (e) => {
		console.log(e);
		console.log(gutscheine);
		//e.sortField = ErsteAufladung
		var value = [...gutscheine];
		value.sort((data1, data2) => {
			let value1 = data1[e.sortField];
			let value2 = data2[e.sortField];
			console.log(value1);
			console.log(value2);
		});
	} 

	return (
		<React.Fragment>
			<div className="grid p-component">
				<div className="col-12">
					<DataTable
					ref={dtRef}
					filterDisplay="row"
					value={gutscheine}
					sortField="Id" sortOrder={-1} dataKey="Id"
					lazy paginator first={lazyParams.first}
					rows={lazyParams.rows} totalRecords={gutscheinAnzahl} onPage={onPage}
					onFilter={onFilter} filters={lazyParams.filters} loading={loading} style={{width: "100%"}} scrollable scrollHeight="60vh"
					className="p-datatable-gutscheine"
					onRowClick={row => onBuchungenClick(row.data)}
					onSort={onSort}
					>
						<Column
							field="Id" header="Id" filter
							filterElement={idFilterElement}
							showFilterMenu={false}
							onFilterClear={() => setIdFilter([null, null])}
							style={{width: "18em", maxWidth: "18m"}}
							headerStyle={{width: "18em", maxWidth: "18m"}}
							/>
						<Column
							field="Barcode"
							header="Barcode"
							filter
							showFilterMenu={false}
							style={{width: "36em", maxWidth: "36m", textAlign: "left"}}
							/>
						<Column
							field="AufladeBarcode"
							header="Auflade Barcode"
							filter
							showFilterMenu={false}
							style={{width: "36em", maxWidth: "36m", textAlign: "left"}}
							/>
						<Column
							field="ErsteAufladung"
							header="Erste Aufladung"
							style={{width: "10rem"}}
							showFilterMenu={false}
							body={row => row.ErsteAufladung?.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" })}
							sortable
							sortFunction={(a) => console.log("a")}
							/>
						<Column
							field="Ablaufdatum"
							header="Ablaufdatum"
							style={{width: "8rem"}}
							showFilterMenu={false}
							body={row => row.Ablaufdatum?.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" })}
							sortable
							sortFunction={(a) => console.log("a")}
							/>
						<Column
							field="Betrag" header="Betrag" filter
							filterElement={betragFilterElement}
							bodyStyle={{textAlign: "right"}}
							showFilterMenu={false}
							body={row => row.Betrag.toLocaleString("de-AT", { style: "currency", currency: "EUR" })}
							onFilterClear={() => setBetragFilter([null, null])}
							style={{width: "22em", maxWidth: "22m"}}
							headerStyle={{width: "22em", maxWidth: "22m"}}
							/>
						<Column field="Gesperrt" header="Gesperrt"
							filter filterElement={gesperrtFilterElement}
							body={rowData => <div style={{width: "100%", textAlign: "center"}}>{rowData.Gesperrt ? <React.Fragment><i className="pi pi-lock"></i>&nbsp;Gesperrt </React.Fragment>: ""}</div>}
							style={{ width: "6rem"}}
							showFilterMenu={false}
							onFilterClear={() => setGesperrtFilter(null)}
							showClearButton={false}
							/>
						<Column
							header="Aktionen"
							body={rowData => <PopupMenu label="Aktion auswählen"
							icon="pi pi-pencil"
							onClick={() => {
								setBearbeitenVon(rowData.Id);
								setBearbeitenBis(rowData.Id);
							}}
							model={[
								{ label: "Exportieren", icon: 'pi pi-download', command: onExportierenClick },
								{ label: "Sperren", icon: 'pi pi-lock', command: onSperrenClick },
								{ label: "Entsperren", icon: 'pi pi-lock-open', command: onEntsperrenClick },
								{ label: "Betrag übertragen", icon: 'pi pi-angle-double-right', command: () => onBetragUebertragenClick(rowData.Id, rowData.Betrag) },
								{ label: "Löschen", icon: 'pi pi-trash', command: onLoeschenClick }
							]}/>}
							filter
							filterElement={
								<div>
								<Button label="Aktion auswählen" icon="pi pi-bars"
									onClick={() => { setBearbeitenDialogOffen(true); setBearbeitenVon(null); setBearbeitenBis(null); }}
									style={{width: "12em"}}
								/>
								</div>
							}
							style={{width: "14em", minWidth: "14em", maxWidth: "14em"}}
							headerStyle={{width: "14em", maxWidth: "14em"}}
							filterHeaderStyle={{width: "100%"}}
							showFilterMenu={false}
							/>
					</DataTable>
				</div>
				<div className="col-12 grid">
					<div className="col-8">
						<table>
							<tr>
								<td style={{paddingRight: "1rem"}}>Aufgeladener Wert</td>
								<td>&euro;</td>
								<td style={{textAlign: "right"}}>{wertAufgeladen.toLocaleString("de", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
							</tr>
							<tr>
								<td style={{paddingRight: "1rem"}}>Ausgegebener Wert</td>
								<td>&euro;</td>
								<td style={{textAlign: "right"}}>{wertAusgegeben.toLocaleString("de", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
							</tr>
						</table>
					</div>
					<div className="col-4">
						<Chart type="pie" data={{labels: ["Aktiviert", "Nicht Aktiviert"], datasets: [{data: [gutscheinAktiviertAnzahl, gutscheinGesamtAnzahl - gutscheinAktiviertAnzahl], backgroundColor: ["#0e5ea2", "#959ca3"]}]}} options={{animation: {duration: 0}, plugins: {legend: {position: 'bottom', reverse: true}}}}/>
					</div>
				</div>
			</div>
			<Dialog visible={bearbeitenDialogOffen} onHide={() => setBearbeitenDialogOffen(false)} header="Bearbeiten">
				<div className="grid" style={{width: "min-content"}}>
					<div className="col-12">
						<InputNumber value={bearbeitenVon} onChange={e => setBearbeitenVon(e.value)} min={0} placeholder="von" style={{width: "50%"}} inputStyle={{width: "100%"}}/>
						<InputNumber value={bearbeitenBis} onChange={e => setBearbeitenBis(e.value)} min={0} placeholder="bis" style={{width: "50%"}} inputStyle={{width: "100%"}}/>
					</div>
					<div className="col-12">
						<PopupMenu 
							label="Aktion auswählen" icon="pi pi-pencil"
							disabled={bearbeitenVon === undefined || bearbeitenVon === null || bearbeitenBis === undefined || bearbeitenBis === null || bearbeitenVon > bearbeitenBis}
							style={{whiteSpace: "nowrap"}} model={[
							{ label: "Exportieren", command: onExportierenClick },
							{ label: "Sperren", command: onSperrenClick },
							{ label: "Entsperren", command: onEntsperrenClick },
							{ label: "Löschen", command: onLoeschenClick }
						]}/>
					</div>
				</div>
			</Dialog>
			<Dialog visible={transferBetragDialogOffen} onHide={() => setTransferBetragDialogOffen(false)} header="Gutschein und betrag auswählen" footer={<Button label="Bestätigen" disabled={transferBetragGutscheinVon === undefined || transferBetragGutscheinVon === null || transferBetragGutscheinZu === undefined || transferBetragGutscheinZu === null || transferBetragBetrag === undefined || transferBetragBetrag === null || transferBetragBetrag === 0} onClick={onBetragUebertragenBestaetigenClick}/>}>
				<div className="grid">
					<div className="col-12">
						<InputNumber value={transferBetragGutscheinZu} onChange={e => setTransferBetragGutscheinZu(e.value)} min={0} placeholder="Ziel Gutschein Id" style={{width: "100%"}} inputStyle={{width: "100%"}}/>
					</div>
					<div className="col-12">
						<InputNumber value={transferBetragBetrag} onValueChange={e => setTransferBetragBetrag(e.value)} mode="decimal" minFractionDigits={2} maxFractionDigits={2} prefix="€ " style={{width: "100%"}} inputStyle={{width: "100%"}} min={0} max={transferBetragBetragMax} placeholder="Betrag"/>
					</div>
				</div>
			</Dialog>
			<Dialog visible={gutscheinBuchungen !== undefined && gutscheinBuchungen !== null} onHide={() => { setSelectedGutschein(null); setGutscheinBuchungen(null); }} header={ selectedGutschein?.Id + " - " + selectedGutschein?.Barcode}>
				<table className="tablePadding" style={{width: "100%", borderCollapse: "collapse"}}>
					<tr>
						<td style={{fontWeight: "500", textAlign: "center"}}>Zeitpunkt</td>
						<td style={{fontWeight: "500", textAlign: "center"}}>Betrag</td>
						<td style={{fontWeight: "500", textAlign: "center"}}>BelegNr</td>
						<td style={{fontWeight: "500", textAlign: "center"}}>KassenID</td>
					</tr>
					{
						gutscheinBuchungen?.map(buchung => <tr>
							<td style={{borderTopStyle: "solid", borderWidth: "1px"}}>{buchung.Zeit.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric"}) + " " + buchung.Zeit.toLocaleTimeString("de", { hour: "2-digit", minute: "2-digit", second: "2-digit" })}</td>
							<td style={{borderTopStyle: "solid", borderWidth: "1px", textAlign: "right"}}>{buchung.Betrag.toLocaleString("de-AT", { style: "currency", currency: "EUR" })}</td>
							<td style={{borderTopStyle: "solid", borderWidth: "1px", textAlign: "right"}}>{buchung.BelegNr}</td>
							<td style={{borderTopStyle: "solid", borderWidth: "1px"}}>{buchung.KassenID}</td>
						</tr>)
					}
				</table>
			</Dialog>
		</React.Fragment>
	);
}