import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalState } from "./GlobalState";
import { UmsatzService } from "../service/UmsatzService";
import { KassenService } from "../service/KassenService";
import { DashboardService } from "../service/DashboardService";
import { ExportService } from "../service/ExportService";
import { Calendar } from "primereact/calendar";
import { addDE } from "./LocalDE";
import { Chart } from "primereact/chart";
import { ZoomPopup } from "./ZoomPopup";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";

export default function Tagesumsaetze() {
	const kategorienUmsatzTypeLookup = {
		0: ["Kategorien Vergleich", "KategorienVergleich"],
		1: ["Warengruppen Vergleich", "WarengruppenVergleich"],
		2: ["Artikelgruppen Vergleich", "ArtikelgruppenVergleich"]
	}

	const umsatzService = new UmsatzService();
	const kassenService = new KassenService();
	const dashboardService = new DashboardService();
	const exportService = new ExportService();

	const [globalState, setGlobalState] = useContext(GlobalState);
	const [kassen, setKassen] = useState([]);
	const [tagesumsaetze, setTagesumsaetze] = useState([]);
	const [loading, setLoading] = useState(false);
	const [datumsbereich, setDatumsbereich] = useState();
	const [selectedKassen, setSelectedKassen] = useState([]);
	const [kommentarEditorValues, setKommentarEditorValues] = useState({});
	const [selectedDate, setSelectedDate] = useState(null);
	const [date2, setDate2] = useState();
	const [vergleichDialogVisible, setVergleichDialogVisible] = useState(false);
	const [tagesumsatzVergleichData, setTagesumsatzVergleichData] = useState(null);
	const [kategorienVergleichTarget, setKategorienVergleichTarget] = useState([0, null]);
	const [kategorienVergleichData, setKategorienVergleichData] = useState(null);
	const [kategorienVergleichIds, setKategorienVergleichIds] = useState([]);
	const [exportLoading, setExportLoading] = useState(false);

	const toastRef = useRef(null);
	const dtRef = useRef(null);
	const tagesumsatzVergleichRef = useRef(null);
	const kategorienVergleichRef = useRef(null);

	addDE();

	const download = (url, name) => {
		const a = window.document.createElement("a");
		a.href = url;
		a.download = name;
		window.document.body.append(a);
		a.click();
		a.remove();
	};

	function Load() {
		setKommentarEditorValues({});
		if (!Array.isArray(datumsbereich) || !(datumsbereich[0] instanceof Date) || !(datumsbereich[1] instanceof Date)) {
			setTagesumsaetze([]);
			return;
		}
		setLoading(true);
		umsatzService.getTagesumsaetze(datumsbereich[0], datumsbereich[1]).then(data => setTagesumsaetze(data.map(u => ({...u, Date: new Date(u.Date)})))).finally(() => setLoading(false));
	}
	
	useEffect(() => {
		setGlobalState({...globalState, page: "Tagesumsätze"});
		var d = new Date();
		d.setDate(d.getDate() - 30);

		setDatumsbereich([d, new Date()]);
		kassenService.getKassen().then(setKassen);
	}, []);

	useEffect(() => {
		Load();
	}, [datumsbereich]);

	
	useEffect(() => 
		async () => {
		if (!(selectedDate instanceof Date)) {
			setTagesumsatzVergleichData(null);
			return;
		}

		const data = await umsatzService.getUmsaetzeTag2(selectedDate);
		if (date2 instanceof Date) {
			const data2 = await umsatzService.getUmsaetzeTag2(date2);
			const labels = [...new Set([...data.map(d => d.KassenID), ...data2.map(d => d.KassenID)])];
			const datasets = [
				{
					label: selectedDate.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }),
					data: labels.map(kassenID => data.filter(d => d.KassenID === kassenID)[0]?.Umsatz ?? 0),
					backgroundColor: "#229954"
				},
				{
					label: date2.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }),
					data: labels.map(kassenID => data2.filter(d => d.KassenID === kassenID)[0]?.Umsatz ?? 0),
					backgroundColor: "#445e4f"
				}
			];
			console.log(datasets);
			setTagesumsatzVergleichData({
				labels: labels,
				datasets: datasets
			});
		} else {
			const labels = data.map(d => d.KassenID);
			const datasets = [
				{
					label: selectedDate.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }),
					data: data.map(d => d.Umsatz),
					backgroundColor: "#229954"
				}
			];
			setTagesumsatzVergleichData({
				labels: labels,
				datasets: datasets
			});
		}
	}, [selectedDate, date2]);

	useEffect(() =>
		async () => {
		if (!(selectedDate instanceof Date)) {
			if (kategorienVergleichTarget[0] !== 0) setKategorienVergleichTarget([0, null]);
			if (kategorienVergleichData !== null) setKategorienVergleichData(null);
			if (kategorienVergleichIds.length !== 0) setKategorienVergleichIds([]);
			return;
		}

		if (date2 instanceof Date) {
			let umsatzData;
			let umsatzData2;
			switch (kategorienVergleichTarget[0]) {
				case 0:
					umsatzData = await dashboardService.GetKategorienUmsatz(kassen.map(k => k.KassenID), selectedDate, selectedDate, false);
					umsatzData2 = await dashboardService.GetKategorienUmsatz(kassen.map(k => k.KassenID), date2, date2, false);
					break;
				case 1:
					umsatzData = await dashboardService.GetWarengruppenUmsatz(kassen.map(k => k.KassenID), kategorienVergleichTarget[1], selectedDate, selectedDate, false);
					umsatzData2 = await dashboardService.GetWarengruppenUmsatz(kassen.map(k => k.KassenID), kategorienVergleichTarget[1], date2, date2, false);
					break;
				case 2:
					umsatzData = await dashboardService.GetArtikelgruppenUmsatz(kassen.map(k => k.KassenID), kategorienVergleichTarget[1], selectedDate, selectedDate, false);
					umsatzData2 = await dashboardService.GetArtikelgruppenUmsatz(kassen.map(k => k.KassenID), kategorienVergleichTarget[1], date2, date2, false);
					break;
			}
			const ids = [...new Set([...umsatzData.map(u => u.ID), ...umsatzData2.map(u => u.ID)])];
			setKategorienVergleichIds(ids);
			setKategorienVergleichData({
				labels: umsatzData.map(u => u.Bezeichnung),
				datasets: [
					{
						label: selectedDate.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }),
						data: ids.map(id => umsatzData.filter(u => u.ID === id)[0]?.Umsatz ?? 0),
						backgroundColor: "#229954"
					},
					{
						label: date2.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }),
						data: ids.map(id => umsatzData2.filter(u => u.ID === id)[0]?.Umsatz ?? 0),
						backgroundColor: "#445e4f"
					}
				]
			});
		} else {
			let umsatzData;
			switch (kategorienVergleichTarget[0]) {
				case 0:
					umsatzData = await dashboardService.GetKategorienUmsatz(kassen.map(k => k.KassenID), selectedDate, selectedDate, false);
					break;
				case 1:
					umsatzData = await dashboardService.GetWarengruppenUmsatz(kassen.map(k => k.KassenID), kategorienVergleichTarget[1], selectedDate, selectedDate, false);
					break;
				case 2:
					umsatzData = await dashboardService.GetArtikelgruppenUmsatz(kassen.map(k => k.KassenID), kategorienVergleichTarget[1], selectedDate, selectedDate, false);
					break;
			}
			setKategorienVergleichIds(umsatzData.map(u => u.ID));
			setKategorienVergleichData({
				labels: umsatzData.map(u => u.Bezeichnung),
				datasets: [
					{
						label: selectedDate.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }),
						data: umsatzData.map(u => u.Umsatz),
						backgroundColor: "#229954"
					}
				]
			});
		}
	}, [selectedDate, date2, kassen, kategorienVergleichTarget]);


	const onExportClick = () => {
		if (!Array.isArray(datumsbereich) || !(datumsbereich[0] instanceof Date) || !(datumsbereich[1] instanceof Date)) return;
		setExportLoading(true);
		exportService.downloadTagesumsatzListe(datumsbereich[0], datumsbereich[1]).catch(() => {
			toastRef.current.show({severity:"error", summary: "Fehler", detail:"Der Export ist fehlgeschlagen", life: 3000});
		}).finally(() => setExportLoading(false));
	}

	const kommentarEditor = props => <InputText value={kommentarEditorValues[props.rowIndex] ?? props.rowData.Kommentar} onChange={e => setKommentarEditorValues({...kommentarEditorValues, [props.rowIndex]: e.target.value})}/>;

	const kommentarEditorClear = e => {
		setKommentarEditorValues({...kommentarEditorValues, [e.columnProps.rowIndex]: undefined});
	};

	const kommentarEditorSubmit = e => {
		console.log(e);
		umsatzService.setTagesumsatzKommentar(e.columnProps.rowData.KassenID, e.columnProps.rowData.Date, kommentarEditorValues[e.columnProps.rowIndex] ?? e.columnProps.rowData.Kommentar ?? "");
		kommentarEditorClear(e);
		Load();
	};

	const kassenFilter = <MultiSelect className="column-filter" value={selectedKassen} onChange={e => { setSelectedKassen(e.value); dtRef.current.filter(e.value, "KassenID", "in"); }} options={kassen} optionLabel="KassenID" optionValue="KassenID"/>
	const dateFilter = <Calendar className="column-filter" value={datumsbereich} onChange={e => setDatumsbereich(e.value)} locale="de" showIcon selectionMode="range"/>;

	return <React.Fragment>
		<Toast ref={toastRef}/>
		<div className="p-component grid">
			<div className="col-12" style={{textAlign: "right"}}>
				{exportLoading && <i className="pi pi-spin pi-spinner mr-2 mt-2" style={{fontSize: '2rem'}}/>}
				<Button label="Exportieren" icon="pi pi-download" onClick={onExportClick} disabled={exportLoading}/>
			</div>
			<div className="col-12">
				<DataTable
					filterDisplay="row"
					ref={dtRef} className="p-datatable-striped tableCursorPointer" rowHover
					value={tagesumsaetze.map(u => 
						({...u,
							DateDisplay: u.Date.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }),
							UmsatzGutscheinDisplay: u.UmsatzGutschein.toLocaleString("de", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
							UmsatzBarDisplay: u.UmsatzBar.toLocaleString("de", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
							UmsatzBankomatDisplay: u.UmsatzBankomat.toLocaleString("de", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
							UmsatzGesamtDisplay: (u.UmsatzGutschein + u.UmsatzBar + u.UmsatzBankomat).toLocaleString("de", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}))}
					paginator rows={20} rowsPerPageOptions={[20,30,40]}
					onRowClick={row => setSelectedDate(row.data.Date)} scrollable scrollHeight="60vh" loading={loading}>
					<Column field="KassenID" header="Standort" filter filterElement={kassenFilter} style={{width: "20rem"}} showFilterMenu={false}/>
					<Column field="DateDisplay" header="Datum" filter filterElement={dateFilter} sortable style={{width: "16rem"}} showFilterMenu={false}/>
					<Column field="UmsatzGutscheinDisplay" header="Umsatz Gutschein" sortable style={{width: "9rem", textAlign: "right"}}
						body={rowData => <>&euro;<span style={{width:"100%", textAlign:"right"}}>{rowData.UmsatzGutscheinDisplay}</span></>} showFilterMenu={false}/>
					<Column field="UmsatzBarDisplay" header="Umsatz Bar" sortable style={{width: "9rem", textAlign: "right"}}
						body={rowData => <>&euro;<span style={{width:"100%", textAlign:"right"}}>{rowData.UmsatzBarDisplay}</span></>} showFilterMenu={false}/>
					<Column field="UmsatzBankomatDisplay" header="Umsatz Bankomat" sortable style={{width: "13rem", textAlign: "right"}}
						body={rowData => <>&euro;<span style={{width:"100%", textAlign:"right"}}>{rowData.UmsatzBankomatDisplay}</span></>} showFilterMenu={false}/>
					<Column field="UmsatzGesamtDisplay" header="Umsatz Gesamt" sortable style={{width: "11rem", textAlign: "right"}}
						body={rowData => <>&euro;<span style={{width:"100%", textAlign:"right"}}>{rowData.UmsatzGesamtDisplay}</span></>} showFilterMenu={false}/>
					<Column field="Kommentar" header="Kommentar" editor={kommentarEditor} onEditorCancel={kommentarEditorClear} onEditorSubmit={kommentarEditorSubmit}/>
				</DataTable>
			</div>
			{ selectedDate instanceof Date &&
				<React.Fragment>
					<div className="col-6">
						<ZoomPopup header="Tagesumsatz Vergleich" footer={<Button label="Download" icon="pi pi-image" onClick={() => download(tagesumsatzVergleichRef.current.getBase64Image(), "TagesumsatzVergleich.png")}/>}>
							<Chart ref={tagesumsatzVergleichRef} type="bar" data={tagesumsatzVergleichData} options={{animation: {duration: 0}, plugins: {legend: {position: "bottom"}}}}/>
						</ZoomPopup>
					</div>
					<div className="col-6">
						<ZoomPopup header={kategorienUmsatzTypeLookup[kategorienVergleichTarget[0]][0]} footer={<Button label="Download" icon="pi pi-image" onClick={() => download(kategorienVergleichRef.current.getBase64Image(), kategorienUmsatzTypeLookup[kategorienVergleichTarget[0]][1] + ".png")}/>}>
							<Chart ref={kategorienVergleichRef} type="bar" data={kategorienVergleichData} options={{animation: {duration: 0}, plugins: {legend: {position: "bottom"}}, onClick: (e, elements) => {
								if (elements.length > 0) {
									if (kategorienVergleichTarget[0] >= 2) return;
									setKategorienVergleichTarget([kategorienVergleichTarget[0] + 1, kategorienVergleichIds[elements[0].index]]);
								} else {
									if (kategorienVergleichTarget[0] <= 0) return;
									setKategorienVergleichTarget([0, null]);
								}
							}}}/>
						</ZoomPopup>
					</div>
					<div className="col-12" style={{textAlign: "center"}}>
						<Button label="Vergleichen" onClick={() => setVergleichDialogVisible(true)}/>
					</div>
				</React.Fragment>
			}
		</div>
		<Dialog visible={vergleichDialogVisible} onHide={() => setVergleichDialogVisible(false)} header="Datum zum Vergleichen angeben">
			<Calendar value={date2} onChange={e => { setDate2(e.value); setVergleichDialogVisible(false); }} showIcon locale="de"/>
		</Dialog>
	</React.Fragment>;
}