import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { LieferantenService } from '../service/LieferantenService';
import { GlobalState } from './GlobalState';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { ExportService } from '../service/ExportService';
import { Toast } from 'primereact/toast'
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete'

function Lieferanten() {
	const history = useHistory();
	const dt = useRef(null);
	const toastRef = useRef(null);
	const lieferantenService = new LieferantenService();
	const exportService = new ExportService();

	const [lieferanten, setLieferanten] = useState([]);
	const [selectedLieferantElement, setSelectedLieferantElement] = useState(null);
	const [globalFilter, setGlobalFilter] = useState(null);
	const [filteredLieferanten, setFilteredLieferanten] = useState([]);
	const [globalState, setGlobalState] = useContext(GlobalState);
	const [exportDialogVisible, setExportDialogVisible] = useState(false);
	const [exportOptions, setExportOptions] = useState({});
	const [showSpinner, setShowSpinner] = useState(false);
	const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
	const [selectedLieferant, setSelectedLieferant] = useState({});
	const [otherLieferanten, setOtherLieferanten] = useState([]);
	const [selectedOtherLieferant, setSelectedOtherLieferant] = useState();
	const [selectedName, setSelectedName] = useState('');
	const [filteredNamen, setFilteredNamen] = useState([]);
	const [selectedEmail, setSelectedEmail] = useState('');
	const [filteredEmail, setFilteredEmail] = useState([]);
	const [selectedTelNr, setSelectedTelNr] = useState('');
	const [filteredTelNr, setFilteredTelNr] = useState([]);
	const [selectedHandy1, setSelectedHandy1] = useState('');
	const [filteredHandy1, setFilteredHandy1] = useState([]);
	const [selectedAdresse, setSelectedAdresse] = useState('');
	const [filteredAdressen, setFilteredAdressen] = useState([]);
	const [selectedOrt, setSelectedOrt] = useState('');
	const [filteredOrte, setFilteredOrte] = useState([]);

	const onNewLieferantClick = () => history.push('/lieferant/neu');

	const onRowClicked = e => {
		if (selectedLieferantElement !== e.originalEvent.target.parentElement) {
			let className = e.originalEvent.target.parentElement.className;
			if (selectedLieferantElement !== undefined && selectedLieferantElement !== null)
				selectedLieferantElement.className = selectedLieferantElement.className.replace(' p-highlight', '');
			setSelectedLieferantElement(e.originalEvent.target.parentElement);
				e.originalEvent.target.parentElement.className = className + " p-highlight";
		}
	}

	const lieferantenExportieren = () => {
		setShowSpinner(true);
		exportService.downloadLieferantenliste(Object.entries(exportOptions).filter(option => option[1]).map(option => option[0])).then(() => {
			setShowSpinner(false);
		}).catch(() => {
			setShowSpinner(false);
			toastRef?.current?.show({severity:'error', summary: 'Fehler', detail:'Exportieren fehlgeschlagen', life: 2000});
		});
	};

	const onEditClick = rowData => history.push("/lieferant/bearbeiten/"+rowData.ID);

	const onDeleteClick = rowData => {
		setSelectedLieferant(rowData);
		setOtherLieferanten(lieferanten.filter(l => l.ID !== rowData.ID));
		setSelectedOtherLieferant()
		setDeleteDialogVisible(true);
	};

	const onDeleteConfirmed = async () => {
		setDeleteDialogVisible(false);
		await lieferantenService.delete(selectedLieferant.ID, selectedOtherLieferant.ID);
		lieferantenService.getAll().then(data => setLieferanten(data));
	};

	useEffect(() => {
		setGlobalState({...globalState, page: 'Lieferanten'});
		lieferantenService.getAll().then(data => setLieferanten(data));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const renderHeader = () => {
		return (
			<div>
				<div className="grid">
					<InputText className="col" type="search" onInput={e => setGlobalFilter(e.target.value)} placeholder="Globale Suche" />
				</div>
			</div>
		);
	};

	const actionBodyTemplate = rowData => {
		return (
			<React.Fragment>
				<Button tooltipOptions={{position: 'bottom'}} tooltip="Lieferant bearbeiten" type="button" icon="pi pi-pencil" className="p-button-secondary mr-1" onClick={() => onEditClick(rowData)}></Button>
				<Button tooltipOptions={{position: 'bottom'}} tooltip="Lieferant löschen" type="button" icon="pi pi-trash" className="p-button-danger mr-1" onClick={() => onDeleteClick(rowData)}></Button>
			</React.Fragment>
		);
	};

	function onlyUnique(value, index, self) {
		return self.indexOf(value) === index;
	}

    const namenFilter = () => {
        return (
            <AutoComplete dropdown forceSelection value={selectedName} suggestions={filteredNamen} completeMethod={filterNamen}
            placeholder="Name" minLength={1}
			onChange={(e) => {dt.current.filter(e.value, 'Name', 'contains'); setSelectedName(e.value)}}
            inputStyle={{width: '100%'}}
            style={{width: '100%'}} />
        );
    }

	const filterNamen = (event) => {
        let results = lieferanten.filter((_lieferanten) => {
            return _lieferanten.Name.toLowerCase().includes(event.query.toLowerCase());
        });
        results = results.map(function(lieferant) {
           return lieferant.Name; 
        });

		results = results.filter(onlyUnique);

        setFilteredNamen(results);
    }

	const emailFilter = () => {
        return (
            <AutoComplete dropdown forceSelection value={selectedEmail} suggestions={filteredEmail} completeMethod={filterEmail}
            placeholder="Email" minLength={1}
			onChange={(e) => {dt.current.filter(e.value, 'Email', 'contains'); setSelectedEmail(e.value)}}
            inputStyle={{width: '100%'}}
            style={{width: '100%'}} />
        );
    }

	const filterEmail = (event) => {
        let results = lieferanten.filter((lieferant) => {
            return lieferant.Email?.toLowerCase().includes(event.query.toLowerCase());
        });
        results = results.map(function(lieferant) {
           return lieferant.Email; 
        });

		results = results.filter(onlyUnique);

        setFilteredEmail(results);
    }

	const telFilter = () => {
		return (
            <AutoComplete dropdown forceSelection value={selectedTelNr} suggestions={filteredTelNr} completeMethod={filterTelNr}
            placeholder="TelNr" minLength={1}
			onChange={(e) => {dt.current.filter(e.value, 'TelNr', 'contains'); setSelectedTelNr(e.value)}}
            inputStyle={{width: '100%'}}
            style={{width: '100%'}} />
        );
	}

	const filterTelNr = (event) => {
        let results = lieferanten.filter((lieferant) => {
            return lieferant.TelNr?.toLowerCase().includes(event.query.toLowerCase());
        });
        results = results.map(function(lieferant) {
           return lieferant.TelNr; 
        });

		results = results.filter(onlyUnique);

        setFilteredTelNr(results);
    }

	const handy1Filter = () => {
		return (
            <AutoComplete dropdown forceSelection value={selectedHandy1} suggestions={filteredHandy1} completeMethod={filterHandy1}
            placeholder="Handy" minLength={1}
			onChange={(e) => {dt.current.filter(e.value, 'Handy1', 'contains');
			setSelectedHandy1(e.value)}}
            inputStyle={{width: '100%'}}
            style={{width: '100%'}} />
        );
	}

	const filterHandy1 = (event) => {
        let results = lieferanten.filter((lieferant) => {
            return lieferant.Handy1?.toLowerCase().includes(event.query.toLowerCase());
        });
        results = results.map(function(lieferant) {
           return lieferant.Handy1; 
        });

		results = results.filter(onlyUnique);

        setFilteredHandy1(results);
    }

	const adressFilter = () => {
		return (
            <AutoComplete dropdown forceSelection value={selectedAdresse} suggestions={filteredAdressen} completeMethod={filterAdresse}
            placeholder="Adresse" minLength={1}
			onChange={(e) => {dt.current.filter(e.value, 'Adresse', 'contains');
			setSelectedAdresse(e.value)}}
            inputStyle={{width: '100%'}}
            style={{width: '100%'}} />
        );
	}

	const filterAdresse = (event) => {
        let results = lieferanten.filter((lieferant) => {
            return lieferant.Adresse?.toLowerCase().includes(event.query.toLowerCase());
        });
        results = results.map(function(lieferant) {
           return lieferant.Adresse; 
        });

		results = results.filter(onlyUnique);

        setFilteredAdressen(results);
    }

	const ortFilter = () => {
		return (
            <AutoComplete dropdown forceSelection value={selectedOrt} suggestions={filteredOrte} completeMethod={filterOrt}
            placeholder="Ort" minLength={1}
			onChange={(e) => {dt.current.filter(e.value, 'Ort', 'contains');
			setSelectedOrt(e.value)}}
            inputStyle={{width: '100%'}}
            style={{width: '100%'}} />
        );
	}

	const filterOrt = (event) => {
        let results = lieferanten.filter((lieferant) => {
            return lieferant.Ort?.toLowerCase().includes(event.query.toLowerCase());
        });
        results = results.map(function(lieferant) {
           return lieferant.Ort; 
        });

		results = results.filter(onlyUnique);

        setFilteredOrte(results);
    }


	const header = renderHeader();

	return (
		<React.Fragment>
			<Toast ref={toastRef}/>
			<div className="grid">
				<div className="col-6">
					<Button label="neuer Lieferant" icon="pi pi-plus-circle" onClick={onNewLieferantClick} />
				</div>
				<div className="col-6" style={{textAlign: 'right'}}>
					<Button label="Lieferanten exportieren" icon="pi pi-download" onClick={() => {setExportOptions({
						ID: false,
						Name: false,
						Ort: false,
						PLZ: false,
						Adresse: false,
						Telefon: false,
						Email: false,
						UIDNR: false,
						FNNR: false
					}); setExportDialogVisible(true)}}/>
				</div>
				<div className="col-12">
					<DataTable
						ref={dt} value={lieferanten}
						filterDisplay="row"
						onValueChange={filteredData => setFilteredLieferanten(filteredData)}
						header={header} responsive className="p-datatable-striped" dataKey="id"
						rowHover globalFilter={globalFilter} paginator rows={8}
						emptyMessage="keine Lieferanten gefunden" currentPageReportTemplate='({first} von {totalRecords})'
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
						rowsPerPageOptions={[8,20,30,40]}
						onRowClick={onRowClicked}
						>
						<Column
							field="ID"
							sortable filter header="ID"
							bodyStyle={{display: "none"}}
							headerStyle={{display:"none"}}
							filterHeaderStyle={{display:"none"}}
							/>
						<Column
							field="Name"
							filter
							filterPlaceholder="Name eingeben"
							sortable header="Name"
							filterMatchMode="contains"
							showFilterMenu={false}
							filterElement={namenFilter}
							/>
						<Column
							field="Email" filter filterPlaceholder="E-Mail eingeben" sortable header="E-Mail"
							showFilterMenu={false} filterMatchMode="contains"
							body={(row) => {
								var mailto = "mailto:" + row.Email;
								return <a href={mailto}>{row.Email}</a>;
							}}
							filterElement={emailFilter}
							/>
						<Column
							field="TelNr" filter
							filterPlaceholder="Telefonnummer eingeben"
							sortable header="Telefonnummer" filterMatchMode="contains"
							filterElement={telFilter}
							showFilterMenu={false}
							/>
						<Column field="Handy1" filter filterPlaceholder="Handynummer eingeben"
							sortable header="Handynummer" filterMatchMode="contains"
							filterElement={handy1Filter}
							showFilterMenu={false}/>
						<Column
							field="Adresse" filter filterPlaceholder="Adresse wählen"
							sortable header="Adresse" filterMatchMode="contains"
							filterElement={adressFilter}
							showFilterMenu={false}/>
						<Column
							field="Ort" filter
							filterPlaceholder="Ort wählen" sortable header="Ort"
							filterMatchMode="contains" showFilterMenu={false}
							filterElement={ortFilter}
							/>
						<Column body={actionBodyTemplate} headerStyle={{width: '10em', maxWidth: '10em', textAlign: 'center'}} bodyStyle={{width: '10em', maxWidth: '10em', textAlign: 'center', overflow: 'visible'}}/>
					</DataTable>
				</div>
			</div>
			<Dialog visible={exportDialogVisible} onHide={() => setExportDialogVisible(false)} header="Lieferanten exportieren" footer={<React.Fragment><Button label="Lieferanten exportieren" icon="pi pi-download" onClick={() => lieferantenExportieren()}/>{showSpinner ? <i className="pi pi-spin pi-spinner ml-2" style={{fontSize: '2rem'}}></i> : <React.Fragment></React.Fragment>}</React.Fragment>}>
				<div className="field">
					<Checkbox inputId="ExportID" checked={exportOptions.ID} onChange={e => setExportOptions({...exportOptions, ID: e.target.checked})} />
					<label htmlFor="ExportID" className="mb-0 ml-1">ID</label><br/>
				</div>
				<div className="field">
					<Checkbox inputId="ExportName" checked={exportOptions.Name} onChange={e => setExportOptions({...exportOptions, Name: e.target.checked})} />
					<label htmlFor="ExportName" className="mb-0 ml-1">Name</label><br/>
				</div>
				<div className="field">
					<Checkbox inputId="ExportOrt" checked={exportOptions.Ort} onChange={e => setExportOptions({...exportOptions, Ort: e.target.checked})} />
					<label htmlFor="ExportOrt" className="mb-0 ml-1">Ort</label><br/>
				</div>
				<div className="field">
					<Checkbox inputId="ExportPLZ" checked={exportOptions.PLZ} onChange={e => setExportOptions({...exportOptions, PLZ: e.target.checked})} />
					<label htmlFor="ExportPLZ" className="mb-0 ml-1">PLZ</label><br/>
				</div>
				<div className="field">
					<Checkbox inputId="ExportAdresse" checked={exportOptions.Adresse} onChange={e => setExportOptions({...exportOptions, Adresse: e.target.checked})} />
					<label htmlFor="ExportAdresse" className="mb-0 ml-1">Adresse</label><br/>
				</div>
				<div className="field">
					<Checkbox inputId="ExportTelefon" checked={exportOptions.Telefon} onChange={e => setExportOptions({...exportOptions, Telefon: e.target.checked})} />
					<label htmlFor="ExportTelefon" className="mb-0 ml-1">Telefon</label><br/>
				</div>
				<div className="field">
					<Checkbox inputId="ExportEmail" checked={exportOptions.Email} onChange={e => setExportOptions({...exportOptions, Email: e.target.checked})} />
					<label htmlFor="ExportEmail" className="mb-0 ml-1">E-Mail</label><br/>
				</div>
				<div className="field">
					<Checkbox inputId="ExportUIDNR" checked={exportOptions.UIDNR} onChange={e => setExportOptions({...exportOptions, UIDNR: e.target.checked})} />
					<label htmlFor="ExportUIDNR" className="mb-0 ml-1">UID Nummer</label><br/>
				</div>
				<div className="field">
					<Checkbox inputId="ExportFNNR" checked={exportOptions.FNNR} onChange={e => setExportOptions({...exportOptions, FNNR: e.target.checked})} />
					<label htmlFor="ExportFNNR" className="mb-0 ml-1">FN Nummer</label><br/>
				</div>
				<Button label="Alle auswählen" onClick={() => setExportOptions({
					ID: true,
					Name: true,
					Ort: true,
					PLZ: true,
					Adresse: true,
					Telefon: true,
					Email: true,
					UIDNR: true,
					FNNR: true
				})}/>
			</Dialog>
			<Dialog visible={deleteDialogVisible} onHide={() => setDeleteDialogVisible(false)} header={'Lieferant "' + selectedLieferant.Name + '" Löschen'} footer={<Button label="Bestätigen" icon="pi pi-trash" disabled={selectedOtherLieferant === undefined || selectedOtherLieferant === null} onClick={onDeleteConfirmed}/>}>
				Bitte geben Sie an an welchen anderen Lieferant<br/>
				die Produkte übertragen werden sollen.<br/>
				<br/>
				<Dropdown value={selectedOtherLieferant} options={otherLieferanten} optionLabel="Name" placeholder="Lieferant" onChange={e => setSelectedOtherLieferant(e.target.value)}/>
			</Dialog>
		</React.Fragment>
	);
}
export default Lieferanten;