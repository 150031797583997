import React, { useState, useEffect, useContext } from 'react';
import { GlobalState } from './GlobalState';
import { WarenbestandService } from '../service/WarenbestandService';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function Warenbestand2() {
	const warenbestandService = new WarenbestandService();

	const [globalState, setGlobalState] = useContext(GlobalState);
	const [warenbestand, setWarenbestand] = useState([]);
	const [showOnlyKritisch, setShowOnlyKritisch] = useState(false);
	const [hideZero, setHideZero] = useState(false);
	const [warenbestandDisplay, setWarenbestandDisplay] = useState([]);

	useEffect(() => {
		setGlobalState({...globalState, page: 'Warenbestand'});
		warenbestandService.getAll().then(data => setWarenbestand(data));
	}, []);

	useEffect(() => {
		let bestand = warenbestand;
		if (hideZero) bestand = bestand.filter(ware => ware.SollBestand !== 0);
		if (showOnlyKritisch) bestand = bestand.filter(ware => ware.Bestand < ware.WarnBestand);
		setWarenbestandDisplay(bestand.map(ware => ({...ware, AufzMenge: Math.max(ware.SollBestand - ware.Bestand, 0)})));
	}, [warenbestand, showOnlyKritisch, hideZero]);

	return (
		<React.Fragment>
			<div className="grid p-component">
				<div className="col-12 grid">
					<div className="col-12 lg:col-6">
						<Checkbox className="ml-2 mt-2" style={{alignSelf: 'center'}} inputId="showOnlyKritisch" checked={showOnlyKritisch} onChange={e => setShowOnlyKritisch(e.target.checked)}/>
						<label className="ml-1 mt-2" style={{alignSelf: 'center'}} htmlFor="showOnlyKritisch">nur Kritische Warenbestände anzeigen</label>
					</div>
					<div className="col-12 lg:col-6">
						<Checkbox className="ml-2 mt-2" style={{alignSelf: 'center'}} inputId="hideZero" checked={hideZero} onChange={e => setHideZero(e.target.checked)}/>
						<label className="ml-1 mt-2" style={{alignSelf: 'center'}} htmlFor="hideZero">keine Warenbestände mit einen Soll-Bestand von 0 anzeigen</label>
					</div>
				</div>
				<div className="col-12">
					<DataTable value={warenbestandDisplay} responsive className="p-datatable-striped" rowHover paginator rows={20} emptyMessage="keine Produkte gefunden" currentPageReportTemplate='({first} von {totalRecords})' paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[20,30,40]} autoLayout>
						<Column field="Bezeichnung" sortable filter header="Bezeichnung" filterMatchMode="contains"/>
						<Column field="Bestand" style={{textAlign: "right"}} sortable header="Ist-Bestand" body={row => <React.Fragment>{row.Bestand?.toLocaleString('de')}</React.Fragment>}/>
						<Column field="SollBestand" style={{textAlign: "right"}} sortable header="Soll-Bestand" body={row => <React.Fragment>{row.SollBestand?.toLocaleString('de')}</React.Fragment>}/>
						<Column field="AufzMenge" style={{textAlign: "right"}} sortable header="Aufz. Menge" body={row => <React.Fragment>{row.AufzMenge?.toLocaleString('de')}</React.Fragment>}/>
					</DataTable>
				</div>
			</div>
		</React.Fragment>
	);
}