import React, { useState, useRef, useEffect } from "react";
import { LogService } from '../service/LogService';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import { ZoomPopup } from './ZoomPopup';

/*
props: kassen, selectedKassen, umsatzVerlaufBereich
*/
export function LogDiagramm(props) {

    const logService = new LogService();
	const logChartRef = useRef(null);
    const [umsatzVerlaufOffset, setUmsatzVerlaufOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [dataSet, setDataSet] = useState([]);
    const [selectedTagMenge, setSelectedTagMenge] = useState(7);

    const formatDate = (value) => {
        return value?.toLocaleDateString('de', { day: "2-digit", month: "2-digit", year: "numeric" });
    }

    useEffect(() => {
		if (!Array.isArray(props.umsatzVerlaufBereich) || !(props.umsatzVerlaufBereich[0] instanceof Date) || !(props.umsatzVerlaufBereich[1] instanceof Date)) return;

		let kassenIds = props.selectedKassen;
		if (kassenIds === undefined || kassenIds === null || kassenIds.length === 0) {
			kassenIds = props.kassen.map(k => k.KassenID);
		}
        console.log(props.umsatzVerlaufBereich);
		setLoading(true);

        logService.getLogVonBis(kassenIds, props.umsatzVerlaufBereich[0], props.umsatzVerlaufBereich[1]).then(data => (setDataSet(data), console.log(data))).finally(() => setLoading(false));
		
	}, [props.umsatzVerlaufBereich]);

    const download = (url, name) => {
		const a = window.document.createElement('a');
		a.href = url;
		a.download = name;
		window.document.body.append(a);
		a.click();
		a.remove();
	};

    useEffect(() => {
        try {
            let Difference_In_Time = props.umsatzVerlaufBereich[1].getTime() - props.umsatzVerlaufBereich[0].getTime();
            let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            setSelectedTagMenge(Difference_In_Days);
        } catch(e) {}
    }, [selectedTagMenge])

    useEffect(() => {
		const now = new Date();
		const year = now.getFullYear();
		const month = now.getMonth();
		const day = now.getDate();
		props.setUmsatzVerlaufBereich([
			new Date(year, month, (day + selectedTagMenge * umsatzVerlaufOffset) - selectedTagMenge + 1),
			new Date(year, month, day + selectedTagMenge * umsatzVerlaufOffset)
		]);
	}, [selectedTagMenge, props.selectedKassen, props.kassen, umsatzVerlaufOffset]);

    const renderPopupFooter = () => {
        return(
            <React.Fragment>
                <Button label="Download" icon="pi pi-image"
                onClick={() => download(logChartRef.current.getBase64Image(),
                'Fehlerübersicht ' +
                (props.umsatzVerlaufBereich?.[0]?.getTime() === props.umsatzVerlaufBereich?.[1]?.getTime() ?
                formatDate(props.umsatzVerlaufBereich?.[0]) : formatDate(props.umsatzVerlaufBereich?.[0]) + "-" + formatDate(props.umsatzVerlaufBereich?.[1]))
                 + '.png')}/>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            { loading && <i className="pi pi-spin pi-spinner" style={{fontSize: '2rem', position: "absolute", left: "calc(50% - 1rem)", top: "calc(50% - 1rem)"}}/> }
            <ZoomPopup header={<div style={{textAlign: "center", position: "relative"}}>
                <span style={{position: "absolute", left: "0px"}}>Fehlerübersicht ausgewählter Zeitraum</span><div style={{display: "inline-block"}}>
                    <Button className="p-button-secondary p-button-text" icon="pi pi-angle-left" style={{padding: "0px"}}
                        onClick={() => setUmsatzVerlaufOffset(umsatzVerlaufOffset - 1)}/>
                    {props.umsatzVerlaufBereich?.[0]?.getTime() === props.umsatzVerlaufBereich?.[1]?.getTime() ? formatDate(props.umsatzVerlaufBereich?.[0]) : props.umsatzVerlaufBereich?.[0]?.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }) + " - " + props.umsatzVerlaufBereich?.[1]?.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" })}<Button className="p-button-secondary p-button-text" icon="pi pi-angle-right" style={{padding: "0px"}} onClick={() => setUmsatzVerlaufOffset(umsatzVerlaufOffset + 1)}/></div></div>}
                footer={renderPopupFooter}>
                <Chart ref={logChartRef} type="bar" data={dataSet}
                options={{animation: {duration: 0}, plugins: {legend: {position: 'bottom'}}}}/>
            </ZoomPopup>
        </React.Fragment>
    );
}

export default LogDiagramm;