import React from 'react';

export const formatCurrency = (value) => {
    return (
        <React.Fragment>
            <div style={{textAlign: "left"}}>
                &euro;&nbsp;
                <span style={{display: "inline-block", textAlign: "right", minWidth: "4rem"}}>
                {value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
                })
                }
                </span>
            </div>
        </React.Fragment>
    );
}