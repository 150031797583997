import axios from 'axios';
import { GetAxiosConfig } from './AuthService';
import CONFIG from 'config';

export class Wareneingangservice {
    async new(data) {
        console.log(data);
        return axios.post(CONFIG.ServerUrl + '/Wareneingang/App/New', {
           data
          }, await GetAxiosConfig())
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
    }
    
    async newServer(url, data) {
      if(!url.startsWith('http')) url = 'http://' + url;
      return axios.post(url + '/Wareneingänge/New', data, await GetAxiosConfig()).then(res => res.data);
    }

    async getDetails(kasse, produkt) {
      return axios.get(CONFIG.ServerUrl + '/Wareneingang/Details/' + kasse + '/' + produkt + '/10', await GetAxiosConfig()).then(res => res.data);
    }

    async GetAddWareneingang(eingang, url)
    {
      return axios.post(url+ '/Wareneingang/GetAdd', eingang, await GetAxiosConfig()).then(res => res.data);
    }
}