import React, { useState, useEffect, useRef, useContext, createRef } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { RabattService } from "../service/RabattService";
import { BelegeService } from "../service/BelegeService";
import { ExportService } from '../service/ExportService';
import { GlobalState } from './GlobalState';
import { Calendar } from 'primereact/calendar';
import RabattAuswahlDialog from './RabattAuswahlDialog';
import { EtikettenService } from '../service/EtikettenService';
import { KassenService } from '../service/KassenService';
import { SelectButton } from 'primereact/selectbutton';
import { AutoComplete } from 'primereact/autocomplete';
import { FilterService } from 'primereact/api';
import { InputText } from "primereact/inputtext";
import { Dialog } from 'primereact/dialog';

export default function Rabatte()
{
    const rabattService = new RabattService();
    const kassenService = new KassenService();
    const etikettenService = new EtikettenService();
    const belegeService = new BelegeService();
    const exportService = new ExportService();
    const [rabatte, setRabatte] = useState([]);
    const [globalFilter, setGlobalFilter] = useState([]);
    const [filteredRabatte, setFilteredRabatte] = useState([]);
    const [globalState, setGlobalState] = useContext(GlobalState);
    const [einlösDatumsbereich, setEinlösDatumsbereich] = useState([new Date(), new Date()]);
    const [ablaufDatumsbereich, setAblaufDatumsbereich] = useState([new Date(), new Date()]);
    const [ausstelldatum, setAusstelldatum] = useState([new Date(), new Date()]);
    const [rabattDialogVisible, setRabattDialogVisible] = useState(false);
    const [rabatt, setRabatt] = useState({});
    const [selectedGrund, setSelectedGrund] = useState([]);
    const [eingelöst, setEingelöst] = useState(1);
    const [rabatteDisplay, setRabatteDisplay] = useState([]);
    const [kassen, setKassen] = useState([]);
    const [selectedKassen, setSelectedKassen] = useState([]);
    const [rabattOptions, setRabattOptions] = useState([]);
    const [selectedRabatte, setSelectedRabatte] = useState([]);
    const [filteredBezeichnungen, setFilteredBezeichnungen] = useState([]);
    const [selectedBezeichnung, setSelectedBezeichnung] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [selectedProdukt, setSelectedProdukt] = useState();
    const [selectedProdukt2, setSelectedProdukt2] = useState();
    const [zeit, setZeit] = useState(null);
    const [zeitOption, setZeitOption] = useState(null);
    const [belegHtml, setBelegHtml] = useState('');
    const [belegDetailsOffen, setBelegDetailsOffen] = useState(false);
    const [selectedBeleg, setSelectedBeleg] = useState({});
    const [filters, setFilters] = useState({'Zeit': { value: null, matchMode: "timeFilter" }});

    FilterService.register("timeFilter", (a, b) => {
		if (a === null || a === undefined || zeit === null || zeit === undefined)
			return true;
		let value = a;
		let filter = zeit;
		const [valueHour, valueMinute, valueSecond] = value.split(':').map(str => {const n = Number(str); return isNaN(n) ? undefined : n});
		const valueTime = ((valueHour ?? 0) * 60 + (valueMinute ?? 0)) * 60 + (valueSecond ?? 0);
		const [filter1, filter2] = (filter ?? '').split('-').map(str => str.trim());
		const [filter1Hour, filter1Minute, filter1Second] = (filter1 ?? '').split(':').map(str => {const n = Number(str); return isNaN(n) ? undefined : n});
		const [filter2Hour, filter2Minute, filter2Second] = (filter2 ?? filter1).split(':').map(str => {const n = Number(str); return isNaN(n) ? undefined : n});
		const filter1Time = ((filter1Hour ?? 0) * 60 + (filter1Minute ?? 0)) * 60 + (filter1Second ?? 0);
		const filter2Time = ((filter2Hour ?? 23) * 60 + (filter2Minute ?? 59)) * 60 + (filter2Second ?? 59);
		return filter1Time <= valueTime && valueTime <= filter2Time;
	});

	const dt = useRef(null);

    useEffect(() => {
        setGlobalState({...globalState, page: 'Rabatte'});
        rabattService.getRabatte().then(data => {
            let _data = data.map(
                d => ({...d,
                    Ausstelldatum: new Date(d.Ausstelldatum)
                }));
            setRabatte(_data);
            setFilteredRabatte(_data);
            let unique = data
                .map(item => item.Rabatt)
                .filter((value, index, self) => self.indexOf(value) === index);
            setRabattOptions(unique);
        });
        kassenService.getKassen().then(data => setKassen(data));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (rabatte !== undefined && rabatte !== null)
        {
            try {
                if (eingelöst === 0)
                {
                    let filteredRabatte = rabatte.filter(function(value) {
                        return  (value.Einlösedatum !== undefined && value.Einlösedatum !== null);
                    });
                    setFilteredRabatte(filteredRabatte);
                    setRabatteDisplay(filteredRabatte.map(rabatt => ({...rabatt,
                        Ausstelldatum: new Date(rabatt.Ausstelldatum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}),
                        Zeit: new Date(rabatt.Ausstelldatum).toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit', second: undefined }),
                        Einlösedatum: rabatt.Einlösedatum ? new Date(rabatt.Einlösedatum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric', hour: "numeric", minute: "numeric"}) : null,
                        Ablaufdatum: rabatt.Ablaufdatum ? new Date(rabatt.Ablaufdatum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}) : null
                    })));
                }
                else if (eingelöst === 1)
                {
                    let filteredRabatte = rabatte.filter(function(value) {
                        return  !(value.Einlösedatum !== undefined && value.Einlösedatum !== null);
                    });
                    setFilteredRabatte(filteredRabatte);
                    let neueRabatte = filteredRabatte.map(rabatt => ({...rabatt,
                        Ausstelldatum: new Date(rabatt.Ausstelldatum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}),
                        Zeit: new Date(rabatt.Ausstelldatum).toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit', second: undefined }),
                        Einlösedatum: rabatt.Einlösedatum ? new Date(rabatt.Einlösedatum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}) : null,
                        Ablaufdatum: rabatt.Ablaufdatum ? new Date(rabatt.Ablaufdatum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}) : null
                    }));
                    setRabatteDisplay(neueRabatte);
                }
            } catch (e) {
                console.log(e);
            }
        }
    }, [eingelöst, rabatte])


    const onRowClicked = async e => {
        console.log(e);
        setSelectedProdukt2(e.data);
        if (selectedProdukt !== e.originalEvent.target.parentElement) {
            let className = e.originalEvent.target.parentElement.className;
            if (selectedProdukt !== undefined && selectedProdukt !== null)
                selectedProdukt.className = selectedProdukt.className.replace(' p-highlight', '');
            setSelectedProdukt(e.originalEvent.target.parentElement);
                e.originalEvent.target.parentElement.className = className + " p-highlight";
        }
        let BelegNr = e.data.BelegNr;
        let KassenID = e.data.KassenID;
        const dataPromise = belegeService.getDetails2(BelegNr, KassenID);
		//const qrCodePromise = belegeService.getQrCode(e.data.ID);
		const htmlPromise = exportService.belegHtml2(BelegNr, KassenID);
		const data = await dataPromise;
		//const qrCode = await qrCodePromise;
		const html = await htmlPromise;
		setSelectedBeleg(data);
		//setQrCode(URL.createObjectURL(qrCode));
		setBelegHtml(html);
		setBelegDetailsOffen(true);
	}

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const onDeleteClick = async (e) => {
        await rabattService.deleteRabatt(e.ID, e.KassenID);
        await delay(250);
        await rabattService.getRabatte().then(data => setRabatte(data));
    }

    const actionBodyTemplate = rowData => {
		return (
			<React.Fragment>
				{/* <Button tooltipOptions={{position: 'bottom'}} tooltip="Rabatt neu ausdrucken" type="button" icon="pi pi-print" className="p-button-secondary mr-1" onClick={() => onPrintClick(rowData)}></Button> */}
				<Button tooltipOptions={{position: 'bottom'}} tooltip="Rabatt löschen" type="button" icon="pi pi-trash" className="p-button-danger mr-1" onClick={() => onDeleteClick(rowData)}></Button>
			</React.Fragment>
		);
	};

    const onEinlösDatumsbereichChange = e => {
		setEinlösDatumsbereich(e.target.value);
        let _target0 = new Date(e.target.value[0]).setHours(0,0,0);
        let _target1 = new Date(e.target.value[1]).setHours(0,0,0);
        let _filteredRabatte = filteredRabatte.filter(function(value) {
            let _datum = new Date(value.Einlösedatum).setHours(0,0,0,0,0);
            let ret = _datum >= _target0 &&
                _datum <= _target1;
            return  ret;
        });
        setRabatteDisplay(_filteredRabatte.map(rabatt => ({...rabatt,
            Ausstelldatum: new Date(rabatt.Ausstelldatum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}),
            Zeit: new Date(rabatt.Ausstelldatum).toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit', second: undefined }),
            Einlösedatum: rabatt.Einlösedatum ? new Date(rabatt.Einlösedatum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric', hour: "numeric", minute: "numeric"}) : null,
            Ablaufdatum: rabatt.Ablaufdatum ? new Date(rabatt.Ablaufdatum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}) : null
        })));
	}

    const onAblaufDatumsbereichChange = e => {
		setAblaufDatumsbereich(e.target.value);
        let _target0 = new Date(e.target.value[0]).setHours(0,0,0);
        let _target1 = new Date(e.target.value[1]).setHours(0,0,0);
        let _filteredRabatte = filteredRabatte.filter(function(value) {
            let _datum = new Date(value.Ablaufdatum).setHours(0,0,0,0,0);
            let ret = _datum >= _target0 &&
                _datum <= _target1;
            return  ret;
        });
        setRabatteDisplay(_filteredRabatte.map(rabatt => ({...rabatt,
            Ausstelldatum: new Date(rabatt.Ausstelldatum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}),
            Zeit: new Date(rabatt.Ausstelldatum).toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit', second: undefined }),
            Einlösedatum: rabatt.Einlösedatum ? new Date(rabatt.Einlösedatum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric', hour: "numeric", minute: "numeric"}) : null,
            Ablaufdatum: rabatt.Ablaufdatum ? new Date(rabatt.Ablaufdatum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}) : null
        })));
	}

    const onAusstelldatumChange = e => {
		setAusstelldatum(e.target.value);
        let _target0 = new Date(e.target.value[0]).setHours(0,0,0);
        let _target1 = new Date(e.target.value[1]).setHours(0,0,0);
        let _filteredRabatte = filteredRabatte.filter(function(value) {
            let _ausstelldataum = new Date(value.Ausstelldatum).setHours(0,0,0,0,0);
            let ret = _ausstelldataum >= _target0 &&
                _ausstelldataum <= _target1;
            return  ret;
        });
        setRabatteDisplay(_filteredRabatte.map(rabatt => ({...rabatt,
            Ausstelldatum: new Date(rabatt.Ausstelldatum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}),
            Zeit: new Date(rabatt.Ausstelldatum).toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit', second: undefined }),
            Einlösedatum: rabatt.Einlösedatum ? new Date(rabatt.Einlösedatum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric', hour: "numeric", minute: "numeric"}) : null,
            Ablaufdatum: rabatt.Ablaufdatum ? new Date(rabatt.Ablaufdatum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}) : null
        })));
	}

    const renderAustellDatumFilter = () => {
		return (
            <Calendar id="ausstelldatumsberich" className="column-filter"
            value={ausstelldatum} onChange={onAusstelldatumChange} selectionMode="range"
            locale='de' showIcon={true}
            />
        );
	};

    const renderEinlösDatumFilter = () => {
		return (
            <Calendar id="einlösdatumsbereich" className="column-filter"
            value={einlösDatumsbereich} onChange={onEinlösDatumsbereichChange} selectionMode="range"
            locale='de' showIcon={true}
            />
        );
	};

    const renderAblaugDatumFilter = () => {
		return (
			<Calendar id="ablaufdatumsbereich" className="column-filter"
            value={ablaufDatumsbereich} onChange={onAblaufDatumsbereichChange} locale="de"
            showIcon={true} selectionMode="range"/>
        );
	};

	const einlösdatumFilter = renderEinlösDatumFilter();
    const ablaufdatumFilter = renderAblaugDatumFilter();
    const ausstelldatumFilter = renderAustellDatumFilter();

    const rabattButtonClick = (e) => {
        console.log("Rabatt "  + e);
        setRabattDialogVisible(false);
        let _rabatt = {
            ...rabatt,
            Rabatt: e.value,
        };
        setRabatt(_rabatt);
        rabattService.createRabatt(_rabatt);
    }

    const print = (e) => {
        etikettenService.printPreisEtikett(e, 1);
    }

    const renderGrundFilter = () => {
		return (
			<MultiSelect className="column-filter"
                value={selectedGrund} options={["MHD","Kundenanwerbung","Beschädigt","Abverkauf","Sonstiges"]}
                onChange={e => {
                    setSelectedGrund(e.target.value);
                    setFilters(prevFilter => ({...prevFilter, 'Grund': {value: e.target.value, matchMode: "in"}}));
                }}
                placeholder="Alle" maxSelectedLabels={1} />
		);
	};

    const renderKassenIDFilter = () => {
		return (
			<MultiSelect className="column-filter" value={selectedKassen} options={kassen} onChange={e => { setSelectedKassen(e.target.value); dt.current.filter(e.target.value, 'KassenID', 'in'); }} placeholder="Alle" optionLabel="KassenID" optionValue="KassenID" maxSelectedLabels={3}/>
		);
	};

    const renderRabattFilter = () => {
        return (
            <MultiSelect className="column-filter" value={selectedRabatte} options={rabattOptions} onChange={e => { setSelectedRabatte(e.target.value); dt.current.filter(e.target.value, 'Rabatt', 'in'); }} placeholder="Alle"/>
        );
    };

    const filterBezeichnung = (event) => {
        let _bezeichnungen = new Array();
        let results = filteredRabatte.filter((x) => {
            return x.Bezeichnung.toLowerCase().includes(event.query.toLowerCase());
        });
        results = results.forEach(element => {
            if (_bezeichnungen.findIndex(x => x == element.Bezeichnung) === -1)
                _bezeichnungen.push(element.Bezeichnung);
        });

        setFilteredBezeichnungen(_bezeichnungen);
    }

    const onBezeichnungChange = (event) => {

        setFilters(prevFilter => ({...prevFilter, 'Bezeichnung': {value: event.target.value, matchMode: "contains"}}));
        setSelectedBezeichnung(event.value);
    }

    const renderBezeichnungsFilter = () => {
        return (
            <AutoComplete dropdown forceSelection value={selectedBezeichnung} suggestions={filteredBezeichnungen} completeMethod={filterBezeichnung}
            placeholder="Bezeichnung" minLength={1} onChange={onBezeichnungChange}
            inputStyle={{width: '100%'}}
            style={{width: '100%'}} />
        );
    }

    const zeitFilter = (option) => {
		return <InputText value={zeit} onChange={(e) => {
			setZeit(e.target.value);
			setZeitOption(option);
		}} />
	}

    useEffect(() => {
		zeitOption?.filterCallback(zeit);
		zeitOption?.filterApplyCallback()
	}, [zeit])

	const grundFilter = renderGrundFilter();
    const kassenIDFilter = renderKassenIDFilter();
    const rabattFilter = renderRabattFilter();
    const bezeichnungsFilter = renderBezeichnungsFilter();

    function calculateCustomerTotal(name) {
        let total = 0;

        if (rabatteDisplay) {
            for (let rabatt of rabatteDisplay) {
                if (rabatt.Bezeichnung === name) {
                    total++;
                }
            }
        }

        return total;
    }

    const downloadBeleg = () => {
		exportService.downloadBeleg(selectedBeleg.ID);
	}

    return(
        <React.Fragment>
            <div className='grid'>
                <div className='col-12 p-component'>
                    <SelectButton value={eingelöst} options={[{value: 1, name: "aktiv"}, { value: 0, name: "eingelöst"}]}
                        optionLabel="name" optionValue="value"
                        onChange={(e) => setEingelöst(e.value)} />
                </div>
                <div className="col-12 p-component">
					<DataTable ref={dt} value={rabatteDisplay}
                        filterDisplay="row"
                        filters={filters}
                        dataKey="ID"
                        scrollable scrollDirection="both" scrollHeight='75vh' className="p-datatable-produkte p-datatable-striped" rowHover globalFilter={globalFilter}
                        paginator rows={20} emptyMessage="keine Rabatte gefunden" currentPageReportTemplate='({first} von {totalRecords})'
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[20,30,40]} onRowClick={onRowClicked}
                        expandableRowGroups expandedRows={expandedRows}
                        onRowToggle={((e) => { setExpandedRows(e.data); } )}
						sortOrder={1} sortMode="single" sortField={"Bezeichnung"}
                        rowGroupHeaderTemplate={(rowData) => <span>{rowData.Bezeichnung} ({calculateCustomerTotal(rowData.Bezeichnung)})</span>}
                        rowGroupFooterTemplate={() => <React.Fragment></React.Fragment>}
                        groupRowsBy="Bezeichnung"
                        onValueChange={filteredData => console.log(dt.current)}
                        rowGroupMode="subheader"
                        >
						<Column
                            field="Bezeichnung"
                            header="Bezeichnung"
                            style={{minWidth: "20em", width: "20em"}}
                            filter
                            filterPlaceholder="Bezeichnung eingeben"
                            filterElement={bezeichnungsFilter}
                            showFilterMenu={false}
                            filterClear={() => setSelectedBezeichnung(null)}
                            showClearButton={true}
                            sortable  
                        />
						<Column
                            field="Ausstelldatum" header="Erstelldatum"
                            style={{minWidth: "16em", width: "16em"}} 
                            filter filterElement={ausstelldatumFilter} showFilterMenu={false}
                            filterClear={() => setAusstelldatum(null)}
                            bodyStyle={{justifyContent: "center"}}
                            body={row => <span>{row.Ausstelldatum}</span>}
                            sortable 
                            />
                        <Column
                            field="Zeit" header="Zeit"
                            style={{minWidth: "10em", width: "10em"}}
                            filter showFilterMenu={false}
                            filterElement={zeitFilter}
                            filterClear={() => setZeit(null)}
                            bodyStyle={{justifyContent: "center"}}
                            body={row => <span>{row.Zeit}</span>}
                            sortable 
                            />
						<Column
                            field="Grund" header="Grund"
                            style={{minWidth: "10em", width: "10em"}}
                            filter
                            filterPlaceholder="Grund eingeben"
                            filterMatchMode="contains"
                            filterElement={grundFilter}
                            filterClear={() => setSelectedGrund(null)}
                            showFilterMenu={false} 
                            sortable
                            />
						{(eingelöst === 0) &&
                            <Column
                                field="Einlösedatum" header="Einlösedatum" 
                                style={{minWidth: "16em", width: "16em"}} 
                                headerStyle={{minWidth: "16em", width: "16em"}} 
                                bodyStyle={{minWidth: "16em", width: "16em", justifyContent: "center"}} 
                                filter
                                sortable
                                filterElement={einlösdatumFilter}
                                showFilterMenu={false} 
                                body={row => <span>{row.Einlösedatum}</span>}
                                />
                        }
                        <Column
                            field="Ablaufdatum" header="Ablaufdatum"
                            style={{minWidth: "16em", width: "16em"}}
                            bodyStyle={{justifyContent: "center"}}
                            body={row => <span>{row.Ablaufdatum}</span>}
                            filter sortable filterElement={ablaufdatumFilter} showFilterMenu={false} 
                            />
                        <Column
                            field="KassenID" header="Standort"
                            style={{minWidth: "10em", width: "10em"}}
                            filter filterPlaceholder="Kassa eingeben" filterMatchMode="contains" filterElement={kassenIDFilter} showFilterMenu={false} 
                            sortable 
                            />
                        <Column
                            field="Grundpreis" header="Grundpreis"
                            style={{minWidth: "8em", width: "8em"}}
                            body={row => <React.Fragment>&euro;<span style={{width: "100%", paddingRight: "2rem", textAlign:"right"}}>{row?.Grundpreis.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></React.Fragment>}
                            showFilterMenu={false} 
                            />
                        <Column
                            field="Rabatt" header="Rabatt"
                            style={{minWidth: "8em", width: "7em"}}
                            body={row => <React.Fragment><span style={{width: "100%", paddingRight: "3rem", textAlign:"right"}}>{row.Rabatt} %</span></React.Fragment>}
                            bodyStyle={{textAlign: 'right'}}
                            filter filterMatchMode="contains"  filterElement={rabattFilter} showFilterMenu={false} 
                            />
                        <Column
                            field="Rabattpreis" header="Rabattpreis"
                            style={{minWidth: "8em", width: "8em"}}
                            bodyStyle={{minWidth: "8em", width: "8em"}}
                            body={row => <React.Fragment>&euro;<span style={{width: "100%", paddingRight: "2rem", textAlign:"right"}}>{row?.Rabattpreis.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></React.Fragment>}
                            showFilterMenu={false} 
                            />
						{(eingelöst === 1) &&
                            <Column
                                body={actionBodyTemplate}
                                headerStyle={{width: '5em', textAlign: 'center'}}
                                style={{width: '5em'}}
                                bodyStyle={{width: '5em', textAlign: 'center', overflow: 'visible'}}
                                />
                        }
					</DataTable>
				</div>
            </div>

            <RabattAuswahlDialog visible={rabattDialogVisible} buttonClick={rabattButtonClick}
                                    Grund={rabatt.Grund}
                                    onGrundChange={(e) => setRabatt(prevRabatt => ({...prevRabatt, Grund: e.value }))}
                                    setVisible={(e) => setRabattDialogVisible(e)}
                                    onHide={() => setRabattDialogVisible(false)}
                                    Kassa={rabatt.KassenID}
                                    onKassaChange={(e) => setRabatt(prevRabatt => ({...prevRabatt, KassenID: e.value}))}
                                    />
            
            <Dialog header={
                    <React.Fragment><span style={{fontSize: '1.25em'}}>Beleg Details</span>
                        <Button style={{marginLeft: '1em', marginRight: '1em', float: 'right'}} icon="pi pi-file-pdf" label="Download" onClick={downloadBeleg}/>
                    </React.Fragment>
                }
				visible={belegDetailsOffen} onHide={() => setBelegDetailsOffen(false)}>
				<div style={{fontFamily: 'monospace'}} dangerouslySetInnerHTML={{__html: belegHtml}}></div>
            </Dialog>
        </React.Fragment>
    );
}