import React, { useRef, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalState } from './GlobalState';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { KundenService } from '../service/KundenService';
import { AutoComplete } from 'primereact/autocomplete'

export default function Kunden(props) {
	const kundenService = new KundenService();
	const history = useHistory();
	const dt = useRef(null);
	const [filteredKunden, setFilteredKunden] = useState([]);
	const [selectedVorname, setSelectedVorname] = useState('');
	const [filteredVorname, setFilteredVorname] = useState([]);
	const [selectedNachname, setSelectedNachname] = useState('');
	const [filteredNachname, setFilteredNachname] = useState([]);
	const [selectedTel, setSelectedTel] = useState('');
	const [filteredTels, setFilteredTels] = useState([]);
	const [selectedHandy1, setSelectedHandy1] = useState('');
	const [filteredHandy1, setFilteredHandy1] = useState([]);
	const [selectedEmail, setSelectedEmail] = useState('');
	const [filteredEmails, setFilteredEmails] = useState([]);
	const [selectedAdresse, setSelectedAdresse] = useState('');
	const [filteredAdressen, setFilteredAdressen] = useState([]);
	const [selectedOrt, setSelectedOrt] = useState('');
	const [filteredOrte, setFilteredOrte] = useState([]);

	const [globalState, setGlobalState] = useContext(GlobalState);
	const [kunden, setKunden] = useState([]);

	useEffect(() => {
		setGlobalState({...globalState, page: 'Kunden'});
		kundenService.getAll().then(data => { setKunden(data); setFilteredKunden(data)});
	}, []);

	const createKunde = () => history.push('/kunde/neu');

	const editKunde = rowData => history.push("/kunde/bearbeiten/" + rowData.Kundennummer);

	const deleteKunde = rowData => {
		kundenService.delete(rowData.Kundennummer).then(() => {
			kundenService.getAll().then(data => { setKunden(data); setFilteredKunden(data) });
		});
	};

	const vornameFilter = () => {
		return (
            <AutoComplete dropdown forceSelection value={selectedVorname} suggestions={filteredVorname} completeMethod={filterVorname}
            placeholder="Vorname" minLength={1}
			onChange={(e) => {dt.current.filter(e.value, 'Vorname', 'contains');
			setSelectedVorname(e.value)}}
            inputStyle={{width: '100%'}}
            style={{width: '100%'}} />
        );
	}

	const filterVorname = (event) => {
        let results = filteredKunden.filter((kunde) => {
            return kunde.Vorname?.toLowerCase().includes(event.query.toLowerCase());
        });
        results = results.map(function(kunde) {
           return kunde.Vorname; 
        });
        setFilteredVorname(results);
    }

	const nachnameFilter = () => {
		return (
            <AutoComplete dropdown forceSelection value={selectedNachname} suggestions={filteredNachname} completeMethod={filterNachname}
            placeholder="Nachname" minLength={1}
			onChange={(e) => {dt.current.filter(e.value, 'Nachname', 'contains');
			setSelectedNachname(e.value)}}
            inputStyle={{width: '100%'}}
            style={{width: '100%'}} />
        );
	}

	const filterNachname = (event) => {
        let results = filteredKunden.filter((kunde) => {
            return kunde.Nachname?.toLowerCase().includes(event.query.toLowerCase());
        });
        results = results.map(function(kunde) {
			if (kunde.Nachname?.length > 0)
           		return kunde.Nachname; 
        });
        setFilteredNachname(results);
    }

	const emailFilter = () => {
		return (
            <AutoComplete dropdown forceSelection value={selectedEmail} suggestions={filteredEmails} completeMethod={filterEmail}
            placeholder="Email" minLength={1}
			onChange={(e) => {dt.current.filter(e.value, 'EMail', 'contains');
			setSelectedEmail(e.value)}}
            inputStyle={{width: '100%'}}
            style={{width: '100%'}} />
        );
	}

	const filterEmail = (event) => {
        let results = filteredKunden.filter((kunde) => {
            return kunde.EMail?.toLowerCase().includes(event.query.toLowerCase());
        });
        results = results.map(function(kunde) {
			if (kunde.EMail?.length > 0)
          		return kunde.EMail; 
        });
        setFilteredEmails(results);
    }

	const telFilter = () => {
		return (
            <AutoComplete dropdown forceSelection value={selectedTel} suggestions={filteredTels} completeMethod={filterTel}
            placeholder="Telefon" minLength={1}
			onChange={(e) => {dt.current.filter(e.value, 'Telefon', 'contains');
			setSelectedTel(e.value)}}
            inputStyle={{width: '100%'}}
            style={{width: '100%'}} />
        );
	}

	const filterTel = (event) => {
        let results = filteredKunden.filter((kunde) => {
            return kunde.Telefon?.toLowerCase().includes(event.query.toLowerCase());
        });
        results = results.map(function(kunde) {
           return kunde.Telefon; 
        });
        setFilteredTels(results);
    }

	const handy1Filter = () => {
		return (
            <AutoComplete dropdown forceSelection value={selectedHandy1} suggestions={filteredHandy1} completeMethod={filterHandy1}
            placeholder="Handy" minLength={1}
			onChange={(e) => {dt.current.filter(e.value, 'Handy1', 'contains');
			setSelectedHandy1(e.value)}}
            inputStyle={{width: '100%'}}
            style={{width: '100%'}}
			/>
        );
	}

	const filterHandy1 = (event) => {
        let results = filteredKunden.filter((kunde) => {
            return kunde.Handy1?.toLowerCase().includes(event.query.toLowerCase());
        });
        results = results.map(function(kunde) {
           return kunde.Handy1; 
        });
        setFilteredHandy1(results);
    }

	const adresseFilter = () => {
		return (
            <AutoComplete dropdown forceSelection value={selectedAdresse} suggestions={filteredAdressen} completeMethod={filterAdresse}
            placeholder="Adresse" minLength={1}
			onChange={(e) => {dt.current.filter(e.value, 'Adresse', 'contains');
			setSelectedAdresse(e.value)}}
            inputStyle={{width: '100%'}}
            style={{width: '100%'}}
			/>
        );
	}

	const filterAdresse = (event) => {
        let results = filteredKunden.filter((kunde) => {
            return kunde.Adresse?.toLowerCase().includes(event.query.toLowerCase());
        });
        results = results.map(function(kunde) {
           return kunde.Adresse; 
        });
        setFilteredAdressen(results);
    }

	const ortFilter = () => {
		return (
            <AutoComplete
			dropdown forceSelection value={selectedOrt} suggestions={filteredOrte} completeMethod={filterOrt}
            placeholder="Orte" minLength={1}
			onChange={(e) => {dt.current.filter(e.value, 'Ort', 'contains');
			setSelectedOrt(e.value)}}
            inputStyle={{width: '100%'}}
            style={{width: '100%'}}
			/>
        );
	}

	const filterOrt = (event) => {
        let results = filteredKunden.filter((kunde) => {
            return kunde.Ort?.toLowerCase().includes(event.query.toLowerCase());
        });
        results = results.map(function(kunde) {
           return kunde.Ort; 
        });
        setFilteredOrte(results);
    }

	return (
		<React.Fragment>
			<div className="p-component grid">
				<div className="col-12">
					<Button label="neuer Kunde" icon="pi pi-plus-circle" onClick={createKunde}/>
				</div>
				<div className="col-12">
					<DataTable
						ref={dt}
						filterDisplay="row"
						value={kunden} className="p-datatable-striped" rowHover paginator
						rows={20} rowsPerPageOptions={[20,30,40]} scrollable scrollHeight="60vh">
						<Column field="Vorname" header="Vorname"
							sortable filter
							filterElement={vornameFilter}
							showFilterMenu={false}/>
						<Column field="Nachname" header="Nachname"
							filterElement={nachnameFilter}
							sortable filter showFilterMenu={false}/>
						<Column field="EMail" header="E-Mail"
							filterElement={emailFilter}
							sortable filter showFilterMenu={false}/>
						<Column field="Telefon" header="Telefonnummer"
							filterElement={telFilter}
							sortable filter showFilterMenu={false}/>
						<Column field="Handy1" header="Handynummer"
							filterElement={handy1Filter}
							sortable filter showFilterMenu={false}
							/>
						<Column field="Adresse" header="Adresse"
							filterElement={adresseFilter}
							sortable filter showFilterMenu={false}/>
						<Column field="Ort" header="Ort"
							filterElement={ortFilter}
							sortable filter showFilterMenu={false}/>
						<Column body={rowData =>
							<React.Fragment>
								<Button
									tooltipOptions={{position: 'bottom'}}
									tooltip="Kunde bearbeiten"
									icon="pi pi-pencil"
									className="p-button-secondary mr-1"
									onClick={() => editKunde(rowData)}/>
								<Button tooltipOptions={{position: 'bottom'}}
									tooltip="Kunde löschen"
									icon="pi pi-trash"
									className="p-button-danger mr-1"
									onClick={() => deleteKunde(rowData)}/>
							</React.Fragment>}
							headerStyle={{width: '10em', maxWidth: '10em'}}
							bodyStyle={{width: '10em', maxWidth: '10em', textAlign: 'center'}}/>
					</DataTable>
				</div>
			</div>
		</React.Fragment>
	);
}