import axios from "axios";
import { GetAxiosConfig } from "./AuthService";
import CONFIG from 'config';

export class EinstellungenService {
	async GetEinstellung(name) {
		return axios.get(CONFIG.ServerUrl + "/Einstellungen/" + name, await GetAxiosConfig()).then(res => res.data);
	}

	async SetEinstellung(name, value) {
		return axios.post(CONFIG.ServerUrl + "/Einstellungen/" + name, value, await GetAxiosConfig()).then(res => res.data);
	}
}