import axios from 'axios';
import { GetAxiosConfig } from './AuthService';
import CONFIG from 'config';

export class RabattService {
    async getRabatte() {
        return axios.get(CONFIG.ServerUrl + '/Rabatte', await GetAxiosConfig()).then(res => res.data);
    }

    async deleteRabatt(id, kassenid) {
        return axios.get(CONFIG.ServerUrl + '/Rabatt/Delete/'+id+'/Kassa/'+kassenid, await GetAxiosConfig()).then(res => res.data);
    }

    async createRabatt(e) {
        return axios.post(CONFIG.ServerUrl + '/Rabatte/Neu', e, await GetAxiosConfig()).then(res => res.data);
    }

    async appCreateRabatt(e) {
        return axios.post(CONFIG.ServerUrl + '/Rabatte/App/Neu', e, await GetAxiosConfig()).then(res => res.data);
    }

    async checkProdukt(id) {
        return axios.get(CONFIG.ServerUrl + '/Rabatt/CheckProdukt/'+id, await GetAxiosConfig()).then(res => res.data);
        
    }
}