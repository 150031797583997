import React, { useState, useEffect, useRef, useContext } from 'react';
import { Card } from 'primereact/card';
import { KassenService } from '../service/KassenService';
import { UmsatzService } from '../service/UmsatzService';
import { DashboardService } from '../service/DashboardService';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Chart } from 'primereact/chart';
import { GlobalState } from './GlobalState';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { SyncService } from '../service/SyncService';
import { ZoomPopup } from './ZoomPopup';
import { SelectButton } from 'primereact/selectbutton';
import { addDE } from "./LocalDE";
import { useLocalStorage } from "../helper/localStorageState";
import { Dialog } from 'primereact/dialog';
import { AuthService } from '../service/AuthService';

function Dashboard(props) {
	addDE();

	const kassenService = new KassenService();
	const umsatzService = new UmsatzService();
	const syncService = new SyncService();
	const dashboardService = new DashboardService();
	const authService = new AuthService();
	const toastRef = useRef(null);
	const lineChartRef = useRef(null);
	const barChartRef = useRef(null);


	const [kassen, setKassen] = useState([]);
	const [kassenMitUrl, setKassenMitUrl] = useState([]);
	const [kassenOnline, setKassenOnline] = useState({});
	let [kassenDetails, setKassenDetails] = useState({});
	const [kassenAnzahlOnline, setKassenAnzahlOnline] = useState(0);
	const [kassenAnzahlOffline, setKassenAnzahlOffline] = useState(0);
	const [serverDetails, setServerDetails] = useState({});
	let [syncInProgress, setSyncInProgress] = useState({});
	const [selectedTagMenge, setSelectedTagMenge] = useState(7);
	const [selectedKassen2, setSelectedKassen2] = useState([]);
	const [lineChartDatasets, setLineChartDatasets] = useState([]);
	const [selectedKassen3, setSelectedKassen3] = useState([]);
	const [barChartDatasets, setBarChartDatasets] = useState([]);
	const [globalState, setGlobalState] = useContext(GlobalState);
	const [syncPossible, setSyncPossible] = useState(true);
	const [selectedKassen5, setSelectedKassen5] = useState([]);
	const [selectedKassen6, setSelectedKassen6] = useState([]);
	const [dashboardUmsatzData, setDashboardUmsatzData] = useState({});
	const [dashboardBelegeData, setDashboardBelegeData] = useState({});
	const [netto1, setNetto1] = useLocalStorage("netto1", true);
	const [netto2, setNetto2] = useLocalStorage("netto2", true);
	const [loading01, setLoading01] = useState(false);
	const [loading02, setLoading02] = useState(false);
	const [loading03, setLoading03] = useState(false);
	const [loading04, setLoading04] = useState(false);
	const [loading05, setLoading05] = useState(false);
	const [loading06, setLoading06] = useState(false);
	const [umsatzDay, setUmsatzDay] = useState(new Date());
	const [belegeDay, setBelegeDay] = useState(new Date());
	const [umsatzVerlaufOffset, setUmsatzVerlaufOffset] = useState(0);
	const [umsatzVerlaufBereich, setUmsatzVerlaufBereich] = useState();
	const [vorwocheOffset, setVorwocheOffset] = useState(0);
	const [forceSyncSelectedKassa, setForceSyncSelectedKassa] = useState();
	const [forceSyncDialogVisible, setForceSyncDialogVisible] = useState(false);
	const [roles, setRoles] = useState([]);

	const kassenMitUrlRef = useRef(kassenMitUrl);
	const kassenOnlineRef = useRef(kassenOnline);

	const download = (url, name) => {
		const a = window.document.createElement('a');
		a.href = url;
		a.download = name;
		window.document.body.append(a);
		a.click();
		a.remove();
	};

	async function IsOnline(url) {
		try {
			if(!url.startsWith('http')) url = 'http://' + url;
			await fetch(url, { mode: "no-cors" });
			return true;
		} catch {
			return false;
		}
	}

	function GetDatumsVerlauf(bis, count) {
		if (count === 1) {
			return Array.from(new Array(24), (_, i) => i.toString().padStart(2, "0") + ":00");
		} else {
			const year = bis.getFullYear();
			const month = bis.getMonth();
			const day = bis.getDate() - count + 1;
			const output = [];
			for (let offset = 0; offset < count; offset++) {
				output.push(new Date(year, month, day + offset).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}));
			}
			return output;
		}
	}

	function SimpleStringHash(str) {//https://stackoverflow.com/questions/17083316/hashing-a-string-between-two-integers-with-a-good-distribution-uniform-hash
		let hash = 0;
		for (let i = 0; i < str.length; i++) {
			hash += str.charCodeAt(i);
		}
		return hash;
	}

	function Load() {
		let kassenAnzahlOnline = 0;
		setKassenAnzahlOnline(kassenAnzahlOnline);
		let kassenAnzahlOffline = 0;
		setKassenAnzahlOffline(kassenAnzahlOffline);
		for (const kasse of kassenMitUrlRef.current) {
			IsOnline(kasse.url).then(online => {
				kassenOnlineRef.current[kasse.KassenID] = online;
				setKassenOnline({...kassenOnlineRef.current});
				if (online) {
					setLoading02(true);
					kassenService.getDetails(kasse.url, kasse.KassenID).then(data => {
						kassenDetails = {...kassenDetails, [kasse.KassenID]: data};
						setKassenDetails(kassenDetails);
						setLoading02(false);
					}).catch(() => setLoading02(false));
					kassenAnzahlOnline++;
					setKassenAnzahlOnline(kassenAnzahlOnline);
				} else {
					kassenAnzahlOffline++;
					setKassenAnzahlOffline(kassenAnzahlOffline);
				}
			});
		}
		LoadSync();
	}

	function LoadSync() {
		setLoading01(true);
		syncService.syncStatus().then(data => setServerDetails(
			Object.fromEntries(data.map(sync => [sync.KassenID, {SyncDatum: sync.Zeit, LetzterSyncErfolgreich: sync.Status === 1}]))))
			.finally(() => setLoading01(false));
	}

	const syncServer = () => {
		setSyncPossible(false);
		Promise.allSettled(kassenMitUrl.map(kasse => {
			syncInProgress[kasse.KassenID] = true;
			setSyncInProgress({...syncInProgress});
			return syncService.syncKasse(kasse.KassenID).then(() => {
				console.log('Sync erfolgreich ' + kasse.KassenID);
				syncInProgress[kasse.KassenID] = false;
				setSyncInProgress({...syncInProgress});
				LoadSync();
			}).catch(() => {
				console.log('Sync fehlgeschlagen ' + kasse.KassenID);
				syncInProgress[kasse.KassenID] = false;
				setSyncInProgress({...syncInProgress});
				LoadSync();
			});
		})).then(() => {
			setSyncPossible(true);
		});
	}

	useEffect(() => {
		let kassenIds = selectedKassen5;
		if (kassenIds === undefined || kassenIds === null || kassenIds.length === 0) {
			kassenIds = kassen.map(k => k.KassenID);
		}
		setLoading03(true);
		dashboardService.GetDashboardUmsatzData(kassenIds, umsatzDay, netto1).then(setDashboardUmsatzData).finally(() => setLoading03(false));
		// eslint-disable-next-line
	}, [selectedKassen5, kassen, serverDetails, netto1, umsatzDay]);

	useEffect(() => {
		let kassenIds = selectedKassen6;
		if (kassenIds === undefined || kassenIds === null || kassenIds.length === 0) {
			kassenIds = kassen.map(k => k.KassenID);
		}
		setLoading04(true);
		dashboardService.GetDashboardBelegeData(kassenIds, belegeDay, netto2).then(setDashboardBelegeData).finally(() => setLoading04(false));
		// eslint-disable-next-line
	}, [selectedKassen6, kassen, serverDetails, netto2, belegeDay]);

	useEffect(() => {
		try {

			if (!Array.isArray(umsatzVerlaufBereich) || !(umsatzVerlaufBereich[0] instanceof Date) || !(umsatzVerlaufBereich[1] instanceof Date)) return;

			let kassenIds = selectedKassen2;
			if (kassenIds === undefined || kassenIds === null || kassenIds.length === 0) {
				kassenIds = kassen.map(k => k.KassenID);
			}
			setLoading06(true);
			if (umsatzVerlaufBereich[0].getTime() === umsatzVerlaufBereich[1].getTime()) {
				umsatzService.getUmsaetzeTag(kassenIds, umsatzVerlaufBereich[0]).then(data => {
					const datasets = []
					for (let i = 0; i < data.length; i++) {
						if (kassenIds[i] === undefined) continue;
						if (data[i] === undefined || data[i] === null || data[i].length === 0 || data[i].every(n => n === null)) continue;
						datasets.push({
							label: kassenIds[i],
							data: data[i],
							fill: false,
							borderColor: "hsl(" + SimpleStringHash(kassenIds[i]) * 100 + ", 90%, 45%)",
							tension: 0.3
						});
					}
					setLineChartDatasets(datasets);
				}).finally(() => setLoading06(false));
			} else {
				umsatzService.getUmsaetzeVonBis(kassenIds, umsatzVerlaufBereich[0], umsatzVerlaufBereich[1]).then(data => {
					const datasets = []
					if (data === null || data === undefined)
						return;
					for (let i = 0; i < data.length; i++) {
						if (kassenIds[i] === undefined) continue;
						if (data[i] === undefined || data[i] === null || data[i].length === 0 || data[i].every(n => n === null)) continue;
						datasets.push({
							label: kassenIds[i],
							data: data[i],
							fill: false,
							borderColor: "hsl(" + SimpleStringHash(kassenIds[i]) * 100 + ", 90%, 45%)",
							tension: 0.3
						});
					}
					setLineChartDatasets(datasets);
				}).finally(() => setLoading06(false));
			}
		} catch (e) {}
		// eslint-disable-next-line
	}, [umsatzVerlaufBereich]);

	useEffect(() => {
		const now = new Date();
		const year = now.getFullYear();
		const month = now.getMonth();
		const day = now.getDate();
		setUmsatzVerlaufBereich([
			new Date(year, month, (day + selectedTagMenge * umsatzVerlaufOffset) - selectedTagMenge + 1),
			new Date(year, month, day + selectedTagMenge * umsatzVerlaufOffset)
		]);
	}, [selectedTagMenge, selectedKassen2, kassen, serverDetails, umsatzVerlaufOffset]);

	function getMonday() {
		const now = new Date();
		return new Date(now.getFullYear(), now.getMonth(), now.getDate() - (now.getDay() === 0 ? 7 : now.getDay()) + 1);
	}

	useEffect(() => {
		let kassenIds = selectedKassen3;
		if (kassenIds === undefined || kassenIds === null || kassenIds.length === 0) {
			kassenIds = kassen.map(k => k.KassenID);
		}
		const monday = getMonday();
		const year = monday.getFullYear();
		const month = monday.getMonth();
		const day = monday.getDate();
		setLoading05(true);
		umsatzService.getUmsaetzeVonBis(kassenIds, new Date(year, month, day), new Date(year, month, day + 6)).then(data => {
			umsatzService.getUmsaetzeVonBis(kassenIds, new Date(year, month, day - 7 * (vorwocheOffset + 1)), new Date(year, month, day - 7 * vorwocheOffset - 1)).then(dataVorwoche => {
				const datasets = []
				for (let i = 0; i < dataVorwoche.length; i++) {
					if(kassenIds[i] === undefined) continue;
					if (dataVorwoche[i] === undefined || dataVorwoche[i] === null || dataVorwoche[i].length === 0 || dataVorwoche[i].every(n => n === null)) continue;
					datasets.push({
						label: "Umsatz Vorwoche " + kassenIds[i],
						data: dataVorwoche[i],
						fill: false,
						backgroundColor: "hsl(" + SimpleStringHash(kassenIds[i]) * 100 + ", 40%, 40%)",
						tension: 0.3,
						stack: "Vorwoche"
					});
				}
				for (let i = 0; i < data.length; i++) {
					if(kassenIds[i] === undefined) continue;
					if (data[i] === undefined || data[i] === null || data[i].length === 0 || data[i].every(n => n === null)) continue;
					datasets.push({
						label: "Umsatz diese Woche " + kassenIds[i],
						data: data[i],
						fill: false,
						backgroundColor: "hsl(" + SimpleStringHash(kassenIds[i]) * 100 + ", 90%, 45%)",
						tension: 0.3,
						stack: "Diese Woche"
					});
				}

				setBarChartDatasets(datasets);
				setLoading05(false);
			}).catch(() => setLoading05(false));
		}).catch(() => setLoading05(false));
		// eslint-disable-next-line
	}, [selectedKassen3, kassen, serverDetails, vorwocheOffset]);

	useEffect(() => {
		const kassenMitUrl = kassen.filter(kasse => kasse.url !== undefined && kasse.url !== null && kasse.url !== '');
		setKassenMitUrl(kassenMitUrl);
		kassenMitUrlRef.current = kassenMitUrl;
		// eslint-disable-next-line
	}, [kassen]);

	useEffect(() => {
		Load();
	}, [kassenMitUrl]);

	useEffect(() => {
		setGlobalState({...globalState, page: 'Dashboard'});
		kassenService.getKassen().then(data => setKassen(data));
		authService.getTokenInfo().then(info => {
			setRoles(info?.roles.split(';') ?? []);
		});
		Load();
		const interval = setInterval(Load, 1000*30);
		return () => clearInterval(interval);
		// eslint-disable-next-line
	}, []);

	const onForceSyncClick = (kasse) => {
		setForceSyncDialogVisible(true);
		setForceSyncSelectedKassa(kasse);
	}

	return(
		<React.Fragment>
			<Toast ref={toastRef}/>
			<Dialog visible={forceSyncDialogVisible} onHide={() => setForceSyncDialogVisible(false)}
				style={{minWidth: "600px"}}
				header={"Synchronisieren - " + forceSyncSelectedKassa?.KassenID}
				footer={
					<div>
						<Button label="Abbrechen" icon="pi pi-times" onClick={() => setForceSyncDialogVisible(false)} />
						<Button label="Ok" icon="pi pi-check" onClick={async () => {
							await syncService.forceSync(forceSyncSelectedKassa.url)
							.then ((e) => {
								console.log(e);
								if (e && e === 502)
									toastRef.current.show({severity:'info', summary: 'Info', detail:'Kassa synchronisiert gerade.', life: 3000});
								else
									toastRef.current.show({severity:'success', summary: 'Info', detail:'Kassa wird synchronisiert.', life: 3000});
								setForceSyncDialogVisible(false);
							})
							.catch (() => {
								toastRef.current.show({severity:'error', summary: 'Fehler', detail:'Fehler beim Synchronisieren der Kassa.', life: 3000});
							});
						}}/>
					</div>
				}
				>
				<div>
					<img  src={"/assets/achtung.jpeg"} className="mr-5" width={"100px"} height={"100px"} style={{float: "left"}} alt=""/>
					Bitte vergewissern Sie sich mithilfe der Kameras,<br />
					dass gerade niemand die Kassa bedient<br /><br />
					Wollen Sie wirklich synchronisieren?
				</div>
			</Dialog>
			<div className="grid">
				<div className="col-12 md:col-6 lg:col-4 p-p-1" style={{position: "relative"}}>
					{ loading01 && <i className="pi pi-spin pi-spinner" style={{fontSize: '2rem', position: "absolute", left: "calc(50% - 1rem)", top: "calc(50% - 1rem)"}}/> }
					<Card title="Status des Servers" style={{width: '100%', height: '100%'}} footer={<Button label="Jetzt Synchronisieren" icon="pi pi-refresh" onClick={syncServer} disabled={!syncPossible}/>}>
						<table id="Dashboard-Kassen" style={{width: '100%'}}>
							<tr>
								<th style={{textAlign: "left"}}>Standort</th>
								<th>Sync-Datum</th>
								<th>Letzte Sync</th>
								<th style={{width: '2em'}}></th>
							</tr>
							{
								kassenMitUrl.map(kasse => <tr>
									<td style={{textAlign: "left"}}>{kasse.KassenID}</td>
									<td>{serverDetails[kasse.KassenID] === undefined ? <span style={{color: "grey"}}>-</span> : <span>{new Date(serverDetails[kasse.KassenID].SyncDatum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}) + ' ' + new Date(serverDetails[kasse.KassenID].SyncDatum).toLocaleTimeString('de')}</span>}</td>
									<td>{serverDetails[kasse.KassenID] === undefined ? <span style={{color: "grey"}}>-</span> : (serverDetails[kasse.KassenID].LetzterSyncErfolgreich ? <i className="pi pi-check" style={{color: "lime"}}></i> : <i className="pi pi-times" style={{color: "red"}}></i>)}</td>
									<td>{syncInProgress[kasse.KassenID] ? <i className="pi pi-spin pi-spinner"></i> : <React.Fragment></React.Fragment>}</td>
								</tr>)
							}
						</table>
					</Card>
				</div>
				{roles.includes('Chef') &&
				<React.Fragment>
				<div className="col-12 md:col-6 lg:col-4 p-p-1" style={{position: "relative"}}>
					{ loading02 && <i className="pi pi-spin pi-spinner" style={{fontSize: '2rem', position: "absolute", left: "calc(50% - 1rem)", top: "calc(50% - 1rem)"}}/> }
					<Card title={<React.Fragment><span>Status der Kassen </span><span style={{fontSize: '1rem', fontWeight: '500', float: 'right'}}>{kassenAnzahlOnline} online {kassenAnzahlOffline} offline</span></React.Fragment>} style={{width: '100%', height: '100%'}}>
						<table id="Dashboard-Kassen" style={{width: '100%'}}>
							<tr>
								<th style={{textAlign: "left"}}>Standort</th>
								<th>Status</th>
								<th>Sync-Datum</th>
								<th>Letzte Sync</th>
								<th></th>
							</tr>
							{
								kassenMitUrl.map(kasse =>
								<tr>
									<td style={{textAlign: "left"}}>{kasse.KassenID}</td>
									<td>{typeof(kassenOnline[kasse.KassenID]) === "undefined" ? <span style={{color: "grey"}}>Ausstehend</span> : (kassenOnline[kasse.KassenID] ? <span style={{color: "lime"}}>Online</span> : <span style={{color: "red"}}>Offline</span>)}</td>
									<td>{kassenDetails[kasse.KassenID] === undefined ? <span style={{color: "grey"}}>-</span> : <span>{new Date(kassenDetails[kasse.KassenID].SyncDatum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}) + ' ' + new Date(kassenDetails[kasse.KassenID].SyncDatum).toLocaleTimeString('de')}</span>}</td>
									<td>{kassenDetails[kasse.KassenID] === undefined ? <span style={{color: "grey"}}>-</span> : (kassenDetails[kasse.KassenID].LetzterSyncErfolgreich ? <i className="pi pi-check" style={{color: "lime"}}></i> : <i className="pi pi-times" style={{color: "red"}}></i>)}</td>
									<td><Button color='white' icon="pi pi-refresh" onClick={(e) => onForceSyncClick(kasse)} /></td>
								</tr>)
							}
						</table>
					</Card>
				</div>
				<div className="col-12 md:col-6 lg:col-4 p-p-1" style={{position: "relative"}}>
					{ loading03 && <i className="pi pi-spin pi-spinner" style={{fontSize: '2rem', position: "absolute", left: "calc(50% - 1rem)", top: "calc(50% - 1rem)"}}/> }
					<Card title={<React.Fragment>Umsatz <MultiSelect value={selectedKassen5} options={kassen} onChange={e => setSelectedKassen5(e.target.value)} placeholder="Alle Kassen" optionLabel="KassenID" optionValue="KassenID" maxSelectedLabels={1} style={{float: "right"}}/><SelectButton value={netto1} onChange={e => setNetto1(e.value)} options={[{label: "Brutto", value: false}, {label: "Netto", value: true}]} style={{float: "right", marginRight: "0.5rem"}}/></React.Fragment>} style={{width: '100%', height: '100%'}} className="dashboard-umsatz-card">
						<table style={{width: "100%"}}>
							<tr>
								<td style={{textAlign: "right", width: "50%"}}><Button className="p-button-secondary p-button-text" icon="pi pi-angle-left" onClick={() => setUmsatzDay(new Date(umsatzDay.getFullYear(), umsatzDay.getMonth(), umsatzDay.getDate() - 1))}/></td>
								<td style={{textAlign: "center"}}>{umsatzDay?.toLocaleDateString("de", { day: '2-digit', month: '2-digit', year: 'numeric'})}</td>
								<td style={{textAlign: "left", width: "50%"}}><Button className="p-button-secondary p-button-text" icon="pi pi-angle-right" onClick={() => setUmsatzDay(new Date(umsatzDay.getFullYear(), umsatzDay.getMonth(), umsatzDay.getDate() + 1))}/></td>
							</tr>
						</table>
						<div style={{position: "relative", height: "100%", minHeight: "10rem", width: "100%", overflowY: "auto"}}>
							<table style={{borderCollapse: "collapse", position: "absolute", top: "0px", left: "0px", width: "calc(100% - 0.1rem)"}}>
								<tr>
									<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>Tagesbelege Gutschein:</th>
									<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>&euro; {dashboardUmsatzData?.belegeGutscheinHeute?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
								</tr>
								<tr>
									<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>Tagesbelege Bar:</th>
									<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>&euro; {dashboardUmsatzData?.belegeBarHeute?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
								</tr>
								<tr>
									<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>Tagesbelege Bankomat:</th>
									<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>&euro; {dashboardUmsatzData?.belegeBankomatHeute?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
								</tr>
								<tr>
									<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>Tagesumsatz:</th>
									<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>&euro; {dashboardUmsatzData?.umsatzHeute?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
								</tr>
								<tr>
									<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "2px"}}>TagesDB:</th>
									<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "2px"}}>&euro; {dashboardUmsatzData?.dbHeute?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
								</tr>
								<tr>
									<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>Wochenbelege Gutschein:</th>
									<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>&euro; {dashboardUmsatzData?.belegeGutscheinWoche?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
								</tr>
								<tr>
									<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>Wochenbelege Bar:</th>
									<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>&euro; {dashboardUmsatzData?.belegeBarWoche?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
								</tr>
								<tr>
									<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>Wochenbelege Bankomat:</th>
									<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>&euro; {dashboardUmsatzData?.belegeBankomatWoche?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
								</tr>
								<tr>
									<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>Wochenumsatz:</th>
									<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>&euro; {dashboardUmsatzData?.umsatzWoche?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
								</tr>
								<tr>
									<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "2px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>WochenDB:</th>
									<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "2px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>&euro; {dashboardUmsatzData?.dbWoche?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
								</tr>
								<tr>
									<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>Monatsbelege Gutschein:</th>
									<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>&euro; {dashboardUmsatzData?.belegeGutscheinMonat?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
								</tr>
								<tr>
									<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>Monatsbelege Bar:</th>
									<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>&euro; {dashboardUmsatzData?.belegeBarMonat?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
								</tr>
								<tr>
									<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>Monatsbelege Bankomat:</th>
									<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>&euro; {dashboardUmsatzData?.belegeBankomatMonat?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
								</tr>
								<tr>
									<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>Monatsumsatz:</th>
									<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>&euro; {dashboardUmsatzData?.umsatzMonat?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
								</tr>
								<tr>
									<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "2px"}}>MonatsDB:</th>
									<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "2px"}}>&euro; {dashboardUmsatzData?.dbMonat?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
								</tr>
								<tr>
									<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>Jahresbelege Gutschein:</th>
									<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>&euro; {dashboardUmsatzData?.belegeGutscheinJahr?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
								</tr>
								<tr>
									<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>Jahresbelege Bar:</th>
									<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>&euro; {dashboardUmsatzData?.belegeBarJahr?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
								</tr>
								<tr>
									<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>Jahresbelege Bankomat:</th>
									<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>&euro; {dashboardUmsatzData?.belegeBankomatJahr?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
								</tr>
								<tr>
									<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>Jahresumsatz:</th>
									<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>&euro; {dashboardUmsatzData?.umsatzJahr?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
								</tr>
								<tr>
									<th style={{textAlign: "left", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>JahresDB:</th>
									<td style={{textAlign: "right", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>&euro; {dashboardUmsatzData?.dbJahr?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
								</tr>
							</table>
						</div>
					</Card>
				</div>
				<div className="col-12 md:col-6 lg:col-4 p-p-1" style={{position: "relative"}}>
					{ loading04 && <i className="pi pi-spin pi-spinner" style={{fontSize: '2rem', position: "absolute", left: "calc(50% - 1rem)", top: "calc(50% - 1rem)"}}/> }
					<Card title={<React.Fragment>Belege <MultiSelect value={selectedKassen6} options={kassen} onChange={e => setSelectedKassen6(e.target.value)} placeholder="Alle Kassen" optionLabel="KassenID" optionValue="KassenID" maxSelectedLabels={1} style={{float: "right"}}/><SelectButton value={netto2} onChange={e => setNetto2(e.value)} options={[{label: "Brutto", value: false}, {label: "Netto", value: true}]} style={{float: "right", marginRight: "0.5rem"}}/></React.Fragment>} style={{width: '100%', height: '100%'}}>
						<table style={{width: "100%"}}>
							<tr>
								<td style={{textAlign: "right", width: "50%"}}><Button className="p-button-secondary p-button-text" icon="pi pi-angle-left" onClick={() => setBelegeDay(new Date(belegeDay.getFullYear(), belegeDay.getMonth(), belegeDay.getDate() - 1))}/></td>
								<td style={{textAlign: "center"}}>{belegeDay.toLocaleDateString("de", { day: '2-digit', month: '2-digit', year: 'numeric'})}</td>
								<td style={{textAlign: "left", width: "50%"}}><Button className="p-button-secondary p-button-text" icon="pi pi-angle-right" onClick={() => setBelegeDay(new Date(belegeDay.getFullYear(), belegeDay.getMonth(), belegeDay.getDate() + 1))}/></td>
							</tr>
						</table>
						<table style={{width: "100%", borderCollapse: "collapse"}}>
							<tr>
								<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>Tagesbelege:</th>
								<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>{dashboardBelegeData?.belegeHeute?.toLocaleString('de', { maximumFractionDigits: 0 })}</td>
							</tr>
							<tr>
								<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>Tagesbelege Durchschnitt:</th>
								<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>&euro; {dashboardBelegeData?.belegeDurchschnittHeute?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
							</tr>
							<tr>
								<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "2px"}}>Tagesbelege Durchschnittliche Länge:</th>
								<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "2px"}}>{dashboardBelegeData?.belegeDurchschnittLaengeHeute?.toLocaleString('de', { maximumFractionDigits: 0 })}mm</td>
							</tr>
							<tr>
								<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>Wochenbelege:</th>
								<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>{dashboardBelegeData?.belegeWoche?.toLocaleString('de', { maximumFractionDigits: 0 })}</td>
							</tr>
							<tr>
								<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>Wochenbelege Durchschnitt:</th>
								<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>&euro; {dashboardBelegeData?.belegeDurchschnittWoche?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
							</tr>
							<tr>
								<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "2px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>Wochenbelege Durchschnittliche Länge:</th>
								<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "2px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>{dashboardBelegeData?.belegeDurchschnittLaengeWoche?.toLocaleString('de', { maximumFractionDigits: 0 })}mm</td>
							</tr>
							<tr>
								<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>Monatsbelege:</th>
								<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>{dashboardBelegeData?.belegeMonat?.toLocaleString('de', { maximumFractionDigits: 0 })}</td>
							</tr>
							<tr>
								<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>Monatsbelege Durchschnitt:</th>
								<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px"}}>&euro; {dashboardBelegeData?.belegeDurchschnittMonat?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
							</tr>
							<tr>
								<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "2px"}}>Monatsbelege Durchschnittliche Länge:</th>
								<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "2px"}}>{dashboardBelegeData?.belegeDurchschnittLaengeMonat?.toLocaleString('de', { maximumFractionDigits: 0 })}mm</td>
							</tr>
							<tr>
								<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>Jahresbelege:</th>
								<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>{dashboardBelegeData?.belegeJahr?.toLocaleString('de', { maximumFractionDigits: 0 })}</td>
							</tr>
							<tr>
								<th style={{textAlign: "left", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>Jahresbelege Durchschnitt:</th>
								<td style={{textAlign: "right", borderBottomStyle: "solid", borderBottomWidth: "1px", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>&euro; {dashboardBelegeData?.belegeDurchschnittJahr?.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
							</tr>
							<tr>
								<th style={{textAlign: "left", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>Jahresbelege Durchschnittliche Länge:</th>
								<td style={{textAlign: "right", backgroundColor: "rgba(0, 100, 250, 0.2)"}}>{dashboardBelegeData?.belegeDurchschnittLaengeJahr?.toLocaleString('de', { maximumFractionDigits: 0 })}mm</td>
							</tr>
						</table>
					</Card>
				</div>
				<div className="col-12 md:col-6 lg:col-4 p-p-1" style={{position: "relative"}}>
					{ loading05 && <i className="pi pi-spin pi-spinner" style={{fontSize: '2rem', position: "absolute", left: "calc(50% - 1rem)", top: "calc(50% - 1rem)"}}/> }
					<Card title={<React.Fragment>Tagesumsatz im Wochenvergleich <MultiSelect value={selectedKassen3} options={kassen} onChange={e => setSelectedKassen3(e.target.value)} placeholder="Alle Kassen" optionLabel="KassenID" optionValue="KassenID" maxSelectedLabels={1} style={{float: "right"}}/></React.Fragment>} style={{width: '100%', height: '100%'}}>
						<ZoomPopup header={<div style={{textAlign: "center", position: "relative"}}><span style={{position: "absolute", left: "0px"}}>Tagesumsatz im Wochenvergleich</span><div style={{display: "inline-block"}}><Button className="p-button-secondary p-button-text" icon="pi pi-angle-left" style={{padding: "0px"}} onClick={() => setVorwocheOffset(vorwocheOffset + 1)}/>{new Date(getMonday().getFullYear(), getMonday().getMonth(), getMonday().getDate() - 7 * (vorwocheOffset + 1)).toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" })}<Button className="p-button-secondary p-button-text" icon="pi pi-angle-right" style={{padding: "0px"}} onClick={() => setVorwocheOffset(vorwocheOffset - 1)}/></div></div>} footer={<Button label="Download" icon="pi pi-image" onClick={() => download(barChartRef.current.getBase64Image(), 'TagesumsatzImWochenvergleich.png')}/>} onHide={() => setVorwocheOffset(0)}>
							<Chart ref={barChartRef} type="bar" data={{labels: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"], datasets: barChartDatasets}} options={{animation: {duration: 0}, scales: {x: {stacked:true}, y: {stacked:true}}, plugins: {legend: {position: 'bottom', onClick: function(e, legendItem) {
								const index = legendItem.datasetIndex;
								const chart = this.chart;
								const meta = chart.getDatasetMeta(index);
								const meta2 = chart.getDatasetMeta((index + chart.data.datasets.length / 2) % chart.data.datasets.length);
								const hidden = meta.hidden === null ? !chart.data.datasets[index].hidden : null;

								meta.hidden = hidden
								meta2.hidden = hidden

								chart.update();
							}}}}}/>
						</ZoomPopup>
					</Card>
				</div>
				<div className="col-12 md:col-6 lg:col-4 p-p-1" style={{position: "relative"}}>
					{ loading06 && <i className="pi pi-spin pi-spinner" style={{fontSize: '2rem', position: "absolute", left: "calc(50% - 1rem)", top: "calc(50% - 1rem)"}}/> }
					<Card title={<React.Fragment>Umsatz Verlauf <MultiSelect value={selectedKassen2} options={kassen} onChange={e => setSelectedKassen2(e.target.value)} placeholder="Alle Kassen" optionLabel="KassenID" optionValue="KassenID" maxSelectedLabels={1} style={{float: "right"}}/><Dropdown value={selectedTagMenge} options={[1, 7, 14, 30]} onChange={e => setSelectedTagMenge(e.value)} valueTemplate={t => <React.Fragment>{t} Tage</React.Fragment>} style={{float: "right"}}/></React.Fragment>} style={{width: '100%', height: '100%'}}>
						<ZoomPopup header={<div style={{textAlign: "center", position: "relative"}}><span style={{position: "absolute", left: "0px"}}>Umsatz Verlauf</span><div style={{display: "inline-block"}}><Button className="p-button-secondary p-button-text" icon="pi pi-angle-left" style={{padding: "0px"}} onClick={() => setUmsatzVerlaufOffset(umsatzVerlaufOffset - 1)}/>{umsatzVerlaufBereich?.[0]?.getTime() === umsatzVerlaufBereich?.[1]?.getTime() ? umsatzVerlaufBereich?.[0]?.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }) : umsatzVerlaufBereich?.[0]?.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }) + " - " + umsatzVerlaufBereich?.[1]?.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" })}<Button className="p-button-secondary p-button-text" icon="pi pi-angle-right" style={{padding: "0px"}} onClick={() => setUmsatzVerlaufOffset(umsatzVerlaufOffset + 1)}/></div></div>} footer={<Button label="Download" icon="pi pi-image" onClick={() => download(lineChartRef.current.getBase64Image(), 'Umsatz Verlauf ' + (umsatzVerlaufBereich?.[0]?.getTime() === umsatzVerlaufBereich?.[1]?.getTime() ? umsatzVerlaufBereich?.[0]?.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }) : umsatzVerlaufBereich?.[0]?.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }) + "-" + umsatzVerlaufBereich?.[1]?.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" })) + '.png')}/>} onHide={() => setUmsatzVerlaufOffset(0)}>
							<Chart ref={lineChartRef} type="line" data={{labels: GetDatumsVerlauf(new Date(), selectedTagMenge), datasets: lineChartDatasets}} options={{animation: {duration: 0}, plugins: {legend: {position: 'bottom'}}}}/>
						</ZoomPopup>
					</Card>
				</div>
				</React.Fragment>
				}
			</div>
		</React.Fragment>
	);
}

export default Dashboard;