import React, { useState, useRef, useEffect } from "react";
import { LogService } from '../service/LogService';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Card } from 'primereact/card';
import { Chart } from 'primereact/chart';
import { ZoomPopup } from './ZoomPopup';

export function LogAuswertung(props) {

    const logService = new LogService();
    const [selectedKassen, setSelectedKassen] = useState(null);
    const [selectedTagMenge, setSelectedTagMenge] = useState(7);
	const logChartRef = useRef(null);
    const [umsatzVerlaufOffset, setUmsatzVerlaufOffset] = useState(0);
	const [umsatzVerlaufBereich, setUmsatzVerlaufBereich] = useState();
    const [loading, setLoading] = useState(false);
    const [dataSet, setDataSet] = useState([]);

    const formatDate = (value) => {
        return value?.toLocaleDateString('de', { day: "2-digit", month: "2-digit", year: "numeric" });
    }

    useEffect(() => {
		if (!Array.isArray(umsatzVerlaufBereich) || !(umsatzVerlaufBereich[0] instanceof Date) || !(umsatzVerlaufBereich[1] instanceof Date)) return;

		let kassenIds = selectedKassen;
		if (kassenIds === undefined || kassenIds === null || kassenIds.length === 0) {
			kassenIds = props.kassen.map(k => k.KassenID);
		}
        console.log(umsatzVerlaufBereich);
		setLoading(true);

        logService.getLogVonBis(kassenIds, umsatzVerlaufBereich[0], umsatzVerlaufBereich[1]).then(data => (setDataSet(data), console.log(data))).finally(() => setLoading(false));
		
	}, [umsatzVerlaufBereich]);

    useEffect(() => {
		const now = new Date();
		const year = now.getFullYear();
		const month = now.getMonth();
		const day = now.getDate();
		setUmsatzVerlaufBereich([
			new Date(year, month, (day + selectedTagMenge * umsatzVerlaufOffset) - selectedTagMenge + 1),
			new Date(year, month, day + selectedTagMenge * umsatzVerlaufOffset)
		]);
	}, [selectedTagMenge, selectedKassen, props.kassen, umsatzVerlaufOffset]);

    const renderCardTitle = () => {
        return (
            <React.Fragment>
                Fehlerübersicht       
                <MultiSelect value={selectedKassen} options={props.kassen} onChange={e => setSelectedKassen(e.target.value)}
                    placeholder="Alle Kassen" optionLabel="KassenID" optionValue="KassenID" maxSelectedLabels={1}
                    style={{float: "right"}}/>
                <Dropdown value={selectedTagMenge} options={[1, 7, 14, 30]} onChange={e => setSelectedTagMenge(e.value)}
                    valueTemplate={t => <React.Fragment>{t} Tage</React.Fragment>} style={{float: "right"}}/>
            </React.Fragment>
        );
    }

    const download = (url, name) => {
		const a = window.document.createElement('a');
		a.href = url;
		a.download = name;
		window.document.body.append(a);
		a.click();
		a.remove();
	};

    const renderPopupFooter = () => {
        return(
            <React.Fragment>
                <Button label="Download" icon="pi pi-image"
                onClick={() => download(logChartRef.current.getBase64Image(),
                'Umsatz Verlauf ' +
                (umsatzVerlaufBereich?.[0]?.getTime() === umsatzVerlaufBereich?.[1]?.getTime() ?
                formatDate(umsatzVerlaufBereich?.[0]) : formatDate(umsatzVerlaufBereich?.[0]) + "-" + formatDate(umsatzVerlaufBereich?.[1]))
                 + '.png')}/>
            </React.Fragment>
        );
    }

    return (
        <div className="col-12 md:col-6 lg:col-4 p-p-1" style={{position: "relative"}}>
        { loading && <i className="pi pi-spin pi-spinner" style={{fontSize: '2rem', position: "absolute", left: "calc(50% - 1rem)", top: "calc(50% - 1rem)"}}/> }
        <Card title={renderCardTitle} style={{width: '100%', height: '100%'}}>
            <ZoomPopup header={<div style={{textAlign: "center", position: "relative"}}>
                <span style={{position: "absolute", left: "0px"}}>Umsatz Verlauf</span><div style={{display: "inline-block"}}>
                    <Button className="p-button-secondary p-button-text" icon="pi pi-angle-left" style={{padding: "0px"}}
                        onClick={() => setUmsatzVerlaufOffset(umsatzVerlaufOffset - 1)}/>
                    {umsatzVerlaufBereich?.[0]?.getTime() === umsatzVerlaufBereich?.[1]?.getTime() ? formatDate(umsatzVerlaufBereich?.[0]) : umsatzVerlaufBereich?.[0]?.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" }) + " - " + umsatzVerlaufBereich?.[1]?.toLocaleDateString("de", { day: "2-digit", month: "2-digit", year: "numeric" })}<Button className="p-button-secondary p-button-text" icon="pi pi-angle-right" style={{padding: "0px"}} onClick={() => setUmsatzVerlaufOffset(umsatzVerlaufOffset + 1)}/></div></div>}
                footer={renderPopupFooter}>
                <Chart ref={logChartRef} type="bar" data={dataSet}
                options={{animation: {duration: 0}, plugins: {legend: {position: 'bottom'}}}}/>
            </ZoomPopup>
        </Card>
    </div>
    );
}

export default LogAuswertung;