import axios from 'axios';
import { GetAxiosConfig, GetToken } from './AuthService';
import CONFIG from 'config';

export class LieferscheinService {
    async getFromTo(from, to) {
        return axios.get(CONFIG.ServerUrl + '/Lieferscheine/From/' + from.toISOString() + '/To/' + to.toISOString(), await GetAxiosConfig()).then(res => res.data);
    }

    async getLieferscheinHTML(id) {
		return fetch(CONFIG.ServerUrl + '/Lieferscheine/HTML/' + id, {
			headers: {
				'Authorization': 'Bearer ' + (await GetToken())
			}
		}).then(response => {
			if (response.ok) {
				return response.text().then(text => {
					return text;
				});
			}
		});
	}

	async setKunde(lieferschein, kundennummer){
		if (lieferschein === null || lieferschein === undefined || kundennummer === null || kundennummer === undefined)
			return;
		return axios.get(CONFIG.ServerUrl + '/Lieferschein/' + lieferschein.ID + '/Kunde/' + kundennummer, await GetAxiosConfig()).then(res => res.data);
	}
}