import axios from 'axios';
import { GetAxiosConfig } from './AuthService';
import { GetToken } from './AuthService';
import CONFIG from 'config';

export class WareneingangsjournalService {
	async getData() {
		return axios.get('assets/demo/data/wareneingangsjournal.json', await GetAxiosConfig())
		.then(res => res.data);
	}

	async getDataFromTo(from, to) {
		return axios.get(CONFIG.ServerUrl + '/Wareneingang/From/' + from.toISOString() + '/To/' + to.toISOString(), await GetAxiosConfig())
			.then(res => res.data)
			.catch(e => console.log(e));
	}

	async getGruende() {
		return axios.get(CONFIG.ServerUrl + '/Wareneingänge/Gründe', await GetAxiosConfig()).then(res => res.data);
	}

	async downloadBericht(von, bis, kassenIds) {
		return fetch(CONFIG.ServerUrl + '/Wareneingaenge/Export', {
			method: 'POST',
			headers: {
				'Authorization': 'Bearer ' + (await GetToken()),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({Von: von.toISOString(), Bis: bis.toISOString(), Kassen: kassenIds})
		}).then(response => {
			if (response.ok) {
				return response.blob().then(blob => {
					if (blob.size === 0) throw new Error("The response was empty.");
					this.downloadBlob(blob, "Wareneingangsjournal " + von.getDate().toLocaleString("de-DE", {minimumIntegerDigits: 2}) + (von.getMonth() + 1).toLocaleString("de-DE", {minimumIntegerDigits: 2}) + von.getFullYear() + " bis " + bis.getDate().toLocaleString("de-DE", {minimumIntegerDigits: 2}) + (bis.getMonth()+ 1).toLocaleString("de-DE", {minimumIntegerDigits: 2}) + bis.getFullYear() + ".xlsx");
				});
			} else {
				throw new Error("There was an not OK response.");
			}
		});
	}

	downloadBlob(blob, name) {
		const a = window.document.createElement('a');
		a.href = window.URL.createObjectURL(blob);
		a.download = name;
		window.document.body.append(a);
		a.click();
		window.URL.revokeObjectURL(a.href);
		a.remove();
	}
}