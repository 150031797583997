import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalState } from "./components/GlobalState";
import { AuthService } from "./service/AuthService";
import { PopupMenu } from "./components/PopupMenu";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { useHistory } from 'react-router-dom';

import CONFIG from 'config';

export function AppTopbar(props) {
	const labelWidth = "10em";

	const authService = new AuthService();

	const [globalState] = useContext(GlobalState);
	const [username, setUsername] = useState();
	const [passwortDialogVisible, setPasswortDialogVisible] = useState(false);
	const [neuesPasswort, setNeuesPasswort] = useState();
	const [neuesPasswort2, setNeuesPasswort2] = useState();
	const toastRef = useRef(null);
	const history = useHistory();

	useEffect(() => {
		async function Login() {
			setUsername((await authService.getTokenInfo())?.name);
		};
		Login();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalState]);

	const logout = () => {
		authService.logout();
		history.go("/");
		props.reload();
	};

	async function changePassword() {
		if (neuesPasswort !== neuesPasswort2) {
			toastRef.current.show({ severity: "error", summary: "Das Passwort konnte nicht geändert werden", detail: "Die eingegebenen neuen Passwörter stimmen nicht überein", life: 5000, closable: false });
			return;
		}
		authService.changePassword(neuesPasswort).then(() => {
			toastRef.current.show({ severity:"success", summary: "Passwort geändert", life: 2000, closable: false });
			setPasswortDialogVisible(false);
		}).catch(() => {
			toastRef.current.show({ severity: "error", summary: "Das Passwort konnte nicht geändert werden", detail: "Das alte Passwort ist inkorrekt", life: 5000, closable: false });
		});
	}

	return <React.Fragment>
		<Toast ref={toastRef}/>
		<div className="layout-topbar clearfix">
			<table style={{width: "calc(100% - 6px)", height: "100%", borderCollapse: "separate", borderSpacing: "7px", marginLeft: "3px", marginRight: "3px"}}>
				<tr>
					<td>
						<button className="p-link layout-menu-button" onClick={props.onToggleMenu}>
							<span className="pi pi-bars"/>
						</button>
					</td>
					<td style={{width: "100%"}}>
						<h1 style={{fontSize: "1.5em"}}>{CONFIG.Typ === "Kassa" ? CONFIG.Kassa : (globalState?.page ?? "Dashboard")}</h1>
					</td>
					<td>
						<PopupMenu buttonClassName="p-button-secondary p-button-rounded p-button-outlined" icon="pi pi-user" model={[
							{ label: username, items: [
								{ label: "Logout", icon: "pi pi-sign-out", command: logout },
								{ label: "Passwort ändern", icon: "pi pi-user-edit", command: () => { setNeuesPasswort(undefined); setNeuesPasswort2(undefined); setPasswortDialogVisible(true); } }
							]}
						]}/>
					</td>
				</tr>
			</table>
		</div>
		<Dialog visible={passwortDialogVisible} onHide={() => setPasswortDialogVisible(false)} header="Passwort ändern" footer={<Button label="Passwort ändern" onClick={changePassword}/>}>
			<div className="p-component">
				<div className="field grid">
					<label htmlFor="neuesPasswort" className="col-fixed" style={{width: labelWidth}}>Neues Passwort:</label>
					<div className="col">
						<Password inputId="neuesPasswort" value={neuesPasswort} onChange={e => setNeuesPasswort(e.target.value)} toggleMask/>
					</div>
				</div>
				<div className="field grid">
					<label htmlFor="neuesPasswort2" className="col-fixed" style={{width: labelWidth}}>Neues Passwort bestätigen:</label>
					<div className="col">
						<Password inputId="neuesPasswort2" value={neuesPasswort2} onChange={e => setNeuesPasswort2(e.target.value)} toggleMask/>
					</div>
				</div>
			</div>
		</Dialog>
	</React.Fragment>;
}