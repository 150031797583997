import axios from 'axios';
import { GetAxiosConfig, GetToken } from './AuthService';
import CONFIG from 'config';

export class BelegeService {
    async getBelege() {
        //return axios.get('assets/demo/data/belege.json').then(res => res.data);
        return axios.get(CONFIG.ServerUrl + '/Belege', await GetAxiosConfig()).then(res => res.data);
    }

    async getBelegeFromTo(from, to) {
        return axios.get(CONFIG.ServerUrl + '/Belege/From/' + from.toISOString() + '/To/' + to.toISOString(), await GetAxiosConfig()).then(res => res.data);
    }

    async getKennzahlen(kassen, from, to) {
        //return axios.get('assets/demo/data/belegeKennzahlen.json').then(res => res.data);
        return axios.post(CONFIG.ServerUrl + '/Belege/Kennzahlen/From/' + from.toISOString() + '/To/' + to.toISOString(), kassen, await GetAxiosConfig()).then(res => res.data);
    }

    async getDetails(id) {
        return axios.get(CONFIG.ServerUrl + '/Belege/Details/' + id, await GetAxiosConfig()).then(res => res.data);
    }

    async getDetails2(BelegNr, KassenID) {
        return axios.get(CONFIG.ServerUrl + '/Belege/Details/' + KassenID + "/" + BelegNr, await GetAxiosConfig()).then(res => res.data);
    }

    async getUnternehmensInfo() {
        //return axios.get('assets/demo/data/belegUnternehmensInfo.json').then(res => res.data);
        return axios.get(CONFIG.ServerUrl + '/Belege/UnternehmensInfo', await GetAxiosConfig()).then(res => res.data);
    }

    async getQrCode(id) {
        const response = await fetch(CONFIG.ServerUrl + '/Belege/QrCode/' + id, {
            headers: {
				'Authorization': 'Bearer ' + (await GetToken())
			}
        });
        return response.blob();
    }

    async Storno(url, kasse, belegNr, grund) {
        await axios.get(url + '/Belege/StornoKasse/' + belegNr + "/" + grund, await GetAxiosConfig());
        await axios.get(CONFIG.ServerUrl + '/Belege/StornoServer/' + kasse + '/' + belegNr + "/" + grund, await GetAxiosConfig());
    }

    async GetDoppelte() {
        return axios.get(CONFIG.ServerUrl + '/Belege/Doppelte', await GetAxiosConfig()).then(res => res.data);
    }
}