import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';

export function ZoomPopup(props) {
	const [isBig, setIsBig] = useState(false);

	const onClick = e => {
		e.stopPropagation();
		setIsBig(true);
	};

	return (
		<React.Fragment>
			<div onClickCapture={onClick} style={{cursor: 'pointer'}}>
				{props.children}
			</div>
			<Dialog visible={isBig} onHide={e => { setIsBig(false); props.onHide?.(e) }} style={{width: '100vmin'}} header={props.header} footer={props.footer}>
				{props.children}
			</Dialog>
		</React.Fragment>
	);
}