import axios from 'axios';
import { GetAxiosConfig } from './AuthService';
import CONFIG from 'config';

export class BestaendeService {
	async changeSollbestand(ProduktID, Bestand) {
		var url = CONFIG.ServerUrl;
		var data = {
			ProduktID: ProduktID,
			Sollbestand: Bestand
		};
		if(!url.startsWith('http')) url = 'http://' + url;
		return axios.post(url + '/Bestaende/Sollbestand/Change', data, await GetAxiosConfig()).then(res => res.data);
	}

	async changeWarnbestand(ProduktID, Bestand) {
		var url = CONFIG.ServerUrl;
		var data = {
			ProduktID: ProduktID,
			Warnbestand: Bestand
		};
		if(!url.startsWith('http')) url = 'http://' + url;
		return axios.post(url + '/Bestaende/Warnbestand/Change', data, await GetAxiosConfig()).then(res => res.data);
	}
  
}