import React, { useState, useEffect, useRef, useContext, createRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { ProduktService } from '../service/ProduktService';
import { WarengruppenService } from '../service/Warengruppenservice';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { AutoComplete } from 'primereact/autocomplete';
import { useHistory } from "react-router-dom";
import { Divider } from 'primereact/divider';
import { Calendar } from "primereact/calendar";
import { Toast } from 'primereact/toast';
import { GlobalState } from './GlobalState';
import { PopupMenu } from './PopupMenu';
import { Checkbox } from 'primereact/checkbox';

import { EtikettenService } from '../service/EtikettenService';
import { ExportService } from '../service/ExportService';
import { RabattAuswahlDialog } from './RabattAuswahlDialog';
import { LieferantenService } from '../service/LieferantenService';
import { RabattService } from '../service/RabattService';
import { ProduzentenService } from '../service/ProduzentenService'
import CONFIG from 'config';


function Produktstammdaten() {
    let steuer;
    switch (CONFIG.Land) {
        case 'AT':
            steuer = {
                0: 0,
                1: 0.1,
                2: 0.13,
                3: 0.2
            }
            break;
        case 'DE':
            steuer = {
                0: 0,
                7: 0.07,
                8: 0.19
            }
            break;
        default:
            throw new Error('Land "' + CONFIG.Land + '" unbekant');
    }
    
    const [produkte, setProdukte] = useState([]);
    const [origProdukte, setOrigProdukte] = useState([]);
    const [selectedProdukt, setSelectedProdukt] = useState(null);
    const [selectedProdukt2, setSelectedProdukt2] = useState();
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedProduktgruppen, setSelectedProduktgruppen] = useState(null);
    const [produktgruppen, setProduktgruppen] = useState([]);
    const [filteredBezeichnungen, setFilteredBezeichnungen] = useState([]);
    const [selectedBezeichnung, setSelectedBezeichnung] = useState(null);
    const [filteredProdukte, setFilteredProdukte] = useState([]);
    const [filteredBarcode, setFilterdBarcode] = useState([]);
    const [selectedBarcode, setSelectedBarcode] = useState(null);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [dialogRegaletikettenVisible, setDialogRegaletikettenVisible] = useState(false);
    const [anzahlEtiketten, setAnzahlEtiketten] = useState(1);
    const [anzahlRegaletiketten, setAnzahlRegaletiketten] = useState(1);
    const [anzahlRabattEtiketten, setAnzahlRabattEtiketten] = useState(1);
    const [rabatt, setRabatt] = useState({});
    const [produkt, setProdukt] = useState(null);
    const [lieferanten, setLieferanten] = useState([]);
    const [selectedLieferanten, setSelectedLieferanten] = useState([]);
    const [produzenten, setProduzenten] = useState([]);
    const [selectedProduzenten, setSelectedProduzenten] = useState([]);
    const [selectedEinheit, setSelectedEinheit] = useState();
    const [nurinderproduktsuche, setNurinderproduktsuche] = useState();
    const [datum, setDatum] = useState();
    const [vk, setVK] = useState();
    const [vk2, setVK2] = useState();
    const [vk3, setVK3] = useState();
    const [ek, setEK] = useState();
	const [spanne, setSpanne] = useState();
    const [pfand, setPfand] = useState();
    const [produktID, setProduktID] = useState();
    const [produktPreisID, setProduktPreisiD] = useState();
    const [globalState, setGlobalState] = useContext(GlobalState);
    const [exportDialogVisible, setExportDialogVisible] = useState(false);
	const [exportOptions, setExportOptions] = useState({});
    const [showSpinner, setShowSpinner] = useState(false);
    const [produktRefs, setProduktRefs] = useState({});
    const [preisDrucken, setPreisDrucken] = useState(true);
    const [rabattDialogVisible, setRabattDialogVisible] = useState(false);
    const [rabattDialogMengeVisible, setRabattDialogMengeVisible] = useState(false);
    const [barcodeScannenDialogVisible, setBarcodeScannenDialogVisible] = useState(false);
    const [barcode, setBarcode] = useState();
    const produktservice = new ProduktService();
    const warengruppenService = new WarengruppenService();
    const lieferantenService = new LieferantenService();
    const etikettenService = new EtikettenService();
    const exportService = new ExportService();
    const rabattService = new RabattService();
    const produzentenService = new ProduzentenService();
    let dt = useRef(null);
    const history = useHistory();
    const labelWidth = '200px';
    const preisLabelWidth = '100px';
    const preisInputWidth = '100px'; 
    const minDate = new Date();
    const toast = useRef(null);
    const einheiten = ["Stueck", "KG"];

    useEffect(() => {
        setGlobalState({...globalState, page: 'Produktstammdaten'});
        let produktPromise = produktservice.getProdukte();
        produktPromise.then(data => setProdukte(data));
        produktPromise.then(data => setFilteredProdukte(data));
        warengruppenService.getAll().then(data => setProduktgruppen(data));
        lieferantenService.getAll().then(data => setLieferanten(data));
        produzentenService.getAll().then(data => setProduzenten(data));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
	const onSpanneChange = e => {
        if (e?.value === undefined || e?.value === null) return;
        
		setSpanne(e.value);
        if (steuer[selectedProdukt2.Steuersatz] === undefined || steuer[selectedProdukt2.Steuersatz] === null || ek === undefined || ek === null) return;

        setVK(Math.round(((ek / (1 - e.value / 100) * (1 + steuer[selectedProdukt2.Steuersatz])) + Number.EPSILON) * 100) / 100);
        //setVK(Math.round(((ek * (1 + e.value / 100) * (1 + steuer[selectedProdukt2.Steuersatz])) + Number.EPSILON) * 100) / 100);
	}

    const onEkChange = e => {
        setEK(e.value)
        if ( selectedProdukt2 === undefined || selectedProdukt2 === null) return; 
        if (e.value === undefined || e.value === null || vk === undefined || vk === null) return;

        let nettovk = vk / (1 + steuer[selectedProdukt2.Steuersatz]);
        setSpanne(Math.round(((1 - (e.value / nettovk)) * 100)));
    }

    const onVkChange = e => {
        setVK(e.value);
        if ( selectedProdukt2 === undefined || selectedProdukt2 === null) return;
        if (ek === undefined || ek === null || e.value === undefined || e.value === null) return;


        let nettovk = e.value / (1 + steuer[selectedProdukt2.Steuersatz]);
        setSpanne(Math.round(((1 - (ek / nettovk)) * 100)));
    }

    const renderHeader = () => {
        return (
            <div>
                <div  className="grid">
                    <InputText className="col" type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Globale Suche" />
                </div>
            </div>
        );
    };

    const renderWarengruppenFilter = () => {
        return (
            <MultiSelect className="column-filter" value={selectedProduktgruppen} options={produktgruppen}
                onChange={onProduktgruppenFilterChange}
                itemTemplate={produktgruppenItemTemplate}
                placeholder="Alle"
                optionLabel="Bezeichnung"
                optionValue="Bezeichnung"
                filter/>
        );
    };

    const produktgruppenItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{option.Bezeichnung}</span>
            </div>
        );
    };

    const onProduktgruppenFilterChange = (event) => {
        dt.current.filter(event.value, 'Warengruppe', 'in');
        setSelectedProduktgruppen(event.value);
    };

    useEffect(() => {
        setProduktRefs(Object.fromEntries(produkte.map(p => [p.ID, createRef(null)])));
    }, [produkte]);

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <PopupMenu buttonClassName="p-button-secondary" label="Aktionen" icon="pi pi-chevron-down" model={[
                    { label: 'bearbeiten', icon: 'pi pi-pencil', command: () => onEditClick(rowData) },
                    { label: 'kopieren', icon: 'pi pi-copy', command: () => history.push("/produkt/kopieren/"+rowData.ID) },
                    { label: 'Ettikett drucken', icon: 'pi pi-print', command: () => onPrintClick(rowData) },
                    { label: 'Regaletikett drucken', icon: 'pi pi-print', command: () => onRegaletikettPrintClick(rowData) },
                    { label: 'Rabattetikett drucken', icon: 'pi pi-print', command: () => onRabattetikettPrintclick(rowData)},
                    { label: 'Deaktivieren', icon: 'pi pi-trash', command: async () => produktservice.produktDeaktivieren(rowData.ID).then(() => produktservice.getProdukte().then(data => setProdukte(data))).catch(() => toast?.current?.show({severity:'error', summary: 'Produkt kann nicht deaktiviert werden', detail:'Bereits verkaufte Produkte können nicht deaktiviert werden', life: 5000})) }
                ]}/>
            </React.Fragment>
        );
    };

    const onEditClick = (e) => {
        history.push("/produkt/bearbeiten/"+e.ID);
    }

    const onPrintClick = (e) => {
        console.log(e);
        produktservice.getProdukt(e.ID).then(
            data => {
                const barcodes = data.Barcodes.map(x => { return {...x, Datum: new Date(x.Datum)}});
                data.Barcodes = barcodes;
                setProdukt(data);
            }
        );
        setDialogVisible(true);
    }

    const onRegaletikettPrintClick = (e) => {
        produktservice.getProdukt(e.ID).then(
            data => {
                const barcodes = data.Barcodes.map(x => { return {...x, Datum: new Date(x.Datum)}});
                data.Barcodes = barcodes;
                setProdukt(data);
            }
        );
        setDialogRegaletikettenVisible(true);
    }

    const onRabattetikettPrintclick = (e) => {
        console.log(e);
        if (e.Einheit === "KG") {
            toast.current.show({severity:'error', summary: 'Fehler', detail:'Produkte die vom Kunden gewogen werden müssen können nicht rabattiert werden.', life: 3000});
            return;
        }
        else if (e.BarcodeGewichtsAbhaengig === 1 || e.BarcodePreisAbhaengig === 1)
        {
            toast.current.show({severity:'error', summary: 'Fehler', detail:'Preis- oder gewichtsabhängige Produkte können nur an der Kassa rabattiert werden..', life: 3000});
            return;
        }
       
        rabattService.checkProdukt(e.ID)
        .then(() => {
            produktservice.getProdukt(e.ID).then(
                data => {
                    const barcodes = data.Barcodes.map(x => { return {...x, Datum: new Date(x.Datum)}});
                    data.Barcodes = barcodes;
                    console.log(data);
                    setProdukt(data);
                }
            ).then(setRabattDialogVisible(true));
        })
        .catch((err) => {
            if (err.response) {
                // The client was given an error response (5xx, 4xx)
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
            }
            else {
                console.log(err);
            }
            toast.current.show({severity:'error', summary: 'Fehler', detail:'Preis- oder gewichtsabhängige Produkte können nur an der Kassa rabattiert werden..', life: 3000});
            return;
        });
    }
    const rabattButtonClick = (e) => {

        console.log(produkt);

        setRabattDialogVisible(false);
        let _rabatt = {...rabatt,
            Rabatt: e,
            ProduktID: produkt.ID, 
        };
        setRabatt(_rabatt);
        console.log(_rabatt);
        rabattService.createRabatt(_rabatt)
        .then((id) => {
            etikettenService.printRabattEtikett(produkt, id, 1);
            setRabatt({...rabatt,
                Grund: undefined,
                Rabatt: undefined,
                Verderbdatum: undefined,
            });
        })
        .catch(e => {
            console.log(e.response);
            toast?.current?.show({severity:'error', summary: 'Fehler', detail:'Fehler beim Rabatt erstellen', life: 3000});
        });
    }

    const print = (e) => {
        console.log(e);
        let _produkt = {...e, printPreis: preisDrucken};
        etikettenService.printPreisEtikett(_produkt, anzahlEtiketten);
    }

    const printRegalEtikett = (e) => {
        let p = {...e};
        try {
            let l = lieferanten.filter(x => x.ID === p.LieferantenID)[0];
            p.Lieferant=l;
        } catch {}
        try {
            let produzent = produzenten.filter(x => x.ID === p.ProduzentID)[0];
            p.Produzent=produzent;
        } catch {}
        console.log(p);
        etikettenService.printRegaletikett(p, anzahlRegaletiketten);
    }
    
    const filterBezeichnung = (event) => {
        let results = filteredProdukte.filter((produkt) => {
            return produkt.Bezeichnung.toLowerCase().includes(event.query.toLowerCase());
        });
        results = results.map(function(produkt) {
           return produkt.Bezeichnung; 
        });
        setFilteredBezeichnungen(results);
    }

    const onBezeichnungChange = (event) => {
        dt.current.filter(event.value, 'Bezeichnung', 'contains');
        setSelectedBezeichnung(event.value);
    }

    const bezeichnungFilter = () => {
        return (
            <AutoComplete dropdown forceSelection value={selectedBezeichnung} suggestions={filteredBezeichnungen} completeMethod={filterBezeichnung}
            placeholder="Bezeichnung" minLength={1} onChange={onBezeichnungChange}
            inputStyle={{width: '100%'}}
            style={{width: '100%'}} />
        );
    }

    const renderLieferantenFilter = () => {
		return (
			<MultiSelect className="column-filter" value={selectedLieferanten} options={lieferanten}
                onChange={e => { setSelectedLieferanten(e.target.value); dt.current.filter(e.target.value, 'Lieferant', 'in'); }}
                placeholder="Alle" optionLabel="Name" optionValue="Name" maxSelectedLabels={1} filter />
		);
	};

    const renderProduzentenFilter = () => {
        return (
            <MultiSelect className="column-filter" value={selectedProduzenten} options={produzenten}
                onChange={e => {
                    setSelectedProduzenten(e.target.value);
                    dt.current.filter(e.target.value, 'Produzent', 'in');
                
                }}
                placeholder="Alle" optionLabel="Name" optionValue="Name" maxSelectedLabels={1} filter />
        );
    }
    
    const filterBarcode = (event) => {
        let results = filteredProdukte.filter((produkt) => {
            if (produkt.Barcode !== undefined)
                return produkt.Barcode?.includes(event.query);
        });
        results = results.map(function(produkt) {
           return produkt.Barcode; 
        });
        setFilterdBarcode(results);
    }
    
    const onBarcodeChange = (event) => {
        dt.current.filter(event.value, 'Barcode', 'contains');
        setSelectedBarcode(event.value);
    }
    
    const renderBarcodeFilter = () => {
        return (
            <AutoComplete value={selectedBarcode} suggestions={filteredBarcode} completeMethod={filterBarcode}
            placeholder="Barcode eingeben" minLength={1} onChange={onBarcodeChange} 
            inputStyle={{width: '100%'}}
            style={{width: '100%'}}/>
        );
    }

    const onEinheitChange = (e) => {
        dt.current.filter(e.value, 'Einheit', 'contains');
        setSelectedEinheit(e.value);
    }

    const renderEinheitFilter = () => {
        return(
            <MultiSelect placeholder="Alle" options={einheiten}
                value={selectedEinheit}
                onChange={onEinheitChange}
                style={{width: '100%'}}
            />
        );
    }
    
    const onNeuesProduktClick = () => {
        history.push("/produkt/neu");
    }

    const preislisteExportieren = () => {
        setShowSpinner(true);
		exportService.downloadPreisliste(Object.entries(exportOptions).filter(option => option[1]).map(option => option[0])).then(() => {
			setShowSpinner(false);
            setExportDialogVisible(false);
		}).catch(() => {
			setShowSpinner(false);
			toast?.current?.show({severity:'error', summary: 'Fehler', detail:'Exportieren fehlgeschlagen', life: 2000});
		});
    };

    const footer = (
        <div>
            <Button label="Drucken" icon="pi pi-check" onClick={(e) => {setDialogVisible(false); print(produkt)}} />
            <Button label="Abbrechen" icon="pi pi-times" onClick={(e) => {setDialogVisible(false)}} />
        </div>
    );

    const regaletikettenFooter = (
        <div>
            <Button label="Drucken" icon="pi pi-check" onClick={(e) => {setDialogRegaletikettenVisible(false); printRegalEtikett(produkt)}} />
            <Button label="Abbrechen" icon="pi pi-times" onClick={(e) => {setDialogRegaletikettenVisible(false)}} />
        </div>
    );

    const rabattFooter = (
        <div>
            <Button label="Drucken" icon="pi pi-check" onClick={(e) => {setRabattDialogMengeVisible(false);}} />
            <Button label="Abbrechen" icon="pi pi-times" onClick={(e) => {
                setRabatt({...rabatt,
                    Grund: undefined,
                    Rabatt: undefined,
                    Verderbdatum: undefined,
                });
                setRabattDialogMengeVisible(false);
            }} />
        </div>
    );

    const onProduktPreiseLoaded = (e) => {
        console.log(e);
        let date = new Date(e.Datum);
        let today = new Date();
        if (date < today)
            date = today;
        setDatum(date);
        setEK(e.EK);
        setSpanne(e.Spanne);
        setVK(e.VK);
        setVK2(e.VK2);
        setVK3(e.VK3);
        if (e.Pfand === null)
            setPfand(0);
        else
            setPfand(e.Pfand);
        setProduktID(e.ProduktID);
        setProduktPreisiD(e.ID);
    }

    const onNurProduktsucheAnzeigen = (e) => {
        console.log(e);
        setNurinderproduktsuche(e.checked);
        if (e.checked === true)
        {
            setOrigProdukte(produkte);
            let _produkte = [];
            produkte.map(function(produkt) {
                if ((produkt.Barcode === '' || produkt.Einheit === 'KG') && produkt.KassenCount > 0)
                _produkte.push(produkt); 
             });
             setProdukte(_produkte);
        }
        else 
        {
            setProdukte(origProdukte);
        }
    }


    const onPreisLoadError = (e) => 
    {
        setDatum(new Date());
        setEK(null);
        setVK(null);
        setVK2(null)
        setVK3(null)
        setPfand(null)
    }

    const onRowClicked = (e) => {
        produktservice.getProduktPreis(e.data.ID).then(onProduktPreiseLoaded).catch(onProduktPreiseLoaded);
        setSelectedProdukt2(e.data);
        if (selectedProdukt !== e.originalEvent.target.parentElement) {
            let className = e.originalEvent.target.parentElement.className;
            if (selectedProdukt !== undefined && selectedProdukt !== null)
                selectedProdukt.className = selectedProdukt.className.replace(' p-highlight', '');
            setSelectedProdukt(e.originalEvent.target.parentElement);
                e.originalEvent.target.parentElement.className = className + " p-highlight";
        }
    }

    const onSpeichernError = (e) => {
        toast.current.show({severity:'error', summary: 'Fehler', detail:'Fehler beim Speichern', life: 3000});
    }

    const onSpeichernSuccess = (e) => {
        /*
        setVK(null);
        setVK2(null);
        setVK3(null);
        setEK(null);
        setDatum(null);
        setPfand(null);
        setProduktID(null);
        setProduktPreisiD(null);
        */
        toast.current.show({severity:'success', summary: 'Erfolgreich', detail:'Preis gespeichert', life: 3000});
    }

    const onSpeichernClick= async (e) => {
        console.log(datum);
        
        if (ek > vk)
        {
            toast.current.show({severity:'error', summary: 'Fehler', detail:'Einkaufspreis darf nicht höher als der Verkaufspreis sein', life: 2000});
            return;
        }
        
        let preis =  {
            ID: produktPreisID,
            ProduktID: produktID,
            Datum: datum,
            Spanne: spanne,
            VK: vk,
            VK2: vk2,
            VK3: vk3,
            EK: ek,
            Pfand: pfand ?? 0
        }

        if (preis.ProduktID === undefined || preis.ProduktID === null || preis.ID === undefined || preis.ID === null || preis.Datum === undefined || preis.Datum === null || preis.EK === undefined || preis.EK === null || preis.VK === undefined || preis.VK === null || preis.Spanne === undefined || preis.Spanne === null || preis.Pfand === undefined || preis.Pfand === null) {
            onSpeichernError();
            return;
        }
        const alterPreis = await produktservice.getProduktPreis(produktID);
        produktservice.updatePreis(preis).then(() => {
            onSpeichernSuccess();
            if (alterPreis.VK !== preis.VK) {
                setProdukt(selectedProdukt2);
                setAnzahlRegaletiketten(selectedProdukt2.KassenCount);
                setDialogRegaletikettenVisible(true);
            }
        }).catch(onSpeichernError);
    }

    const onSelectedChange = (e) => {
        setSelectedProdukt(e.value);
    }

    const onCalendarChange = (e) => {
        try {
            console.log(e);
            let _date = e.value;
            _date.setTime(_date.getTime() + (2*60*60*1000));
        
            console.log(_date);
            setDatum(_date);
        } catch (e) {
             setDatum(new Date());
        }
    }


    const header = renderHeader();
    const warengruppenFilter = renderWarengruppenFilter();
    const bezeichnungsFilter = bezeichnungFilter();
    const barcodeFilter = renderBarcodeFilter();
    const einheitFilter = renderEinheitFilter();;
    const lieferantenFilter = renderLieferantenFilter();
    const produzentenFilter = renderProduzentenFilter();

    return (
        <React.Fragment>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-6">
                    <Button label="neues Produkt" icon="pi pi-plus-circle" className="mr-3" onClick={onNeuesProduktClick} />
                    <Checkbox id="produktsucheanzeigen" checked={nurinderproduktsuche} onChange={onNurProduktsucheAnzeigen} />
                    <label htmlFor="produktsucheanzeigen" className="ml-1 p-component">nur in der Produktsuche vorhandene Artikel anzeigen</label>                    
                </div>
                <div className="col-6" style={{textAlign: "right"}}>
                    <Button label="Preisliste exportieren" icon="pi pi-download" onClick={() => {setExportOptions({
                        ID: false,
                        Bezeichnung: false,
                        Einheit: false,
                        Kategorie: false,
                        Warengruppe: false,
                        Artikelgruppe: false,
                        Barcode: false,
                        Steuersatz: false,
                        EK: false,
                        Spanne: false,
                        VK: false,
                        VK2: false,
                        VK3: false,
                        ['Barcode preisabhängig']: false,
                        ['Barcode gewichtsabhängig']: false,
                        Lieferant: false,
                        Produzent: false,
                        Herkunft: false,
                        aktiviert: false,
                        ['Bestände']: false,
                        ['nur aktivierte Produkte']: false
                    }); setExportDialogVisible(true)}} />
                </div>
                <div className="col-12">
                    <DataTable scrollable scrollHeight="50vh" ref={dt}
                        value={produkte.map(p => ({...p, Lieferant: p.Lieferant ?? 'kein Lieferant'}))}
                        filterDisplay="row"
                        tableClassName='p-datatable-produktstammdaten'
                        onValueChange={filteredData => setFilteredProdukte(filteredData)}
                        header={header} responsive="true" className="p-datatable-produkte p-datatable-striped" dataKey="id" rowHover globalFilter={globalFilter}
                        paginator rows={20} emptyMessage="keine Produkte gefunden" currentPageReportTemplate='({first} von {totalRecords})'
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[20,30,40]}
                        onRowClick={onRowClicked}
                    >
                        <Column
                            field="ID" sortable filter header="ID"
                            bodyStyle={{display: "none"}}
                            headerStyle={{display:"none"}}
                            filterHeaderStyle={{display:"none"}}
                            showFilterMenu={false}
                            />
                        <Column field="Bezeichnung" filter filterPlaceholder="Bezeichnung eingeben"
                            sortable header="Bezeichnung" filterElement={bezeichnungsFilter}
                            showFilterMenu={false}
                            onFilterClear={() => setSelectedBezeichnung(null)}
                            />
                        <Column field="Warengruppe" filter filterPlaceholder="Warengruppe eingeben"
                            sortable header="Warengruppe" filterElement={warengruppenFilter}
                            showFilterMenu={false}
                            onFilterClear={() => setSelectedProduktgruppen(null)}
                            />
                        <Column field="Barcode" filter filterPlaceholder="Barcode eingeben" bodyStyle={{overflow: 'hidden'}}
                            sortable header="Barcodes" filterElement={barcodeFilter}
                            showFilterMenu={false}
                            onFilterClear={() => setSelectedBarcode(null)}
                            />
                        <Column field="Lieferant"
                            filter
                            filterPlaceholder="Lieferant eingeben"
                            sortable header="Lieferant"
                            filterElement={lieferantenFilter}
                            showFilterMenu={false}
                            onFilterClear={() => setSelectedLieferanten(null)}
                            />
                        <Column field="Produzent"
                            filter
                            filterPlaceholder='Produzent eingeben'
                            sortable header='Produzent'
                            filterElement={produzentenFilter}
                            showFilterMenu={false}
                            onFilterClear={() => setSelectedProduzenten(null)}
                            />
                        <Column
                            field="Einheit" filter
                            filterPlaceholder="Einheit wählen"
                            sortable header="Einheit"
                            filterElement={einheitFilter}
                            headerStyle={{maxWidth: '10em'}}
                            style={{maxWidth: '10em'}}
                            bodyStyle={{maxWidth: '10em'}}
                            onFilterClear={() => setSelectedEinheit(null)}
                            showFilterMenu={false}
                            />
                        <Column
                            header="Aktionen" body={actionBodyTemplate}
                            headerStyle={{ justifyContent: 'center', maxWidth: '10em' }}
                            bodyStyle={{textAlign: 'center', overflow: 'visible', maxWidth: '10em'}}
                            style={{maxWidth: '10em'}}
                            showFilterMenu={false}
                            />
                    </DataTable>
                </div>
                <Divider />
                <div className="col-12 grid p-component">
                    <div className="col">
                        <div className="field grid">
                            <label htmlFor="datum" className="col-fixed" style={{width: preisLabelWidth}}>Datum</label>
                            <div className="col">
                                <Calendar id="datum" value={datum} onChange={onCalendarChange} showIcon={true} inputStyle={{width: preisInputWidth}} 
                                minDate={minDate} locale="de"/>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="field grid">
                            <label htmlFor="ek" className="col-fixed" style={{width: preisLabelWidth}}>EK (netto)</label>
                            <div className="col">
                                <InputNumber id="ek" value={ek} onChange={onEkChange} mode="currency" currency="EUR" locale="de-DE" inputStyle={{width: preisInputWidth}}/>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="field grid">
                            <label htmlFor="spanne" className="col-fixed" style={{width: preisLabelWidth}}>Rohmarge</label>
                            <div className="col">
                                <InputNumber id="spanne" value={spanne} onChange={onSpanneChange} showButtons suffix=" %"
                                    inputStyle={{width: '80px'}}
                                    />
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="field grid">
                            <label htmlFor="vk" className="col-fixed" style={{width: preisLabelWidth}}>VK (brutto)</label>
                            <div className="col">
                                <InputNumber id="vk" value={vk} onChange={onVkChange} mode="currency" currency="EUR" locale="de-DE" inputStyle={{width: preisInputWidth}}/>
                            </div>
                        </div>
                        <div className="field grid">
                            <label htmlFor="vk2" className="col-fixed" style={{width: preisLabelWidth}}>VK2 (brutto)</label>
                            <div className="col">
                                <InputNumber id="vk2" value={vk2} onChange={e => setVK2(e.value)} mode="currency" currency="EUR" locale="de-DE" inputStyle={{width: preisInputWidth}}/>
                            </div>
                        </div>
                        <div className="field grid">
                            <label htmlFor="vk3" className="col-fixed" style={{width: preisLabelWidth}}>VK3 (brutto)</label>
                            <div className="col">
                                <InputNumber id="vk3" value={vk3} onChange={e => setVK3(e.value)} mode="currency" currency="EUR" locale="de-DE" inputStyle={{width: preisInputWidth}}/>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="field grid">
                            <label htmlFor="pfand" className="col-fixed" style={{width: preisLabelWidth}}>Pfand (brutto)</label>
                            <div className="col">
                                <InputNumber id="pfand" value={pfand} onChange={e => setPfand(e.value)} mode="currency" currency="EUR" locale="de-DE" inputStyle={{width: preisInputWidth}}/>
                            </div>
                        </div>
                    </div>
                    <div className="field col">
                        <Button label="Speichern" icon="pi pi-save" onClick={onSpeichernClick}></Button>
                    </div>
                </div>
            </div>

            <RabattAuswahlDialog    visible={rabattDialogVisible}
                                    buttonClick={rabattButtonClick}
                                    Grund={rabatt.Grund}
                                    onGrundChange={(e) => setRabatt({...rabatt, Grund: e.value })}
                                    setVisible={(e) => setRabattDialogVisible(e)}
                                    onHide={() => setRabattDialogVisible(false)}
                                    Kassa={rabatt.KassenID}
                                    Verderbdatum={rabatt.Verderbdatum}
                                    onVerderbDatumChange={(e) => setRabatt({...rabatt, Verderbdatum: e.value})}
                                    onKassaChange={(e) => setRabatt({...rabatt, KassenID: e.value})}
                                    />

            <Dialog header="Anzahl?" visible={dialogVisible} style={{ width: '30vw' }} footer={footer} onHide={() => setDialogVisible(false)} >
                <div className='grid'>
                    <div className='col-12'>
                        <div className="p-d-block p-pb-4">Wie viele Etiketten sollen gedruckt werden?</div>
                        <div className="p-d-block">
                            <InputNumber value={anzahlEtiketten} onValueChange={(e) => setAnzahlEtiketten(e.target.value)} mode="decimal" showButtons buttonLayout="vertical" style={{width: '6em'}}
                            decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className="p-d-block">
                            <Checkbox className='mr-3' checked={preisDrucken} onChange={(e) => setPreisDrucken(e.checked)} />Preis drucken?
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog header="Anzahl?" visible={dialogRegaletikettenVisible} style={{ width: '30vw' }} footer={regaletikettenFooter} onHide={() => setDialogRegaletikettenVisible(false)} >
                <div className="p-d-block p-pb-4">Wie viele Regaletiketten sollen gedruckt werden?</div>
                <div className="p-d-block">
                    <InputNumber value={anzahlRegaletiketten} onValueChange={(e) => setAnzahlRegaletiketten(e.target.value)} mode="decimal" showButtons buttonLayout="vertical" style={{width: '6em'}}
                    decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                </div>
                { produkt?.Herkunft === 0 &&
                    <div className="p-d-block p-pt-4">Das Produkt ist Regional! Nicht vergessen das Etikett auf das richtige Regalschild zu kleben.</div>
                }
            </Dialog>
            <Dialog header="Anzahl?" visible={rabattDialogMengeVisible} style={{ width: '30vw' }} footer={rabattFooter} onHide={() => setRabattDialogMengeVisible(false)} >
                <div className="p-d-block p-pb-4">Wie viele Etiketten sollen gedruckt werden?</div>
                <div className="p-d-block">
                    <InputNumber value={anzahlRabattEtiketten} onValueChange={(e) => setAnzahlRabattEtiketten(e.target.value)} mode="decimal" showButtons buttonLayout="vertical" style={{width: '6em'}}
                    decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                </div>
            </Dialog>
            <Dialog visible={exportDialogVisible} onHide={() => setExportDialogVisible(false)} header="Preisliste exportieren"
                footer={
                    <React.Fragment>
                        <Button label="Preisliste exportieren" icon="pi pi-download" onClick={preislisteExportieren}/>
                        {showSpinner ? <i className="pi pi-spin pi-spinner ml-2" style={{fontSize: '2rem'}}></i> : <React.Fragment></React.Fragment>}
                    </React.Fragment>}>
                <div className="field">
					<Checkbox inputId="ExportID" checked={exportOptions.ID} onChange={e => setExportOptions({...exportOptions, ID: e.target.checked})} />
					<label htmlFor="ExportID" className="mb-0 ml-1">ID</label><br/>
				</div>
                <div className="field">
					<Checkbox inputId="ExportBezeichnung" checked={exportOptions.Bezeichnung} onChange={e => setExportOptions({...exportOptions, Bezeichnung: e.target.checked})} />
					<label htmlFor="ExportBezeichnung" className="mb-0 ml-1">Bezeichnung</label><br/>
				</div>
                <div className="field">
					<Checkbox inputId="ExportVEEinheit" checked={exportOptions.VEEinheit} onChange={e => setExportOptions({...exportOptions, VEEinheit: e.target.checked})} />
					<label htmlFor="ExportVEEinheit" className="mb-0 ml-1">Verrechnungseinheit</label><br/>
				</div>
                <div className="field">
					<Checkbox inputId="ExportGPEinheit" checked={exportOptions.GPEinheit} onChange={e => setExportOptions({...exportOptions, GPEinheit: e.target.checked})} />
					<label htmlFor="ExportGPEinheit" className="mb-0 ml-1">GPEinheit</label><br/>
				</div>
                <div className="field">
					<Checkbox inputId="ExportVPEinheit" checked={exportOptions.ExportVPEinheit} onChange={e => setExportOptions({...exportOptions, ExportVPEinheit: e.target.checked})} />
					<label htmlFor="ExportVPEinheit" className="mb-0 ml-1">ExportVPEinheit</label><br/>
				</div>
                <div className="field">
					<Checkbox inputId="ExportKategorie" checked={exportOptions.Kategorie} onChange={e => setExportOptions({...exportOptions, Kategorie: e.target.checked})} />
					<label htmlFor="ExportKategorie" className="mb-0 ml-1">Kategorie</label><br/>
				</div>
                <div className="field">
					<Checkbox inputId="ExportWarengruppe" checked={exportOptions.Warengruppe} onChange={e => setExportOptions({...exportOptions, Warengruppe: e.target.checked})} />
					<label htmlFor="ExportWarengruppe" className="mb-0 ml-1">Warengruppe</label><br/>
				</div>
                <div className="field">
					<Checkbox inputId="ExportArtikelgruppe" checked={exportOptions.Artikelgruppe} onChange={e => setExportOptions({...exportOptions, Artikelgruppe: e.target.checked})} />
					<label htmlFor="ExportArtikelgruppe" className="mb-0 ml-1">Artikelgruppe</label><br/>
				</div>
                <div className="field">
					<Checkbox inputId="ExportBarcode" checked={exportOptions.Barcode} onChange={e => setExportOptions({...exportOptions, Barcode: e.target.checked})} />
					<label htmlFor="ExportBarcode" className="mb-0 ml-1">Barcode</label><br/>
				</div>
                <div className="field">
					<Checkbox inputId="ExportSteuersatz" checked={exportOptions.Steuersatz} onChange={e => setExportOptions({...exportOptions, Steuersatz: e.target.checked})} />
					<label htmlFor="ExportSteuersatz" className="mb-0 ml-1">Steuersatz</label><br/>
				</div>
                <div className="field">
					<Checkbox inputId="ExportEK" checked={exportOptions.EK} onChange={e => setExportOptions({...exportOptions, EK: e.target.checked})} />
					<label htmlFor="ExportEK" className="mb-0 ml-1">EK</label><br/>
				</div>
                <div className="field">
					<Checkbox inputId="ExportSpanne" checked={exportOptions.Spanne} onChange={e => setExportOptions({...exportOptions, Spanne: e.target.checked})} />
					<label htmlFor="ExportSpanne" className="mb-0 ml-1">Rohmarge</label><br/>
				</div>
                <div className="field">
					<Checkbox inputId="ExportVK" checked={exportOptions.VK} onChange={e => setExportOptions({...exportOptions, VK: e.target.checked})} />
					<label htmlFor="ExportVK" className="mb-0 ml-1">VK</label><br/>
				</div>
                <div className="field">
					<Checkbox inputId="ExportVK2" checked={exportOptions.VK2} onChange={e => setExportOptions({...exportOptions, VK2: e.target.checked})} />
					<label htmlFor="ExportVK2" className="mb-0 ml-1">VK2</label><br/>
				</div>
                <div className="field">
					<Checkbox inputId="ExportVK3" checked={exportOptions.VK3} onChange={e => setExportOptions({...exportOptions, VK3: e.target.checked})} />
					<label htmlFor="ExportVK3" className="mb-0 ml-1">VK3</label><br/>
				</div>
                <div className="field">
					<Checkbox inputId="ExportBarcodePreisabhaengig" checked={exportOptions['Barcode preisabhängig']} onChange={e => setExportOptions({...exportOptions, ['Barcode preisabhängig']: e.target.checked})} />
					<label htmlFor="ExportBarcodePreisabhaengig" className="mb-0 ml-1">Barcode preisabhängig</label><br/>
				</div>
                <div className="field">
					<Checkbox inputId="ExportBarcodeGewichtsabhaengig" checked={exportOptions['Barcode gewichtsabhängig']} onChange={e => setExportOptions({...exportOptions, ['Barcode gewichtsabhängig']: e.target.checked})} />
					<label htmlFor="ExportBarcodeGewichtsabhaengig" className="mb-0 ml-1">Barcode gewichtsabhängig</label><br/>
				</div>
                <div className="field">
					<Checkbox inputId="ExportLieferant" checked={exportOptions.Lieferant} onChange={e => setExportOptions({...exportOptions, Lieferant: e.target.checked})} />
					<label htmlFor="ExportLieferant" className="mb-0 ml-1">Lieferant</label><br/>
				</div>
                <div className="field">
					<Checkbox inputId="ExportProduzent" checked={exportOptions.Produzent} onChange={e => setExportOptions({...exportOptions, Produzent: e.target.checked})} />
					<label htmlFor="ExportProduzent" className="mb-0 ml-1">Produzent</label><br/>
				</div>
                <div className="field">
					<Checkbox inputId="ExportHerkunft" checked={exportOptions.Herkunft} onChange={e => setExportOptions({...exportOptions, Herkunft: e.target.checked})} />
					<label htmlFor="ExportHerkunft" className="mb-0 ml-1">Herkunft</label><br/>
				</div>
                <div className="field">
					<Checkbox inputId="ExportAktiviert" checked={exportOptions.aktiviert} onChange={e => setExportOptions({...exportOptions, aktiviert: e.target.checked})} />
					<label htmlFor="ExportAktiviert" className="mb-0 ml-1">Aktiviert</label><br/>
				</div>
                <div className="field">
					<Checkbox inputId="ExportBestaende" checked={exportOptions['Bestände']} onChange={e => setExportOptions({...exportOptions, ['Bestände']: e.target.checked})} />
					<label htmlFor="ExportBestaende" className="mb-0 ml-1">Bestände</label><br/>
				</div>
                <div className="field">
					<Checkbox inputId="ExportRabattfähig" checked={exportOptions.Rabattfaehig} onChange={e => setExportOptions({...exportOptions, Rabattfähig: e.target.checked})} />
					<label htmlFor="ExportRabattfähig" className="mb-0 ml-1">rabattfähig</label><br/>
				</div>
                <Button label="Alle auswählen" onClick={() => setExportOptions({
					ID: true,
					Bezeichnung: true,
					VEEinheit: true,
                    GPEinheit: true,
                    VPEinheit: true,
					Artikelgruppe: true,
                    Warengruppe: true,
                    Kategorie: true,
					Barcode: true,
					Steuersatz: true,
					EK: true,
					Spanne: true,
					VK: true,
                    VK2: true,
                    VK3: true,
                    ['Barcode preisabhängig']: true,
                    ['Barcode gewichtsabhängig']: true,
                    Lieferant: true,
                    Produzent: true,
                    Herkunft: true,
                    aktiviert: true,
                    ['Bestände']: true,
                    Rabattfaehig: true,
				})}/>
			</Dialog>
            <Dialog header="Barcode scannen"
                    style={{width: '700px'}}
                    visible={barcodeScannenDialogVisible}>
                <InputText value={barcode} onChange={(e) => setBarcode(e.target.value)} />
            </Dialog>
        </React.Fragment>
    );
};

export default Produktstammdaten;
