import React, {useRef, useState, useEffect, useContext} from "react";
import { KassenService } from "../service/KassenService";
import { WareneingangsjournalService } from '../service/WareneingangsjournalService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { addDE } from './LocalDE';
import { GlobalState } from './GlobalState';
import { LieferantenService } from '../service/LieferantenService';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { ImportService } from '../service/ImportService';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { AuthService } from '../service/AuthService';
import { ProduktService } from "../service/ProduktService";
import { FilterService } from 'primereact/api';
import { InputText } from 'primereact/inputtext';

import CONFIG from 'config';

function Wareneingangsjournal(props)
{
	const [wareneingangsjournal, setWareneingangsjournal] = useState([]);
	const [wareneingangsjournalDisplay, setWareneingangsjournalDisplay] = useState([]);
	const [datumsbereich, setDatumsbereich] = useState([new Date(), new Date()]);
	const [exportDatumsbereich, setExportDatumsbereich] = useState([new Date(), new Date()]);
	const [selectedKassen, setSelectedKassen] = useState(null);
	const [selectedExportKassen, setSelectedExportKassen] = useState(null);
	const [kassen, setKassen] = useState([]);
	const [globalState, setGlobalState] = useContext(GlobalState);
	const [lieferanten, setLieferanten] = useState([]);
	const [selectedLieferanten, setSelectedLieferanten] = useState([]);
	const [gruende, setGruende] = useState([]);
	const [selectedGrund, setSelectedGrund] = useState([]);
	const [fileUploadEmpty, setFileUploadEmpty] = useState(true);
	const [roles, setRoles] = useState([]);
	const [produkte, setProdukte] = useState([]);
	const [selectedProdukte, setSelectedProdukte] = useState([]);
	// eslint-disable-next-line
	const [showSpinner, setShowSpinner] = useState(false);
	const [exportDialogVisible, setExportdialogVisible] = useState(false);
    const [zeit, setZeit] = useState('');
    const [zeitOption, setZeitOption] = useState(null);
	const kassenService = new KassenService();
	const lieferantenService = new LieferantenService();
	const wareneingangsjournalService = new WareneingangsjournalService();
	const importService = new ImportService();
	const authService = new AuthService();
	const produktService = new ProduktService();
	const dt = useRef(null);
	const importUploadRef = useRef(null);
	const toastRef = useRef(null);

	FilterService.register("timeFilter", (a, b) => {
		if (a === null || a === undefined || zeit === null || zeit === undefined)
			return true;
		let value = a;
		let filter = zeit;
		const [valueHour, valueMinute, valueSecond] = value.split(':').map(str => {const n = Number(str); return isNaN(n) ? undefined : n});
		const valueTime = ((valueHour ?? 0) * 60 + (valueMinute ?? 0)) * 60 + (valueSecond ?? 0);
		const [filter1, filter2] = (filter ?? '').split('-').map(str => str.trim());
		const [filter1Hour, filter1Minute, filter1Second] = (filter1 ?? '').split(':').map(str => {const n = Number(str); return isNaN(n) ? undefined : n});
		const [filter2Hour, filter2Minute, filter2Second] = (filter2 ?? filter1).split(':').map(str => {const n = Number(str); return isNaN(n) ? undefined : n});
		const filter1Time = ((filter1Hour ?? 0) * 60 + (filter1Minute ?? 0)) * 60 + (filter1Second ?? 0);
		const filter2Time = ((filter2Hour ?? 23) * 60 + (filter2Minute ?? 59)) * 60 + (filter2Second ?? 59);
		return filter1Time <= valueTime && valueTime <= filter2Time;
	});

	const filters = {
        'Zeit': { value: null, matchMode: "timeFilter" }
    };

	addDE();

	function onlyUnique(value, index, self) {
		return self.indexOf(value) === index;
	}
	
	const onDatumsbereichChange = e => {
		setDatumsbereich(e.target.value);
		if(e.target.value !== undefined && e.target.value !== null && e.target.value.length === 2 && e.target.value[0] !== undefined && e.target.value[0] !== null && e.target.value[1] !== undefined && e.target.value[1] !== null) {
			wareneingangsjournalService.getDataFromTo(e.target.value[0], e.target.value[1])
			.then(data => setWareneingangsjournal(data.map(
				w => ({...w,
					Zeit: new Date(w.Datum).toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit', second: undefined }),
					EK: w.EK.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
				})
			)));
		}
	}

	const onExportDatumsbereichChange = e => {
		setExportDatumsbereich(e.target.value);
	}

	const importWareneingang = async () => {
		try {
			const response = await importService.upload(importUploadRef.current.files?.[0], importUploadRef.current.props.name, CONFIG.ServerUrl + '/Wareneingaenge/Import');
			if (response.ok) {
				importUploadRef.current.clear();
				onDatumsbereichChange({target: {value: datumsbereich}});
			} else {
				const error = await response.json();
				toastRef.current.show({severity:'error', summary: error.summary, detail: error.detail, life: 3000});
			}
		} catch (e) {
			console.log(e);
			toastRef.current.show({severity:'error', summary: 'Der import ist fehlgeschlagen', life: 3000});
		}
	}

	useEffect(() => {
		setGlobalState({...globalState, page: 'Wareneingangsjournal'});
		kassenService.getKassen().then(data => setKassen(data));
		onDatumsbereichChange({target: {value: [new Date(), new Date()]}});
		lieferantenService.getAll().then(data => setLieferanten(data));
		authService.getTokenInfo().then(info => setRoles(info.roles.split(';')));
		produktService.getAll().then((data) => {
			let results = data.filter(onlyUnique);
			setProdukte(results);
		});
		wareneingangsjournalService.getGruende().then(setGruende);
		// eslint-disable-next-line
	}, []);
	
	useEffect(() => {
		setWareneingangsjournalDisplay(wareneingangsjournal?.map(ware => ({...ware, origDatum: ware.Datum, Datum: new Date(ware.Datum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'})})));
	}, [wareneingangsjournal]);

	const renderKassenFilter = () => {
		return (
			<MultiSelect className="column-filter" value={selectedKassen} options={kassen} onChange={e => { setSelectedKassen(e.target.value); dt.current.filter(e.target.value, 'KassenID', 'in'); }} placeholder="Alle" optionLabel="KassenID" optionValue="KassenID" maxSelectedLabels={1}/>
		);
	};

	const renderDatumFilter = () => {
		return (
			<Calendar id="datumsbereich" className="column-filter" value={datumsbereich} onChange={onDatumsbereichChange} locale="de" showIcon={true} selectionMode="range"/>
		);
	}

	const renderLieferantenFilter = () => {
		return (
			<MultiSelect className="column-filter" value={selectedLieferanten} options={lieferanten} onChange={e => { setSelectedLieferanten(e.target.value); dt.current.filter(e.target.value, 'Lieferant', 'in'); }} placeholder="Alle" optionLabel="Name" optionValue="Name" maxSelectedLabels={1} filter/>
		);
	};

	const renderGrundFilter = () => {
		return (
			<MultiSelect className="column-filter" value={selectedGrund} options={gruende} onChange={e => { setSelectedGrund(e.target.value); dt.current.filter(e.target.value, 'Grund', 'in'); }} placeholder="Alle" maxSelectedLabels={1}/>
		);
	}

	const kassenFilter = renderKassenFilter();
	const datumFilter = renderDatumFilter();
	const lieferantenFilter = renderLieferantenFilter();
	const grundFilter = renderGrundFilter();

	const leftContents = (
		<React.Fragment>
			<FileUpload ref={importUploadRef} chooseOptions={{label: 'Import', icon: 'pi pi-fw pi-file-excel'}}
				name="wareneingang" customUpload mode="basic" accept=".xlsx" style={{display: 'inline-block'}}
				onSelect={() => setFileUploadEmpty(importUploadRef.current.files === undefined)} onClear={() => setFileUploadEmpty(true)}/>
			<Button className="ml-1" icon="pi pi-times" onClick={() => importUploadRef.current.clear()} disabled={fileUploadEmpty}/>
			<Button className="ml-1" icon="pi pi-upload" onClick={importWareneingang} disabled={fileUploadEmpty}/>
		</React.Fragment>
	)

	const rightContents = (
		<React.Fragment>
			<Button style={{textAlign: "right"}} label="exportieren" icon="pi pi-download"
				onClick={() => setExportdialogVisible(true)}
			/>
		</React.Fragment>
	)


	const onExportClick = () => {
		wareneingangsjournalService.downloadBericht(
			exportDatumsbereich[0],
			exportDatumsbereich[1],
			selectedExportKassen
		).then(() => {
			setShowSpinner(false);
		}).catch((e) => {
			console.log(e);
			setShowSpinner(false);
			toastRef?.current?.show({severity:'error', summary: 'Fehler', detail:'Exportieren fehlgeschlagen', life: 2000});
		});
	}

	const bezeichnungFilter =
		<MultiSelect className="column-filter" value={selectedProdukte} options={produkte}
		onChange={e => { console.log(e); setSelectedProdukte(e.target.value); dt.current.filter(e.target.value, "Produkt", "in"); }}
		placeholder="Produkt" optionLabel="Bezeichnung" optionValue="Bezeichnung" maxSelectedLabels={1} filter filterMatchMode="contains"
		/>
	
	function onSortFunction(event) {
		let sortedValues = [...wareneingangsjournalDisplay];
		sortedValues.sort((data1, data2) => {
			let result = null;
			if (data1 == null && data2 != null)
				result = -1;
			else if (data1 != null && data2 == null)
				result = 1;
			else if (data1 == null && data2 == null)
				result = 0;
			else if (new Date(data1.origDatum) > new Date(data2.origDatum))
				result = 1;
			else if (new Date(data1.origDatum) < new Date(data2.origDatum))
				result = -1;
			else
				result = 0;
			return event.order * result;
		});
		return sortedValues;
	}

	const zeitFilter = (option) => {
		return <InputText value={zeit} onChange={(e) => {
			setZeit(e.target.value);
			setZeitOption(option);
		}} />
	}

	useEffect(() => {
		zeitOption?.filterCallback(zeit);
		zeitOption?.filterApplyCallback()
	}, [zeit, zeitOption])

	return(
		<React.Fragment>
			<Toast ref={toastRef}/>
			<div className="grid">
				{ roles.includes('Chef') &&
					<div className="col-12">
						<Toolbar left={leftContents} right={rightContents} style={{padding: 0, border: 0}} />
					</div>
				}
				<div className="col-12">
					<DataTable
						filterDisplay="row"
						ref={dt}
						responsive="true"
						className="p-datatable-wareneingansjournal"
						dataKey="id"
						value={wareneingangsjournalDisplay}
						rowHover paginator rows={20}
						emptyMessage="keine Wareneingänge gefunden"
						currentPageReportTemplate='({first} von {totalRecords})'
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
						rowsPerPageOptions={[20,30,40]}
						sortField="Datum"
						sortOrder={-1}
						scrollable
						scrollHeight="60vh"
						filters={filters}
						>
						<Column
							field="KassenID"
							filter
							filterElement={kassenFilter}
							header="Standort"
							showClearButton={false}
							showFilterMenu={false}
						/>
						<Column
							field="Datum"
							header="Datum"
							filter
							filterElement={datumFilter}
							sortable
							sortFunction={onSortFunction}
							showFilterMenu={false}
							style={{maxWidth: "12em", width: "12em"}}
							filterClear={() => setDatumsbereich(null)}
						/>
						<Column field="Zeit"
							header="Zeit"
							filter
							sortable
							style={{maxWidth: "12em", width: "12em"}}
							showFilterMenu={false}
							filterClear={() => setZeit(null)}
							filterElement={zeitFilter}
						/>
						<Column
							field="ArtNr"
							header="ArtNr"
							sortable
							style={{maxWidth: "8em", width: "8em"}}
							showFilterMenu={false}
						/>
						<Column
							field="Produkt"
							header="Produkt"
							filter
							sortable
							filterElement={bezeichnungFilter}
							showFilterMenu={false}
							filterClear={() => selectedProdukte(null)}
						/>
						<Column
							field="Lieferant"
							header="Lieferant"
							filter
							sortable
							filterElement={lieferantenFilter}
							showFilterMenu={false}
							filterClear={() => setSelectedLieferanten(null)}
						/>
						<Column
							field="Menge"
							header="Menge"
							sortable
							bodyStyle={{textAlign: "right"}}
							showFilterMenu={false}
							showClearButton={false}
							style={{width: "6em", maxWidth:"6em"}}
						/>
						<Column
							field="EK"
							header="EK"
							sortable
							body={row => <React.Fragment>&euro;&nbsp;<span style={{float: "right"}}>{row.EK}</span></React.Fragment>}
							style={{width: "6em", maxWidth: "6em"}}
						/>
						<Column
							field="Grund"
							header="Grund"
							filter
							sortable
							filterElement={grundFilter}
							showFilterMenu={false}
							style={{width: "10em", maxWidth: "10em"}}
							filterClear={() => setSelectedGrund(null)}
						/>
					</DataTable>
				</div>
			</div>
			<Dialog visible={exportDialogVisible}
				header="Wareneingangsjournal exportieren"
				footer={
					<React.Fragment>
						<Button label="Abbrechen" icon="pi pi-times" onClick={() => setExportdialogVisible(false)} />
						<Button label="Exportieren" icon="pi pi-download" onClick={() => {onExportClick(); setExportdialogVisible(false)}} />
					</React.Fragment>
				}
				onHide={() => setExportdialogVisible(false)}
				>
					<div className="grid">
						<div className="col-12">
							<label htmlFor="exportkassen" className="p-pr-2">Kassen</label>
							<MultiSelect
									id="exportkassen"
									value={selectedExportKassen}
									options={kassen}
									onChange={e => { setSelectedExportKassen(e.target.value); }}
									placeholder="Alle"
									optionLabel="KassenID"
									optionValue="KassenID"
									maxSelectedLabels={1}
								/>
						</div>
						<div className="col-12">
							<label htmlFor="exportkassen" className="p-pr-2">Datumsberich</label>
							<Calendar id="datumsbereich"
								value={exportDatumsbereich}
								onChange={onExportDatumsbereichChange}
								locale="de" showIcon={true}
								selectionMode="range"/>
						</div>
					</div>
			</Dialog>
		</React.Fragment>
	);
}

export default Wareneingangsjournal;