import axios from 'axios';
import { GetAxiosConfig } from './AuthService';
import CONFIG from 'config';

export class KassensturzService {
	async getKassenstuerze() {
		return axios.get(CONFIG.ServerUrl + '/Kassenstuerze', await GetAxiosConfig()).then(res => res.data);
	}
	
	async speichern(kassensturz) {
		return axios.post(CONFIG.ServerUrl + '/Kassensturz/Update', kassensturz, await GetAxiosConfig());
	}
}