import axios from "axios";
import { GetAxiosConfig } from "./AuthService";
import CONFIG from 'config';

export class DashboardService {
	async GetDashboardUmsatzData(kassen, day, netto) {
		try {
			return axios.post(CONFIG.ServerUrl + "/Dashboard/UmsatzData/" + (netto ? "true" : "false") + "/" + day.toISOString(), kassen, await GetAxiosConfig()).then(res => res.data).catch(e => console.log(e));
		} catch (e) {
			console.log(e);
		}
	}

	async GetDashboardBelegeData(kassen, day, netto) {
		try {
			return axios.post(CONFIG.ServerUrl + "/Dashboard/BelegeData/" + (netto ? "true" : "false") + "/" + day.toISOString(), kassen, await GetAxiosConfig()).then(res => res.data).catch(e => console.log(e));
		} catch (e) {
			console.log(e);
		}
	}

	async GetDashboardSchwundVerderbBeschaedigtData(kassen, month, netto) {
		if (month instanceof Date) {
			return axios.post(CONFIG.ServerUrl + "/Dashboard/SchwundVerderbBeschaedigtData/" + (netto ? "true" : "false") + "/" + month.toISOString(), kassen, await GetAxiosConfig()).then(res => res.data);
		} else {
			return axios.post(CONFIG.ServerUrl + "/Dashboard/SchwundVerderbBeschaedigtData/" + (netto ? "true" : "false"), kassen, await GetAxiosConfig()).then(res => res.data);
		}
	}

	async GetKategorienUmsatz(kassen, from, to, netto) {
		return axios.post(CONFIG.ServerUrl + "/Dashboard/KategorienUmsatz/" + (netto ? "true" : "false") + "/From/" + from.toISOString() + "/To/" + to.toISOString(), kassen, await GetAxiosConfig()).then(res => res.data);
	}

	async GetWarengruppenUmsatz(kassen, kategorieId, from, to, netto) {
		return axios.post(CONFIG.ServerUrl + "/Dashboard/WarengruppenUmsatz/" + kategorieId.toString() + "/" + (netto ? "true" : "false") + "/From/" + from.toISOString() + "/To/" + to.toISOString(), kassen, await GetAxiosConfig()).then(res => res.data);
	}

	async GetArtikelgruppenUmsatz(kassen, warengruppenId, from, to, netto) {
		return axios.post(CONFIG.ServerUrl + "/Dashboard/ArtikelgruppenUmsatz/" + warengruppenId.toString() + "/" + (netto ? "true" : "false") + "/From/" + from.toISOString() + "/To/" + to.toISOString(), kassen, await GetAxiosConfig()).then(res => res.data);
	}

	async GetDashboardRegionalData(kassen) {
		return axios.post(CONFIG.ServerUrl + "/Dashboard/RegionalData", kassen, await GetAxiosConfig()).then(res => res.data);
	}
}