import base64url from "base64url";
import axios from "axios";
import { GetAxiosConfig } from "./AuthService";
import CONFIG from 'config';

let timeout = null;
let jwt = null;
let username = null;
let password = null;

async function RenewToken() {
	const response = await fetch(CONFIG.ManagementApiUrl + "/RenewToken", {
		headers: {
			"Authorization": "Bearer " + jwt
		}
	});
	if (response.ok) {
		SetToken(await response.text());
	} else {
		jwt = null;
		await Login();
	}
}

function SetToken(token) {
	if(token === undefined || token === null || token === "") return;
	const timeLeft = GetTimeLeft(token);
	if (timeLeft > 0) {
		jwt = token;
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			RenewToken();
		}, timeLeft);
	}
}

function GetPayload(token) {
	return JSON.parse(base64url.decode(token.split(".")[1]));
}

function GetTimeLeft(token) {
	return (GetPayload(token).exp - 10) * 1000 - new Date().getTime();
}

async function Login() {
	if (username === undefined || username === null || password === undefined || password === null) {
		await getLogin();
	}

	const response = await fetch(CONFIG.ManagementApiUrl + "/Login", {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify({username: username, password: password})
	});
	if (response.ok) {
		SetToken(await response.text());
	}
	return response;
}

async function getLogin() {
	({ username, password } = (await axios.get(CONFIG.ServerUrl + "/GetManagementApiLogin", await GetAxiosConfig())).data);
}

export async function GetTokenManagementApi() {
	if (jwt !== undefined && jwt !== null && GetTimeLeft(jwt) > 0) return jwt;
	if((await Login())?.ok) return jwt;
	return null;
}

export async function GetAxiosConfigManagementApi() {
	const jwt = await GetTokenManagementApi();
	if (jwt === null) return {};
	return {
		headers: {
			"Authorization": "Bearer " + jwt
		}
	};
}