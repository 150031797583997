import axios from 'axios';
import { GetAxiosConfig } from './AuthService';
import CONFIG from 'config';

export class ProduktService {
    async produktKopieren(produkt) {
        return axios.post(CONFIG.ServerUrl + "/Produkt/kopieren", produkt, await GetAxiosConfig()).then(res => res.data);
    }

    async produktDeaktivieren(id) {
        return axios.get(CONFIG.ServerUrl + "/Produkt/deaktivieren/" + id, await GetAxiosConfig()).then(res => res.data);
    }

    async getProduktVK(id) {  
        let url = CONFIG.ServerUrl + "/v2/ProduktPreis/ID/" + id;
        console.log(url);
        return axios.get(url, await GetAxiosConfig()).then(res => res.data);
    }

    async getProdukte() {
        let url = CONFIG.ServerUrl + "/v2/Produkte";
        console.log(url);
        return axios.get(url, await GetAxiosConfig())
                .then(res => res.data);
    }

    async checkBarcode(barcode) {
        let url = CONFIG.ServerUrl + "/Produkte/CheckBarcode/" + barcode;
        return axios.get(url, await GetAxiosConfig()).then(res => res.data)
    }

    async updateProdukt(produkt) {
        let url = CONFIG.ServerUrl + "/Produkt/Update";
        console.log(url);
        return axios.post(url, produkt, await GetAxiosConfig())
                .then(res => res.data);
    }

    async updatePreis(preis) {
        let url = CONFIG.ServerUrl + "/Produkt/updatePreis";
        console.log(url);
        return axios.post(url, preis, await GetAxiosConfig())
                .then(res => res.data);
    }

    async neuesProdukt() {
        let url = CONFIG.ServerUrl + "/Produkt/neuTemplate";
        console.log(url);
        return axios.get(url, await GetAxiosConfig())
                .then(res => res.data);
    }
    
    async getProdukt(id) {
        let url = CONFIG.ServerUrl + '/v1/Produkt/'+id;
        console.log(url);
        return axios.get(url, await GetAxiosConfig())
                .then(res => res.data);
    }

    async getProduktPreis(id) {
        let url = CONFIG.ServerUrl + '/Produkt/'+id+'/Preise/';
        console.log(url);
        return axios.get(url, await GetAxiosConfig())
                .then(res => res.data);
    }

    async generateBarcode() {
        let url = CONFIG.ServerUrl + '/Produkt/Barcodegenerieren/';
        console.log(url);
        return axios.get(url, await GetAxiosConfig())
                .then(res => res.data);
    }

    async generateBarcode7stellig() {
        let url = CONFIG.ServerUrl + '/Produkt/Barcodegenerieren7stellig/';
        console.log(url);
        return axios.get(url, await GetAxiosConfig())
                .then(res => res.data);
    }

    async getProduktWareneingang(id) {
        return axios.get(CONFIG.ServerUrl + '/v2/ProduktMitBestand/ID/'+id, await GetAxiosConfig())
                .then(res => res.data);
    }

    async getProduktWareneingangByBarcode(barcode) {
        console.log(CONFIG.ServerUrl + '/v2/ProduktMitBestand/Barcode/'+barcode);
        return axios.get(CONFIG.ServerUrl + '/v2/ProduktMitBestand/Barcode/'+barcode, await GetAxiosConfig())
                .then(res => res.data);
    }

    async getImage(id) {
        if (id === -1)
            return;
        let url = CONFIG.ServerUrl + '/Produkte/' + id + '/Bild'
        return axios.get(url, await GetAxiosConfig())
        .then(res => res.data).catch(e => console.log(e));
    }

    async getAll() {
        //return axios.get('assets/demo/data/produkte.json').then(res => res.data);
        return axios.get(CONFIG.ServerUrl + '/Produkte2', await GetAxiosConfig()).then(res => res.data);
    }
}