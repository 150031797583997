import axios from 'axios';
import { GetAxiosConfig } from './AuthService';

import CONFIG from 'config';

export class EtikettenService {

    async getRegalLabelParam(produkt) {
        return axios.post(CONFIG.ServerUrl + "/Etiketten/Regaletikett", produkt, await GetAxiosConfig()).then(res => res.data);
    }

    async getPreisLabelParam(produkt) {
        console.log(produkt);
        return axios.post(CONFIG.ServerUrl + "/Etiketten/Preisetikett", produkt, await GetAxiosConfig()).then(res => res.data);
    }
    
    async getRabattLabelParam(param) {
        return axios.post(CONFIG.ServerUrl + "/Etiketten/Rabattetikett", param, await GetAxiosConfig()).then(res => res.data);
    }

    getRegalLabel(_this, param, is550) {
        if (is550 === true)
            return _this.setLabel(param, '/assets/Etiketten/Regal_550.dymo', is550);
        else
            return _this.setLabel(param, '/assets/Etiketten/Regaletikett.label', is550);
    }

    getPreisLabel(_this, param, is550) {
        if (is550 === true)
            return _this.setPreisLabel(param, '/assets/Etiketten/Barcode_550.dymo', is550);
        else
            return _this.setPreisLabel(param, '/assets/Etiketten/Barcode.label', is550);
    }

    getRabattLabel(_this, param, is550) {
        if (is550 === true)
            return _this.setPreisLabel(param, '/assets/Etiketten/Barcode_550.dymo');
        else
            return _this.setPreisLabel(param, '/assets/Etiketten/Rabattbarcode.label');
    }

    setLabel(param, filename, is550) {
        var label = window.dymo.label.framework.openLabelFile(CONFIG.FrontendServerUrl + filename);
        label.getObjectNames().forEach((currentValue, index, arr) => {
            if (currentValue === "BEZEICHNUNG")
            {
                console.log("setBezeichnung: " + param.Bezeichnung);
                if (param.Bezeichnung !== undefined && param.Bezeichnung !== null)
                    label.setObjectText(currentValue, param.Bezeichnung);
                else
                    label.setObjectText(currentValue, "");
            } else if (currentValue === "BARCODE")
            {
                if (param.Barcode !== undefined && param.Barcode !== null)
                    label.setObjectText(currentValue, param.Barcode);
                else
                    label.setObjectText(currentValue, "");
            } else if (currentValue === "PRODUZENT" || currentValue === "LIEFERANT")
            {
                if (param.Produzent !== undefined && param.Produzent !== null)
                    label.setObjectText(currentValue, param.Produzent);
                else
                    label.setObjectText(currentValue, "");
            } else if (currentValue === "PREIS" )
            {
                if (param.Preis !== undefined && param.Preis !== null)
                    label.setObjectText(currentValue, param.Preis);
                else
                    label.setObjectText(currentValue, "");
            } else if (currentValue === "PREISPROEINHEIT") {
                if (param.PreisProEinheit !== undefined && param.PreisProEinheit !== null)
                    label.setObjectText(currentValue, param.PreisProEinheit)
                else
                    label.setObjectText(currentValue, "");
            } else if (currentValue === "ARTNR")
            {
                if (param.Artnr !== undefined && param.Artnr !== null)
                    label.setObjectText("ARTNR", param.Artnr);
                else
                    label.setObjectText(currentValue, "");
            } else if (currentValue === "PFAND")
            {
                if (param.Pfand !== undefined && param.Pfand !== null)
                    label.setObjectText("PFAND", param.Pfand);
                else
                    label.setObjectText(currentValue, "");
            } else if (currentValue === "VEEINHEIT" || currentValue === "Einheit")
            {
                if (param.VEEinheit !== undefined && param.VEEinheit !== null)
                    label.setObjectText(currentValue, param.VEEinheit);
                else
                    label.setObjectText(currentValue, "");
            }
        });
       
        return label;
    }

    setPreisLabel(param, filename, is550) {
        var label = window.dymo.label.framework.openLabelFile(CONFIG.FrontendServerUrl + filename);
        label.getObjectNames().forEach((currentValue, index, arr) => {
            console.log(currentValue);
            console.log(param);
            if (currentValue === "TEXT_1")
            {
                if (param.Bezeichnung !== undefined && param.Bezeichnung !== null)
                    label.setObjectText(currentValue, param.Bezeichnung);
                else
                    label.setObjectText(currentValue, "");
            }
            if (currentValue === "TEXT")
            {
                if (param.Preis !== undefined && param.Preis !== null)
                    label.setObjectText(currentValue, param.Preis);
                else
                    label.setObjectText(currentValue, "");

            }
            if (currentValue === "BARCODE" || currentValue === "Barcode")
            {
                if (param.Barcode !== undefined && param.Barcode !== null)
                {
                    if (is550 === true) {
                        label.setObjectText(currentValue, param.Barcode);
                    }
                    else
                        label.setObjectText(currentValue, param.Barcode);
                }
                else
                    label.setObjectText(currentValue, "");
            }
        });
        return label;
    }

    printRegaletikett(produkt, anzahlEtiketten) {
        this.getRegalLabelParam(produkt).then((labelparam) => {
            this.printEtikett(labelparam, anzahlEtiketten, this.getRegalPrinter, this.getRegalParam, this.getRegalLabel, 0);
        });
    }

    printPreisEtikett(produkt, anzahlEtiketten) {   
        this.getPreisLabelParam(produkt).then((labelparam) => {
            this.printEtikett(labelparam, anzahlEtiketten, this.getPreisPrinter, this.getPreisParam, this.getPreisLabel, 0);
        });
    }

    printRabattEtikett(produkt, id, anzahlEtiketten) {
        var param = new Object();
        param.Produkt = produkt;
        param.RabattID = id;
        this.getRabattLabelParam(param).then((labelparam) => {
            this.printEtikett(labelparam, anzahlEtiketten, this.getPreisPrinter, this.getPreisParam, this.getRabattLabel, 0);
        });
    }

    getRegalPrinter(printers) {
        let twinturboprinter = null;
        let etikettenprinter = null;
        printers.forEach(printer => {
            if (printer.isConnected === true)
                etikettenprinter = printer;
            if (printer.isTwinTurbo === true && printer.isConnected === true)
            {
                twinturboprinter = printer;
            }
        });
        if (twinturboprinter === null) {
            return etikettenprinter;
        }
        return twinturboprinter;
    }

    getPreisParam(anzahlEtiketten) {
        var printParams = '<?xml version="1.0" encoding="utf-8"?>\
            <LabelWriterPrintParams>\
                <Copies>'+ anzahlEtiketten +'</Copies>\
                <TwinTurboRoll>Left</TwinTurboRoll> \
            </LabelWriterPrintParams>';
        return printParams;
    }

    getRegalParam(anzahlEtiketten) {
        var printParams = '<?xml version="1.0" encoding="utf-8"?>\
            <LabelWriterPrintParams>\
                <Copies>'+ anzahlEtiketten +'</Copies>\
                <TwinTurboRoll>Right</TwinTurboRoll> \
            </LabelWriterPrintParams>';
        return printParams;
    }

    getPreisPrinter(printers) {
        let _printer = null;
        printers.forEach(p => {
            if (p.isConnected === true)
                _printer = p;
        });
        return _printer;
    }

    printEtikett(labelparam, anzahlEtiketten, getPrinter, getParams, getLabel) {
        if (labelparam === null || labelparam === undefined)
            return;
        let _this = this;
        try {
            window.dymo.label.framework.init(function() {
                var printers = window.dymo.label.framework.getPrinters();
                console.log(printers);
                if (printers.length === 0) {
                    alert("No DYMO printers are installed. Install DYMO printers.");
                    return null;
                }
                let printer = getPrinter(printers);
                console.log(printer);
                var is550 = window.dymo.label.framework.is550Printer(printer.name);
                let label = getLabel(_this, labelparam, is550);
                var printParams = getParams(anzahlEtiketten);
                if (printer === null)
                {
                    alert("kein passender Drucker gefunden!");
                    return;
                }
                label.print(printer.name, printParams);
                console.log("Erfolgreich gedruckt!");
            });
        } catch(err) {
            console.log(window.dymo.label.framework.checkEnvironment());
            console.log(err.message);
            alert("Fehler beim Etiketten drucken.");
        }
    }

    async printEtikettServer(id, menge, PreisDrucken) {
        if (PreisDrucken === undefined || PreisDrucken === null)
            PreisDrucken = true;
        return axios.get(CONFIG.ServerUrl + '/Produkt/DruckeEtikett/'+id+'/' + menge + '/' + PreisDrucken, await GetAxiosConfig())
                .then(res => res.data);
    }
    
    async printRegalEtikettServer(id, menge) {
        return axios.get(CONFIG.ServerUrl + '/Produkt/DruckeRegalEtikett/'+id+'/' + menge, await GetAxiosConfig())
                .then(res => res.data);
    }
}