import React, { useState, useEffect, useContext } from 'react';
import { GlobalState } from './GlobalState';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Card } from 'primereact/card';
import { KassenService } from '../service/KassenService';
import { ExportService } from '../service/ExportService';

export function Downloads(props) {
	const buttonWidth = '12em';

	const kassenService = new KassenService();
	const exportService = new ExportService();

	const [globalState, setGlobalState] = useContext(GlobalState);
	const [regaletikettenDialogOffen, setRegaletikettenDialogOffen] = useState(false);
	const [kassen, setKassen] = useState([]);
	const [selectedKasse, setSelectedKasse] = useState();

	useEffect(() => {
		setGlobalState({...globalState, page: 'Downloads'});
		kassenService.getKassen().then(data => {
			setKassen(data)
			setSelectedKasse(data[0].KassenID);
		});
	}, []);

	const download = (...urls) => {
		for (const url of urls) {
			const a = window.document.createElement('a');
			a.href = url;
			a.download = url.substring(url.lastIndexOf('/') + 1);
			window.document.body.append(a);
			a.click();
			a.remove();
		}
	};

	return (
		<React.Fragment>
			<div className="grid">
				<div className="col-4 p-p-1">
					<Card title={<span style={{marginLeft: '0.5rem'}}>Anleitungen</span>} style={{width: '100%', height: '100%'}} className="grid">
						<div className="col-12">
							<Button label="Download Anleitung Wareneingang mit Smartphone" icon="pi pi-book" onClick={() => download("/download/Anleitung Wareneingang per Smartphone.pdf")}/>
						</div>
						<div className="col-12">
							<Button label="Download Handbuch" icon="pi pi-book" onClick={() => download("/download/25012021_Handbuch_IT&Kassa.pdf")}/>
						</div>
					</Card>
				</div>
				<div className="col-4 p-p-1">
					<Card title={<span style={{marginLeft: '0.5rem'}}>Dymo</span>} style={{width: '100%', height: '100%'}} className="grid">
						<div className="col-12">
							<Button label="Download Dymo Framework" icon="pi pi-print" onClick={() => download("/download/DLS8Setup8.7.4.exe")}/>
						</div>
					</Card>
				</div>
				<div className="col-4 p-p-1">
					<Card title={<span style={{marginLeft: '0.5rem'}}>Schriftarten</span>} style={{width: '100%', height: '100%'}} className="grid">
						<div className="col-12">
							<Button label="Download Schriftart 'Fineday Style One'" icon="pi pi-pencil" onClick={() => download("/download/Mika Melvas - Fineday Style One.otf")}/>
						</div>
						<div className="col-12">
							<Button label="Download Schriftart 'Above The Sky Marker'" icon="pi pi-pencil" onClick={() => download("/download/My Creative Land - Above The Sky Marker.otf")}/>
						</div>
						<div className="col-12">
							<Button label="Download Schriftart 'WorkSans'" icon="pi pi-pencil" onClick={() => download("/download/WorkSans-Medium.ttf")}/>
						</div>
					</Card>
				</div>
				<div className="col-4 p-p-1">
					<Card title={<span style={{marginLeft: '0.5rem'}}>Regaletiketten</span>} style={{width: '100%', height: '100%'}} className="grid">
						<div className="col-12">
							<Button label="Download Seriendruck Regaletiketten Regional" icon="pi pi-table" onClick={() => download("/download/Vorlage_Regional_3Spalten.docx")}/>
						</div>
						<div className="col-12">
							<Button label="Download Seriendruck Regaletiketten Regional incl. Grundpreisauszeichnung" icon="pi pi-table" onClick={() => download("/download/Vorlage_Regional_3Spalten_mit_GP")}/>
						</div>
						<div className="col-12">
							<Button label="Download Seriendruck Regaletiketten nicht Regional" icon="pi pi-table" onClick={() => download("/download/Vorlage_nicht Regional_3Spalten.docx")}/>
						</div>
						<div className="col-12">
							<Button label="Download Seriendruck Regaletiketten nicht Regional incl. Grundpreisauszeichnung" icon="pi pi-table" onClick={() => download("/download/Vorlage_nicht Regional_3Spalten_mit_GP.docx")}/>
						</div>
						<div className="col-12">
							<Button label="Download Seriendruck Regaletiketten Daten" icon="pi pi-window-maximize" onClick={() => setRegaletikettenDialogOffen(true)}/>
						</div>
					</Card>
				</div>
			</div>
			<Dialog visible={regaletikettenDialogOffen} onHide={() => setRegaletikettenDialogOffen(false)} header={<React.Fragment>Download Regaletiketten für <Dropdown value={selectedKasse} options={kassen} onChange={e => setSelectedKasse(e.target.value)} optionLabel="KassenID" optionValue="KassenID"/></React.Fragment>}>
				<table style={{borderCollapse: 'separate', borderSpacing: '1em'}}>
					<tr>
						<td>
							<Button style={{width: buttonWidth}} label="Regional" icon="pi pi-file-excel" onClick={() => exportService.downloadRegaletikettenCsv(selectedKasse, 0)}/>
						</td>
						<td>
							<Button style={{width: buttonWidth}} label="nicht Regional" icon="pi pi-file-excel" onClick={() => exportService.downloadRegaletikettenCsv(selectedKasse, 1)}/>
						</td>
					</tr>
				</table>
			</Dialog>
		</React.Fragment>
	);
}