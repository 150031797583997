import React, { useRef, useState, useEffect, useContext } from 'react';
import { GlobalState } from './GlobalState';
import { KassenService } from '../service/KassenService';
import { EinstellungenService } from '../service/EinstellungenService';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';

export default function Einstellungen(props) {
	const labelWidth = '10em';

	const [globalState, setGlobalState] = useContext(GlobalState);
	const [kassen, setKassen] = useState([]);
	
    const kassenService = new KassenService();
	const einstellungenService = new EinstellungenService();

    let standortdt = useRef(null);

    useEffect(() => {
		setGlobalState({...globalState, page: 'Einstellungen'});
		kassenService.getKassenBankomatwerteEinstellungen().then(data => { console.log(data); setKassen(data)});
	}, []);


    const verfügbarTemplate = (props, rowData) => {
        return (
            <React.Fragment>
                <Checkbox  checked={rowData.aktiviert} onChange={(e) => onVerfügbarChanged(e.target.checked, rowData, props)}  />
            </React.Fragment>
        );
    }

    const onVerfügbarChanged = (e, row, props) => {
        let updateRows = [...props];
        updateRows[props.indexOf(row)]["aktiviert"] = e;
        setKassen(updateRows);
    } 

    const onSpeichernClick = (e) => {
        kassenService.updateKassenBankomatwerteEinstellungen(kassen).then((e) => onSpeichernSuccess(e)).catch(onSpeichernError);
    }

    const onSpeichernError = (e) => {
        console.log(e);
    }

    const onSpeichernSuccess = (e) => {
        console.log(e);
    }

    const inputTextEditor = (productKey, props, field) => {
        return (
            <table>
                <tr>
                    <td>
                        <InputText type="text" value={props.value} onChange={(e) => props.editorCallback(e.target.value)} />
                    </td>
                    <td>
                        <Button icon="pi pi-clone" tooltip="für alle übernehmen" tooltipOptions={{position: 'bottom'}} onClick={() => {
                            setKassen(props.value.map(s => ({...s, [field]: props.rowData[field]})));
                        }}/>
                    </td>
                </tr>
            </table>
        );
    }

    const onCellEditComplete = (e) => {
        let _kassen = [...kassen]
        _kassen.forEach(function(item, i) { if (item.KassenID == e.newRowData.KassenID) _kassen[i] = e.newRowData; });
        
        setKassen(_kassen);
    }

    return (
		<React.Fragment>
			<div className="grid p-component">
                <div className="col-12">
                    <Accordion activeIndex={0}>
                        <AccordionTab header="Zahlungsmethoden">
                            <div className="field grid">
                                <DataTable
                                    ref={standortdt}
                                    value={kassen}
                                    rowHover responsive="true"
                                    className="p-datatable-produkt-standorte editable-cells-table" 
                                    paginator rows={10}
                                    editMode="cell"
                                    emptyMessage="keine Standorte vorhanden"
                                    style={{width: "100%"}}
                                    >
                                    <Column field="KassenID" header="Standort" />
                                    <Column field="aktiviert"
                                        header={
                                            <React.Fragment>
                                                <Checkbox className="mr-1"
                                                    checked={kassen.every(s => s.aktiviert)}
                                                    onChange={e => {console.log(kassen)}}
                                                />
                                                aktiviert
                                            </React.Fragment>}
                                        body={(e) => verfügbarTemplate(kassen, e)}
                                    />
                                    <Column
                                        field="Bankomatwert"
                                        header="Bankomatwert"
                                        style={{width: "400px", maxWidth: "400px"}}
                                        editor={(props) => inputTextEditor('Standort', props, "Bankomatwert")}
                                        onEditorInit={e => e.originalEvent.target.children[1].firstChild.firstChild.firstChild.select()}
                                        onCellEditComplete={onCellEditComplete}
                                        />
                                </DataTable>
                            </div>
                        </AccordionTab>
                    </Accordion>
                </div>
                    
                <div className="col-12">
                    <Button onClick={onSpeichernClick}>Speichern</Button>
                </div>
			</div>
        </React.Fragment>
	);
}