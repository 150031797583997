import React, { useState, useEffect, useContext, useRef } from "react";
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { WarenbestandService } from '../service/WarenbestandService';
import { GlobalState } from './GlobalState';
import { CustomAutoComplete } from "./CustomAutoComplete";
import { Dropdown } from 'primereact/dropdown';
import { addDE } from './LocalDE';
import { KassenService } from '../service/KassenService';
import { ProduktService } from "../service/ProduktService";
import { Wareneingangservice } from "../service/Wareneingangsserice";
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { EtikettenService } from '../service/EtikettenService';
import { Divider } from 'primereact/divider';
import { Chart } from 'primereact/chart';
import { Calendar } from 'primereact/calendar';
import { ZoomPopup } from "./ZoomPopup";
import { Checkbox } from "primereact/checkbox";

function Wareneingang()
{
	const Gruende = [
		[
			{label: 'Schwund', data: 'Schwund'},
			{label: 'Verderb', data: 'Verderb'},
			{label: 'Beschädigt', data: 'Beschaedigt'},
			{label: 'Eigenverbrauch', data: 'Eigenverbrauch'},
			{label: 'Bestandskorrektur', data: 'Bestandskorrektur'}
		],
		[
			{label: '\u00A0', data: null}
		],
		[
			{label: '\u00A0', data: null},
			{label: 'nicht vorhanden', data: 'na'},
			{label: 'Bestandskorrektur', data: 'Bestandskorrektur'}
		],
		[
			{label: '\u00A0', data: null},
			{label: 'zusätzliche Befüllung', data: 'zusaetzliche Befuellung'},
			{label: 'Bestandskorrektur', data: 'Bestandskorrektur'}
		]
	];

	const [eingaenge, setEingaenge] = useState([]);
	const [produkte, setProdukte] = useState([]);
	const [globalState, setGlobalState] = useContext(GlobalState);
	const [selectedProdukt, setSelectedProdukt] = useState(null);
	const [menge, setMenge] = useState(0);
	const [gruende, setGruende] = useState([]);
	const [selectedGrund, setSelectedGrund] = useState({});
	const [kassen, setKassen] = useState([]);
	const [selectedKasse, setSelectedKasse] = useState({});
	const [sollBestand, setSollBestand] = useState();
	const [istBestand, setIstBestand] = useState();
	const [produkteMap, setProdukteMap] = useState(new Map());
	const [selectedBarcode, setSelectedBarcode] = useState();
	const [preisEtikettDialog, setPreisEtikettDialog] = useState(false);
	const [regalEtikettDialog, setRegalEtikettDialog] = useState(false);
	const [anzahlEtiketten, setAnzahlEtiketten] = useState(1);
	const [wareneingangDetailsOffen, setWareneingangDetailsOffen] = useState(false);
	const [selectedWareneingangDetails, setSelectedWareneingangDetails] = useState([]);
	const [warenbestandVerlauf, setWarenbestandVerlauf] = useState([]);
	const [datumsbereich, setDatumsbereich] = useState([new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000), new Date()]);
	const [produktNichtVorhandenDialog, setProduktNichtVorhandenDialog] = useState(false);
	const produktService = new ProduktService();
	const warenbestandService = new WarenbestandService();
	const kassenService = new KassenService();
	const wareneingangsservice = new Wareneingangservice();
	const etikettenService = new EtikettenService();
	const toastRef = useRef(null);
	const barcodeRef = useRef(null);
	const [preisDrucken, setPreisDrucken] = useState(true);

	const selectedKasseRef = useRef(selectedKasse);
	const selectedProduktRef = useRef(selectedProdukt);
	const lineChartRef = useRef(null);

	addDE();

	const download = (url, name) => {
		const a = window.document.createElement('a');
		a.href = url;
		a.download = name;
		window.document.body.append(a);
		a.click();
		a.remove();
	};

	function GetDatumsVerlauf(von, bis) {
		const year = von.getFullYear();
		const month = von.getMonth();
		const day = von.getDate();
		const count = Math.round((bis - von) / (24 * 60 * 60 * 1000)) + 1;
		const output = [];
		for (let offset = 0; offset < count; offset++) {
			output.push(new Date(year, month, day + offset).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}));
		}
		return output;
	}

	const eingangHinzufuegen = () => {
		if (selectedProdukt === undefined || selectedProdukt === null || selectedProdukt.Bezeichnung === '') return;

		console.log(selectedKasse);
		let eingang = {
			Produkt: selectedProdukt.ID,
			Menge: menge,
			Datum: new Date().toISOString(),
			KassenID: selectedKasse.KassenID,
			EK: selectedProdukt.EK,
			Grund: selectedGrund.data
			/*
			KassenID: selectedKasse?.KassenID,
			Bezeichnung: selectedProdukt.Bezeichnung,
			Menge: menge,
			GrundLabel: selectedGrund.label,
			Einheit: selectedProdukt.Einheit,
			Kasse: selectedKasse,
			Produkt: selectedProdukt,
			Grund: selectedGrund
			*/
		};
		wareneingangsservice.GetAddWareneingang(eingang, selectedKasse.url)
		.then(x => {
			console.log(x);
			x.forEach(eingang => {
				let _eingang = {
					KassenID: selectedKasse?.KassenID,
					Bezeichnung: selectedProdukt.Bezeichnung,
					Menge: eingang.Menge,
					Einheit: selectedProdukt.Einheit,
					Kasse: selectedKasse,
					Produkt: selectedProdukt,
					Grund: eingang.Grund
				}
				eingaenge.push(_eingang);
			});
			console.log(eingaenge);
			setEingaenge([...eingaenge]);
			setSelectedProdukt(null);
			selectedProduktRef.current = null;
			setMenge(0);
			setSelectedGrund({});
			setSelectedBarcode('');
			setSollBestand(null);
			setIstBestand(null);
		});
		return;		
	};

	const eingangEntfernen = (rowData, row) => {
		eingaenge.splice(row.rowIndex, 1);
		setEingaenge([...eingaenge]);
	};

	const updateGruende = (produkt, menge, index, setIndex, setSelected) => {
		if (produkt === undefined || produkt === null) return;
		if (menge < 0) {
			if (index !== 0) {
				setSelected({label: 'Schwund', data: 'Schwund'});
			}
			setIndex(0);
			return;
		}
		const gesamtMenge = istBestand + menge;
		if (gesamtMenge === sollBestand) {
			if (index !== 1) {
				setSelected({label: '\u00A0', data: null});
			}
			setIndex(1);
			return;
		}
		if (gesamtMenge < sollBestand) {
			if (index !== 2) {
				setSelected({label: '\u00A0', data: null});
			}
			setIndex(2);
			return;
		}
		if (gesamtMenge > sollBestand) {
			if (index !== 3) {
				if (sollBestand === 0) {
					setSelected({label: 'zusätzliche Befüllung', data: 'zusaetzliche Befuellung'});
				} else {
					setSelected({label: 'Bestandskorrektur', data: 'Bestandskorrektur'});
				}
			}
			setIndex(3);
			return;
		}
	};

	const speichern = () => {
		const listen = eingaenge.reduce((obj, eingang) => {
			(obj[eingang.Kasse.url] = obj[eingang.Kasse.url] ?? []).push(eingang);
			return obj;
		}, {});

		console.log(listen);

		for (const url in listen) {
			const eingaengeProKasse = listen[url].map(eingang => ({
				Produkt: eingang.Produkt.ID,
				Menge: eingang.Menge,
				Datum: new Date().toISOString(),
				EK: eingang.Produkt.EK,
				KassenID: eingang.KassenID,
				Grund: eingang.Grund
			}));
			wareneingangsservice.newServer(url, eingaengeProKasse).then((data) => {
				console.log(data);
				toastRef.current.show({severity: 'success', summary: 'Erfolgreich', detail:'Wareneingang hinzugefügt.', life: 2000});
				if (data.data !== null && data.data !== undefined)
					setProduktNichtVorhandenDialog(true);
				setEingaenge([]);
				updateWarenbestandVerlauf();
			}).catch(e => {
				console.log(e);
				toastRef.current.show({severity:'error', summary: 'Der Wareneingang konnte nicht hinzugefügt werden', life: 3000});
			});
		}
	};

	const onPreisEtikettdialog = () => {
		if (selectedProdukt === undefined || selectedProdukt === null || selectedProdukt.Bezeichnung === '') return;

		setPreisEtikettDialog(true);
		setAnzahlEtiketten(1);
	}

	const onRegalEtikettdialog = () => {
		if (selectedProdukt === undefined || selectedProdukt === null || selectedProdukt.Bezeichnung === '') return;

		setRegalEtikettDialog(true);
		setAnzahlEtiketten(1);
	}

	const preisEtikettDrucken = () => {
		let _produkt = {...selectedProdukt, printPreis: preisDrucken};
		etikettenService.printPreisEtikett(_produkt, anzahlEtiketten);
	}

	const regalEtikettDrucken = () => {
		etikettenService.printRegaletikett(selectedProdukt, anzahlEtiketten);
	}

	const wareneingaengeAnzeigen = () => {
		if(selectedProdukt === undefined || selectedProdukt === null || selectedProdukt.Bezeichnung === '') return;

		wareneingangsservice.getDetails(selectedKasse.KassenID, selectedProdukt.ID).then(data => {
			setSelectedWareneingangDetails(data);
			setWareneingangDetailsOffen(true);
		});
	}

	const onWareneingangDetailsOffenHide = () => {
		setSelectedWareneingangDetails([]);
		setWareneingangDetailsOffen(false);
	}

	function updateIstBestand() {
		if (selectedProduktRef.current === undefined || selectedProduktRef.current === null || selectedProduktRef.current.ID === undefined || selectedProduktRef.current.ID === null || selectedKasseRef.current === undefined || selectedKasseRef.current === null || selectedKasseRef.current.url === undefined || selectedKasseRef.current.url === null) {
			setIstBestand(null);
			return;
		}
		warenbestandService.getBestand(selectedKasseRef.current.url, selectedProduktRef.current.ID).then(data => setIstBestand(data.Bestand ?? 0)).catch(() => setIstBestand(0));
	}

	useEffect(() => {
		if (selectedBarcode === undefined || selectedBarcode === null || selectedBarcode === '') return;
		let produkt = produkteMap.get(selectedBarcode)
		if (produkt === undefined || produkt === null) produkt = produkteMap.get(selectedBarcode.substr(0, 7));
		if (produkt === undefined || produkt === null) return;

		setSelectedProdukt(produkt);
		selectedProduktRef.current = produkt;
	}, [selectedBarcode, produkteMap]);

	useEffect(() => {
		const map = new Map();
		for (const produkt of produkte) {
			if (produkt.Barcode === undefined || produkt.Barcode === null || produkt.Barcode === '') continue;
			map.set(produkt.Barcode, produkt);
		}
		setProdukteMap(map);
	}, [produkte]);

	useEffect(() => {
		updateGruende(selectedProdukt, menge, gruende, setGruende, setSelectedGrund);
		// eslint-disable-next-line
	}, [selectedProdukt, menge, sollBestand, istBestand]);

	function updateWarenbestandVerlauf() {
		if (selectedProdukt === undefined || selectedProdukt === null || selectedProdukt.ID === undefined || selectedProdukt.ID === null || selectedKasse === undefined || selectedKasse === null || selectedKasse.KassenID === undefined || selectedKasse.KassenID === null || datumsbereich === undefined || datumsbereich === null || datumsbereich.length === 0 || datumsbereich[0] === undefined || datumsbereich[0] === null || datumsbereich[1] === undefined || datumsbereich[1] === null) {
			setWarenbestandVerlauf([]);
			return;
		}
		warenbestandService.getVerlauf(selectedProdukt.ID, selectedKasse.KassenID, datumsbereich[0], datumsbereich[1]).then(data => setWarenbestandVerlauf(data));
	}

	useEffect(() => {
		updateWarenbestandVerlauf();
		// eslint-disable-next-line
	}, [selectedProdukt, selectedKasse, datumsbereich])

	useEffect(() => {
		if (selectedProdukt === undefined || selectedProdukt === null || selectedProdukt.ID === undefined || selectedProdukt.ID === null || selectedKasse === undefined || selectedKasse === null || selectedKasse.KassenID === undefined || selectedKasse.KassenID === null) return;
		warenbestandService.getBestaende(selectedProdukt.ID, selectedKasse.KassenID).then(data => setSollBestand(data?.Sollbestand));
		updateIstBestand();
		// eslint-disable-next-line
	}, [selectedProdukt, selectedKasse]);

	useEffect(() => {
		setGlobalState({...globalState, page: 'Wareneingang'});
		produktService.getAll().then(data => setProdukte(data));
		kassenService.getKassen().then(data => {
			setKassen(data.filter(kasse => kasse.url !== undefined && kasse.url !== null && kasse.url !== ''));
			setSelectedKasse(data.filter(kasse => kasse.url !== undefined && kasse.url !== null && kasse.url !== '')[0]);
			selectedKasseRef.current = data.filter(kasse => kasse.url !== undefined && kasse.url !== null && kasse.url !== '')[0];
		});
		const interval = setInterval(updateIstBestand, 1000*60);
		return () => clearInterval(interval);
		// eslint-disable-next-line
	}, []);

	return (
		<React.Fragment>
			<Toast ref={toastRef} />
			<div className="grid">
				<div className="col-12">
					<CustomAutoComplete field="Bezeichnung" value={selectedProdukt} onChange={e => { setSelectedProdukt(e.target.value); selectedProduktRef.current = e.target.value; }} placeholder="Produkt" options={produkte} filter={(query, produkt) => produkt.Bezeichnung.toLowerCase().includes(query.toLowerCase())}/>
					{(selectedProdukt !== null && selectedProdukt !== undefined) &&
						<React.Fragment>
							<InputNumber className="ml-2" inputStyle={{width: '5em', textAlign: 'center'}} value={menge}
								onValueChange={e => setMenge(e.target.value)} showButtons buttonLayout="horizontal" step={1}
								decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus"
								decrementButtonIcon="pi pi-minus" locale="de" minFractionDigits={selectedProdukt?.Einheit === 'KG' ? 3 : 0}
								maxFracionDigits={selectedProdukt?.Einheit === 'KG' ? 3 : 0}/>
							<Dropdown className="ml-2" style={{width: '13em'}} value={selectedGrund} options={Gruende[gruende]}
								onChange={e => setSelectedGrund(e.target.value)} optionLabel="label" placeholder="Grund"/>
						</React.Fragment>
					}
					<Dropdown className="ml-2" value={selectedKasse} options={kassen} onChange={e => { setSelectedKasse(e.target.value); selectedKasseRef.current = e.target.value; }} placeholder="Standort" optionLabel="KassenID"/>
					{(selectedProdukt !== null && selectedProdukt !== undefined && menge !== 0) &&
						<Button className="ml-4" icon="pi pi-plus-circle" label="Hinzufügen" onClick={eingangHinzufuegen}/>
					}
					{(selectedProdukt !== null && selectedProdukt !== undefined) &&
						<React.Fragment>
							<Button className="ml-2" style={{float: 'right'}} label="Regaletikett" icon="pi pi-print" onClick={onRegalEtikettdialog}/>
							<Button className="ml-2" style={{float: 'right'}} label="Preisetikett" icon="pi pi-print" onClick={onPreisEtikettdialog}/>
							<Button style={{float: 'right'}} label="Wareneingänge anzeigen" icon="pi pi-book" onClick={wareneingaengeAnzeigen}/>
						</React.Fragment>
					}
				</div>
				<div className="col-12">
					<InputText ref={barcodeRef} value={selectedBarcode} onChange={e => setSelectedBarcode(e.target.value)} placeholder="Barcode" style={{borderTopRightRadius: '0px', borderBottomRightRadius: '0px'}}/>
					<Button icon="pi pi-times" className="p-button-danger" onClick={() => { setSelectedBarcode(''); barcodeRef.current.focus()}} style={{borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px'}}/>
				</div>
				<div className="col-12 grid p-component">
					<div className="col-4">
						<table>
							<tr>
								<td style={{paddingRight: '2em'}}>Artikel-Nr:</td>
								<td style={{textAlign: 'right'}}>{selectedProdukt?.ArtNr}</td>
							</tr>
							<tr>
								<td style={{paddingRight: '2em'}}>Bezeichnung:</td>
								<td style={{textAlign: 'right'}}>{selectedProdukt?.Bezeichnung}</td>
							</tr>
							<tr>
								<td style={{paddingRight: '2em'}}>Produktgruppe:</td>
								<td style={{textAlign: 'right'}}>{selectedProdukt?.Produktgruppe}</td>
							</tr>
							<tr>
								<td style={{paddingRight: '2em'}}>Einheit:</td>
								<td style={{textAlign: 'right'}}>{selectedProdukt?.Einheit}</td>
							</tr>
						</table>
					</div>
					<div className="col-2">
						<table>
							<tr>
								<td style={{paddingRight: '2em'}}>Ist-Bestand:</td>
								<td style={{textAlign: 'right'}}>{istBestand?.toLocaleString('de')}</td>
							</tr>
							<tr>
								<td style={{paddingRight: '2em'}}>Soll-Bestand:</td>
								<td style={{textAlign: 'right'}}>{sollBestand?.toLocaleString('de')}</td>
							</tr>
							<tr>
								<td style={{paddingRight: '2em'}}>aufzufüllende Menge:</td>
								<td style={{textAlign: 'right'}}>{(sollBestand === undefined || sollBestand === null || istBestand === undefined || istBestand === null) ? '' : Math.max(sollBestand - istBestand, 0).toLocaleString('de')}</td>
							</tr>
						</table>
					</div>
					<div className="col-2">
						<table>
							<tr>
								<td style={{paddingRight: '2em'}}>EK</td>
								<td style={{textAlign: 'right'}}>{selectedProdukt?.EK !== undefined && selectedProdukt?.EK !== null ? '€ ' + selectedProdukt.EK.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</td>
							</tr>
							<tr>
								<td style={{paddingRight: '2em'}}>Spanne</td>
								<td style={{textAlign: 'right'}}>{selectedProdukt?.Spanne !== undefined && selectedProdukt?.Spanne !== null ? selectedProdukt.Spanne.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' %' : ''}</td>
							</tr>
							<tr>
								<td style={{paddingRight: '2em'}}>VK (brutto)</td>
								<td style={{textAlign: 'right'}}>{selectedProdukt?.VK !== undefined && selectedProdukt?.VK !== null ? '€ ' + selectedProdukt.VK.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</td>
							</tr>
							<tr>
								<td style={{paddingRight: '2em'}}>VK2</td>
								<td style={{textAlign: 'right'}}>{selectedProdukt?.VK2 !== undefined && selectedProdukt?.VK2 !== null ? '€ ' + selectedProdukt.VK2.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</td>
							</tr>
							<tr>
								<td style={{paddingRight: '2em'}}>VK3</td>
								<td style={{textAlign: 'right'}}>{selectedProdukt?.VK3 !== undefined && selectedProdukt?.VK3 !== null ? '€ ' + selectedProdukt.VK3.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</td>
							</tr>
						</table>
					</div>
					<div className="col-4" style={{position: 'relative'}}>
						<Calendar value={datumsbereich} onChange={e => setDatumsbereich(e.target.value)} locale="de" showIcon={true} selectionMode="range" readOnlyInput maxDate={new Date()} style={{width: '15em', position: 'absolute', left: 'calc(50% - 7.5em)', bottom: '100%'}}/>
						<ZoomPopup header="Warenbestand" footer={<Button label="Download" icon="pi pi-image" onClick={() => download(lineChartRef.current.getBase64Image(), 'Warenbestand.png')}/>}>
							<Chart ref={lineChartRef} type="line" data={{labels: GetDatumsVerlauf(datumsbereich[0], datumsbereich[1]), datasets: [{label: "Warenbestand", data: warenbestandVerlauf, fill: false, borderColor: "#229954", tension: 0.3}]}} options={{animation: {duration: 0}, plugins: {legend: {position: 'bottom'}}}}/>
						</ZoomPopup>
					</div>
				</div>
				<div className="col-12">
						<DataTable value={eingaenge} responsive className="p-datatable-warenbestand" dataKey="id" rowHover paginator rows={20} emptyMessage="keine zu befüllenden Produkte" currentPageReportTemplate='({first} von {totalRecords})' paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[20,30,40]} style={{marginTop: '20px'}} >
							<Column field="KassenID" header="Standort"/>
							<Column field="Bezeichnung" header="Bezeichnung"/>
							<Column field="Menge" header="Menge"/>
							<Column field="Grund" header="Grund"/>
							<Column field="Einheit" header="Einheit"/>
							<Column body={(rowData, row) => <Button tooltipOptions={{position: 'bottom'}} tooltip="Wareneingang entfernen" className="p-button-danger" icon="pi pi-trash" onClick={() => eingangEntfernen(rowData, row)}/>} bodyStyle={{textAlign: 'center'}} style={{width: '4.5em'}}/>
						</DataTable>
					</div>
					{(eingaenge !== undefined && eingaenge !== null && eingaenge.length > 0) &&
						<div className="col-12">
							<Button label="Speichern" icon="pi pi-save" onClick={speichern}/>
						</div>
					}
			</div>
			<Dialog header={selectedProdukt?.Bezeichnung} visible={wareneingangDetailsOffen} onHide={onWareneingangDetailsOffenHide}>
				<table className="tablePadding" style={{width: "100%"}}>
					<tr>
						<td style={{paddingLeft: "2em", paddingRight: "2em"}}><span style={{fontWeight: "bold"}}>ArtikelNr</span></td>
						<td style={{textAlign: "center", paddingLeft: "2em", paddingRight: "2em"}}><span style={{fontWeight: "bold"}}>Standort</span></td>
						<td style={{textAlign: "right", paddingLeft: "2em", paddingRight: "2em"}}><span style={{fontWeight: "bold"}}>Lieferant</span></td>
					</tr>
					<tr>
						<td style={{paddingLeft: "2em", paddingRight: "2em"}}>{selectedProdukt?.ArtNr}</td>
						<td style={{textAlign: "center", paddingLeft: "2em", paddingRight: "2em"}}>{selectedKasse.KassenID}</td>
						<td style={{textAlign: "right", paddingLeft: "2em", paddingRight: "2em"}}>{selectedProdukt?.Lieferant?.Name}</td>
					</tr>
				</table>
				<Divider />
				<table className="tablePadding" style={{width: "100%", borderCollapse: "collapse"}}>
					<tr>
						<td style={{fontWeight: "500", textAlign: "center"}}>Datum</td>
						<td style={{fontWeight: "500", textAlign: "center"}}>Zeit</td>
						<td style={{fontWeight: "500", textAlign: "center"}}>Menge</td>
						<td style={{fontWeight: "500", textAlign: "center"}}>Grund</td>
					</tr>
					{
						selectedWareneingangDetails?.map(einlagerung => <tr>
							<td style={{borderTopStyle: "solid", borderWidth: "1px"}}>{new Date(einlagerung.Datum).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'})}</td>
							<td style={{borderTopStyle: "solid", borderWidth: "1px"}}>{new Date(einlagerung.Datum).toLocaleTimeString('de', { hour: "2-digit", minute: "2-digit", second: undefined })}</td>
							<td style={{textAlign: "right", borderTopStyle: "solid", borderWidth: "1px"}}>{einlagerung.Menge}</td>
							<td style={{borderTopStyle: "solid", borderWidth: "1px"}}>{einlagerung.Grund}</td>
						</tr>)
					}
				</table>
			</Dialog>
			<Dialog visible={preisEtikettDialog} onHide={() => setPreisEtikettDialog(false)} style={{ width: '30vw' }} header="Anzahl?" footer={<div><Button label="Drucken" icon="pi pi-check" onClick={(e) => {setPreisEtikettDialog(false); preisEtikettDrucken(); }}/><Button label="Abbrechen" icon="pi pi-times" onClick={(e) => {setPreisEtikettDialog(false)}}/></div>}>
				<div className="p-d-block p-pb-4">Wie viele Etiketten sollen gedruckt werden?</div>
				<div className="p-d-block">
					<InputNumber value={anzahlEtiketten} onValueChange={(e) => setAnzahlEtiketten(e.target.value)} mode="decimal" showButtons buttonLayout="vertical" style={{width: '6em'}}
					decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
				</div>
				<div className='col-12'>
					<div className="p-d-block p-pb-4"><Checkbox className="mr-2" checked={preisDrucken} onChange={(e) => setPreisDrucken(e.checked)} />Preis drucken?
					</div>
                </div>
			</Dialog>
			<Dialog visible={regalEtikettDialog} onHide={() => setRegalEtikettDialog(false)} style={{ width: '30vw' }} header="Anzahl?" footer={<div><Button label="Drucken" icon="pi pi-check" onClick={(e) => {setRegalEtikettDialog(false); regalEtikettDrucken(); }}/><Button label="Abbrechen" icon="pi pi-times" onClick={(e) => {setRegalEtikettDialog(false)}}/></div>}>
				<div className="p-d-block p-pb-4">Wie viele Regaletiketten sollen gedruckt werden?</div>
				<div className="p-d-block">
					<InputNumber value={anzahlEtiketten} onValueChange={(e) => setAnzahlEtiketten(e.target.value)} mode="decimal" showButtons buttonLayout="vertical" style={{width: '6em'}}
					decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
				</div>
				{ selectedProdukt?.Herkunft === 0 &&
					<div className="p-d-block p-pt-4">Das Produkt ist Regional! Nicht vergessen das Etikett auf das richtige Regalschild zu kleben.</div>
				}
			</Dialog>
			<Dialog visible={produktNichtVorhandenDialog} onValueChange={() => setProduktNichtVorhandenDialog(false)} style={{width: '30vw'}} header="Achtung"
				footer={<div><Button label="Ok" icon="pi pi-check" onClick={() => setProduktNichtVorhandenDialog(false)} /></div>}>
					<div>
						<img  src={"/assets/achtung.jpeg"} width={"100px"} height={"100px"} style={{float: "left"}} alt="Achtung" />
						Einlagerung erfolgreich. <br />
						Einige neu angelegte Produkte können am Standort noch nicht verkauft werden.<br />
						Die Kassa muss vor Ort zuerst synchronisiert werden.
					</div>
			</Dialog>
		</React.Fragment>
	);
}

export default Wareneingang;
