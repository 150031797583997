import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalState } from "./GlobalState";
import { MitarbeiterService } from "../service/MitarbeiterService";
import { AuthService } from "../service/AuthService";
import { LieferantenService } from "../service/LieferantenService";
import { KassenService } from "../service/KassenService";
import { useHistory, useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { MultiSelect } from "primereact/multiselect";
import { Accordion, AccordionTab } from "primereact/accordion";
import { addDE } from "./LocalDE";
import base64url from 'base64url';

export function Benutzer2(props) {
	const labelWidth = '8em';
	const inputWidth = '15em';

	const mitarbeiterService = new MitarbeiterService();
	const authService = new AuthService();
	const lieferantenService = new LieferantenService();
	const kassenService = new KassenService();

	const history = useHistory();
	const { mitarbeiterNr } = useParams();

	const [globalState, setGlobalState] = useContext(GlobalState);
	const [lieferanten, setLieferanten] = useState([]);
	const [kassen, setKassen] = useState([]);
	const [mitarbeiter, setMitarbeiter] = useState({
		Pos: 'Chef'
	});
	const [passwortBestaetingen, setPasswortBestaetingen] = useState();
	const [selectedStandorte, setSelectedStandorte] = useState([]);
	const [oldEmail, setOldEmail] = useState();

	const toastRef = useRef(null);

	addDE();

	useEffect(() => {
		setGlobalState({...globalState, page: props.action === 'bearbeiten' ? 'Benutzer > bearbeiten' : 'Benutzer > anlegen'});
		if (props.action === 'bearbeiten') {
			mitarbeiterService.getMitarbeiter(mitarbeiterNr).then(data => {
				data.Geburtsdatum = data.Geburtsdatum === undefined || data.Geburtsdatum === null ? null : new Date(data.Geburtsdatum);
				data.Firmeneinstieg = data.Firmeneinstieg === undefined || data.Firmeneinstieg === null ? null : new Date(data.Firmeneinstieg);
				data.Firmenausstieg = data.Firmenausstieg === undefined || data.Firmenausstieg === null ? null : new Date(data.Firmenausstieg);
				setSelectedStandorte(data.Standorte ?? []);
				setOldEmail(data.Email);
				setMitarbeiter(data)
			});
		}
		lieferantenService.getAll().then(data => setLieferanten(data));
		kassenService.getKassen().then(data => setKassen(data));
		// eslint-disable-next-line
	}, []);

	const onSpeichernClick = async () => {
		const mitarbeiterZumSpeichern = {...mitarbeiter};
		mitarbeiterZumSpeichern.Vorname = cleanString(mitarbeiterZumSpeichern.Vorname);
		mitarbeiterZumSpeichern.Nachname = cleanString(mitarbeiterZumSpeichern.Nachname);
		mitarbeiterZumSpeichern.Geburtsdatum = mitarbeiterZumSpeichern.Geburtsdatum ?? null;
		mitarbeiterZumSpeichern.Adresse = cleanString(mitarbeiterZumSpeichern.Adresse);
		mitarbeiterZumSpeichern.PLZ = cleanString(mitarbeiterZumSpeichern.PLZ);
		mitarbeiterZumSpeichern.Ort = cleanString(mitarbeiterZumSpeichern.Ort);
		mitarbeiterZumSpeichern.Telefon = cleanString(mitarbeiterZumSpeichern.Telefon);
		mitarbeiterZumSpeichern.Firmeneinstieg = mitarbeiterZumSpeichern.Firmeneinstieg ?? null;
		mitarbeiterZumSpeichern.Firmenausstieg = mitarbeiterZumSpeichern.Firmenausstieg ?? null;
		mitarbeiterZumSpeichern.Austrittsgrund = cleanString(mitarbeiterZumSpeichern.Austrittsgrund);
		mitarbeiterZumSpeichern.Benutzername = cleanString(mitarbeiterZumSpeichern.Benutzername);
		mitarbeiterZumSpeichern.Passwort = (mitarbeiterZumSpeichern.Passwort ?? '') === '' ? null : mitarbeiterZumSpeichern.Passwort;
		mitarbeiterZumSpeichern.Email = cleanString(mitarbeiterZumSpeichern.Email);
		mitarbeiterZumSpeichern.Befuellungsliste = mitarbeiterZumSpeichern.Befuellungsliste ?? false;
		mitarbeiterZumSpeichern.Tagesbericht = mitarbeiterZumSpeichern.Tagesbericht ?? false;
		mitarbeiterZumSpeichern.Wochenbericht = mitarbeiterZumSpeichern.Wochenbericht ?? false;
		mitarbeiterZumSpeichern.Monatsbericht = mitarbeiterZumSpeichern.Monatsbericht ?? false;
		mitarbeiterZumSpeichern.MitarbeiterStandort = (selectedStandorte ?? []).map(s => ({ KassenID: s }));

		if (mitarbeiterZumSpeichern.Pos !== 'Lieferant') mitarbeiterZumSpeichern.LieferantenID = null;
		if (mitarbeiterZumSpeichern.Email === null || (selectedStandorte ?? []).length === 0) {
			mitarbeiterZumSpeichern.Befuellungsliste = false;
			mitarbeiterZumSpeichern.Tagesbericht = false;
			mitarbeiterZumSpeichern.Wochenbericht = false;
			mitarbeiterZumSpeichern.Monatsbericht = false;
		}

		if (mitarbeiterZumSpeichern.Passwort !== ((passwortBestaetingen ?? '') === '' ? null : passwortBestaetingen)) {
			toastRef.current.show({ severity: "error", summary: "Der Benutzer konnte nicht angelegt werden", detail: "Die eingegebenen Passwörter stimmen nicht überein", life: 3000, closable: false });
			return;
		}

		if (mitarbeiterZumSpeichern.Benutzername === null || (props.action !== 'bearbeiten' && mitarbeiterZumSpeichern.Passwort === null) || (mitarbeiterZumSpeichern.Pos === 'Lieferant' && (mitarbeiterZumSpeichern.LieferantenID === undefined || mitarbeiterZumSpeichern.LieferantenID === null))) {
			toastRef.current.show({ severity: "error", summary: "Der Benutzer konnte nicht angelegt werden", detail: "Nicht alle Pflichtfelder wurden ausgefüllt.", life: 3000, closable: false });
			return;
		}

		if (mitarbeiterZumSpeichern.Passwort !== null) mitarbeiterZumSpeichern.Passwort = base64url.encode(await crypto.subtle.digest('SHA-512', new TextEncoder().encode(mitarbeiterZumSpeichern.Passwort)));

		mitarbeiterService.mitarbeiterUpdate(mitarbeiterZumSpeichern).then(async () => {
			await Promise.allSettled(kassen.map(kassa => ([
				kassenService.removeBerichteEmails(kassa.url, [oldEmail]).catch(() => { }),
				kassenService.removeWochenberichtEmails(kassa.url, [oldEmail]).catch(() => { })
			])).flat());
			for (const url of kassen.filter(k => selectedStandorte?.includes(k.KassenID)).map(k => k.url)) {
				if (mitarbeiterZumSpeichern.Befuellungsliste || mitarbeiterZumSpeichern.Tagesbericht) {
					kassenService.addBerichteEmails(url, [mitarbeiterZumSpeichern.Email]).catch(() => { });
				}
				if (mitarbeiterZumSpeichern.Wochenbericht || mitarbeiterZumSpeichern.Monatsbericht) {
					kassenService.addWochenberichtEmails(url, [mitarbeiterZumSpeichern.Email]).catch(() => { });
				}
			}

			const token = await authService.getTokenInfo();
			if (token.id === mitarbeiterZumSpeichern.MitarbeiterNr) {
				if (mitarbeiterZumSpeichern.Passwort !== null) {
					await authService.reloadToken({ username: mitarbeiterZumSpeichern.Benutzername, password: mitarbeiterZumSpeichern.Passwort });
				} else {
					await authService.reloadToken({ username: mitarbeiterZumSpeichern.Benutzername });
				}
			}
			toastRef.current.show({severity:'success', summary: 'Erfolgreich', detail:'Benutzer gespeichert.', life: 2000});
			setTimeout(() => history.goBack(), 1000);
		}).catch(() => {
			toastRef.current.show({severity:'error', summary: 'Der Benutzer konnte nicht gespeichert werden', detail:'Der Benutzername ist bereits vergeben.', life: 5000});
		});
	};

	function cleanString(str) {
		if (typeof(str) !== 'string') return null;
		str = str?.trim();
		if (str === undefined || str === null || str === '') return null;
		return str;
	}

	return (
		<React.Fragment>
			<Toast ref={toastRef}/>
			<div className="p-component grid">
				<div className="col-12">
					<div className="field grid">
						<Button label="Speichern" icon="pi pi-save" style={{marginRight: '.5em'}} onClick={onSpeichernClick} />
						<Button label="Abbrechen" icon="pi pi-times" onClick={() => history.goBack()} />
					</div>
				</div>
				<div className="col-16">
					<div className="field grid">
						<label htmlFor="benutzername" className="col-fixed" style={{width: labelWidth}}>Benutzername</label>
						<div className="col">
							<InputText value={mitarbeiter.Benutzername} id="benutzername" onChange={e => setMitarbeiter({...mitarbeiter, Benutzername: e.target.value})} className={mitarbeiter.Benutzername === undefined || mitarbeiter.Benutzername === null || mitarbeiter.Benutzername === '' ? 'p-invalid' : ''} style={{width: inputWidth}}/>
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="passwort" className="col-fixed" style={{width: labelWidth}}>Passwort:</label>
						<div className="col">
							<Password inputId="passwort" value={mitarbeiter.Passwort} onChange={e => setMitarbeiter({...mitarbeiter, Passwort: e.target.value})} toggleMask className={props.action !== 'bearbeiten' && (mitarbeiter.Passwort === undefined || mitarbeiter.Passwort === null || mitarbeiter.Passwort === '') ? 'p-invalid' : ''} inputStyle={{width: inputWidth}}/>
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="passwortBestaetingen" className="col-fixed" style={{width: labelWidth}}>Passwort bestätigen:</label>
						<div className="col">
							<Password inputId="passwortBestaetingen" value={passwortBestaetingen} onChange={e => setPasswortBestaetingen(e.target.value)} toggleMask className={props.action !== 'bearbeiten' && (passwortBestaetingen === undefined || passwortBestaetingen === null || passwortBestaetingen === '') ? 'p-invalid' : ''} inputStyle={{width: inputWidth}}/>
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="berechtigungen" className="col-fixed" style={{width: labelWidth}}>Berechtigungen</label>
						<div className="col">
							<Dropdown inputId="berechtigungen" value={mitarbeiter.Pos} onChange={e => setMitarbeiter({...mitarbeiter, Pos: e.target.value})} options={['Chef', 'Lieferant']} style={{width: inputWidth}}/>
						</div>
					</div>
					{ mitarbeiter.Pos === 'Lieferant' &&
						<div className="field grid">
							<label htmlFor="lieferant" className="col-fixed" style={{width: labelWidth}}>Lieferant</label>
							<div className="col">
								<Dropdown inputId="lieferant" value={mitarbeiter.LieferantenID} onChange={e => setMitarbeiter({...mitarbeiter, LieferantenID: e.target.value})} options={lieferanten} optionLabel="Name" optionValue="ID" className={mitarbeiter.LieferantenID === undefined || mitarbeiter.LieferantenID === null ? 'p-invalid' : ''} style={{width: inputWidth}}/>
							</div>
						</div>
					}
				</div>
				<div className="col-12">
					<Accordion activeIndex={0}>
						<AccordionTab header="Allgemein">
							<div className="field grid">
								<label htmlFor="vorname" className="col-fixed" style={{width: labelWidth}}>Vorname</label>
								<div className="col">
									<InputText value={mitarbeiter.Vorname} id="vorname" onChange={e => setMitarbeiter({...mitarbeiter, Vorname: e.target.value})} style={{width: inputWidth}}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="nachname" className="col-fixed" style={{width: labelWidth}}>Nachname</label>
								<div className="col">
									<InputText value={mitarbeiter.Nachname} id="nachname" onChange={e => setMitarbeiter({...mitarbeiter, Nachname: e.target.value})} style={{width: inputWidth}}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="geburtsdatum" className="col-fixed" style={{width: labelWidth}}>Geburtsdatum</label>
								<div className="col">
									<Calendar inputId="geburtsdatum" value={mitarbeiter.Geburtsdatum} showIcon={true} locale="de" onChange={e => setMitarbeiter({...mitarbeiter, Geburtsdatum: e.target.value})} style={{width: inputWidth}}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="telefon" className="col-fixed" style={{width: labelWidth}}>Telefon</label>
								<div className="col">
									<InputText value={mitarbeiter.Telefon} id="telefon" type="text" style={{width: inputWidth}} onChange={e => setMitarbeiter({...mitarbeiter, Telefon: e.target.value})}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="firmeneinstieg" className="col-fixed" style={{width: labelWidth}}>Firmeneinstieg</label>
								<div className="col">
									<Calendar inputId="firmeneinstieg" value={mitarbeiter.Firmeneinstieg} showIcon={true} locale="de" onChange={e => setMitarbeiter({...mitarbeiter, Firmeneinstieg: e.target.value})} style={{width: inputWidth}}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="firmenausstieg" className="col-fixed" style={{width: labelWidth}}>Firmenausstieg</label>
								<div className="col">
									<Calendar inputId="firmenausstieg" value={mitarbeiter.Firmenausstieg} showIcon={true} locale="de" onChange={e => setMitarbeiter({...mitarbeiter, Firmenausstieg: e.target.value})} style={{width: inputWidth}}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="austrittsgrund" className="col-fixed" style={{width: labelWidth}}>Austrittsgrund</label>
								<div className="col">
									<InputText value={mitarbeiter.Austrittsgrund} id="austrittsgrund" type="text" style={{width: inputWidth}} onChange={e => setMitarbeiter({...mitarbeiter, Austrittsgrund: e.target.value})}/>
								</div>
							</div>
						</AccordionTab>
						<AccordionTab header="Adresse">
							<div className="field grid">
								<label htmlFor="strasse" className="col-fixed" style={{width: labelWidth}}>Straße</label>
								<div className="col">
									<InputText value={mitarbeiter.Adresse} id="strasse" type="text" style={{width: inputWidth}} onChange={e => setMitarbeiter({...mitarbeiter, Adresse: e.target.value})}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="plz" className="col-fixed" style={{width: 'calc(' + labelWidth + ' / 4)'}}>PLZ</label>
								<label htmlFor="ort" className="col-fixed" style={{width: 'calc((' + labelWidth + ' / 4) * 3)'}}>Ort</label>
								<div className="col">
									<InputText value={mitarbeiter.PLZ} id="plz" type="text" style={{width: 'calc(' + inputWidth + ' / 4)'}} onChange={e => setMitarbeiter({...mitarbeiter, PLZ: e.target.value})}/>
									<InputText value={mitarbeiter.Ort} id="ort" type="text" style={{width: 'calc((' + inputWidth + ' / 4) * 3)'}} onChange={e => setMitarbeiter({...mitarbeiter, Ort: e.target.value})}/>
								</div>
							</div>
						</AccordionTab>
						<AccordionTab header="Berichte">
							<div className="field grid">
								<label htmlFor="standorte" className="col-fixed" style={{width: labelWidth}}>Standorte</label>
								<div className="col">
									<MultiSelect inputId="standorte" value={selectedStandorte} onChange={e => setSelectedStandorte(e.target.value)} options={kassen} optionLabel="KassenID" optionValue="KassenID" style={{width: inputWidth}}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="email" className="col-fixed" style={{width: labelWidth}}>E-Mail</label>
								<div className="col">
									<InputText value={mitarbeiter.Email} id="email" onChange={e => setMitarbeiter({...mitarbeiter, Email: e.target.value})} style={{width: inputWidth}}/>
								</div>
							</div>
							{ (cleanString(mitarbeiter.Email) !== null && (selectedStandorte ?? []).length > 0) &&
								<React.Fragment>
									<div className="field grid">
										<label htmlFor="befuellungsliste" className="col-fixed" style={{width: labelWidth}}>Befüllungsliste</label>
										<div className="col">
											<Checkbox inputId="befuellungsliste" checked={mitarbeiter.Befuellungsliste} onChange={e => setMitarbeiter({...mitarbeiter, Befuellungsliste: e.target.checked})}/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="tagesbericht" className="col-fixed" style={{width: labelWidth}}>Tagesbericht</label>
										<div className="col">
											<Checkbox inputId="tagesbericht" checked={mitarbeiter.Tagesbericht} onChange={e => setMitarbeiter({...mitarbeiter, Tagesbericht: e.target.checked})}/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="wochenbericht" className="col-fixed" style={{width: labelWidth}}>Wochenbericht</label>
										<div className="col">
											<Checkbox inputId="wochenbericht" checked={mitarbeiter.Wochenbericht} onChange={e => setMitarbeiter({...mitarbeiter, Wochenbericht: e.target.checked})}/>
										</div>
									</div>
									<div className="field grid">
										<label htmlFor="monatsbericht" className="col-fixed" style={{width: labelWidth}}>Monatsbericht</label>
										<div className="col">
											<Checkbox inputId="monatsbericht" checked={mitarbeiter.Monatsbericht} onChange={e => setMitarbeiter({...mitarbeiter, Monatsbericht: e.target.checked})}/>
										</div>
									</div>
								</React.Fragment>
							}
						</AccordionTab>
					</Accordion>
				</div>
			</div>
		</React.Fragment>
	);
}