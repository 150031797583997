import axios from 'axios';
import { GetAxiosConfig } from './AuthService';
import CONFIG from 'config';

export class KundenService {
	async getAll() {
		return axios.get(CONFIG.ServerUrl + '/Kunden', await GetAxiosConfig()).then(res => res.data);
	}

	async getFromId(id) {
		return axios.get(CONFIG.ServerUrl + '/Kunde/' + id, await GetAxiosConfig()).then(res => res.data);
	}

	async set(kunde) {
		return axios.post(CONFIG.ServerUrl + '/Kunde/Set', kunde, await GetAxiosConfig()).then(res => res.data);
	}

	async delete(id) {
		return axios.get(CONFIG.ServerUrl + '/Kunde/Delete/' + id, await GetAxiosConfig()).then(res => res.data);
	}
}