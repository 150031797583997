import base64url from 'base64url';
import CONFIG from 'config';
let timeout = null;

async function RenewToken() {
	const oldToken = sessionStorage.getItem('jwt');
	const response = await fetch(CONFIG.ServerUrl + '/RenewToken', {
		headers: {
			'Authorization': 'Bearer ' + oldToken
		}
	});
	if (response.ok) {
		SetToken(await response.text());
	} else {
		sessionStorage.removeItem('jwt');
		await Login();
	}
}

function SetToken(token) {
	if(token === undefined || token === null || token === '') return;
	const timeLeft = GetTimeLeft(token);
	if (timeLeft > 0) {
		sessionStorage.setItem('jwt', token);
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			RenewToken();
		}, timeLeft);
	}
}

function GetPayload(token) {
	return JSON.parse(base64url.decode(token.split('.')[1]));
}

function GetTimeLeft(token) {
	return (GetPayload(token).exp - 10) * 1000 - new Date().getTime();
}

async function Login() {
	const username = sessionStorage.getItem('username') ?? localStorage.getItem('username');
	const password = sessionStorage.getItem('password') ?? localStorage.getItem('password');
	
	if (username === undefined || username === null || password === undefined || password === null) return;

	const response = await fetch(CONFIG.ServerUrl + '/Login', {
		method: 'POST',
		body: JSON.stringify({username: username, password: password})
	});
	if (response.ok) {
		SetToken(await response.text());
	} else if (response.status === 429) {
		throw new Error("Zu viele Login versuche");
	}
	return response;
}

export async function GetToken() {
	const jwt = sessionStorage.getItem('jwt');
	if (jwt !== undefined && jwt !== null && GetTimeLeft(jwt) > 0) return jwt;
	if((await Login())?.ok) return sessionStorage.getItem('jwt');
	return null;
}

export async function GetAxiosConfig() {
	const jwt = await GetToken();
	if (jwt === null) return {};
	return {
		headers: {
			'Authorization': 'Bearer ' + jwt
		}
	};
}

export class AuthService {
	async login(username, password) {
		const hash = await crypto.subtle.digest('SHA-512', new TextEncoder().encode(password))
		const base64 = base64url.encode(hash);
		if (CONFIG.Typ === 'Kassa') {
			localStorage.setItem('username', username);
			localStorage.setItem('password', base64);
		} else {
			sessionStorage.setItem('username', username);
			sessionStorage.setItem('password', base64);
		}
		if(!((await Login()).ok)) {
			sessionStorage.removeItem('username');
			sessionStorage.removeItem('password');
			localStorage.removeItem('username');
			localStorage.removeItem('password');
			throw new Error('Login Fehlgeschlagen');
		}
	}

	logout() {
		clearTimeout(timeout);
		sessionStorage.removeItem('jwt');
		sessionStorage.removeItem('username');
		sessionStorage.removeItem('password');
		localStorage.removeItem('username');
		localStorage.removeItem('password');
	}

	async changePassword(newPassword, id) {
		const hash = await crypto.subtle.digest('SHA-512', new TextEncoder().encode(newPassword));
		const base64 = base64url.encode(hash);
		const jwt = await GetToken();
		const response = await fetch(CONFIG.ServerUrl + '/ChangePassword', {
			method: 'POST',
			headers: {
				'Authorization': 'Bearer ' + jwt
			},
			body: JSON.stringify({id: (id ?? (await this.getTokenInfo()).id), newPassword: base64})
		});
		if (!response.ok) throw new Error('Das alte Passwort war falsch');
		if (id === undefined || id === null || id === (await this.getTokenInfo()).id) {
			if (CONFIG.Typ === 'Kassa') {
				localStorage.setItem('password', base64);
			} else {
				sessionStorage.setItem('password', base64);
			}
		}
	}

	async createUser(user) {
		const hash = await crypto.subtle.digest('SHA-512', new TextEncoder().encode(user.Passwort))
		user.Passwort = base64url.encode(hash);
		const jwt = await GetToken();
		const response = await fetch(CONFIG.ServerUrl + '/CreateUser', {
			method: 'POST',
			headers: {
				'Authorization': 'Bearer ' + jwt
			},
			body: JSON.stringify(user)
		});
		return response.ok;
	}

	isLogedIn() {
		const jwt = sessionStorage.getItem('jwt');
		if (jwt !== undefined && jwt !== null && GetTimeLeft(jwt) > 0) return true;

		const username = sessionStorage.getItem('username') ?? localStorage.getItem('username');
		const password = sessionStorage.getItem('password') ?? localStorage.getItem('password');
		return username !== undefined && username !== null && password !== undefined && password !== null;
	}

	async getTokenInfo() {
		let jwt = sessionStorage.getItem('jwt');
		if (jwt === undefined || jwt === null) {
			await Login();
		}
		jwt = sessionStorage.getItem('jwt');
		if (jwt === undefined || jwt === null) return null;
		return GetPayload(jwt);
	}

	async reloadToken({username, password}) {
		if (username !== undefined && username !== null) {
			if (CONFIG.Typ === 'Kassa') {
				localStorage.setItem('username', username);
			} else {
				sessionStorage.setItem('username', username);
			}
		}
		if (password !== undefined && password !== null) {
			const hash = await crypto.subtle.digest('SHA-512', new TextEncoder().encode(password))
			const base64 = base64url.encode(hash);
			if (CONFIG.Typ === 'Kassa') {
				localStorage.setItem('password', base64);
			} else {
				sessionStorage.setItem('password', base64);
			}
		}
		await RenewToken(sessionStorage.getItem('jwt'));
	}
}