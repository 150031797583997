import axios from 'axios';
import { GetAxiosConfig } from './AuthService';
import CONFIG from 'config';

export class ArtikelgruppenService {
    async getAll() {
        let url = CONFIG.ServerUrl + "/AlleGruppen/";
        console.log(url);
        return axios.get(url, await GetAxiosConfig())
                .then(res => res.data);
    }

}

export default ArtikelgruppenService;