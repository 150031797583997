import React from 'react';
import { Component, createRef, CSSProperties } from "react";
import { FileUpload, FileUploadOptions } from "primereact/fileupload";
import { Button } from "primereact/button";

type ImageSelectorProps = {
	options: string[],
	allowOther: boolean,
	chooseOptions: FileUploadOptions,
	style: CSSProperties,
	imageStyle: CSSProperties,
	selectedStyle: CSSProperties
}

export class ImageSelector extends Component<ImageSelectorProps, {}> {
	selectedUrl: string | undefined;
	clearDisabled: boolean = true;
	uploadRef = createRef<FileUpload>();

	async getBlob() : Promise<Blob | null> {
		console.log(this.uploadRef.current);
		if (this.selectedUrl !== undefined) {
			return (await fetch(this.selectedUrl)).blob()
		} else if (this.uploadRef.current?.getFiles().length > 0) {
			return this.uploadRef.current?.getFiles()[0];
		} else {
			return null;
		}
	}

	render() {
		return <div style={this.props.style}>
			<div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
				{
					this.props.options?.map(url => <img src={url} style={{...{cursor: "pointer", width: "5rem", margin: "0.5rem", boxSizing: "content-box", objectFit: "contain", aspectRatio: "1 / 1"}, ...(url === this.selectedUrl ? {...this.props.imageStyle, ...{borderStyle: "solid", borderWidth: "1px", margin: "calc(0.5rem - 1px)"}, ...this.props.selectedStyle} : this.props.imageStyle)}} onClick={() => {
						this.selectedUrl = url;
						this.uploadRef.current?.clear();
						this.forceUpdate();
					}}/>)
				}
			</div>
			{ this.props.allowOther &&
				<table style={{width: "100%", borderCollapse: "collapse"}}>
					<tr>
						<td style={{width: "40px", padding: "0px"}}>
							<FileUpload ref={this.uploadRef} chooseOptions={this.props.chooseOptions} customUpload mode="basic" accept="image/*" onSelect={() => {
								console.log(this.uploadRef.current);	
								this.clearDisabled = this.uploadRef.current?.getFiles() == 0;
								if (this.uploadRef.current?.getFiles().length > 0) this.selectedUrl = undefined;
								this.forceUpdate();
							}} onClear={() => {
								this.clearDisabled = true;
								this.forceUpdate();
							}}/>
						</td>
						<td style={{padding: "0px"}}>
							<Button icon="pi pi-times" onClick={() => this.uploadRef.current?.clear()} disabled={this.clearDisabled}/>
						</td>
					</tr>
				</table>
			}
		</div>;
	}
}