import { GetToken } from './AuthService';
import CONFIG from 'config';

export class ExportService {
	async downloadBericht(von, bis, kassenIds, name) {
		return fetch(CONFIG.ServerUrl + '/Export/Bericht', {
			method: 'POST',
			headers: {
				'Authorization': 'Bearer ' + (await GetToken()),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({Von: von.toISOString(), Bis: bis.toISOString(), Kassen: kassenIds, Name: name})
		}).then(response => {
			if (response.ok) {
				return response.blob().then(blob => {
					if (blob.size === 0) throw new Error("The response was empty.");
					this.downloadBlob(blob, name + '.xlsx');
				});
			} else {
				throw new Error("There was an not OK response.");
			}
		});
	}

	async downloadBefuellungsliste(typ, kassenIds, excludeSollBestand0) {
		return fetch(CONFIG.ServerUrl + '/Export/Befuellungsliste/' + typ + '/' + (excludeSollBestand0 ? 'true' : 'false'), {
			method: 'POST',
			headers: {
				'Authorization': 'Bearer ' + (await GetToken()),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(kassenIds)
		}).then(response => {
			if (response.ok) {
				return response.blob().then(blob => {
					if (blob.size === 0) throw new Error("The response was empty.");
					this.downloadBlob(blob, typ + '.xlsx');
				});
			} else {
				throw new Error("There was an not OK response.");
			}
		});
	}

	async downloadPreisliste(spalten) {
		return fetch(CONFIG.ServerUrl + '/Export/Preisliste', {
			method: 'POST',
			headers: {
				'Authorization': 'Bearer ' + (await GetToken()),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(spalten)
		}).then(response => {
			if (response.ok) {
				return response.blob().then(blob => {
					if (blob.size === 0) throw new Error("The response was empty.");
					this.downloadBlob(blob, 'Preisliste.xlsx');
				});
			} else {
				throw new Error("There was an not OK response.");
			}
		});
	}

	async downloadLieferantenliste(spalten) {
		return fetch(CONFIG.ServerUrl + '/Export/Lieferantenliste', {
			method: 'POST',
			headers: {
				'Authorization': 'Bearer ' + (await GetToken()),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(spalten)
		}).then(response => {
			if (response.ok) {
				return response.blob().then(blob => {
					if (blob.size === 0) throw new Error("The response was empty.");
					this.downloadBlob(blob, 'Lieferantenliste.xlsx');
				});
			} else {
				throw new Error("There was an not OK response.");
			}
		});
	}

	async belegHtml(id) {
		return fetch(CONFIG.ServerUrl + '/Export/BelegHtml/' + id, {
			headers: {
				'Authorization': 'Bearer ' + (await GetToken())
			}
		}).then(response => {
			if (response.ok) {
				return response.text().then(text => {
					return text;
				});
			} else {
				throw new Error("There was an not OK response.");
			}
		});
	}

	async belegHtml2(BelegNr, KassenID) {
		return fetch(CONFIG.ServerUrl + '/Export/BelegHtml/' + KassenID + '/' + BelegNr, {
			headers: {
				'Authorization': 'Bearer ' + (await GetToken())
			}
		}).then(response => {
			if (response.ok) {
				return response.text().then(text => {
					return text;
				});
			} else {
				throw new Error("There was an not OK response.");
			}
		});
	}

	async downloadBeleg(id) {
		return fetch(CONFIG.ServerUrl + '/Export/BelegPdf/' + id, {
			headers: {
				'Authorization': 'Bearer ' + (await GetToken())
			}
		}).then(response => {
			if (response.ok) {
				return response.blob().then(blob => {
					if (blob.size === 0) throw new Error("The response was empty.");
					this.downloadBlob(blob, 'Beleg.pdf');
				});
			} else {
				throw new Error("There was an not OK response.");
			}
		});
	}

	async downloadRegaletikettenCsv(kasse, herkunft) {
		return fetch(CONFIG.ServerUrl + '/Export/RegaletikettenRegional/' + kasse + '/' + herkunft, {
			headers: {
				'Authorization': 'Bearer ' + (await GetToken())
			}
		}).then(response => {
			if (response.ok) {
				return response.blob().then(blob => {
					if (blob.size === 0) throw new Error("The response was empty.");
					this.downloadBlob(blob, 'Regaletiketten' + (herkunft === 1 ? ' nicht ' : ' ') + 'Regional.csv');
				});
			} else {
				throw new Error("There was an not OK response.");
			}
		});
	}

	async downloadGutscheinListe() {
		return fetch(CONFIG.ServerUrl + '/Export/Gutscheine', {
			headers: {
				'Authorization': 'Bearer ' + (await GetToken())
			}
		}).then(response => {
			if (response.ok) {
				return response.blob().then(blob => {
					if (blob.size === 0) throw new Error("The response was empty.");
					this.downloadBlob(blob, 'Gutscheine.xlsx');
				});
			} else {
				throw new Error("There was an not OK response.");
			}
		});
	}

	async downloadTagesumsatzListe(from, to) {
		return fetch(CONFIG.ServerUrl + '/Export/Tagesumsaetze/From/' + from.toISOString() + '/To/' + to.toISOString(), {
			headers: {
				'Authorization': 'Bearer ' + (await GetToken())
			}
		}).then(response => {
			if (response.ok) {
				return response.blob().then(blob => {
					if (blob.size === 0) throw new Error("The response was empty.");
					this.downloadBlob(blob, 'TagesumsatzListe.xlsx');
				});
			} else {
				throw new Error("There was an not OK response.");
			}
		});
	}

	downloadBlob(blob, name) {
		const a = window.document.createElement('a');
		a.href = window.URL.createObjectURL(blob);
		a.download = name;
		window.document.body.append(a);
		a.click();
		window.URL.revokeObjectURL(a.href);
		a.remove();
	}
}