import React, { useEffect, useState, useContext, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GlobalState } from "./GlobalState";
import { ProduzentenService } from "../service/ProduzentenService";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Accordion, AccordionTab } from "primereact/accordion";

export default function Produzent(props) {
	const labelWidth = '10em';
	const inputWidth = '20em';

	const produzentenService = new ProduzentenService();
	const history = useHistory();
	const { produzentenId } = useParams();

	const [globalState, setGlobalState] = useContext(GlobalState);
	const [produzent, setProduzent] = useState({});

	const toastRef = useRef(null);

	useEffect(() => {
		setGlobalState({...globalState, page: props.action === 'bearbeiten' ? 'Produzenten > bearbeiten' : 'Produzenten > anlegen'});
		if (props.action === 'bearbeiten') {
			produzentenService.getFromId(produzentenId).then(data => setProduzent(data));
		}
	}, []);

	const onSpeichernClick = async () => {
		const produzentZumSpeichern = {...produzent};
		produzentZumSpeichern.Name = cleanString(produzentZumSpeichern.Name);
		produzentZumSpeichern.Ort = cleanString(produzentZumSpeichern.Ort);
		produzentZumSpeichern.PLZ = cleanString(produzentZumSpeichern.PLZ);
		produzentZumSpeichern.Adresse = cleanString(produzentZumSpeichern.Adresse);
		produzentZumSpeichern.TelNr = cleanString(produzentZumSpeichern.TelNr);
		produzentZumSpeichern.Handy1 = cleanString(produzentZumSpeichern.Handy1);
		produzentZumSpeichern.EMail = cleanString(produzentZumSpeichern.EMail);
		produzentZumSpeichern.Fax = cleanString(produzentZumSpeichern.Fax);

		if (produzentZumSpeichern.Name == null) {
			toastRef.current.show({ severity: "error", summary: "Der Produzent konnte nicht angelegt werden", detail: "Nicht alle Pflichtfelder wurden ausgefüllt.", life: 3000, closable: false });
			return;
		}

		produzentenService.set(produzentZumSpeichern).then(async () => {
			toastRef.current.show({severity:'success', summary: 'Erfolgreich', detail:'Produzent gespeichert.', life: 2000});
			setTimeout(() => history.goBack(), 1000);
		}).catch(() => {
			toastRef.current.show({severity:'error', summary: 'Der Produzent konnte nicht gespeichert werden', detail:'Der Name ist bereits vergeben.', life: 5000});
		});
	};

	function cleanString(str) {
		if (typeof(str) !== 'string') return null;
		str = str?.trim();
		if (str === undefined || str === null || str === '') return null;
		return str;
	}

	return (
		<React.Fragment>
			<Toast ref={toastRef}/>
			<div className="p-component grid">
				<div className="col-12">
					<div className="field grid">
						<Button label="Speichern" icon="pi pi-save" style={{marginRight: '.5em'}} onClick={onSpeichernClick} />
						<Button label="Abbrechen" icon="pi pi-times" onClick={() => history.goBack()} />
					</div>
				</div>
				<div className="col-12">
					<div className="field grid">
						<label htmlFor="name" className="col-fixed" style={{width: labelWidth}}>Name</label>
						<div className="col">
							<InputText value={produzent.Name} id="name" onChange={e => setProduzent({...produzent, Name: e.target.value})}  style={{width: inputWidth}} className={produzent.Name === undefined || produzent.Name === null || produzent.Name === '' ? 'p-invalid' : ''}/>
						</div>
					</div>
				</div>
				<div className="col-12">
					<Accordion activeIndex={0}>
						<AccordionTab header="Adresse">
							<div className="field grid">
								<label htmlFor="strasse" className="col-fixed" style={{width: labelWidth}}>Straße</label>
								<div className="col">
									<InputText value={produzent.Adresse} id="strasse" style={{width: inputWidth}} onChange={e => setProduzent({...produzent, Adresse: e.target.value})}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="plz" className="col-fixed" style={{width: 'calc(' + labelWidth + ' / 4)'}}>PLZ</label>
								<label htmlFor="ort" className="col-fixed" style={{width: 'calc((' + labelWidth + ' / 4) * 3)'}}>Ort</label>
								<div className="col">
									<InputText value={produzent.PLZ} id="plz" style={{width: 'calc(' + inputWidth + ' / 4)'}} onChange={e => setProduzent({...produzent, PLZ: e.target.value})}/>
									<InputText value={produzent.Ort} id="ort" style={{width: 'calc((' + inputWidth + ' / 4) * 3)'}} onChange={e => setProduzent({...produzent, Ort: e.target.value})}/>
								</div>
							</div>
						</AccordionTab>
						<AccordionTab header="Kontakt">
							<div className="field grid">
								<label htmlFor="telefon" className="col-fixed" style={{width: labelWidth}}>Telefon</label>
								<div className="col">
									<InputText value={produzent.TelNr} id="telefon" onChange={e => setProduzent({...produzent, TelNr: e.target.value})} style={{width: inputWidth}}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="fax" className="col-fixed" style={{width: labelWidth}}>Fax</label>
								<div className="col">
									<InputText value={produzent.Fax} id="fax" onChange={e => setProduzent({...produzent, Fax: e.target.value})} style={{width: inputWidth}}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="handy" className="col-fixed" style={{width: labelWidth}}>Handy</label>
								<div className="col">
									<InputText value={produzent.Handy1} id="handy" onChange={e => setProduzent({...produzent, Handy1: e.target.value})} style={{width: inputWidth}}/>
								</div>
							</div>
							<div className="field grid">
								<label htmlFor="email" className="col-fixed" style={{width: labelWidth}}>E-Mail</label>
								<div className="col">
									<InputText value={produzent.EMail} id="email" onChange={e => setProduzent({...produzent, EMail: e.target.value})} style={{width: inputWidth}}/>
								</div>
							</div>
						</AccordionTab>
					</Accordion>
				</div>
			</div>
		</React.Fragment>
	);
}