import { countries } from '../data/countries';
import { states } from '../data/states';

function compare (country, arg) {
    arg = arg.toLowerCase();
    return (
      country.alpha2.toLowerCase() === arg || country.name.toLowerCase() === arg
    );
};

export function getCountries()
{
    return countries;
}

export function getCountry(arg)
{
    return countries.find(x => compare(x, arg));
}

export function getStates(code)
{
    try {
        return states.filter(state => state.country.toLowerCase() === code.toLowerCase());
    } catch (e)
    {

    }
}
