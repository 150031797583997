import React, {useState, useEffect, useRef, useContext} from 'react';
import {InputText} from 'primereact/inputtext';
import { InputNumber } from "primereact/inputnumber";
import {Checkbox} from 'primereact/checkbox';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import { Calendar } from "primereact/calendar";
import { Accordion, AccordionTab } from 'primereact/accordion';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {LieferantenService} from '../service/LieferantenService';
import {EinheitService} from '../service/EinheitService';
import { SettingsService } from '../service/SettingsService';
import { ProduktService } from "../service/ProduktService";
import { ArtikelgruppenService } from "../service/ArtikelgruppenService";
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom'
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { ListBox } from 'primereact/listbox'
import { FileUpload } from 'primereact/fileupload';
import { InputTextarea } from 'primereact/inputtextarea';
import { EtikettenService} from '../service/EtikettenService';
import { GlobalState } from './GlobalState';
import { ImportService } from '../service/ImportService';
import { addDE } from './LocalDE';
import { InlineIcon } from '@iconify/react';
import barcodeScanner24Filled from '@iconify-icons/fluent/barcode-scanner-24-filled';
import { ProduzentenService } from '../service/ProduzentenService';
import { KategorienService } from "../service/KategorienService";
import { TreeSelect } from "primereact/treeselect";
import { getCountries, getStates, getCountry } from '../service/countrypicker';
import { formatCurrency } from '../helper/Util'; 

import CONFIG from 'config';

function Produkt(props) {
    const lieferantenService = new LieferantenService();
    const einheitenService = new EinheitService();
    const produktService = new ProduktService();
    const artikelgruppenService = new ArtikelgruppenService();
    const importService = new ImportService();
    const produzentenService = new ProduzentenService();
    const kategorienService = new KategorienService();
    const { id } = useParams()
    const [produkt, setProdukt] = useState(null);
    const [lieferanten, setLieferanten] = useState([]);
    const [einheiten, setEinheiten] = useState([]);
    const [datum, setDatum] = useState(new Date());
    const [ek, setEK] = useState(0);
    const [ekbrutto, setEKBrutto] = useState(0);
    const [spanne, setSpanne] = useState(0);
    const [vk, setVK] = useState(0);
    const [vk2, setVK2] = useState(0);
    const [vk3, setVK3] = useState(0);
    const [pfand, setPfand] = useState(0);
    const [preis100gAktiviert, setPreis100gAktiviert] = useState(false);
    const [artikelgruppenVisible, setArtikelgruppenVisible] = useState(false);
    const [lieferantRegaltext, setLieferantRegaltext] = useState();
    const [artikelgruppe, setArtikelgruppe] = useState();
    const [artikelgruppen, setArtikelgruppen] = useState();
    const [selectedArtikelgruppe, setSelectedArtikelgruppe] = useState();
    const [warengruppen, setWarengruppen] = useState();
    const [selectedWarengruppe, setSelectedWarengruppe] = useState();
    const [kategorien, setKategorien] = useState();
    const [selectedKategorie, setSelectedKategorie] = useState();
    const [warengruppenOptions, setWarengruppenOptions] = useState();
    const [ArtikelgruppenOptions, setArtikelgruppenOptions] = useState();
    const [uploadUrl, setUploadUrl] = useState();
    const [anzahlRegaletiketten, setAnzahlRegaletiketten] = useState(1);
    const [dialogRegaletikettenVisible, setDialogRegaletikettenVisible] = useState(false);
    const [vorschauData, setVorschauData] = useState(null);
    const [produzentRegaltext, setProduzentRegaltext] = useState();
    const [imageData, setImageData] = useState(null);
    const [uploadClearDisabled, setUploadClearDisabled] = useState(true);
    const [globalState, setGlobalState] = useContext(GlobalState);
    const [produkttextEingegeben, setProdukttextEingegeben] = useState(false);
    const [regaletikettTextEingegeben, setRegaletikettTextEingegeben] = useState(false);
    const [produzenten, setProduzenten] = useState([]);
    const [kategorienTree, setKategorienTree] = useState();
    const [anzeigeKategorien, setAnzeigeKategorien] = useState();
    const [barcodeGewichtsOderPreisabhaengigDeaktiviert, setBarcodeGewichtsOderPreisabhaengigDeaktiviert] = useState(false);
    const [veeinheiten, setVeeinheiten] = useState([]);
    const [barcodePreisabhaengigConfirmDialog, setBarcodePreisabhaengigConfirmDialog] = useState(false);
    const [barcodeGewichtsabhaengigConfirmDialog, setBarcodeGewichtsabhaengigConfirmDialog] = useState(false);
    const [barcodeKGConfirmDialog, setBarcodeKGConfirmDialog] = useState(false);
    const [states, setStates] = useState([]);
    const countries = getCountries();
    const etikettenService = new EtikettenService();
    const toast = useRef(null);
    const minDate = new Date();
    let steuer;
    let steuer2;

    switch (CONFIG.Land) {
        case 'AT':
            steuer = [
                {label: '0', value: 0},
                {label: '10', value: 1},
                {label: '13', value: 2},
                {label: '20', value: 3},
            ];
            steuer2 = {
                0: 0,
                1: 0.1,
                2: 0.13,
                3: 0.2
            };
            break;
        case 'DE':
            steuer = [
                {label: '0', value: 0},
                {label: '7', value: 7},
                {label: '9,5', value: 9},
                {label: '19', value: 8}
            ];
            steuer2 = {
                0: 0,
                7: 0.07,
                8: 0.19
            };
            break;
        default:
            throw new Error('Land "' + CONFIG.Land + '" unbekant');
    }

    const herkunftOptions =   [
        {label: 'R', value: 0},
        {label: 'N', value: 1},
    ];

    const gpeinheiten = [
        "KG",
        "100g",
        "L",
        "100ml",
        "m",
        "m²",
        "m³"
    ]

    const veeinheitenoptions = [
        "KG",
        "g",
        "L",
        "m",
        "m²",
        "m³"
    ]

    function getveeinheiten() {
        if (produkt === null)
            return veeinheitenoptions;

        if (produkt.GPEinheit === "KG" || produkt.GPEinheit === "100g")
        {
            return ([
                "KG",
                "g"
            ]);
        }
        else if (produkt.GPEinheit === "L" || produkt.GPEinheit === "100ml")
        {
            return [
                "L",
                "ml"
            ]
        }
        else if (produkt.GPEinheit === "m")
        {
            return [
                "m",
            ]
        }
        else if (produkt.GPEinheit === "m²")
        {
            return [
                "m²",
            ]
        }
        else if (produkt.GPEinheit === "m³")
        {
            return [
                "m³"
            ]
        }
        else return veeinheitenoptions;
    }

    const labelWidth = '200px';
    const preisLabelWidth = '100px';
    const preisInputWidth = '100px';
    let preisdt = useRef(null);
    let standortdt = useRef(null);
    let uploadRef = useRef(null);
    const history = useHistory();

    useEffect(() => {
        setGlobalState({...globalState, page: props.action === 'bearbeiten' ? 'Produktstammdaten > Produkt bearbeiten' : (props.action === 'kopieren' ? 'Produktstammdaten > Produkt kopieren' : 'Produktstammdaten > neues Produkt')});
        lieferantenService.getAll().then(data => setLieferanten(data));
        produzentenService.getAll().then(data => setProduzenten(data));
        if (props.action === 'bearbeiten' || props.action === 'kopieren')
            produktService.getProdukt(id).then(onProduktLoad);
        else
            produktService.neuesProdukt().then(onProduktLoad);
        setEinheiten(einheitenService.getAll());
        kategorienService.GetKategorien().then(kategorien => {
            console.log(convertToAlleKategorien(kategorien));
            console.log(kategorien);
            setAnzeigeKategorien(convertToAlleKategorien(kategorien));
            setKategorienTree(convertToTree(kategorien))
        });
        setVeeinheiten(getveeinheiten());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function convertToTree(kategorien) {
		return kategorien.map(k => ({
			key: k.ID,
            label: k.Bezeichnung,
			children: convertToTree(k.Children)
		}));
	}

    function convertToAlleKategorien(kategorien)
    {
        var _array = [];
        kategorien.forEach(x => {
            _array.push(x);
            if (x.Children !== undefined && x.Children !== null && x.Children.length > 0)
            {
                convertToAlleKategorien(x.Children).forEach(y => _array.push(y));
            }
        });
        return _array;
    }

    useEffect(
        () => {
            if (produkt !== null && artikelgruppen !== null)
                setArtikelgruppe(getArtikelgruppenBezeichnung(produkt.ArtikelgruppenID));
        },
        [produkt,artikelgruppen]
    );

    useEffect(
        () => {
            if (produkt?.GPEinheit !== null && produkt?.GPEinheit !== undefined)
            {
                var _veeinheiten = getveeinheiten();
                setVeeinheiten(_veeinheiten);
            }
        },[produkt?.GPEinheit]
    )

    const onProduktLoad = (data) => {
        produktService.getImage(data.ID).then(data => onBildGeladen(data));
        if (props.action === 'kopieren') {
            data.ID = -1;
            data.ArtNr = undefined;
            data.Barcodes = [];
        } else {
            const barcodes = data.Barcodes.map(x => { return {...x, Datum: new Date(x.Datum)}});
            data.Barcodes = barcodes;
        }
        if (data.Land !== undefined && data.Land !== null && data.Land.length > 0)
        {
            data.Land = getCountry(data.Land);
            if (data.Bundesland !== undefined && data.Bundesland !== null && data.Bundesland.length > 0)
            {
                console.log(data.Land);
                var code = data.Land?.alpha2;
                var states = getStates(code).map(x => x.name);
                console.log(states);
                setStates(states);
            }
        }
        console.log(data);
        setProduktData(data);

		if (data !== null && data !== undefined &&
            data.Produzent !== null && data.Produzent !== undefined &&
            data.Produzent.Regaletiketttext !== null && data.Produzent.Regaletiketttext !== undefined)
		{
			setProduzentRegaltext(data.Produzent.Regaletiketttext);
		}
        setArtikelgruppe(getArtikelgruppenBezeichnung(data.ArtikelgruppenID));
        setUploadUrl(CONFIG.ServerUrl + "/Produkt/" + data.ID + "/upload");
        artikelgruppenService.getAll().then(data => onGruppenLoad(data));
        if (data.Produkttext !== undefined && data.Produkttext !== null && data.Produkttext !== '') {
            setProdukttextEingegeben(true);
        }
        if (data.Regaletiketttext !== undefined && data.Regaletiketttext !== null && data.Regaletiketttext != "") {
            setRegaletikettTextEingegeben(true);
        }
        if (data.Einheit == 'KG')
            setBarcodeGewichtsOderPreisabhaengigDeaktiviert(true);
        else
            setBarcodeGewichtsOderPreisabhaengigDeaktiviert(false);
    } 

    const onBildGeladen = (e) => {
        if (e !== undefined) setImageData("data:image/png;base64," + e.Data);
    }

    const onGruppenLoad = (e) => {
        setArtikelgruppen(e.Artikelgruppen);
        setKategorien(e.Kategorien);   
        setWarengruppen(e.Warengruppen);
    }

    const setProduktData = (e) => {
        setProdukt(e);
        if (e.Einheit == "KG")
            setPreis100gAktiviert(true);
    }

    const onProduktgruppeChange = (e) => {
        var _Produktgruppe = e.value;
        setProdukt(prevProdukt => ({
                ...prevProdukt,    // keep all other key-value pairs
                Produktgruppe: _Produktgruppe,      // update the value of specific key
            }
        ));
    }

    const onLieferantChange = (e) => {
        var _Lieferant = e.target.value;
        setProdukt(prevProdukt => ({
                ...prevProdukt,    // keep all other key-value pairs
                Lieferant: _Lieferant      // update the value of specific key
            }
        ));
    }

    const setEinheitKG = () => {
        setBarcodeGewichtsOderPreisabhaengigDeaktiviert(true);
        let _barcodes = new Array();
        if (produkt.Barcodes !== undefined && produkt.Barcodes !== null && produkt.Barcodes.length > 0)
        {
            produkt.Barcodes.forEach(e => {
                if (e.Barcode?.length >= 13)
                    _barcodes.push(e);
            });
        }
        setProdukt(prevProdukt => ({
                ...prevProdukt,    // keep all other key-value pairs
                Einheit: "KG",      // update the value of specific key
                BarcodePreisAbhaengig: false,
                BarcodeGewichtsAbhängig: false,     // update the value of specific key
                Barcodes: _barcodes,
            }
        ));
    }

    const onEinheitChange = (e) => {
        var _Einheit = e.value;
        if (_Einheit == "KG") {
            console.log(produkt);
            if (produkt.Barcodes?.some(x => x.Barcode.length < 13))
                setBarcodeKGConfirmDialog(true);
            else
                setEinheitKG();
            return;
        }
        else if (_Einheit == "Stueck") {
            if (preis100gAktiviert === true)
                setPreis100gAktiviert(false)
            setBarcodeGewichtsOderPreisabhaengigDeaktiviert(false);
            setProdukt(prevProdukt => ({
                    ...prevProdukt,    // keep all other key-value pairs
                    Einheit: _Einheit,      // update the value of specific key
                }
            ));
            return;    
        }
    }

    const onGPEinheitChange = (e) => {
        var _Einheit = e.value;
        setProdukt(prevProdukt => ({
                ...prevProdukt,    // keep all other key-value pairs
                GPEinheit: _Einheit,      // update the value of specific key
            }
        ));
        return;
    }

    const onVEEinheitChange = (e) => {
        var _Einheit = e.value;
        setProdukt(prevProdukt => ({
                ...prevProdukt,    // keep all other key-value pairs
                VEEinheit: _Einheit,      // update the value of specific key
            }
        ));
        return;
    }

    const onVeMengeChange = (e) => {
        var _Einheit = e.value;
        setProdukt(prevProdukt => ({
                ...prevProdukt,    // keep all other key-value pairs
                VEMenge: _Einheit,      // update the value of specific key
            }
        ));
        return;
    }
    
    const onBezeichnungChange = (e) => {
        const produkt2 = {...produkt};
        produkt2.Bezeichnung = e.target.value;
        if(!produkttextEingegeben) produkt2.Produkttext = e.target.value?.substring(0, 20);
        if(!regaletikettTextEingegeben) produkt2.Regaletiketttext = e.target.value;
        setProdukt(produkt2);
    }
    
    const onProdukttextChange = (e) => {
        try {
            setProdukttextEingegeben(true);
            var _Produkttext = e.target.value.substring(0, 20);
            setProdukt(prevProdukt => ({
                    ...prevProdukt,    // keep all other key-value pairs
                    Produkttext: _Produkttext,      // update the value of specific key
                }
            ));
        } catch {}
    }

    const onRegaletiketttextChange = (e) => {
        setRegaletikettTextEingegeben(true);
        setProdukt(prevProdukt => ({
                ...prevProdukt,    // keep all other key-value pairs
                Regaletiketttext: e.target.value,
            }
        ));
    }

    const onProduzentRegaletiketttextChange = (e) => {
        setProduzentRegaltext(e.target.value);
        
        var produzent = {...produkt.Produzent,
            Regaletiketttext: e.target.value
        }
        var _produkt = {...produkt,
            Produzent: produzent
        }

        var neueProduzenten = produzenten.map(u => u.ID !== produzent.ID ? u : produzent);
        console.log(neueProduzenten);
        setProduzenten(neueProduzenten);
        setProdukt(_produkt);
    }
    
    const onAlkoholChange = (e) => {
        var _Alkohol = e.checked;
        setProdukt(prevProdukt => ({
                ...prevProdukt,    // keep all other key-value pairs
                Alkohol: _Alkohol,      // update the value of specific key
            }
        ));
    }

    const onMengeAendernSofort = (e) => {
        var _MengeAendernSofort = e.checked;
        setProdukt(prevProdukt => ({
                ...prevProdukt,    // keep all other key-value pairs
                MengeSofortAnzeigen: _MengeAendernSofort,      // update the value of specific key
            }
        ));
    }
    
    const onBarcodeChange = (e) => {
        var _Barcode = e.target.value;
        setProdukt(prevProdukt => ({
                ...prevProdukt,    // keep all other key-value pairs
                Barcode: _Barcode,      // update the value of specific key
            }
        ));
    }
    
    const onBarcodepreisabhaengigChange = (e) => {
        if (e.checked == true && (produkt.Barcodes?.length > 0) && (produkt.Barcodes.filter(code => code.Barcode.toString().length < 8)).length == 0)
        {
            toast.current.show({severity:'warn', summary: 'Warnung', detail:'Barcode darf nur 7 Stellen lang sein, wenn die Option preis- oder gewichtsabhängig aktiviert ist.', life: 3000});
            setBarcodePreisabhaengigConfirmDialog(true);
        }
        else 
        {
            var _Barcodepreisabhaengig = !produkt.BarcodePreisAbhaengig;
            setProdukt(prevProdukt => ({
                ...prevProdukt,    // keep all other key-value pairs
                BarcodePreisAbhaengig: _Barcodepreisabhaengig,
                BarcodeGewichtsAbhängig: false,     // update the value of specific key
                AnzeigeKategorienID: null,
            }
            ));
        }

    }

    const toggleBarcodePreisAbhaengig = () => {
        var _Barcodepreisabhaengig = !produkt.BarcodePreisAbhaengig;
        var _Barcodes = produkt.Barcodes;
        _Barcodes = _Barcodes.filter(code => code.Barcode.length <= 7);
        setProdukt(prevProdukt => ({
            ...prevProdukt,    // keep all other key-value pairs
            BarcodePreisAbhaengig: _Barcodepreisabhaengig,
            BarcodeGewichtsAbhängig: false,     // update the value of specific key
            Barcodes: _Barcodes,
            AnzeigeKategorienID: null
        }
        ));
        setBarcodePreisabhaengigConfirmDialog(false);
    }
    
    const toggleBarcodeGewichtsAbhaengig = () => {
        var _BarcodeGewichtsAbhaengig = !produkt.BarcodeGewichtsAbhängig;
        var _Barcodes = produkt.Barcodes;
        const _Barcodes1 = _Barcodes.filter(code => code.Barcode.toString().length < 8);
        setProdukt(prevProdukt => ({
            ...prevProdukt,    // keep all other key-value pairs
            BarcodeGewichtsAbhängig: _BarcodeGewichtsAbhaengig,
            BarcodePreisAbhaengig: false,     // update the value of specific key
            Barcodes: _Barcodes1,
            AnzeigeKategorienID: null
        }
        ));
        setBarcodeGewichtsabhaengigConfirmDialog(false);
    }

    const onBarcodeGewichtsAbhaengigChange = (e) => {
        if (e.checked && (produkt.Barcodes?.length > 0) &&  (produkt.Barcodes.filter(code => code.Barcode.toString().length < 8)).length == 0)
        {
            toast.current.show({severity:'warn', summary: 'Warnung', detail:'Barcode darf nur 7 Stellen lang sein, wenn die Option preis- oder gewichtsabhängig aktiviert ist.', life: 3000});
            setBarcodeGewichtsabhaengigConfirmDialog(true);
        }
        else 
        {
            var _BarcodeGewichtsAbhaengig = e.checked;
            setProdukt(prevProdukt => ({
                ...prevProdukt,    // keep all other key-value pairs
                BarcodePreisAbhaengig: false,
                BarcodeGewichtsAbhängig: _BarcodeGewichtsAbhaengig,     // update the value of specific key
                AnzeigeKategorienID: null,
            }
            ));
        }
    }

    const onLieferantRegaletiketttextChange = (e) => {
        setLieferantRegaltext(e.target.value);
        
        var lieferant = {...produkt.Lieferant,
            Regaletiketttext: e.target.value
        }
        var _produkt = {...produkt,
            Lieferant: lieferant
        }

        var neueLieferanten = lieferanten.map(u => u.ID !== lieferant.ID ? u : lieferant);
        console.log(neueLieferanten);
        setLieferanten(neueLieferanten);
        setProdukt(_produkt);
    }
    
    
    const onPreis100gAnzeigenChange = (e) => {
        var _Preis100gAnzeigen = e.checked;
        setProdukt(prevProdukt => ({
                ...prevProdukt,    // keep all other key-value pairs
                Preis100gAnzeigen: _Preis100gAnzeigen,
            }
        ));
    }

    const onRabattartikelChange = (e) => {
        var _Rabattartikel = e.checked;
        setProdukt(prevProdukt => ({
                ...prevProdukt,    // keep all other key-value pairs
                Rabattartikel: _Rabattartikel,
            }
        ));
    }
    
    const onSteuerChange = (e) => {
        var _steuer = e.value;
        setProdukt(prevProdukt => ({
                ...prevProdukt,    // keep all other key-value pairs
                Steuersatz: _steuer,
            }
        ));
    }

    const onEKSteuerChange = (e) => {
        var _steuer = e.value;
        setProdukt(prevProdukt => ({
                ...prevProdukt,    // keep all other key-value pairs
                EKSteuersatz: _steuer,
            }
        ));
    }

        
    const onHerkunftChange = (e) => {
        setProdukt(prevProdukt => ({
                ...prevProdukt,    // keep all other key-value pairs
                Herkunft: e.value,
            }
        ));
    }

    const onManuellerPreisChange = (e) => {
        setProdukt(prevProdukt => ({
                ...prevProdukt,    // keep all other key-value pairs
                manuellerPreis: e.checked,
            }
        ));
    }
    
    const onSpanneChange = (e) => {
        if (e?.value === undefined || e?.value === null) return;
        
        setSpanne(e.value);
        if (steuer2[produkt?.Steuersatz] === undefined || steuer2[produkt?.Steuersatz] === null || ek === undefined || ek === null) return;

        setVK(Math.round(((ek / (1 - e.value / 100) * (1 + steuer2[produkt.Steuersatz])) + Number.EPSILON) * 100) / 100);
        //setVK(Math.round(((ek * (1 + e.value / 100) * (1 + steuer2[produkt.Steuersatz])) + Number.EPSILON) * 100) / 100); alte Berechnung
    }

    const onEKChange = e => {
        setEK(e.value)
        if (e.value === undefined || e.value === null || vk === undefined || vk === null) return;

        let nettovk = vk / (1 + steuer2[produkt.Steuersatz]);
        setSpanne(Math.round(((1 - (e.value / nettovk)) * 100)));

        let eksteuersatz = 0;
        if (produkt.EKSteuersatz !== undefined && produkt.EKSteuersatz !== 0 && produkt.EKSteuersatz !== null)
            eksteuersatz = produkt.EKSteuersatz;

        console.log(eksteuersatz);
        let bruttoek = e.value * (1+steuer2[eksteuersatz]);
        setEKBrutto(bruttoek);
    }

    const onEKBruttoChange = e => {
        setEKBrutto(e.value);
        if (e.value === undefined || e.value === null || vk === undefined || vk === null) return;

        let eksteuersatz = 0;
        if (produkt.EKSteuersatz !== undefined && produkt.EKSteuersatz != 0 && produkt.EKSteuersatz !== null)
            eksteuersatz = produkt.EKSteuersatz; 
        let nettoek = {value: e.value / (1 + steuer2[eksteuersatz])};
        onEKChange(nettoek);
    }

    const onVKChange = e => {
        setVK(e.value);
        if (ek === undefined || ek === null || e.value === undefined || e.value === null) return;

        let nettovk = e.value / (1 + steuer2[produkt.Steuersatz]);
        setSpanne(Math.round(((1 - (ek / nettovk)) * 100)));
    }

    const onBarcodeGenerieren = (e) => {
        if (produkt.BarcodeGewichtsAbhängig || produkt.BarcodePreisAbhaengig)
            produktService.generateBarcode7stellig().then((data) => setProduktBarcode(data))
        else
            produktService.generateBarcode().then((data) => setProduktBarcode(data))
    }

    const setProduktBarcode = (e) => {
        setProdukt(prevProdukt => ({
            ...prevProdukt,    // keep all other key-value pairs
            Barcode: e,
        }));
    }

    const onCancelClick = (e) => {
        history.goBack();
    }
    
    const onHinzufuegenClick = (e) => {
        var _zeile = {};
        
        if (ek > vk)
        {
            toast.current.show({severity:'error', summary: 'Fehler', detail:'Einkaufspreis darf nicht höher als der Verkaufspreis sein', life: 2000});
            return;
        }

        _zeile.Datum = datum;
        _zeile.EK = ek;
        _zeile.EKBrutto = ekbrutto;
        _zeile.VK = vk;
        _zeile.VK2 = vk2;
        _zeile.VK3 = vk3;
        _zeile.Spanne = spanne;
        _zeile.Pfand = pfand == null ? 0 : pfand;

        if (_zeile.Datum === undefined || _zeile.Datum === null || _zeile.EK === undefined || _zeile.EK === null || _zeile.VK === undefined || _zeile.VK === null || _zeile.Spanne === undefined || _zeile.Spanne === null || _zeile.Pfand === undefined || _zeile.Pfand === null) return;
        
        if (produkt.Preise.length > 0 && produkt.Preise[produkt.Preise.length - 1]?.VK !== vk) {
            setAnzahlRegaletiketten(produkt.Standorte.filter(s => s.aktiviert).length);
            setDialogRegaletikettenVisible(true);
        }
        produkt.Preise.push(_zeile);

        setDatum(null);
        setEK(null);
        setEKBrutto(null);
        setVK(null);
        setVK2(null);
        setVK3(null);
        setSpanne(null);
        setPfand(null);
    }

    addDE();
    
    const inputTextEditor = (productKey, props, field) => {
        return (
            <table>
                <tr>
                    <td>
                        <InputText type="text" value={props.value} onChange={(e) => props.editorCallback(e.target.value)} />
                    </td>
                    <td>
                        <Button icon="pi pi-clone" tooltip="für alle übernehmen" tooltipOptions={{position: 'bottom'}} onClick={() => {
                            setProdukt(produkt => ({
                                ...produkt,
                                Standorte: props.props.value.map(s => ({...s, [field]: props.rowData[field]}))
                            }));
                        }}/>
                    </td>
                </tr>
            </table>
        );
    }

    const onCellEditComplete = (e) => {
        let updatedStandorte = [...produkt.Standorte]
        updatedStandorte.forEach(function(item, i) { if (item.KassenID == e.newRowData.KassenID) updatedStandorte[i] = e.newRowData; });
        
        setProdukt(prevProdukt => ({
            ...prevProdukt,    // keep all other key-value pairs
            Standorte: updatedStandorte,
        }));
    }

    const onVerfügbarChanged = (e, row, props) => {
        let updateRows = [...props];
        updateRows[props.indexOf(row)]["aktiviert"] = e;
        setProdukt(prevProdukt => ({
            ...prevProdukt,    // keep all other key-value pairs
            Standorte: updateRows,
        }));
    } 

    const verfügbarTemplate = (props, rowData) => {
        return (
            <React.Fragment>
                <Checkbox checked={rowData.aktiviert} onChange={(e) => onVerfügbarChanged(e.target.checked, rowData, props)} />
            </React.Fragment>
        );
    }

    const onSpeichernError = (e) => {
        let message = '';
        if (e.request.response !== undefined )
        {
            try {
                message = JSON.parse(e.request.response).message;
            } catch(ex) {}
        }
       
        toast.current.show({severity:'error', summary: 'Fehler', detail: 'Fehler beim Speichern ' + message, life: 3000});
    }

    const onSpeichernSuccess = (e) => {
        let url = uploadUrl;
        if(props.action !== "bearbeiten");
        {
            url = CONFIG.ServerUrl + "/Produkt/" + e.iD + "/upload";
            setUploadUrl(url);
        }

        try {
            importService.upload(uploadRef.current.getFiles()?.[0], uploadRef.current.props.name, url);
        } catch (e) {
            console.log(e);
        }
        toast.current.show({severity:'success', summary: 'Erfolgreich', detail:'Produkt gespeichert', life: 2000});
        setTimeout(function(){ history.goBack(); }, 1000);
    }

    const onSpeichernClick= (e) => {
        let error = false;
        let messages = [];
        if( produkt.Bezeichnung === undefined ||
            produkt.Bezeichnung === null ||
            produkt.Bezeichnung === '' ||
            artikelgruppe === undefined ||
            artikelgruppe === null ||
            artikelgruppe === '' ||
            produkt.Lieferant === undefined ||
            produkt.Lieferant === null ||
            produkt.Produzent === undefined ||
            produkt.Produzent === null ||
            produkt.Einheit === undefined ||
            produkt.Einheit === null ||
            produkt.Steuersatz === undefined ||
            produkt.Steuersatz === null ||
            produkt.Herkunft === undefined ||
            produkt.Herkunft === null) {
                
            messages.push({severity:'error', summary: 'Das Produkt konnte nicht gespeichert werden', detail:'Nicht alle Pflichtfelder wurden ausgefüllt.', life: 3000});
            error = true;
        }

        if ( produkt.Barcode?.length > 0) {
            messages.push({severity:'error', summary: 'Barcode muss noch hinzugefügt werden', detail:'Barcode muss noch hinzugefügt werden', life: 3000});
            error = true;
        }

        if (error === true) {
            toast.current.show(messages);
            return;
        }
        
        var _produkt = {...produkt, Land: produkt.Land?.name};

        if (props.action === "kopieren") {
            produktService.produktKopieren(_produkt).then(onSpeichernSuccess).catch(onSpeichernError);
            return;
        }

        if (props.action === "bearbeiten")
            produktService.updateProdukt(_produkt).then((e) => onSpeichernSuccess(e)).catch(onSpeichernError);
        else
            produktService.updateProdukt(_produkt).then(onSpeichernSuccess).catch(onSpeichernError);
    }

    const onDialogOk = (e) => {
        setArtikelgruppenVisible(false);
        setArtikelgruppe(getArtikelgruppenBezeichnung(selectedArtikelgruppe));
        setProdukt(prevProdukt => ({
            ...prevProdukt,    // keep all other key-value pairs
            ArtikelgruppenID: selectedArtikelgruppe,
        }));
    }

    const renderFooter = () => {
        return (
            <div>
                <Button label="Abbrechen" icon="pi pi-times" onClick={() => setArtikelgruppenVisible(false)} className="p-button-text" />
                <Button label="Ok" icon="pi pi-check" onClick={onDialogOk} autoFocus />
            </div>
        );
    }

    const onKategorieChange = (e) => {
        try {
            if (e !== undefined && e !== null)
            {
                let _warengruppen = warengruppen.filter(function(x){
                    return x.KategorieID === e;
                });
                setWarengruppenOptions(_warengruppen);
                setSelectedKategorie(e);
                onWarengruppenChange(_warengruppen[0].ID);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const onWarengruppenChange = (e) => {
        try {
            if (e !== undefined && e !== null) {
                let _artikelgruppen = artikelgruppen.filter(function(x){
                    return x.WarengruppenID === e;
                });
                setArtikelgruppenOptions(_artikelgruppen);
                setSelectedWarengruppe(e);
                setSelectedArtikelgruppe(_artikelgruppen[0].ID);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const onArtikelgruppenDialogShow = (e) => {
        if (produkt.ArtikelgruppenID !== undefined && produkt.ArtikelgruppenID !== null) {
            setGruppen(produkt.ArtikelgruppenID);
        } else {
            setGruppen(1);
        }
    }

    function setGruppen(artID) {
        let _artikelgruppe = artikelgruppen.filter(function(x){
            return x.ID === artID;
        });

        let _artikelgruppen = artikelgruppen.filter(function(x){
            return x.WarengruppenID === _artikelgruppe[0].WarengruppenID;
        });

        let _warengruppe =  warengruppen.filter(function(x){
            return x.ID === _artikelgruppe[0].WarengruppenID;
        });

        let _warengruppen = warengruppen.filter(function(x){
            return x.KategorieID === _warengruppe[0].KategorieID;
        });

        setSelectedKategorie(_warengruppe[0].KategorieID);
        setWarengruppenOptions(_warengruppen);
        setArtikelgruppenOptions(_artikelgruppen);
        setSelectedWarengruppe(_warengruppe[0].ID);
        setSelectedArtikelgruppe(_artikelgruppe[0].ID);
    };

    const getArtikelgruppenBezeichnung = (id) =>
    { 
        if (artikelgruppen == undefined || artikelgruppen === null)
            return;
        try {
            let _artikelgruppe = artikelgruppen.filter(function(x){
                return x.ID === id;
            });
            if (_artikelgruppe[0] === undefined || _artikelgruppe[0] === null)
                return;
            return _artikelgruppe[0].Bezeichnung;
        } catch (e) {
            console.log(e);
        }
    }

    function formatDate (date) {
        if (date === undefined || date === null)
            return;
        let month = date.getMonth() + 1;
        let day = date.getDate();

        if (month < 10) {
            month = '0' + month;
        }

        if (day < 10) {
            day = '0' + day;
        }

        return day + '.' + month + '.' + date.getFullYear();
    }

    const dateBodyTemplate = (rowData) => {
        let _date = new Intl.DateTimeFormat("de-DE", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
          }).format(new Date(rowData.Datum));
        return (
            <React.Fragment>
                <span>{_date}</span>
            </React.Fragment>
        );
    }

    const onCalendarChange = (e) => {
        let _date = e.value;
        _date.setTime(_date.getTime() + (2*60*60*1000));
    
        setDatum(_date);
    }

    const EKBodyTemplate = (rowData) => {
        if (rowData.EK === undefined || rowData.EK === null) return;

        return formatCurrency(rowData.EK);
    }

    const EKBruttoBodyTemplate = (rowData) => {
        if (rowData.EKBrutto === undefined || rowData.EKBrutto === null) return;

        return formatCurrency(rowData.EKBrutto);
    }

    const RohmargeTemplate = (rowData) => {
        if (rowData.Spanne === undefined || rowData.Spanne === null) return;

        return (
            <React.Fragment>
                <div style={{textAlign: "right", paddingLeft: "2rem"}}>
                    {rowData.Spanne.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })} %
                </div>
            </React.Fragment>
        )
    }

    const VKBodyTemplate = (rowData) => {
        if (rowData.VK === undefined || rowData.VK === null)
            return;
        return formatCurrency(rowData.VK);
    }

    const VK2BodyTemplate = (rowData) => {
        if (rowData.VK2 === undefined || rowData.VK2 === null)
            return;
        return formatCurrency(rowData.VK2);
    }

    const VK3BodyTemplate = (rowData) => {
        if (rowData.VK3 === undefined || rowData.VK3 === null)
            return;
        return formatCurrency(rowData.VK3);
    }

    const PfandBodyTemplate = (rowData) => {
        if (rowData.Pfand === undefined || rowData.Pfand === null)
        return;
        return formatCurrency(rowData.Pfand);
    }

    const onLandChange = (e) => {
        if (e.value === null || e.value === undefined)
        {
            setProdukt({...produkt, Land: e.value});
            return;
        }
        console.log(e.value);
        var states = getStates(e.value.alpha2).map(x => x.name);
        console.log(states);
        setProdukt({...produkt, Land: e.value});
        setStates(states);

    }

    
    const onStateChanged = (e) => {
        console.log(e.value);
        setProdukt({...produkt, Bundesland: e.value});
    }

    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <img alt={option.name} src="/assets/images/flag/flag_placeholder.png"
                        onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                        className={`flag flag-${option.alpha2.toLowerCase()}`} />
                    <div>{option.name}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const countryOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <img alt={option.name} src="/assets/images/flag/flag_placeholder.png"
                //onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                className={`flag flag-${option.alpha2.toLowerCase()}`} />
                <div>{option.name}</div>
            </div>
        );
    }


    const onVorschauClick = (e) => {
        try {
            const preise = produkt.Preise.sort((a, b) => new Date(a.Datum).getTime() - new Date(b.Datum).getTime()).sort((a, b) => (a.ID ?? 1000000000) - (b.ID ?? 1000000000));
            const preis = preise[preise.length - 1];
            if (preis === undefined || preis === null) {
                alert("Fehler beim Erstellen der Vorschau (es gibt keinen Preis)");
                return;
            }
            try {
                window.dymo.label.framework.init(function() {
                    console.log(window.dymo.label.framework.checkEnvironment());
                    var _produkt = {...produkt, Land: produkt.Land?.name};
                    etikettenService.getRegalLabelParam(_produkt).then((labelParam) => 
                    {
                        console.log(labelParam);
                        try {
                        var printers = window.dymo.label.framework.getPrinters();
                        var printer = etikettenService.getRegalPrinter(printers);
                        var is550 = window.dymo.label.framework.is550(printer.name);
                        var label = etikettenService.getRegalLabel(etikettenService, labelParam, is550);
                        var pngData = label.render();
                        setVorschauData("data:image/png;base64," + pngData);
                        } catch (e1) {
                            try {
                                var label = etikettenService.getRegalLabel(etikettenService, labelParam, true);
                                var pngData = label.render();
                                setVorschauData("data:image/png;base64," + pngData);
                            } catch (e2)
                            {
                                var label = etikettenService.getRegalLabel(etikettenService, labelParam, false);
                                var pngData = label.render();
                                setVorschauData("data:image/png;base64," + pngData);
                            }
                        }
                    });
                });
            } catch (e) {
                console.log(e);
                alert("Fehler beim Erstellen der Vorschau");
            }
        } catch (e) {
            console.log(e);
            alert("Fehler beim Erstellen der Vorschau");
        }
    }

    const regaletikettenFooter = (
        <div>
            <Button label="Drucken" icon="pi pi-check" onClick={(e) => {setDialogRegaletikettenVisible(false); printRegalEtikett(produkt)}} />
            <Button label="Abbrechen" icon="pi pi-times" onClick={(e) => {setDialogRegaletikettenVisible(false)}} />
        </div>
    );

    const onRegaletikettPrintClick = (e) => {
        setDialogRegaletikettenVisible(true);
    }

    const printRegalEtikett = (e) => {
        etikettenService.printRegaletikett(produkt, anzahlRegaletiketten);
    }

    const chooseOptions = {label: 'Wähle', icon: 'pi pi-fw pi-upload'};

    const actionBodyTemplate = rowData => {
		return (
			<React.Fragment>
				<Button icon="pi pi-trash" className="p-button-danger p-button-secondary mr-1" onClick={e => {
                        let _barcodes = produkt.Barcodes;
                        for( var i = 0; i < _barcodes.length; i++){
                            if ( _barcodes[i].Barcode == rowData.Barcode) { 
                                _barcodes.splice(i, 1);
                                setProdukt({...produkt, Barcodes: _barcodes});
                                return;
                            }
                        
                        }}} />
			</React.Fragment>
		);
	};

    
    const datumTemplate = (rowData) => {
        return <div>{rowData?.Datum?.toLocaleDateString('de-DE', { month: '2-digit', year: 'numeric', day: '2-digit', hour: '2-digit', minute: '2-digit' })}</div>;
    }

    return (
        <React.Fragment>
         <Dialog header="Wähle Artikelgruppe aus" visible={artikelgruppenVisible} style={{ width: '40vw' }} footer={renderFooter} onShow={onArtikelgruppenDialogShow} onHide={() => setArtikelgruppenVisible(false)}>
             <div class="flex">
                <ListBox className="mr-2" value={selectedKategorie} options={kategorien} optionLabel="Bezeichnung" optionValue="ID" onChange={(e) => onKategorieChange(e.target.value)}/>
                <ListBox className="mr-2" value={selectedWarengruppe} options={warengruppenOptions} optionLabel="Bezeichnung" optionValue="ID" onChange={(e) => onWarengruppenChange(e.target.value)} />
                <ListBox value={selectedArtikelgruppe} options={ArtikelgruppenOptions} optionLabel="Bezeichnung" optionValue="ID" onChange={(e) => setSelectedArtikelgruppe(e.target.value)} />
            </div>
         </Dialog>
         <Dialog header="Anzahl?" visible={dialogRegaletikettenVisible} style={{ width: '30vw' }} footer={regaletikettenFooter} onHide={() => setDialogRegaletikettenVisible(false)} >
                <div className="p-d-block p-pb-4">Wie viele Regaletiketten sollen gedruckt werden?</div>
                <div className="p-d-block">
                    <InputNumber value={anzahlRegaletiketten} onValueChange={(e) => setAnzahlRegaletiketten(e.target.value)} mode="decimal" showButtons buttonLayout="vertical" style={{width: '6em'}}
                    decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                </div>
                { produkt?.Herkunft === 0 &&
                    <div className="p-d-block p-pt-4">Das Produkt ist Regional! Nicht vergessen das Etikett auf das richtige Regalschild zu kleben.</div>
                }
            </Dialog>
        <Toast ref={toast} />
        {produkt && 
            <React.Fragment>
                <div className="grid p-component">
                    <div className="col-12">                                 
                        <div className="field grid">
                            <Button label="Speichern" icon="pi pi-save" style={{marginRight: '.5em'}} onClick={onSpeichernClick} />
                            <Button label="Abbrechen" icon="pi pi-times" onClick={onCancelClick} />
                        </div>
                    </div>
                    <div className="col-6">  
                        <div className="field grid">
                            <label htmlFor="artnr" className="col-fixed" style={{width: labelWidth}}>Art.-Nr.</label>
                            <div className="col">
                                <InputText id="artnr" type="text" value={produkt.ArtNr} disabled style={{width: labelWidth}}/>
                            </div>
                        </div>
                        <div className="field grid">
                            <label htmlFor="bezeichnung" className="col-fixed" style={{width: labelWidth}}>Bezeichnung</label>
                            <div className="col">
                                <InputText value={produkt.Bezeichnung} id="bezeichnung" type="text" style={{width:'100%'}}
                                onChange={onBezeichnungChange} className={produkt.Bezeichnung === undefined || produkt.Bezeichnung === null || produkt.Bezeichnung === '' ? 'p-invalid' : ''}
                                />
                            </div>
                        </div>
                        <div className="field grid">
                            <label htmlFor="bild" className="col-fixed" style={{width:labelWidth}}>Bild (200px x 200px)</label>
                            <div className="col">
                                <FileUpload ref={uploadRef} chooseOptions={chooseOptions} customUpload name="bild" mode="basic" accept="image/*" maxFileSize={1000000} style={{display: 'inline-block'}}
                                    onClear={() => setUploadClearDisabled(true)} />
                                <Button icon="pi pi-times" onClick={() => uploadRef.current.clear()} disabled={uploadClearDisabled}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        {(produkt.FotoID !== null) &&
                         <img src={imageData} style={{width:"200px", marginLEft: "auto", marginRight: "auto"}} />
                        }
                    </div>
                    <div className="col-12">
                    <Accordion className="accordion-custom" activeIndex={0}>
                        <AccordionTab header={<React.Fragment><span>Barcodes</span></React.Fragment>}>
                                <div className="grid">
                                    <div className="col-8">
                                        <div className="field grid">
                                            <label htmlFor="barcode" className="col-fixed" style={{width: labelWidth}}>Barcode</label>
                                            <div className="col">
                                                <InputText className='mr-2' id="barcode" type="text" value={produkt.Barcode} onChange={onBarcodeChange} 
                                                />
                                                <Button classaName="ml-2" onClick={onBarcodeGenerieren}><InlineIcon height='1.25rem' icon={barcodeScanner24Filled}/><span className="p-pl-2">Barcode generieren</span></Button>
                                            </div>
                                        </div>
                                        <div className="field grid">
                                            <label htmlFor="barcode" className="col-fixed" style={{width: labelWidth}}></label>
                                            <div className="col">
                                            <Button label='Barcode hinzufügen' icon='pi pi-plus' onClick={e => {
                                                    if (produkt.Barcode === null || produkt.Barcode === undefined)
                                                        return;
                                                    
                                                    if ((produkt.BarcodeGewichtsAbhängig === true || produkt.BarcodePreisAbhaengig === true) &&
                                                        produkt.Barcode.length > 7)
                                                    {
                                                        toast.current.show({severity:'error', summary: 'Fehler',
                                                            detail:'Barcode kann bei preis- oder gewichtsabhängigigen Artikeln nicht länger als 7 Stellen sein.', life: 3000});
                                                        return;
                                                    }

                                                    produktService.checkBarcode(produkt.Barcode).then(() => {
                                                        let _barcodes = produkt.Barcodes;
                                                        _barcodes.push({ID: null, Barcode: produkt.Barcode, Datum: new Date(), aktiviert: true });
                                                        let _produkt = {...produkt, Barcodes: _barcodes, Barcode: ''};
                                                        setProdukt(_produkt);
                                                    }).catch(() => {
                                                        toast.current.show({severity:'error', summary: 'Fehler', detail:'Barcode bereits im System vorhanden.', life: 3000});
                                                    })
                                                }} />
                                            </div>
                                        </div>

                                        <div className="field grid">
                                            <label htmlFor="barcodepreisabhaengig" className="col-fixed" style={{width:labelWidth}}>Barcode preisabhängig</label>
                                            <div className="col">
                                                <Checkbox id="barcodepreisabhaengig" checked={produkt.BarcodePreisAbhaengig} disabled={barcodeGewichtsOderPreisabhaengigDeaktiviert} onChange={onBarcodepreisabhaengigChange} />
                                                <label htmlFor="barcodepreisabhaengig" className="p-checkbox-label ml-3">(ACHTUNG: Barcode darf nur 7-stellig sein)</label>
                                                {produkt.BarcodePreisAbhaengig === true &&
                                                <React.Fragment>
                                                    <Checkbox id="8testelle" className="ml-3"
                                                        checked={produkt.achteStelle} disabled={barcodeGewichtsOderPreisabhaengigDeaktiviert}
                                                        onChange={(e) => setProdukt({...produkt, achteStelle: e.target.checked})}
                                                        />
                                                    <label className="p-checkbox-label ml-3">8te Stelle berücksichtigen (Preis kann über € 100,00 betragen)</label>
                                                </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                        <div className="field grid">
                                            <label htmlFor="barcodegewichtsabhaengig" className="col-fixed" style={{width:labelWidth}}>Barcode gewichtsabhängig</label>
                                            <div className="col">
                                                <Checkbox id="barcodegewichtsabhaengig" checked={produkt.BarcodeGewichtsAbhängig} disabled={barcodeGewichtsOderPreisabhaengigDeaktiviert} onChange={onBarcodeGewichtsAbhaengigChange} />
                                                <label htmlFor="barcodegewichtsabhaengig" className="p-checkbox-label ml-3">(ACHTUNG: Barcode darf nur 7-stellig sein)</label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <DataTable
                                                value={produkt.Barcodes} className="p-datatable-striped"
                                                style={{width: "100%"}}
                                                >
                                                <Column header="Datum" field='Datum' style={{width: "11em"}} body={datumTemplate}/>
                                                <Column header="Barcode" field="Barcode" style={{width: "12em"}} />
                                                <Column header="" field="" body={actionBodyTemplate} style={{width: "3.5em"}} />
                                            
                                            </DataTable>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        {produkt.BarcodeGewichtsAbhängig === true &&
                                        <img width="500px" src="assets/Barcodes/gewichtsabhaengig.png" />
                                        }
                                        {produkt.BarcodePreisAbhaengig === true && produkt.achteStelle !== true &&
                                        <img width="500px" src="assets/Barcodes/preisabhaengig.png" />
                                        }
                                        {produkt.BarcodePreisAbhaengig === true && produkt.achteStelle === true &&
                                        <img width="500px" src="assets/Barcodes/preisabhaengig_8Stellen.png" />
                                        }
                                    </div>
                                </div>
                        </AccordionTab>
                        <AccordionTab header={<React.Fragment><span>Einheiten</span></React.Fragment>}>
                            <div className="field grid">
                                <label htmlFor="einheit" className="col-fixed" style={{width:labelWidth}}>Verrechnungseinheit</label>
                                <div className="col">
                                <Dropdown id="einheit" value={produkt.Einheit} options={einheiten} onChange={onEinheitChange} style={{width: '20em'}}
                                        filter={false} className={produkt.Einheit === undefined || produkt.Einheit === null ? 'p-invalid' : ''}
                                        />
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="gpeinheit" className="col-fixed" style={{width:labelWidth}}>Grundpreiseinheit</label>
                                <div className="col">
                                <Dropdown id="gpeinheit" value={produkt.GPEinheit} options={gpeinheiten} onChange={onGPEinheitChange} style={{width: '20em'}}
                                        filter={false} showClear="true"
                                        />
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="veeinheit" className="col-fixed" style={{width:labelWidth}}>Verpackungseinheit</label>
                                <div className="col-fixed">
                                    <InputNumber id="vemnenge" value={produkt.VEMenge} onChange={onVeMengeChange} locale="de-DE" inputStyle={{width: "12em"}}/>
                                </div>
                                <div className="col-fixed">              
                                    <Dropdown id="veeinheit" value={produkt.VEEinheit} options={veeinheiten} onChange={onVEEinheitChange} style={{width: '7em'}}
                                    filter={false} showClear={true}
                                    />
                                </div>
                            </div>
                        </AccordionTab>
                        <AccordionTab header={<React.Fragment><span>Produktkonfiguration</span></React.Fragment>}>
                            <div className="field grid">
                                <label htmlFor="txtartikelgruppe" className="col-fixed" style={{width:labelWidth}}>Artikelgruppe</label>
                                <div className="col">
                                <InputText id="txtartikelgruppe" value={artikelgruppe} style={{width: '20em'}}
                                        filter={true} filterPlaceholder="Wähle Artikelgruppe" filterBy="Name"
                                        onClick={(e) => setArtikelgruppenVisible(true)} className={artikelgruppe === undefined || artikelgruppe === null || artikelgruppe === '' ? 'p-invalid' : ''}
                                        />
                                </div>
                            </div>
                            <div className="p-fild grid">
                                <label htmlFor="kategorie" className="col-fixed" style={{width:labelWidth}}>Kategorie</label>
                                <div className="col">
                                    <TreeSelect inputId="kategorie" value={produkt.AnzeigeKategorienID} options={kategorienTree}
                                    onChange={e => setProdukt({...produkt, AnzeigeKategorienID: e.value})}
                                    placeholder="Kategorie auswählen" style={{width: '20em'}}
                                    selectionMode="single"
                                    disabled={produkt.BarcodeGewichtsAbhängig || produkt.BarcodePreisAbhaengig}
                                    />
                                    <Button tooltipOptions={{position: 'bottom'}} tooltip="Kategorie löschen" type="button" icon="pi pi-trash" className="ml-2 p-button-danger mr-1" onClick={() => setProdukt({...produkt, AnzeigeKategorienID: null})}></Button>
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="lieferant" className="col-fixed" style={{width:labelWidth}}>Lieferant</label>
                                <div className="col">
                                <Dropdown inputId="lieferant" value={produkt.Lieferant} options={lieferanten} onChange={onLieferantChange} style={{width: '20em'}}
                                        filter={true} filterPlaceholder="Wähle Lieferant" filterBy="Name"
                                        optionLabel="Name" className={produkt.Lieferant === undefined || produkt.Lieferant === null ? 'p-invalid' : ''}
                                        />
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="produzent" className="col-fixed" style={{width:labelWidth}}>Produzent</label>
                                <div className="col">
                                <Dropdown inputId="produzent" value={produkt.Produzent} options={produzenten}
                                    onChange={e => setProdukt({...produkt, Produzent: e.target.value})} style={{width: '20em'}}
                                    filter={true} filterPlaceholder="Wähle Produzent" filterBy="Name"
                                    optionLabel="Name" className={produkt.Produzent === undefined || produkt.Produzent === null ? 'p-invalid' : ''}
                                    />
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="alkohol" className="col-fixed" style={{width:labelWidth}}>Alkohol</label>
                                <div className="col">
                                    <Checkbox id="alkohol" checked={produkt.Alkohol} onChange={onAlkoholChange} />
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="herkunft" className="col-fixed" style={{width:labelWidth}}>Herkunft</label>
                                <div className="col">
                                    <Dropdown id="herkunft" value={produkt.Herkunft} options={herkunftOptions} onChange={onHerkunftChange} style={{width: '20em'}}
                                        filter={false} showClear={true}
                                        className={produkt.Herkunft === undefined || produkt.Herkunft === null ? 'p-invalid' : ''}
                                        />
                                </div>
                            </div>
                            <div className='field grid'>
                                <label htmlfor="herkunftsland" className="col-fixed" style={{width:labelWidth}}>Herkunftsland</label>
                                <div className='col'>
                                    <Dropdown
                                        value={produkt.Land}
                                        options={countries}
                                        onChange={onLandChange}
                                        optionLabel="name" filter showClear filterBy="name"
                                        placeholder="Land auswählen"
                                        valueTemplate={selectedCountryTemplate}
                                        style={{width: '20em'}}
                                        itemTemplate={countryOptionTemplate} />
                                </div>
                            </div>
                            {(produkt.Land !== null && produkt.Land !== undefined) &&
                            <div className='field grid'>
                                <label htmlfor="herkunftsland" className="col-fixed" style={{width:labelWidth}}>Bundesland</label>
                                <div className='col'>
                                    <Dropdown
                                        value={produkt.Bundesland}
                                        options={states}
                                        onChange={onStateChanged}
                                        filter showClear
                                        filterBy={"label, value"}
                                        style={{width: '20em'}}
                                        placeholder="Bundesland auswählen" />
                                </div>
                            </div>
                            }
                            <div className="field grid">
                                <label htmlFor="steuer" className="col-fixed" style={{width:labelWidth}}>VK Steuersatz</label>
                                <div className="col">
                                    <Dropdown id="steuer" value={produkt.Steuersatz} options={steuer} onChange={onSteuerChange} style={{width: '8em'}}
                                        filter={false} showClear={true} className={produkt.Steuersatz === undefined || produkt.Steuersatz === null ? 'p-invalid' : ''}
                                        valueTemplate={(e) => { if (e !== null) { return (<React.Fragment>{e.label} %</React.Fragment>); } else return (<React.Fragment>&nbsp;</React.Fragment>)}}
                                        itemTemplate={(e) => { if (e !== null) { return (<React.Fragment>{e.label} %</React.Fragment>); } else return (<React.Fragment>&nbsp;</React.Fragment>)}}
                                        />
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="steuerek" className="col-fixed" style={{width:labelWidth}}>EK Steuersatz</label>
                                <div className="col">
                                    <Dropdown id="steuerek" value={produkt.EKSteuersatz} options={steuer} onChange={onEKSteuerChange} style={{width: '8em'}}
                                        filter={false} showClear={true}
                                        valueTemplate={(e) => { if (e !== null) { return (<React.Fragment>{e.label} %</React.Fragment>); } else return (<React.Fragment>&nbsp;</React.Fragment>)}}
                                        itemTemplate={(e) => { if (e !== null) { return (<React.Fragment>{e.label} %</React.Fragment>); } else return (<React.Fragment>&nbsp;</React.Fragment>)}}
                                        />
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="mengeaendern" className="col-fixed" style={{width:labelWidth}}>Menge ändern sofort</label>
                                <div className="col">
                                    <Checkbox id="mengeaendern" checked={produkt.MengeSofortAnzeigen} onChange={onMengeAendernSofort} />
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="Preis100gAnzeigen" className="col-fixed" style={{width:labelWidth}}>Preis pro 100g anzeigen</label>
                                <div className="col">
                                    <Checkbox id="Preis100gAnzeigen" checked={produkt.Preis100gAnzeigen} onChange={onPreis100gAnzeigenChange} disabled={produkt.Einheit !== "KG"} />
                                </div>
                            </div>
                            <div className="field grid">
                                <label htmlFor="Rabattartikel" className="col-fixed" style={{width:labelWidth}}>Rabattartikel</label>
                                <div className="col">
                                    <Checkbox id="Rabattartikel" checked={produkt.Rabattartikel} onChange={onRabattartikelChange} />
                                </div>
                            </div>
                        </AccordionTab>
                        <AccordionTab header={<React.Fragment><span>Preise</span></React.Fragment>}>
                            {anzeigeKategorien?.filter(function(element){ return element.ID === produkt.AnzeigeKategorienID && element?.Bedienmodus === true; })?.length > 0 &&
                            <div className="grid">
                                <div className="col-12">
                                    <div className="field grid">
                                        <label htmlFor="manullerPreis" className="col-fixed">manueller Preis</label>
                                        <div className="col">
                                            <Checkbox id="manullerPreis" checked={produkt.manuellerPreis} onChange={onManuellerPreisChange} ></Checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            <div className="grid">
                                <div className="col-6">
                                    <div className="grid">
                                        <div className="col-12">
                                            <div className="grid">
                                                <div className='col-2'>
                                                    <label htmlFor="datum">gültig ab</label>
                                                </div>
                                                <div className="col">
                                                    <Calendar
                                                    id="datum"
                                                    value={datum}
                                                    onChange={onCalendarChange}
                                                    showIcon={true}
                                                    inputStyle={{width: preisInputWidth}} 
                                                    locale="de"
                                                    minDate={minDate}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className='grid'>
                                                <div className="col-2">
                                                    <label htmlFor="ek">EK</label>
                                                </div>
                                                <div className="col">
                                                    <div className="flex">
                                                        <div className='flex flex-column'>
                                                            <label className="block">Netto</label>
                                                            <InputNumber id="ek" value={ek} onChange={onEKChange} mode="currency" currency="EUR" locale="de-DE" inputStyle={{width: preisInputWidth}}/>
                                                        </div>
                                                        <div className='flex flex-column pl-2'>
                                                            <label className="block">Brutto</label>
                                                            <InputNumber id="ekbrutto" value={ekbrutto} onChange={onEKBruttoChange} mode="currency" currency="EUR" locale="de-DE" inputStyle={{width: preisInputWidth}}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className='grid'>
                                                <div className="col-2">
                                                    <label htmlFor="spanne">Rohmarge</label>
                                                </div>
                                                <div className="col">
                                                    <div className='flex'>
                                                        <InputNumber id="spanne" value={spanne} onChange={onSpanneChange} showButtons suffix=" %" inputStyle={{width: '80px'}} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="flex">
                                                <div className="flex flex-column">
                                                    <label htmlFor="vk" className="col-fixed" style={{width: preisLabelWidth}}><span style={{whiteSpace: 'nowrap'}}>VK (brutto)</span></label>
                                                    <InputNumber id="vk" value={vk} onChange={onVKChange} mode="currency" currency="EUR" locale="de-DE" inputStyle={{width: preisInputWidth}}/>
                                                </div>
                                                <div className="flex flex-column pl-2 pr-2">
                                                    <label htmlFor="vk2" className="col-fixed" style={{width: preisLabelWidth}}><span  style={{whiteSpace: 'nowrap'}}>VK2 (brutto)</span></label>
                                                    <InputNumber id="vk2" value={vk2} onChange={e => setVK2(e.value)} mode="currency" currency="EUR" locale="de-DE" inputStyle={{width: preisInputWidth}}/>
                                                </div>
                                                <div className="flex flex-column ">
                                                    <label htmlFor="vk3" className="col-fixed" style={{width: preisLabelWidth}}><span  style={{whiteSpace: 'nowrap'}}>VK3 (brutto)</span></label>
                                                    <InputNumber id="vk3" value={vk3} onChange={e => setVK3(e.value)} mode="currency" currency="EUR" locale="de-DE" inputStyle={{width: preisInputWidth}}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="grid">
                                                <div className='col-2'>
                                                    <label htmlFor="pfand" className="col-fixed" style={{width: preisLabelWidth}}>Pfand (brutto)</label>
                                                </div>
                                                <div className="col">
                                                    <InputNumber id="pfand" value={pfand} onChange={e => setPfand(e.value)} mode="currency" currency="EUR" locale="de-DE" inputStyle={{width: preisInputWidth}}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                                <Button label="Hinzufügen" icon="pi pi-plus" onClick={onHinzufuegenClick} />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className="field grid">
                                        <DataTable
                                            ref={preisdt} value={produkt.Preise} rowHover responsive
                                            className="p-datatable-preise" 
                                            paginator rows={10} emptyMessage="keine Preise vorhanden"
                                            style={{width: "100%"}}
                                            >
                                            <Column field="Datum" header="gültig ab" body={dateBodyTemplate} />
                                            <Column field="EK" header="EK Netto" body={EKBodyTemplate} headerClassName="p-column-header-content-center"/>
                                            <Column field="EKBrutto" header="EK Brutto" body={EKBruttoBodyTemplate} headerClassName="p-column-header-content-center"/>
                                            <Column field="Spanne" header="Rohmarge" body={RohmargeTemplate} headerClassName="p-column-header-content-center" />
                                            <Column field="VK" header="VK" body={VKBodyTemplate} headerClassName="p-column-header-content-center"/>
                                            <Column field="VK2" header="VK2" body={VK2BodyTemplate} headerClassName="p-column-header-content-center"/>
                                            <Column field="VK3" header="VK3"  body={VK3BodyTemplate} headerClassName="p-column-header-content-center"/>
                                            <Column field="Pfand" header="Pfand" body={PfandBodyTemplate} headerClassName="p-column-header-content-center"/>
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </AccordionTab>
                        <AccordionTab header={<React.Fragment><span>Texte</span></React.Fragment>}>
                            <div className="grid">
                                <div className="col-6">
                                    <div className="field">
                                        <label htmlFor="produkttext" className="col-fixed" style={{width: labelWidth}}>Produkttext am Beleg</label>
                                        <div className="col">
                                            <InputText id="produkttext" type="text" style={{width:'100%'}}
                                            tooltipOptions={{position: 'bottom'}} tooltip="Wenn ausgefüllt wird am Bon angezeigt. (max. 20 Zeichen)"
                                            value={produkt.Produkttext} onChange={onProdukttextChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="regaltext" className="col-fixed" style={{width: labelWidth}}>Regaletikett-Text</label>
                                        <div className="col">
                                            <InputTextarea className="mb-2"  id="regaltext" style={{width:'100%'}}
                                            rows={2}
                                            tooltipOptions={{position: 'bottom'}}
                                            value={produkt.Regaletiketttext }
                                            onChange={onRegaletiketttextChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="produzentregaltext" className="col-fixed">Regaletiketttext für Produzent</label>
                                        <div className="col">
                                            <InputTextarea className="mb-2"  id="produzentregaltext" style={{width:'100%'}}
                                            rows={2}
                                            tooltipOptions={{position: 'bottom'}}
                                            value={produzentRegaltext }
                                            onChange={onProduzentRegaletiketttextChange}
                                            disabled={!(produkt.Produzent !== undefined && produkt.Produzent !== null)}
                                            />
                                            <Button className="mr-3" label="Vorschau erstellen" icon="pi pi-image" onClick={onVorschauClick} />
                                            <Button label="Etikett drucken" icon="pi pi-print" onClick={onRegaletikettPrintClick} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6" style={{position: 'relative'}}>
                                    <img id="vorschaubild" src={vorschauData} className="p-mx-auto p-my-auto" style={{width: "85%"}} />
                                    { produkt.Herkunft === 0 &&
                                        <img src={CONFIG.RegionalZeichen} style={{width: "15%", position: 'absolute', right: '0px'}} />
                                    }
                                </div>
                            </div>
                        </AccordionTab>
                        <AccordionTab header={<React.Fragment><span>Standorte</span></React.Fragment>}>
                            <div className="field grid">
                                <DataTable ref={standortdt} value={produkt.Standorte}
                                    rowHover responsive="true"
                                    className="p-datatable-produkt-standorte editable-cells-table" 
                                    paginator rows={10}
                                    editMode="cell"
                                    emptyMessage="keine Standorte vorhanden"
                                    style={{width: "100%"}}
                                    >
                                    <Column field="KassenID" header="Standort"/>
                                    <Column field="aktiviert" header={<React.Fragment><Checkbox className="mr-1" checked={produkt.Standorte.every(s => s.aktiviert)} onChange={e => setProdukt({...produkt, Standorte: produkt.Standorte.map(s => ({...s, aktiviert: e.checked}))})}/>verfügbar</React.Fragment>} body={(e) => verfügbarTemplate(produkt.Standorte, e)}/>
                                    <Column
                                        field="Sollbestand"
                                        header="Sollbestand"
                                        style={{width: "400px", maxWidth: "400px"}}
                                        editor={(props) => inputTextEditor('Standort', props, "Sollbestand")}
                                        onEditorInit={e => e.originalEvent.target.children[1].firstChild.firstChild.firstChild.select()}
                                        onCellEditComplete={onCellEditComplete}
                                        />
                                    <Column
                                        field="Warnbestand"
                                        header="Warnbestand"
                                        style={{width: "400px", maxWidth: "400px"}}
                                        editor={(props) => inputTextEditor('Standort', props, "Warnbestand")}
                                        onEditorInit={e => e.originalEvent.target.children[1].firstChild.firstChild.firstChild.select()}
                                        onCellEditComplete={onCellEditComplete}
                                        />
                                </DataTable>
                            </div>
                        </AccordionTab>
                    </Accordion>
                    </div>
                </div>       
                <Dialog visible={barcodePreisabhaengigConfirmDialog} footer={<React.Fragment>
                    <Button label='Abbrechen' icon='pi pi-times' onClick={e => setBarcodePreisabhaengigConfirmDialog(false)}/>
                    <Button label='Ok' icon='pi pi-check' onClick={e => toggleBarcodePreisAbhaengig()}/>
                </React.Fragment>}
                onHide={e => setBarcodePreisabhaengigConfirmDialog(false)}
                >
                    <img  src={"/assets/achtung.jpeg"} className="mr-5" width={"100px"} height={"100px"} style={{float: "left"}} />
                    Wenn das Produkt auf gewichtsabhängig umgestellt wird müssen alle bisherigen Barcodes gelöscht werden.<br/>
                    Alternativ können Sie das Produkt kopieren und dann den Barcode umstellen.<br/>
                    Wollen Sie die Barcodes jetzt löschen?
                </Dialog>
                <Dialog visible={barcodeGewichtsabhaengigConfirmDialog} footer={<React.Fragment>
                    <Button label='Abbrechen' icon='pi pi-times' onClick={e => setBarcodeGewichtsabhaengigConfirmDialog(false)}/>
                    <Button label='Ok' icon='pi pi-check' onClick={e => toggleBarcodeGewichtsAbhaengig()}/>
                </React.Fragment>}
                onHide={e => setBarcodePreisabhaengigConfirmDialog(false)}
                >
                    <img  src={"/assets/achtung.jpeg"} className="mr-5" width={"100px"} height={"100px"} style={{float: "left"}} />
                    Wenn das Produkt auf preisabhängig umgestellt wird müssen alle bisherigen Barcodes gelöscht werden.<br/>
                    Alternativ können Sie das Produkt kopieren und dann den Barcode umstellen.<br/>
                    Wollen Sie die Barcodes jetzt löschen?
                </Dialog>
                <Dialog visible={barcodeKGConfirmDialog} style={{width: "1000"}} footer={<React.Fragment>
                    <Button label='Abbrechen' icon='pi pi-times' onClick={e => setBarcodeKGConfirmDialog(false)}/>
                    <Button label='Ok' icon='pi pi-check' onClick={e => {setEinheitKG();setBarcodeKGConfirmDialog(false)}}/>
                </React.Fragment>}>
                    <img  src={"/assets/achtung.jpeg"} className="mr-5" width={"100px"} height={"100px"} style={{float: "left"}} />
                    Bei Umstellung auf "KG" wird die Option "Barcode preisabhängig" deaktiviert <br/>
                    und das Produkt muss an der Kassa vom Kunden gewogen werden. <br/>
                    Alle 7-stelligen Barcodes werden gelöscht. <br/>
                    <br/>
                    Soll der Vorgang jetzt durchgeführt werden?
                </Dialog>
            </React.Fragment>
        }
        </React.Fragment>
    );
};

export default Produkt;
