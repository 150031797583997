import React, { useState, useContext } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from "primereact/button";
import { Card } from 'primereact/card';
import { GlobalState } from './GlobalState';
import { AuthService } from '../service/AuthService';


function Login(props) {
	const [password, setPassword] = useState("");
	const [benutzername, setBenutzername] = useState("");
	const [globalState, setGlobalState] = useContext(GlobalState);
	const authService = new AuthService();

	const onSubmit = e => {
		e.preventDefault();
		authService.login(benutzername, password).then(onLogin).catch((e) => alert(e.message));
	}
	
	const onLogin = () => {
		props.onLogin(true);
	}

	return (
		<Card className="login" header={<img style={{width: '60%', marginLeft: '20%', marginTop: '2em'}} alt="Logo" src="/assets/logo2.svg"/>} footer={<React.Fragment><span style={{fontWeight: "bold"}}>Hinweis</span><br></br>Bei Fragen wenden Sie sich bitte an die mysoft Kassen - Hotline unter <a href="tel:+43255485421100">+43 2554/85 421-100</a></React.Fragment>}>
				<form onSubmit={onSubmit}>
					<InputText className="loginInput loginPos mb-3" type="text" value={benutzername} placeholder="Benutzername" onChange={(e) => setBenutzername(e.target.value)}/><br/>
					<Password className="loginPos mb-3" inputClassName="loginInput" inputStyle={{width: "100%"}} value={password} placeholder="Passwort" onChange={(e) => setPassword(e.target.value)} feedback={false}/><br/>
					<Button className="loginPos p-button-outlined" label="Login" type="submit" value="Submit"/>
				</form>
			</Card>
	);
};

export default Login;