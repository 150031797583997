import { fetchSimple } from "../helper/fetchSimple";
import { GetTokenManagementApi } from "./ManagementApiService";
import { GetToken } from "./AuthService";
import CONFIG from 'config';

export class GutscheinService {
	async ReadAll() {
		return fetchSimple(CONFIG.ManagementApiUrl + "/Gutscheine", await GetTokenManagementApi()).then(response => response.json());
	}

	async ReadRange(fromId, toId) {
		const formData = new FormData();
		formData.append("fromId", fromId);
		formData.append("toId", toId);
		return fetchSimple(CONFIG.ManagementApiUrl + "/Gutscheine", await GetTokenManagementApi(), formData).then(response => response.json());
	}

	async ReadLazy(params) {
		Object.keys(params).forEach(key => {
			if (params[key] === null) {
			  delete params[key];
			}
			if (key === "filters")
			{
				if (params.filters !== undefined)
				{
					Object.keys(params.filters).forEach(key1 => {
						if (params.filters[key1].value === null)
							delete params.filters[key1];
					});
				}	
			}
		  });
		return fetchSimple(CONFIG.ManagementApiUrl + "/Gutscheine/Lazy", await GetTokenManagementApi(), params).then(response => response.json());
	}

	async Read(id) {
		return fetchSimple(CONFIG.ManagementApiUrl + "/Gutschein", await GetTokenManagementApi(), id).then(response => response.json());
	}

	async GetBetragSum() {
		return fetchSimple(CONFIG.ManagementApiUrl + "/Gutschein/BetragSum", await GetTokenManagementApi()).then(response => response.json());
	}

	async GetCount() {
		return fetchSimple(CONFIG.ManagementApiUrl + "/Gutschein/Count", await GetTokenManagementApi()).then(response => response.json());
	}

	async Update(gutschein) {
		return fetchSimple(CONFIG.ManagementApiUrl + "/Gutschein/Update", await GetTokenManagementApi(), gutschein).then(response => response.json());
	}

	async TransferBetrag(fromId, toId, betrag) {
		const formData = new FormData();
		formData.append("fromId", fromId);
		formData.append("toId", toId);
		formData.append("betrag", betrag);
		return fetchSimple(CONFIG.ManagementApiUrl + "/Gutschein/TransferBetrag", await GetTokenManagementApi(), formData);
	}

	async UpdateGesperrt(fromId, toId, gesperrt) {
		const formData = new FormData();
		formData.append("fromId", fromId);
		formData.append("toId", toId);
		formData.append("gesperrt", gesperrt);
		return fetchSimple(CONFIG.ManagementApiUrl + "/Gutscheine/UpdateGesperrt", await GetTokenManagementApi(), formData);
	}

	async Delete(id) {
		return fetchSimple(CONFIG.ManagementApiUrl + "/Gutschein/Delete", await GetTokenManagementApi(), id);
	}

	async DeleteRange(fromId, toId) {
		const formData = new FormData();
		formData.append("fromId", fromId);
		formData.append("toId", toId);
		return fetchSimple(CONFIG.ManagementApiUrl + "/Gutscheine/Delete", await GetTokenManagementApi(), formData);
	}

	async GetGutscheinBuchungen(gutscheinId) {
		return fetchSimple(CONFIG.ServerUrl + "/GutscheinBuchungen/" + gutscheinId.toString(), await GetToken()).then(response => response.json());
	}

	async GetErsteAufladungen(gutscheinIds) {
		return fetchSimple(CONFIG.ServerUrl + "/GutscheinBuchungen/ErsteAufladungen", await GetToken(), gutscheinIds).then(response => response.json());
	}

	async GetAblaufdatum(gutscheinIds) {
		return fetchSimple(CONFIG.ServerUrl + "/GutscheinBuchungen/Ablaufdatum", await GetToken(), gutscheinIds).then(response => response.json());
	}

	async GetBetragAusgegeben() {
		return fetchSimple(CONFIG.ServerUrl + "/GutscheinBuchungen/BetragAusgegeben", await GetToken()).then(response => response.json());
	}

	async GetAnzahlAktiviert() {
		return fetchSimple(CONFIG.ServerUrl + "/GutscheinBuchungen/AnzahlAktiviert", await GetToken()).then(response => response.json());
	}
}