import { useReducer } from "react";

function localStorageAvailableTest() {
	try {
		localStorage.setItem("test", "test");
		localStorage.removeItem("test");
		return true;
	} catch {
		return false;
	}
}

const localStorageAvailable = localStorageAvailableTest();

function localStorageReducer(name, initValue) {
	if (localStorageAvailable) {
		return [
			(state, newState) => {
				localStorage.setItem(name, JSON.stringify(newState));
				return newState;
			},
			localStorage.getItem(name) === null ? initValue : JSON.parse(localStorage.getItem(name))
		]
	} else {
		return [
			(state, newState) => newState,
			initValue
		]
	}
}

export function useLocalStorage(name, initValue) {
	return useReducer(...localStorageReducer(name, initValue));
}