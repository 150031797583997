import React, { useState, useEffect, useRef, useContext } from 'react';
import { KassenService } from '../service/KassenService';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { GlobalState } from './GlobalState';
import { ExportService } from '../service/ExportService';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';

function Jahresbericht(props) {
	const [kassen, setKassen] = useState([]);
	const [selectedKassen, setSelectedKassen] = useState([]);
	const [globalState, setGlobalState] = useContext(GlobalState);
	const [showSpinner, setShowSpinner] = useState(false);
    const [jahre, setJahre] = useState(null);
    const [jahr, setJahr] = useState(null);
    const [visible, setVisible] = useState(false);
	const kassenService = new KassenService();
	const exportService = new ExportService();

	const toastRef = useRef(null);

	const onErstelleBerichtClick = () => {
		setVisible(true);
	};

    const onAcceptClick = () => {
        setVisible(false);
        setShowSpinner(true);
        const von = new Date(jahr, 0, 1);
		const bis = new Date(von.getFullYear()+1, 0, 1);
		bis.setDate(bis.getDate() - 1);
		exportService.downloadBericht(von, bis, Object.entries(selectedKassen).filter(kasse => kasse[1]).map(kasse => kasse[0]),
            von.toLocaleDateString("de", { year: "numeric" }) + "_Jahresbericht").then(() => {
			setShowSpinner(false);
		}).catch(() => {
			setShowSpinner(false);
			toastRef?.current?.show({severity:'error', summary: 'Fehler', detail:'Exportieren fehlgeschlagen', life: 2000});
		});
    }

	useEffect(() => {
		setGlobalState({...globalState, page: 'Jahresbericht'});
		kassenService.getKassen().then(data => setKassen(data));
        setJahresOptions();
	}, []);

    function setJahresOptions() {
        let dates = [];
        let heute = new Date();
        for (let i = 0; i<5; i++)
        {
            var tempdate = new Date(heute.getFullYear() - i, heute.getMonth(), heute.getDay());
            var temp = {
                label: tempdate.getFullYear().toString(),
                value: tempdate.getFullYear().toString()
            }
            dates.push(temp);
        }
        console.log(dates);
        setJahre(dates);
    }

    const renderFooter = () => {
        return (
            <div>
                <Button label="Nein" icon="pi pi-times" onClick={() => setVisible(false)} />
                <Button label="Ja" icon="pi pi-check" onClick={onAcceptClick} autoFocus />
            </div>
        );
    }

    const renderHeader = () => {
        return (
            <React.Fragment>
                <i className="pi pi-exclamation-triangle" style={{fontSize: "2rem"}}></i><span className="ml-3 p-text-justify">Achtung</span>
            </React.Fragment>
        );
    }

	return (
		<React.Fragment>
			<Toast ref={toastRef}/>
			<div className="grid p-component">
				<div className="col">
					<div className="field">Standorte:</div>
					{kassen.map((kassa) => <React.Fragment>
						<div className="field">
							<Checkbox inputId={kassa.KassenID} checked={selectedKassen[kassa.KassenID]} onChange={e => setSelectedKassen({...selectedKassen, [kassa.KassenID]: e.target.checked})}/>
							<label htmlFor={kassa.KassenID} className="mb-0 ml-1">{kassa.KassenID}</label>
						</div>
					</React.Fragment>)}
					<Dropdown className="mr-2" value={jahr} options={jahre} onChange={(e) => setJahr(e.value)} placeholder="Wähle Jahr"/>
					<Button label="Erstelle Bericht" icon="pi pi-check" onClick={onErstelleBerichtClick}/>
					{showSpinner ? <i className="pi pi-spin pi-spinner ml-2" style={{fontSize: '2rem'}}></i> : <React.Fragment></React.Fragment>}
				</div>
			</div>

            <Dialog header={renderHeader} visible={visible} style={{ width: '50vw' }} footer={renderFooter()} onHide={() => setVisible(false)}>
                <div>
    
                    <span className="ml-4">Das Erstellen des Jahresbericht wird einige Minuten dauern. Wollen Sie den Jahresbericht erstellen?</span>
                </div>
            </Dialog>
		</React.Fragment>
	);
}
export default Jahresbericht;