import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalState } from "./GlobalState";
import { WarenbestandService } from "../service/WarenbestandService";
import { WarengruppenService } from "../service/Warengruppenservice";
import { ProduktService } from "../service/ProduktService";
import { ZoomPopup } from "./ZoomPopup";
import { addDE } from "./LocalDE";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { Chart } from "primereact/chart";

export default function WarenbestandDetails() {
	const warenbestandService = new WarenbestandService();
	const warengruppenService = new WarengruppenService();
	const produkteService = new ProduktService();

	const [globalState, setGlobalState] = useContext(GlobalState);
	const [datumsbereich, setDatumsbereich] = useState([convertToDay(new Date(0)), convertToDay(new Date(0))]);
	const [warenbestandDetailsTable, setWarenbestandDetailsTable] = useState([]);
	const [kategorieFilterValue, setKategorieFilterValue] = useState([]);
	const [warengruppen, setWarengruppen] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedProdukt, setSelectedProdukt] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);
	const [lineChartDatasets, setLineChartDatasets] = useState([]);
	const [pieChartDatasets, setPieChartDatasets] = useState({});
	const [produkte, setProdukte] = useState([]);
	const [selectedProdukte, setSelectedProdukte] = useState([]);

	const dtRef = useRef(null);
	const lineChartRef = useRef(null);
	const pieChartRef = useRef(null);

	addDE();

	useEffect(() => {
		setGlobalState({...globalState, page: "Warenbestand Details"});
		setDatumsbereich([convertToDay(new Date()), convertToDay(new Date())]);
		warengruppenService.getAll().then(setWarengruppen);
		produkteService.getAll().then(setProdukte);
		// eslint-disable-next-line
	}, []);

	function convertToDay(date) {
		date.setHours(0);
		date.setMinutes(0);
		date.setSeconds(0);
		return date;
	}

	useEffect(() => {
		if (datumsbereich === undefined || datumsbereich === null || !(datumsbereich[0] instanceof Date) || !(datumsbereich[1] instanceof Date)) return;
		setLoading(true);
		warenbestandService.warenbestandDetailsTable(datumsbereich[0], datumsbereich[1]).then(setWarenbestandDetailsTable).finally(() => setLoading(false));
		// eslint-disable-next-line
	}, [datumsbereich]);

	useEffect(() => {
		if (datumsbereich === undefined || datumsbereich === null || !(datumsbereich[0] instanceof Date) || !(datumsbereich[1] instanceof Date) || selectedProdukt === null) return;

		setLoading(true);
		Promise.allSettled([
			warenbestandService.warenbestandDetailsVerlauf(selectedProdukt.ID, datumsbereich[0], datumsbereich[1]).then(data => {
				const datasets = [];
				datasets.push({
					label: "Eingelagert",
					data: data.eingelagertVerlauf,
					fill: false,
					borderColor: "#0e4cb0",
					tension: 0.3
				});
				datasets.push({
					label: "Verkauft",
					data: data.verkaufVerlauf,
					fill: false,
					borderColor: "#0cb015",
					tension: 0.3
				});
				datasets.push({
					label: "Schwund",
					data: data.schwundVerlauf,
					fill: false,
					borderColor: "#bd2f2f",
					tension: 0.3
				});
				datasets.push({
					label: "Verderb",
					data: data.verderbVerlauf,
					fill: false,
					borderColor: "#7a5430",
					tension: 0.3
				});
				datasets.push({
					label: "Beschädigt",
					data: data.beschaedigtVerlauf,
					fill: false,
					borderColor: "#9c8992",
					tension: 0.3
				});
				/*datasets.push({
					label: "Summe",
					data: data.sumVerlauf,
					fill: true,
					borderColor: "#ffa600",
					backgroundColor: "rgba(255, 166, 0, 0.1)",
					tension: 0.3
				});*/
				setLineChartDatasets(datasets);
			}),
			warenbestandService.warenbestandDetailsVerbrauch(selectedProdukt.ID, datumsbereich[0], datumsbereich[1]).then(setPieChartDatasets)
		]).then(() => setLoading(false));
		// eslint-disable-next-line
	}, [selectedProdukt, datumsbereich]);

	const download = (url, name) => {
		const a = window.document.createElement('a');
		a.href = url;
		a.download = name;
		window.document.body.append(a);
		a.click();
		a.remove();
	};

	function GetDatumsVerlauf(von, bis) {
		if (von === undefined || von === null || bis === undefined || bis === null) return [];

		const year = von.getFullYear();
		const month = von.getMonth();
		const day = von.getDate();
		const count = Math.round((bis - von) / (24 * 60 * 60 * 1000)) + 1;
		const output = [];
		for (let offset = 0; offset < count; offset++) {
			output.push(new Date(year, month, day + offset).toLocaleDateString('de', { day: '2-digit', month: '2-digit', year: 'numeric'}));
		}
		return output;
	}

	const onRowClick = e => {
		if (selectedProdukt?.ID !== e.data.produkt.ID) setSelectedProdukt(e.data.produkt);
		if (selectedRow !== e.originalEvent.target.parentElement) {
			if (selectedRow !== undefined && selectedRow !== null) {
				selectedRow.className = selectedRow.className.replace(" p-highlight", "");
			}
			setSelectedRow(e.originalEvent.target.parentElement);
			e.originalEvent.target.parentElement.className += " p-highlight";
		}
	};

	const kategorieFilter = <MultiSelect className="column-filter" value={kategorieFilterValue} options={warengruppen} optionLabel="Bezeichnung" placeholder="Kategorie" optionValue="ID" onChange={e => { setKategorieFilterValue(e.value); dtRef.current.filter(e.value, "warengruppe.ID", "in"); }}/>;
	const bezeichnungFilter = <MultiSelect className="column-filter" value={selectedProdukte} options={produkte} onChange={e => { setSelectedProdukte(e.target.value); dtRef.current.filter(e.target.value, "produkt.Bezeichnung", "in"); }} placeholder="Produkt" optionLabel="Bezeichnung" optionValue="Bezeichnung" maxSelectedLabels={1} filter filterMatchMode="contains"/>
	const lieferantenFilter = <MultiSelect className="column-filter" value={selectedProdukte} options={produkte} onChange={e => { setSelectedProdukte(e.target.value); dtRef.current.filter(e.target.value, "produkt.Lieferant.Name", "in"); }} placeholder="Lieferant" optionLabel="Lieferant.Name" optionValue="Lieferant" maxSelectedLabels={1} filter filterMatchMode="contains"/>

	return (
		<React.Fragment>
			<div className="p-component grid">
				<div className="col-12">
					<Calendar disabled={loading} value={datumsbereich} onChange={e => setDatumsbereich(e.value)} showIcon locale="de" selectionMode="range"/>
				</div>
				<div className="col-12">
					<DataTable
						ref={dtRef}
						value={warenbestandDetailsTable.map(w => ({...w, avgVerbrauch7: w.avgVerbrauch * 7, avgVerbrauch30: w.avgVerbrauch * 30, lagerumschlagshaeufigkeit: w.avgBestand === 0 ? null : w.verbrauch / w.avgBestand}))}
						paginator rows={20} loading={loading} onRowClick={onRowClick}
						filterDisplay="row"
						>
						<Column field="produkt.ArtNr" header="Art.-Nr." filter showFilterMenu={false} showClearButton={false}/>
						<Column field="produkt.Bezeichnung" header="Bezeichnung" filter filterElement={bezeichnungFilter} showFilterMenu={false} showClearButton={false}/>
						<Column field="warengruppe.Bezeichnung" header="Kategorie" filter filterElement={kategorieFilter} showFilterMenu={false} showClearButton={false}/>
						<Column field="produkt.Lieferant" header="Lieferant" filter filterElement={lieferantenFilter} showFilterMenu={false} showClearButton={false}/>
						<Column field="warengruppe.ID" style={{display: "none"}}/>
						<Column field="verbrauch" header="Verbrauch" filter
							body={rowData => rowData.verbrauch.toLocaleString("de", { minimumFractionDigits: 0, maximumFractionDigits: 3 })} bodyStyle={{textAlign: "right"}}
							showFilterMenu={false} showClearButton={false}/>
						<Column field="avgVerbrauch" header="&Oslash; Verbrauch pro Tag" filter
							body={rowData => rowData.avgVerbrauch?.toLocaleString("de", { minimumFractionDigits: 0, maximumFractionDigits: 3 })}
							bodyStyle={{textAlign: "right"}}
							showFilterMenu={false} showClearButton={false}
							/>
						<Column field="avgVerbrauch7" header="&Oslash; Verbrauch pro 7 Tage" filter
							body={rowData => rowData.avgVerbrauch7?.toLocaleString("de", { minimumFractionDigits: 0, maximumFractionDigits: 3 })}
							bodyStyle={{textAlign: "right"}}
							showFilterMenu={false} showClearButton={false}
							/>
						<Column
							field="avgVerbrauch30"
							header="&Oslash; Verbrauch pro 30 Tage" filter
							body={rowData => rowData.avgVerbrauch30?.toLocaleString("de", { minimumFractionDigits: 0, maximumFractionDigits: 3 })}
							bodyStyle={{textAlign: "right"}}
							showFilterMenu={false} showClearButton={false}
							/>
						<Column
							field="avgBestand" header="&Oslash; Bestand" filter
							body={rowData => rowData.avgBestand?.toLocaleString("de", { minimumFractionDigits: 0, maximumFractionDigits: 3 })}
							bodyStyle={{textAlign: "right"}}
							showFilterMenu={false} showClearButton={false}
							/>
						<Column
							field="lagerumschlagshaeufigkeit"
							header="Lagerumschlags&shy;häufigkeit" filter
							body={rowData => rowData.lagerumschlagshaeufigkeit?.toLocaleString("de", { minimumFractionDigits: 0, maximumFractionDigits: 3 })}
							bodyStyle={{textAlign: "right"}}
							showFilterMenu={false} showClearButton={false}
							/>
					</DataTable>
				</div>
				{ selectedProdukt !== null &&
					<React.Fragment>
						<div className="col-6">
							<ZoomPopup header="Wareneingang Verlauf" footer={<Button label="Download" icon="pi pi-image" onClick={() => download(lineChartRef.current.getBase64Image(), 'Wareneingang Verlauf.png')}/>}>
								<Chart ref={lineChartRef} type="line" data={{labels: GetDatumsVerlauf(datumsbereich?.[0], datumsbereich?.[1]), datasets: lineChartDatasets}} options={{animation: {duration: 0}, plugins: {legend: {position: 'bottom'}}}}/>
							</ZoomPopup>
						</div>
						<div className="col-3">
							<ZoomPopup header="Verbrauch" footer={<Button label="Download" icon="pi pi-image" onClick={() => download(pieChartRef.current.getBase64Image(), 'Verbrauch.png')}/>}>
								<Chart ref={pieChartRef} type="pie" data={{labels: ["Verkauft", "Schwund", "Verderb", "Beschädigt"], datasets: [{data: [pieChartDatasets.verkauf, pieChartDatasets.schwund, pieChartDatasets.verderb, pieChartDatasets.beschaedigt], backgroundColor: ["#0cb015", "#bd2f2f", "#7a5430", "#9c8992"]}]}} options={{animation: {duration: 0}, plugins: {legend: {position: 'bottom', reverse: true}}}}/>
							</ZoomPopup>
						</div>
					</React.Fragment>
				}
			</div>
		</React.Fragment>
	);
}