import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "./GlobalState";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MitarbeiterService } from "../service/MitarbeiterService";
import { AuthService } from "../service/AuthService";
import { KassenService } from "../service/KassenService";
import { PopupMenu } from "./PopupMenu";
import { useHistory } from "react-router";
import { Button } from "primereact/button";

export function Benutzer() {
	const mitarbeiterService = new MitarbeiterService();
	const authService = new AuthService();
	const kassenService = new KassenService();

	const history = useHistory();

	const [globalState, setGlobalState] = useContext(GlobalState);
	const [mitarbeiter, setMitarbeiter] = useState([]);
	const [kassen, setKassen] = useState([]);

	useEffect(() => {
		setGlobalState({...globalState, page: 'Benutzer'});
		mitarbeiterService.getMitarbeiter().then(data => setMitarbeiter(data));
		kassenService.getKassen().then(data => setKassen(data));
		// eslint-disable-next-line
	}, []);

	const onLoeschenClick = rowData => {
		mitarbeiterService.mitarbeiterLoeschen(rowData.MitarbeiterNr).then(async () => {
			for (const url of kassen.map(k => k.url)) {
				kassenService.removeBerichteEmails(url, [rowData.Email]).catch(() => { });
				kassenService.removeWochenberichtEmails(url, [rowData.Email]).catch(() => { });
			}

			const token = await authService.getTokenInfo();
			if (token.id === rowData.MitarbeiterNr) {
				authService.logout();
				setGlobalState({...globalState});
			} else {
				mitarbeiterService.getMitarbeiter().then(data => setMitarbeiter(data));
			}
		});
	};
	
	return (
		<React.Fragment>
			<div className="p-component grid">
				<div className="col-12" style={{textAlign: 'right'}}>
					<Button label="Benutzer anlegen" icon="pi pi-plus-circle" onClick={() => history.push("/benutzer/neu")}/>
				</div>
				<div className="col-12">
					<DataTable value={mitarbeiter} responsive className="p-datatable-striped">
						<Column field="Vorname" header="Vorname" filter sortable filterMatchMode="contains"/>
						<Column field="Nachname" header="Nachname" filter sortable filterMatchMode="contains"/>
						<Column field="Benutzername" header="Benutzername" filter sortable filterMatchMode="contains"/>
						<Column field="Pos" header="Berechtigungen" filter sortable filterMatchMode="contains"/>
						<Column field="Lieferant" header="Lieferant" filter sortable filterMatchMode="contains"/>
						<Column header="Aktionen" body={rowData => <PopupMenu buttonClassName="p-button-secondary" label="Aktionen" icon="pi pi-chevron-down" model={[
							{ label: 'bearbeiten', icon: 'pi pi-pencil', command: () => history.push("/benutzer/" + rowData.MitarbeiterNr) },
							{ label: 'löschen', icon: 'pi pi-trash', command: () => onLoeschenClick(rowData) }
						]}/>} style={{width: '10em', textAlign: 'center'}}/>
					</DataTable>
				</div>
			</div>
		</React.Fragment>
	);
}