import React, { useRef, useState, useEffect, useContext } from 'react';
import { GlobalState } from './GlobalState';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from "primereact/inputnumber";
import { KassenService } from '../service/KassenService';
import { EinstellungenService } from '../service/EinstellungenService';

export default function Konto(props) {
	const labelWidth = '10em';

	const [globalState, setGlobalState] = useContext(GlobalState);
	const [kassen, setKassen] = useState([]);
	const [standortDialogVisible, setStandortDialogVisible] = useState(false);
	const [neuerStandort, setNeuerStandort] = useState();
	const [artikelKasse, setArtikelKasse] = useState();
	const [bestaendeKasse, setBestaendeKasse] = useState();
	const [ersteGutscheinAufladungMinBetrag, setErsteGutscheinAufladungMinBetrag] = useState();
	const [gutscheinExtraKostenWennUnterMin, setGutscheinExtraKostenWennUnterMin] = useState();
	const [gutscheinAblaufInJahren, setGutscheinAblaufInJahren] = useState();
	
	const kassenService = new KassenService();
	const einstellungenService = new EinstellungenService();

	const toastRef = useRef(null);

	function addKasse() {
		if (!(neuerStandort?.match(/^[a-zA-Z0-9-]+$/) ?? false)) {
			toastRef.current.show({ severity: "error", summary: "Der Standort konnte nicht angelegt werden", detail: "Der Standort darf nur aus den Zeichen a-z A-Z 0-9 und - bestehen", life: 5000, closable: false });
			return;
		}
		kassenService.addKasse(neuerStandort, artikelKasse, bestaendeKasse).then(() => {
			toastRef.current.show({severity:'success', summary: 'Standort angelegt', life: 2000});
			setStandortDialogVisible(false);
			kassenService.getKassen().then(data => setKassen(data));
		}).catch(() => {
			toastRef.current.show({ severity: "error", summary: "Der Standort konnte nicht angelegt werden", detail: "Der Standort existiert bereits", life: 5000, closable: false });
		});
	}

	function loadEinstellungen() {
		einstellungenService.GetEinstellung("ersteGutscheinAufladungMinBetrag").then(data => setErsteGutscheinAufladungMinBetrag(data ?? 10));
		einstellungenService.GetEinstellung("gutscheinExtraKostenWennUnterMin").then(data => setGutscheinExtraKostenWennUnterMin(data ?? 3));
		einstellungenService.GetEinstellung("gutscheinAblaufInJahren").then(data => setGutscheinAblaufInJahren(data ?? 2));
	}

	function saveEinstellungen() {
		einstellungenService.SetEinstellung("ersteGutscheinAufladungMinBetrag", ersteGutscheinAufladungMinBetrag);
		einstellungenService.SetEinstellung("gutscheinExtraKostenWennUnterMin", gutscheinExtraKostenWennUnterMin);
		einstellungenService.SetEinstellung("gutscheinAblaufInJahren", gutscheinAblaufInJahren);
	}

	useEffect(() => {
		setGlobalState({...globalState, page: 'Mein Konto'});
		kassenService.getKassen().then(data => setKassen(data));
		loadEinstellungen();
	}, []);

	return (
		<React.Fragment>
			<Toast ref={toastRef} />
			<div className="grid p-component">
				<div className="col-12">
					<Button label="Standort anlegen" icon="pi pi-plus-circle" onClick={() => { setStandortDialogVisible(true); setNeuerStandort(undefined); setArtikelKasse(undefined); setBestaendeKasse(undefined); }}/>
				</div>
				<div className="col-12">
					<h1>Einstellungen</h1>
					<table className="tablePadding">
						<tr>
							<td>
								<label htmlFor="ersteGutscheinAufladungMinBetrag">Gutschein Mindestbetrag beim ersten Aufladen</label>
							</td>
							<td>
								<InputNumber mode="decimal" locale="de-AT" minFractionDigits={2} maxFractionDigits={2} prefix="€ " inputId="ersteGutscheinAufladungMinBetrag" value={ersteGutscheinAufladungMinBetrag} onValueChange={e => setErsteGutscheinAufladungMinBetrag(e.value)}/>
							</td>
						</tr>
						<tr>
							<td>
								<label htmlFor="gutscheinExtraKostenWennUnterMin">Gutschein Extrakosten wenn unter dem<br/>Mindestbetrag beim ersten Aufladen</label>
							</td>
							<td>
								<InputNumber mode="decimal" locale="de-AT" minFractionDigits={2} maxFractionDigits={2} prefix="€ " inputId="gutscheinExtraKostenWennUnterMin" value={gutscheinExtraKostenWennUnterMin} onValueChange={e => setGutscheinExtraKostenWennUnterMin(e.value)}/>
							</td>
						</tr>
						<tr>
							<td>
								<label htmlFor="gutscheinAblaufInJahren">Gutschein Ablaufdauer in Jahren</label>
							</td>
							<td>
								<InputNumber inputId="gutscheinAblaufInJahren" value={gutscheinAblaufInJahren} onValueChange={e => setGutscheinAblaufInJahren(e.value)}/>
							</td>
						</tr>
					</table>
					<Button label="Speichern" icon="pi pi-save" onClick={saveEinstellungen}/>
				</div>
			</div>
			<Dialog visible={standortDialogVisible} onHide={() => setStandortDialogVisible(false)} header="Standort anlegen" footer={<Button label="Standort anlegen" onClick={addKasse}/>}>
				<div className="p-component">
					<div className="field grid">
						<label htmlFor="standort" className="col-fixed" style={{width: labelWidth}}>Standort:</label>
						<div className="col">
							<InputText id="standort" className={neuerStandort?.match(/^[a-zA-Z0-9-]+$/) ? '' : 'p-invalid'} value={neuerStandort} onChange={e => setNeuerStandort(e.target.value)} style={{width: '100%'}}/>
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="produkte" className="col-fixed" style={{width: labelWidth}}>Import Produkte:</label>
						<div className="col">
							<Dropdown inputId="produkte" value={artikelKasse} onChange={e => setArtikelKasse(e.target.value)} options={kassen} optionLabel="KassenID" optionValue="KassenID" showClear style={{width: '100%'}}/>
						</div>
					</div>
					<div className="field grid">
						<label htmlFor="bestaende" className="col-fixed" style={{width: labelWidth}}>Import Bestände:</label>
						<div className="col">
							<Dropdown inputId="bestaende" value={bestaendeKasse} onChange={e => setBestaendeKasse(e.target.value)} options={kassen} optionLabel="KassenID" optionValue="KassenID" showClear style={{width: '100%'}}/>
						</div>
					</div>
				</div>
			</Dialog>
		</React.Fragment>
	);
}